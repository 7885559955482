import React from 'react';
import {observer, inject} from 'mobx-react';
import EllipsisText from "react-ellipsis-text";
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import { Link, Redirect } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import moment from 'moment';
import MultiSelect from "react-multi-select-component";
import NumberFormat from 'react-number-format';
import queryString from 'query-string'

class TransactionHistory extends React.Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
	}
	
	componentDidMount() {
		this.props.TransactionHistoryStore.offsetFetch(0);
		this.props.TransactionHistoryStore.transactionListFetch('Transaction List');
		this.props.TransactionHistoryStore.transactionEarnListFetch('Transaction Earn List');
		this.props.TransactionHistoryStore.transactionSpentListFetch('Transaction Spent List');
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	
	handlePageClick = data => {
		let selected = data.selected;
		let limits = this.props.TransactionHistoryStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.TransactionHistoryStore.offsetFetch(offset); 
		this.props.TransactionHistoryStore.transactionListFetch('Transaction List');
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	handlePageEarnClick = data => {
		let selected = data.selected;
		let limits = this.props.TransactionHistoryStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.TransactionHistoryStore.offsetEarnFetch(offset); 
		this.props.TransactionHistoryStore.transactionEarnListFetch('Transaction Earn List');
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	handlePageSpentClick = data => {
		let selected = data.selected;
		let limits = this.props.TransactionHistoryStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.TransactionHistoryStore.offsetSpentFetch(offset); 
		this.props.TransactionHistoryStore.transactionSpentListFetch('Transaction Spent List');
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	render(){
		const store = this.props.TransactionHistoryStore;
		const skelrow = [1,2,3,4];

		return (
			<>
			<div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
			{store.auth ? <>
				
				<section className="products-page transactionhistory_page" id="products_list">
					<div className="container w1280  pl-0 pr-0">
					<h2 className="page-heading">Transaction History</h2>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><Link to="/">Home</Link></li>
							<li className="breadcrumb-item active" aria-current="page">Transaction History</li>
						</ol>
					</nav>
					</div>
					<div className="container w1280 pl-0 pr-0">
						<div className="row m-0">
							<div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 camp-left">
								<div className="campaign-search-wpr" id="campSticky">
										<div className="search-clear-wpr">
											<span className="seacrh-by">Filter</span>
											<span className="clear-all" id="clearAllFilter">Clear</span>
										</div>
										<div className="searched-cat">
										</div>
										
										<div className="form-group custom-group">
											<label htmlFor="countries" className="leftpanel-title">Transaction Type</label>
											<div className="tc-checkbox campaigns-checkbox">
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">Influencer Hired</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">Campaign Fees</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">Product Purchase</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">Product Sold</span>
													</label>
												</div>
											</div>
										</div>
										
										<div className="left-section-group">
											<h4 className="form-heading">Price</h4>
											<div className="price-slider">
												<InputRange
													maxValue={10000}
													minValue={0}
													step={1}
													value={store.valuePrice}
												 />
											</div>
										</div>

										<div className="form-group custom-group">
											<label htmlFor="countries" className="leftpanel-title">Timeline</label>
											<div className="tc-checkbox campaigns-checkbox">
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">December 2020</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">November 2020</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">October 2020</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input"/>
													<label className="form-check-label">
														<span className="checkbox-text">September 2020</span>
													</label>
												</div>
											</div>
										</div>
								</div>
							</div>
							
								<div className="product-list col-12 col-lg-9 col-xl-9 col-md-9 col-sm-12 ">
									<div className="transactionhistory-tabs-wrapper">
										<div className="tab-top-wpr">
											<ul className="nav nav-tabs custom-nav" id="myTab" role="tablist">
												<li className="nav-item">
													<a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All</a>
												</li>
												<li className="nav-item">
													<a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Earnings</a>
												</li>
												<li className="nav-item">
													<a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Spent</a>
												</li>
											</ul>
										</div>
										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
											<div className="product-header">
										<div className="campaign-header-left">
											<div className="search-inf-wpr">
											</div>
										</div>
										<div className="campaign-header-right">
											<div className="shortingselect-custom">
												<div className="form-group">
												<span className="fas down-arrow fa-chevron-down"></span>
												<label htmlFor="sort By">Sort By :</label>
												<select className="form-control" id="sortBy" name="sortBy">
													<option value="">Default sorting</option>
													<option selected=" " value="1">Default Sorting</option>
													<option selected="" value="2">Default Sorting</option>
												</select>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion-wrapper">
										<div id="accordion">
											{store.preloader ? <>
												{skelrow.map((skelrow,index) => ( 
													<div className="card" key={index}>
														<Skeleton width={945} height={81} />
													</div>
												))} 
											</> : <>
												{store.transaction.length !== 0 ? store.transaction.map((inf,index) =>
												<div className="card" key={index}>
													<div className="card-header" id={`heading${inf.id}`}>
														<div className="accordionlist-info" data-toggle="collapse" data-target={`#collapse${inf.id}`} aria-expanded="false" aria-controls={`collapse${inf.id}`}>
															<div className="accordionimg">
																{(inf.campaignId > 0 && inf.payeeOwnerId == store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Influencer-hired.svg`} alt="No Result" /> :
																(inf.campaignId > 0 && inf.payeeOwnerId != store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Campaign-fees.svg`} alt="No Result" /> :
																(inf.productId > 0 && inf.payeeOwnerId ==  store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Product-purchase.svg`} alt="No Result" />
																: 	<img src={`${process.env.PUBLIC_URL}/images/Product-purchase.svg`} alt="No Result" />}
																
															</div>
															<div className="listwrapper">
																<div className="listwrapper_left">
																{(inf.campaignId > 0 && inf.payeeOwnerId == store.userId) ? 'Influencer Hired' :
																(inf.campaignId > 0 && inf.payeeOwnerId != store.userId) ? 'Campaign Fees' :
																(inf.productId > 0 && inf.payeeOwnerId ==  store.userId) ? 'Product Purchase' : 'Product Sold'}
																</div>
																<div className="listwrapper_right">
																	<span className={inf.txnType == 'debit' ? 'lessamount' : 'addamount'}>
																	{inf.txnType == 'debit' ? '-' : '+'}
																	{inf.currency.toUpperCase()} <NumberFormat value={inf.amount} displayType={'text'} thousandSeparator={true} /></span>
																</div>
															</div>
															<div className="listwrapper_date">{moment(new Date(inf.createdAt)).format("D MMM YYYY")}, {moment(new Date(inf.createdAt)).format("h:mm A")}</div>
														</div>
													</div>

													<div id={`collapse${inf.id}`} className="collapse" aria-labelledby={`heading${inf.id}`} data-parent="#accordion">
														<div className="card-body">
															<div className="accordion-inner-wrapper">
															{inf.productId > 0 ?
																<div className="accordion-info-right">
																	<h2>Order ID: {inf.id}</h2>
																	<div className="productlist-img-wrapper">
																		<div className="productlist-img-avatar">
																		{inf.products.productImage &&
																			<img src={inf.products.productImage} width="97" height="97" alt="No Result" />
																		}
																		</div>
																		<div className="productlist-img-info">
																			<h2>{inf.products.productTitle}</h2>
																			<h3>{inf.products.productAmountCurrency} <NumberFormat value={inf.products.productAmount} displayType={'text'} thousandSeparator={true} /></h3>
																			{/*<p>Paid VIA Strip, Card ******4005</p>*/}
																			<Link to="#">View order details</Link>
																		</div>
																	</div>
																</div>
															: inf.campaignId > 0 ?
																<div className="accordion-info-right">
																	<h2>Order ID: {inf.id}</h2>
																	<div className="productlist-img-wrapper">
																		<div className="productlist-img-avatar">
																		{inf.campaigns.campaignImage &&
																			<img src={inf.campaigns.campaignImage} width="97" height="97" alt="No Result" />
																		}
																		</div>
																		<div className="productlist-img-info">
																			<h2>{inf.campaigns.campaignTitle}</h2>
																			<h3>{inf.campaigns.campaignAmountCurrency} <NumberFormat value={inf.campaigns.campaignAmount} displayType={'text'} thousandSeparator={true} /></h3>
																			{/*<p>Paid VIA Strip, Card ******4005</p>*/}
																			<Link to="#">View order details</Link>
																		</div>
																	</div>
																</div>
															: ''
															}
															</div>
														</div>
													</div>
												</div>
												):
												<div className="col-lg-12">
													 <div className="no-result-wpr">
														<div className="no-result-found">
															<div className="no-result-img">
																<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
															</div>
															<p className="no-result">Sorry, no result found</p>
														</div>
													</div>
												</div>
											}</>}

											</div>
											<div className="pg-wpr row">
											{store.totalCount > store.limit ?
												<>
												<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
													Showing 1 to {store.limit} of {store.totalCount} entries
												</div>
												<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
													<ReactPaginate
														previousLabel={'Previous'}
														nextLabel={'Next'}
														breakLabel={'...'}
														breakClassName={'break-me'}
														pageCount={store.pageCount}
														marginPagesDisplayed={2}
														pageRangeDisplayed={1}
														onPageChange={this.handlePageClick}
														containerClassName={'custom-pagination'}
														subContainerClassName={''}
														activeClassName={'active'}
													/>
												</div>
												</>
												:null}
											</div>
											</div>
											</div>
											<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
											<div className="product-header">
										<div className="campaign-header-left">
											<div className="search-inf-wpr">
											</div>
										</div>
										<div className="campaign-header-right">
											<div className="shortingselect-custom">
												<div className="form-group">
												<span className="fas down-arrow fa-chevron-down"></span>
												<label htmlFor="sort By">Sort By :</label>
												<select className="form-control" id="sortBy" name="sortBy">
													<option value="">Default sorting</option>
													<option selected=" " value="1">Default Sorting</option>
													<option selected="" value="2">Default Sorting</option>
												</select>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion-wrapper">
										<div id="accordion">
											{store.preloaderEarn ? <>
												{skelrow.map((skelrow,index) => ( 
													<div className="card" key={index}>
														<Skeleton width={945} height={81} />
													</div>
												))} 
											</> : <>
												{store.transactionEarn.length !== 0 ? store.transactionEarn.map((inf,index) =>
												<div className="card" key={index}>
													<div className="card-header" id={`headingearn${inf.id}`}>
														<div className="accordionlist-info" data-toggle="collapse" data-target={`#collapseearn${inf.id}`} aria-expanded="false" aria-controls={`collapseearn${inf.id}`}>
															<div className="accordionimg">
																{(inf.campaignId > 0 && inf.payeeOwnerId == store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Influencer-hired.svg`} alt="No Result" /> :
																(inf.campaignId > 0 && inf.payeeOwnerId != store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Campaign-fees.svg`} alt="No Result" /> :
																(inf.productId > 0 && inf.payeeOwnerId ==  store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Product-purchase.svg`} alt="No Result" />
																: 	<img src={`${process.env.PUBLIC_URL}/images/Product-purchase.svg`} alt="No Result" />}
																
															</div>
															<div className="listwrapper">
																<div className="listwrapper_left">
																{(inf.campaignId > 0 && inf.payeeOwnerId == store.userId) ? 'Influencer Hired' :
																(inf.campaignId > 0 && inf.payeeOwnerId != store.userId) ? 'Campaign Fees' :
																(inf.productId > 0 && inf.payeeOwnerId ==  store.userId) ? 'Product Purchase' : 'Product Sold'}
																</div>
																<div className="listwrapper_right">
																	<span className={inf.txnType == 'debit' ? 'lessamount' : 'addamount'}>
																	{inf.txnType == 'debit' ? '-' : '+'}
																	{inf.currency.toUpperCase()} <NumberFormat value={inf.amount} displayType={'text'} thousandSeparator={true} /></span>
																</div>
															</div>
															<div className="listwrapper_date">{moment(new Date(inf.createdAt)).format("D MMM YYYY")}, {moment(new Date(inf.createdAt)).format("h:mm A")}</div>
														</div>
													</div>

													<div id={`collapseearn${inf.id}`} className="collapse" aria-labelledby={`headingearn${inf.id}`} data-parent="#accordion">
														<div className="card-body">
															<div className="accordion-inner-wrapper">
															{inf.productId > 0 ?
																<div className="accordion-info-right">
																	<h2>Order ID: {inf.id}</h2>
																	<div className="productlist-img-wrapper">
																		<div className="productlist-img-avatar">
																		{inf.products.productImage &&
																			<img src={inf.products.productImage} width="97" height="97" alt="No Result" />
																		}
																		</div>
																		<div className="productlist-img-info">
																			<h2>{inf.products.productTitle}</h2>
																			<h3>{inf.products.productAmountCurrency} <NumberFormat value={inf.products.productAmount} displayType={'text'} thousandSeparator={true} /></h3>
																			{/*<p>Paid VIA Strip, Card ******4005</p>*/}
																			<Link to="#">View order details</Link>
																		</div>
																	</div>
																</div>
															: inf.campaignId > 0 ?
																<div className="accordion-info-right">
																	<h2>Order ID: {inf.id}</h2>
																	<div className="productlist-img-wrapper">
																		<div className="productlist-img-avatar">
																		{inf.campaigns.campaignImage &&
																			<img src={inf.campaigns.campaignImage} width="97" height="97" alt="No Result" />
																		}
																		</div>
																		<div className="productlist-img-info">
																			<h2>{inf.campaigns.campaignTitle}</h2>
																			<h3>{inf.campaigns.campaignAmountCurrency} <NumberFormat value={inf.campaigns.campaignAmount} displayType={'text'} thousandSeparator={true} /></h3>
																			{/*<p>Paid VIA Strip, Card ******4005</p>*/}
																			<Link to="#">View order details</Link>
																		</div>
																	</div>
																</div>
															: ''
															}
															</div>
														</div>
													</div>
												</div>
												):
												<div className="col-lg-12">
													 <div className="no-result-wpr">
														<div className="no-result-found">
															<div className="no-result-img">
																<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
															</div>
															<p className="no-result">Sorry, no result found</p>
														</div>
													</div>
												</div>
											}</>}
												</div>
											<div className="pg-wpr row">
											{store.totalCountEarn > store.limit ?
												<>
												<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
													Showing 1 to {store.limit} of {store.totalCountEarn} entries
												</div>
												<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
													<ReactPaginate
														previousLabel={'Previous'}
														nextLabel={'Next'}
														breakLabel={'...'}
														breakClassName={'break-me'}
														pageCount={store.pageCountEarn}
														marginPagesDisplayed={2}
														pageRangeDisplayed={1}
														onPageChange={this.handlePageEarnClick}
														containerClassName={'custom-pagination'}
														subContainerClassName={''}
														activeClassName={'active'}
													/>
												</div>
												</>
												:null}
											</div>
											</div>
								
											</div>
											<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
											<div className="product-header">
										<div className="campaign-header-left">
											<div className="search-inf-wpr">
											{/*<div>Showing 1–9 of 14 results</div>*/}
											</div>
										</div>
										<div className="campaign-header-right">
											<div className="shortingselect-custom">
												<div className="form-group">
												<span className="fas down-arrow fa-chevron-down"></span>
												<label htmlFor="sort By">Sort By :</label>
												<select className="form-control" id="sortBy" name="sortBy">
													<option value="">Default sorting</option>
													<option selected=" " value="1">Default Sorting</option>
													<option selected="" value="2">Default Sorting</option>
												</select>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion-wrapper">
										<div id="accordion">
												{store.preloaderSpent ? <>
												{skelrow.map((skelrow,index) => ( 
													<div className="card" key={index}>
														<Skeleton width={945} height={81} />
													</div>
												))} 
											</> : <>
												{store.transactionSpent.length !== 0 ? store.transactionSpent.map((inf,index) =>
												<div className="card" key={index}>
													<div className="card-header" id={`headingspent${inf.id}`}>
														<div className="accordionlist-info" data-toggle="collapse" data-target={`#collapsespent${inf.id}`} aria-expanded="false" aria-controls={`collapsespent${inf.id}`}>
															<div className="accordionimg">
																{(inf.campaignId > 0 && inf.payeeOwnerId == store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Influencer-hired.svg`} alt="No Result" /> :
																(inf.campaignId > 0 && inf.payeeOwnerId != store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Campaign-fees.svg`} alt="No Result" /> :
																(inf.productId > 0 && inf.payeeOwnerId ==  store.userId) ? 
																	<img src={`${process.env.PUBLIC_URL}/images/Product-purchase.svg`} alt="No Result" />
																: 	<img src={`${process.env.PUBLIC_URL}/images/Product-purchase.svg`} alt="No Result" />}
																
															</div>
															<div className="listwrapper">
																<div className="listwrapper_left">
																{(inf.campaignId > 0 && inf.payeeOwnerId == store.userId) ? 'Influencer Hired' :
																(inf.campaignId > 0 && inf.payeeOwnerId != store.userId) ? 'Campaign Fees' :
																(inf.productId > 0 && inf.payeeOwnerId ==  store.userId) ? 'Product Purchase' : 'Product Sold'}
																</div>
																<div className="listwrapper_right">
																	<span className={inf.txnType == 'debit' ? 'lessamount' : 'addamount'}>
																	{inf.txnType == 'debit' ? '-' : '+'}
																	{inf.currency.toUpperCase()} <NumberFormat value={inf.amount} displayType={'text'} thousandSeparator={true} /></span>
																</div>
															</div>
															<div className="listwrapper_date">{moment(new Date(inf.createdAt)).format("D MMM YYYY")}, {moment(new Date(inf.createdAt)).format("h:mm A")}</div>
														</div>
													</div>

													<div id={`collapsespent${inf.id}`} className="collapse" aria-labelledby={`headingspent${inf.id}`} data-parent="#accordion">
														<div className="card-body">
															<div className="accordion-inner-wrapper">
															{inf.productId > 0 ?
																<div className="accordion-info-right">
																	<h2>Order ID: {inf.id}</h2>
																	<div className="productlist-img-wrapper">
																		<div className="productlist-img-avatar">
																		{inf.products.productImage &&
																			<img src={inf.products.productImage} width="97" height="97" alt="No Result" />
																		}
																		</div>
																		<div className="productlist-img-info">
																			<h2>{inf.products.productTitle}</h2>
																			<h3>{inf.products.productAmountCurrency} <NumberFormat value={inf.products.productAmount} displayType={'text'} thousandSeparator={true} /></h3>
																			{/*<p>Paid VIA Strip, Card ******4005</p>*/}
																			<Link to="#">View order details</Link>
																		</div>
																	</div>
																</div>
															: inf.campaignId > 0 ?
																<div className="accordion-info-right">
															<h2>Order ID: {inf.id}</h2>
																	<div className="productlist-img-wrapper">
																		<div className="productlist-img-avatar">
																		{inf.campaigns.campaignImage &&
																			<img src={inf.campaigns.campaignImage} width="97" height="97" alt="No Result" />
																		}
																		</div>
																		<div className="productlist-img-info">
																			<h2>{inf.campaigns.campaignTitle}</h2>
																			<h3>{inf.campaigns.campaignAmountCurrency} <NumberFormat value={inf.campaigns.campaignAmount} displayType={'text'} thousandSeparator={true} /></h3>
																			{/*<p>Paid VIA Strip, Card ******4005</p>*/}
																			<Link to="#">View order details</Link>
																		</div>
																	</div>
																</div>
															: ''
															}
															</div>
														</div>
													</div>
												</div>
												):
												<div className="col-lg-12">
													 <div className="no-result-wpr">
														<div className="no-result-found">
															<div className="no-result-img">
																<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
															</div>
															<p className="no-result">Sorry, no result found</p>
														</div>
													</div>
												</div>
											}</>}
												</div>
											<div className="pg-wpr row">
											{store.totalCountSpent > store.limit ?
												<>
												<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
													Showing 1 to {store.limit} of {store.totalCountSpent} entries
												</div>
												<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
													<ReactPaginate
														previousLabel={'Previous'}
														nextLabel={'Next'}
														breakLabel={'...'}
														breakClassName={'break-me'}
														pageCount={store.pageCountSpent}
														marginPagesDisplayed={2}
														pageRangeDisplayed={1}
														onPageChange={this.handlePageSpentClick}
														containerClassName={'custom-pagination'}
														subContainerClassName={''}
														activeClassName={'active'}
													/>
												</div>
												</>
												:null}
											</div>
											</div>
								
											</div>
										</div>
									</div>
								</div>	 
						</div>
					</div>
				</section>
				</> : <Redirect to="/" />}
            </>
		)
	}
}
export default inject('TransactionHistoryStore')(observer(TransactionHistory));