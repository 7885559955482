import {action, observable, decorate} from 'mobx';
import { productListResult } from '../api/ProductListApi';

class ProductNewStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	product = [];
	
	newProduct = () => {
		let param1 = {where:{productStatus:1,stockCount:{gt:0}},limit:10,order:"id DESC"}
		productListResult(JSON.stringify(param1))
			.then(res => {
				console.log(res)
				this.productFetch(res.data);
			}).catch(error=>console.log(error))
	}
	
	productFetch = (data) => {
		this.product = data;
	}
}

decorate(ProductNewStore, {
	product: observable,
	productFetch: action,
});

export default ProductNewStore;