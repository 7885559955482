import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Search from './Search';

class Carousel extends Component {
  render() {
    return (
      <div className="carousel-wrapper">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="carousel-image-one">
                <div className="carousel-caption">
                  <div className="carousel-caption-inner">
                    <h2>Become An Influencer And Build Your Own Followers In KOLI</h2>
                    <p>Discover the latest trend in KOLI and what’s hot now. Find your interest and live out your best life with great ideas from our Influencers. Share your passion and inspire other people.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-image-two">
                <div className="carousel-caption">
                  <div className="carousel-caption-inner">
                    <h2>Brands - Triple Your ROI From KOLI Influencer Collaborations</h2>
                    <p>Collaborate with Influencers to promote your brand, products and services internationally.</p>
					<p>Create a customized campaign and choose your favorite influencers to promote your brand. </p>
					<p>Create a loyal following from our user base.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-image-three">
                <div className="carousel-caption">
                  <div className="carousel-caption-inner">
                    <h2>Influencers - Get Opportunity To Collaborate With</h2>
                    <h2>International Brands in KOLI</h2>
                    <p>Join KOLI’s awesome Influencer community. Showcase your talents, connect with your favorite brands and influencers. Grow your followers and increase engagement with your fans.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        {/*<Search/>*/}
      </div>
    );
  }
}

export default Carousel;