import React, { Component } from 'react';
import {observer, inject} from 'mobx-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { GEO_API } from '../geoApi';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import { TagInput } from 'reactjs-tag-input';
import Moment from 'moment';
import FileBase64 from 'react-file-base64';
import MultiSelect from "react-multi-select-component";
import NumericLabel from 'react-pretty-numbers';
import ShowMoreText from 'react-show-more-text';
import NumberFormat from 'react-number-format';

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class DraftCampaigns extends Component{

    constructor(props) {
        super(props); 
		this.openCreateCampPopup = this.openCreateCampPopup.bind(this);			
    }

    componentDidMount(){
        this.props.CreatecampaignListStore.fetchCountries();               
        this.props.CreatecampaignListStore.fetchCategories();                      
    } 

	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
	
	openCreateCampPopup = () =>{
		this.props.CreatecampaignListStore.openCreateCampPopupNew('draftnew');
		this.props.CreatecampaignListStore.closeDraftCampPopup();
	}

    render(){
        const getUploadParams = ({ meta }) => {
            const url = `${GEO_API}`
            return { url, meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` } }
        }
        
        const handleChangeStatus = ({ meta }, status) => {
            console.log(status, meta)
        }
        
        const handleSubmit = (files, allFiles) => { 
            console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
        }

         
          const store = this.props.CreatecampaignListStore;
        return (
            <> 
                <section className={store.campaignsDraftPopup} id="createDraftPopup">
                    <div className="row">
                        <div className="create-right createrightDraft">
                        {/* <a class="slide" href="#">Slide Out</a> */}
							{/*<a className="draftsave" href="#">
                            <img src={`${process.env.PUBLIC_URL}/images/close-slider.svg`} alt=""  className={store.closeDraftCampPopup}/>
							</a>*/} 
						
						<span onClick={store.closeDraftCampPopup} className="slidenew closeinpput">
                            <img src={`${process.env.PUBLIC_URL}/images/close-slider.svg`} alt=""  className={store.closeDraftCampPopup} />
                        </span>

						<div className="inner-create-right box" id="fade-in">
							<div id="draftCampaigns">
								<h2 className="page-heading">Create Campaign</h2>
								<img src={`${process.env.PUBLIC_URL}/images/draft-logo.svg`} alt=""  className="close-campaign img-fluid"/>
								<h3>Six Smart Ways to Write a Powerful Fundraiser Title - GoFundMe</h3>
								<div className="readcareful-wrapper">
									<h4>Read carefully and move on</h4>
									<button className="continue-drafted" onClick={store.openCreateCampPopupDraft}>Continue with the drafted campaign <img src={`${process.env.PUBLIC_URL}/images/draft-btn-arrow.svg`} alt="" /></button> 
									<h5>Or</h5>
									<button className="continue-drafted" onClick={this.openCreateCampPopup}>Discard this draft and create new <img src={`${process.env.PUBLIC_URL}/images/draft-btn-arrow.svg`} alt="" /></button> 
								</div>
							</div>
						</div>
                                             
                            {/*<div className="inner-create-right box" id="fade-in">
                                 <img src={`${process.env.PUBLIC_URL}/images/draft-logo.svg`} alt=""  className="close-campaign"/> */}

                            
                            

                           
                              

                                {/* Draft Camppaigns 
                                <div className={store.draftCampaigns} id="draftCampaigns">
                                    <h2 className="page-heading">Create Campaign</h2>
                                    <div className="no-image d-flex align-items-center justify-content-center">
                                        <img src={`${process.env.PUBLIC_URL}/images/no-img.svg`} alt="" /> 
                                    </div>
                                    <h3>Six Smart Ways to Write a Powerful Fundraiser Title - GoFundMe</h3>
                                    <p className="text-center">Read carefully and move on</p>

                                    <div className="text-center">
                                        <button className="btn btn-primary">Continue with the drafted campaign<i className="fas ml-2 fa-angle-double-right"></i></button>
                                    </div>
                                    <p className="mt-3 mb-3 text-center">or</p>
                                    <div className="text-center">
                                    <button className="btn btn-primary">Discard this draft and create new<i className="fas ml-2 fa-angle-double-right"></i></button>
                                    </div>
                                </div>

                            </div>*/}

                        </div>
                    </div>

                 
                    {/* Close POP UP */}
                    <Popup
                     open={store.open}
                     closeOnDocumentClick
                     onClose={store.closeDraftCampPopup}
                    >
                    <div className="custom-popup text-center">
                       <div className="popup-details">
                            <img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
                            <p className="popup-details mb-4">Are you sure you want to Cancel?</p>
                            <button onClick={store.closeDraftCampPopup} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
                            <button onClick={store.closeDraftCampPopup} className="btn btn-primary">Ok</button>
                        </div>
                        
                    </div>
                    </Popup>
                        
                </section>
            </>
        )
    }
    
}
export default inject('CreatecampaignListStore') (observer(DraftCampaigns))