import {action, observable, decorate} from 'mobx';
import { campaignCountResult } from '../api/CampaignCountApi';
import { campaignListResult } from '../api/CampaignListApi';
import { categoryResult } from '../api/CampaignCategoryApi';
import { countriesResult } from '../api/CountryApi';
import moment from 'moment';

class CampaignListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	campaign = [];
	offset = 0;
	totalCount = '';
	limit = 12;
	categoryFilter = [];
	category = [];
	type = [];
	countryFilter = [];
	gender = '';
	campaignListFilter = [];
	checkSearch = false;
	totalCountFilter = '';
	valuePrice = {min:0, max:1000000};
	priceSort = '';
	preloader = false;
	search = '';
	searchKeyword = false;
	currentDate = moment().format('YYYY-MM-DD');
	selected = 0;
	checkedItems = {};
	checkedType = {};

	handleSubmit = (event) => {
		this.preloaderFetch(true);
		this.checkSearchFetch(true);
		let param;
		let count;
		let priceOrder;
		let search;
		let checkCountry;
		let checkSearch;
		let checkGender;
		let checkCategory;
		let checkType;
		
		let pattern =  '%25'+this.search+'%25'; 
		
		if(this.priceSort === '1'){
			priceOrder = "campaignAmount ASC";
		}else if(this.priceSort === '2'){
			priceOrder = "campaignAmount DESC";
		}else{
			priceOrder = ["featurePosition ASC"];
		}

		if(this.gender){
			checkGender = this.gender
		}else{
			checkGender = {neq:null}
		}
		
		if(this.category){
			let scat = [];
			this.category.map(function(item,index){
				scat[index]='[\"'+item+'\"]';
			});

			if(scat.length > 0){
				checkCategory = {inq:scat}
			}else{
				checkCategory = {neq:null}
			}
		}else{
			checkCategory = {neq:null}
		}
		
		if(this.type){
			let scat = [];
			this.type.map(function(item,index){
				scat[index]=item;
			});

			if(scat.length > 0){
				checkType = {inq:scat}
			}else{
				checkType = {neq:null}
			}
		}else{
			checkType = {neq:null}
		}
		
		if(this.search){
			param = {include:['remarks','profile'],where:{campaignTitle:{like:pattern},campaignType:checkType,lookingInfluencerGender:checkGender,campaignAmount:{between: [this.valuePrice.min,this.valuePrice.max]},isDisabled:0,campaignCategories:checkCategory,campaignStatus:{gt: 1},endStoryPostDate: {gte: this.currentDate}},order:priceOrder,offset:this.offset,limit:this.limit}
			
			count = {campaignTitle:{like:pattern},campaignType:checkType,lookingInfluencerGender:checkGender,campaignAmount:{between: [this.valuePrice.min,this.valuePrice.max]},order:priceOrder,isDisabled:0,campaignCategories:checkCategory,campaignStatus:{gt: 1},endStoryPostDate: {gte: this.currentDate}}
			
		}else{
			param = {include:['remarks','profile'],where:{campaignType:checkType,lookingInfluencerGender:checkGender,campaignAmount:{between: [this.valuePrice.min,this.valuePrice.max]},isDisabled:0,campaignCategories:checkCategory,campaignStatus:{gt: 1},endStoryPostDate: {gte: this.currentDate}},order:priceOrder,offset:this.offset,limit:this.limit}
			
			count = {campaignType:checkType,lookingInfluencerGender:checkGender,campaignAmount:{between: [this.valuePrice.min,this.valuePrice.max]},order:priceOrder,isDisabled:0,campaignCategories:checkCategory,campaignStatus:{gt: 1},endStoryPostDate: {gte: this.currentDate}}
		}
		
		campaignListResult(JSON.stringify(param))
			.then(res => {
				campaignCountResult(JSON.stringify(count))
					.then(res1 => { console.log('data', res.data.campaignAmount);
						this.campaignListFilterFetch(res.data);
						this.pageCountFilterFetch(Math.ceil(res1.data.count / this.limit));
						this.totalCountFilterFetch(res1.data.count);
						this.preloaderFetch(false);
					}).catch(error=>console.log(error))
			}).catch(error=>console.log(error))
	}
	
	fetchCategories = () => {
		let param = {where:{categoryStatus:1}}
		categoryResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.categoryFilterFetch(res.data);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	categoryUnset = (data) => {
		if(this.category.indexOf(data) === -1){
			this.category.push(data)
		}else{
			for( var i = 0; i < this.category.length; i++){ 
				if ( this.category[i] === data) { 
					this.category.splice(i, 1); 
				}
			}
		}
		const isChecked = false;
		const {checkedItems} = this
		checkedItems[data] = isChecked
		this.checkedItemsFetch(checkedItems);
		this.offsetFetch(0);
		this.handleSubmit();
		this.preloaderFetch(true);
	}
	
	changeCategory = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const {checkedItems} = this
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
		if(this.category.indexOf(item) === -1){
			this.category.push(item)
		}else{
			for( var i = 0; i < this.category.length; i++){ 
				if ( this.category[i] === item) { 
					this.category.splice(i, 1); 
				}
			}
		}
		this.offsetFetch(0);
		this.pageCountFilterFetch('');
		this.totalCountFilterFetch('');
		this.handleSubmit();
		this.preloaderFetch(true);
	}
	
	changeType = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const {checkedType} = this
		checkedType[item] = isChecked
		console.log(checkedType)
		this.checkedTypeFetch(checkedType);
		if(this.type.indexOf(item) === -1){
			this.type.push(item)
		}else{
			for( var i = 0; i < this.type.length; i++){ 
				if ( this.type[i] === item) { 
					this.type.splice(i, 1); 
				}
			}
		}
		this.offsetFetch(0);
		this.pageCountFilterFetch('');
		this.totalCountFilterFetch('');
		this.handleSubmit();
		this.preloaderFetch(true);
	}
	
	typeUnset = (data) => {
		if(this.type.indexOf(data) === -1){
			this.type.push(data)
		}else{
			for( var i = 0; i < this.type.length; i++){ 
				if ( this.type[i] === data) { 
					this.type.splice(i, 1); 
				}
			}
		}
		const isChecked = false;
		const {checkedType} = this
		checkedType[data] = isChecked
		this.checkedTypeFetch(checkedType);
		this.offsetFetch(0);
		this.handleSubmit();
		this.preloaderFetch(true);
	}

	clearAllFilter = (newtype) => {
		let category = this.category
		let type 	 = this.type

		const isChecked = false;
		const {checkedItems} = this
		checkedItems[category] = isChecked
		this.checkedItemsFetch(checkedItems);

		const {checkedType} = this
		checkedType[type] = isChecked
		this.checkedTypeFetch(checkedType);
		this.categoryFetch([]);
		this.typeFetch([]);
		
		if(newtype){
			let item = newtype;
			let isChecked = true;
			let {checkedType} = this
			checkedType[item] = isChecked

			this.checkedTypeFetch(checkedType);
			if(this.type.indexOf(item) === -1){
				this.type.push(item)
			}else{
				for( var i = 0; i < this.type.length; i++){ 
					if ( this.type[i] === item) { 
						this.type.splice(i, 1); 
					}
				}
			}
		}
		
		this.genderFetch('');
		this.priceSortFetch('');
		this.offsetFetch(0);
		this.pageCountFilterFetch('');
		this.totalCountFilterFetch('');
		this.valuePriceFetch({min:0, max:1000000});
		this.handleSubmit();
		this.preloaderFetch(true);
	}

	campaignList	 	= (data) => {this.campaign = data;}
	offsetFetch 		= (data) => {this.offset = data;}
	pageCountFetch 		= (data) => {this.pageCount = data;}
	totalCountFetch		= (data) => {this.totalCount = data;}
	categoryFilterFetch = (data) => {this.categoryFilter = data;}
	categoryFetch 		= (data) => {this.category = data;}
	typeFetch 			= (data) => {this.type = data;}
	countryFilterFetch 	= (data) => {this.countryFilter = data;}
	genderFetch 		= (data) => {this.gender = data;}
	
	campaignListFilterFetch = (data) => {this.campaignListFilter = data;}
	pageCountFilterFetch 	= (data) => {this.pageCountFilter = data;}
	totalCountFilterFetch 	= (data) => {this.totalCountFilter = data;}
	checkSearchFetch 		= (data) => {this.checkSearch = data;}
	priceSortFetch 			= (data) => {this.priceSort = data;}
	preloaderFetch 			= (data) => {this.preloader = data;}
	searchFetch 			= (data) => {this.search = data;}
	searchKeywordFetch 		= (data) => {this.searchKeyword = data;}
	valuePriceFetch 		= (data) => {this.valuePrice = data;}
	selectedFetch 			= (data) => {this.selected = data;}
	checkedItemsFetch 		= (data) => {this.checkedItems = data; }
	checkedTypeFetch 		= (data) => {this.checkedType = data; }

}

decorate(CampaignListStore, {
	auth:observable,userId:observable,type:observable,checkedType:observable,checkedItems:observable,selected:observable,campaign: observable,campaignListFilter:observable,offset: observable,totalCount: observable,pageCount:observable,limit: observable,categoryFilter: observable,category: observable,gender: observable,totalCountFilter: observable,pageCountFilter: observable,checkSearch: observable,priceSort:observable,preloader:observable,search: observable,searchKeyword:observable,valuePrice:observable,currentDate:observable,campaignList: action,offsetFetch: action,totalCountFetch: action,categoryFilterFetch: action,categoryFetch: action,handleSubmit: action,genderFetch: action,campaignListFilterFetch: action,pageCountFilterFetch: action,totalCountFilterFetch: action,checkSearchFetch: action,priceSortFetch:action,preloaderFetch:action,pageCountFetch: action, searchFetch:action,searchKeywordFetch:action,valuePriceFetch:action,selectedFetch:action,checkedItemsFetch:action,changeCategory:action,categoryUnset:action,checkedTypeFetch:action,typeFetch:action,typeUnset:action,changeType:action,clearAllFilter:action,newType:action
});

export default CampaignListStore;