import React from 'react';
import { Link } from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

class News extends React.Component{
	
	componentDidMount() {
		this.props.NewsStore.newsGet('News');;
	}
	
	render(){
		const store = this.props.NewsStore;
		return (
			<React.Fragment>
			{/* News Section Start */}
				<section className="container news-wpr">
					<div className="inner-news-wpr">
						<div className="heading">
							<h2> Our News Articles &amp; Tips</h2>
							<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
						</div>
						<div className="news-section">
							{store.news.length !== 0 ? store.news.slice(0,1).map((inf,index) =>
							<div className="news-items item1" key={index}>
								<div className="news-img-wpr">
									<img src={inf.image} alt="" />
								</div>
								<div className="news-details">
									<h3><Link to="#">{inf.title}</Link></h3>
									{ReactHtmlParser(inf.description)}
									<div>
										<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
											<g id="calc" transform="translate(-485 -1775)">
											  <rect id="Rectangle_4025" data-name="Rectangle 4025" width="16" height="16" transform="translate(485 1775)" fill="none"/>
											  <path id="Path_7969" data-name="Path 7969" d="M11.922-7.875A.328.328,0,0,0,12.25-8.2v-.984A1.312,1.312,0,0,0,10.938-10.5H9.625v-1.422A.328.328,0,0,0,9.3-12.25H8.2a.328.328,0,0,0-.328.328V-10.5h-3.5v-1.422a.328.328,0,0,0-.328-.328H2.953a.328.328,0,0,0-.328.328V-10.5H1.313A1.312,1.312,0,0,0,0-9.187V-8.2a.328.328,0,0,0,.328.328ZM.328-7A.328.328,0,0,0,0-6.672V.438A1.312,1.312,0,0,0,1.313,1.75h9.625A1.312,1.312,0,0,0,12.25.438V-6.672A.328.328,0,0,0,11.922-7ZM9.442-4.376a.328.328,0,0,1,0,.464L5.531-.035a.328.328,0,0,1-.464,0L2.808-2.314a.328.328,0,0,1,0-.464l.776-.77a.328.328,0,0,1,.464,0L5.308-2.279l2.9-2.876a.328.328,0,0,1,.464,0Z" transform="translate(487 1788)" fill="#6b6a77"/>
											</g>
										  </svg> calendar-check</span>
										<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
											<g id="category" transform="translate(-602 -1775)">
											  <path id="Path_7970" data-name="Path 7970" d="M8.094-11.375a.656.656,0,0,0-.656.656v4.375a.656.656,0,0,0,.656.656h5.25A.656.656,0,0,0,14-6.344v-4.375a.656.656,0,0,0-.656-.656Zm-2.187,0H.656A.656.656,0,0,0,0-10.719v4.375a.656.656,0,0,0,.656.656h5.25a.656.656,0,0,0,.656-.656v-4.375A.656.656,0,0,0,5.906-11.375ZM0-4.156V.219A.656.656,0,0,0,.656.875h5.25A.656.656,0,0,0,6.563.219V-4.156a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0-4.156ZM8.094.875h5.25A.656.656,0,0,0,14,.219V-4.156a.656.656,0,0,0-.656-.656H8.094a.656.656,0,0,0-.656.656V.219A.656.656,0,0,0,8.094.875Z" transform="translate(603 1788)" fill="#6b6a77"/>
											  <rect id="Rectangle_4026" data-name="Rectangle 4026" width="16" height="16" transform="translate(602 1775)" fill="none"/>
											</g>
										  </svg>  {moment(new Date(inf.news_date)).format("MMMM D, YYYY")}</span>
									</div>
								</div>
							</div>
							):
							<div className="news-items border-0 item1">
								<div className="news-img-wpr">
									<Skeleton  height={298} width={360} />
								</div>
								<div className="news-details">
									<Skeleton count={14}/>
								</div>
							</div>
							}
							{store.news.length !== 0 ? store.news.slice(1,2).map((inf,index) =>
							<div className="news-items item2" key={index}>
								<div className="news-img-wpr">
									<img src={inf.image} alt="" />
								</div>
								<div className="image-details">
									<div className="inner-details">
										<h3><Link to="#">{inf.title}</Link></h3>
										<div>
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<g id="calc" transform="translate(-485 -1775)">
												  <rect id="Rectangle_4025" data-name="Rectangle 4025" width="16" height="16" transform="translate(485 1775)" fill="none"/>
												  <path id="Path_7969" data-name="Path 7969" d="M11.922-7.875A.328.328,0,0,0,12.25-8.2v-.984A1.312,1.312,0,0,0,10.938-10.5H9.625v-1.422A.328.328,0,0,0,9.3-12.25H8.2a.328.328,0,0,0-.328.328V-10.5h-3.5v-1.422a.328.328,0,0,0-.328-.328H2.953a.328.328,0,0,0-.328.328V-10.5H1.313A1.312,1.312,0,0,0,0-9.187V-8.2a.328.328,0,0,0,.328.328ZM.328-7A.328.328,0,0,0,0-6.672V.438A1.312,1.312,0,0,0,1.313,1.75h9.625A1.312,1.312,0,0,0,12.25.438V-6.672A.328.328,0,0,0,11.922-7ZM9.442-4.376a.328.328,0,0,1,0,.464L5.531-.035a.328.328,0,0,1-.464,0L2.808-2.314a.328.328,0,0,1,0-.464l.776-.77a.328.328,0,0,1,.464,0L5.308-2.279l2.9-2.876a.328.328,0,0,1,.464,0Z" transform="translate(487 1788)" fill="#ffffff"/>
												</g>
											  </svg>
											   calendar-check</span>
											<span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<g id="category" transform="translate(-602 -1775)">
												  <path id="Path_7970" data-name="Path 7970" d="M8.094-11.375a.656.656,0,0,0-.656.656v4.375a.656.656,0,0,0,.656.656h5.25A.656.656,0,0,0,14-6.344v-4.375a.656.656,0,0,0-.656-.656Zm-2.187,0H.656A.656.656,0,0,0,0-10.719v4.375a.656.656,0,0,0,.656.656h5.25a.656.656,0,0,0,.656-.656v-4.375A.656.656,0,0,0,5.906-11.375ZM0-4.156V.219A.656.656,0,0,0,.656.875h5.25A.656.656,0,0,0,6.563.219V-4.156a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0-4.156ZM8.094.875h5.25A.656.656,0,0,0,14,.219V-4.156a.656.656,0,0,0-.656-.656H8.094a.656.656,0,0,0-.656.656V.219A.656.656,0,0,0,8.094.875Z" transform="translate(603 1788)" fill="#ffffff"/>
												  <rect id="Rectangle_4026" data-name="Rectangle 4026" width="16" height="16" transform="translate(602 1775)" fill="none"/>
												</g>
											  </svg> {moment(new Date(inf.news_date)).format("MMMM D, YYYY")}</span>
										</div>
									</div>
								</div>
							</div>
							):
							<div className="news-items  border-0 item2">
								<div className="news-img-wpr">
									<Skeleton  height={298} width={360} />
								</div>
							</div>}
							{store.news.length !== 0 ? store.news.slice(2,3).map((inf,index) =>
							<div className="news-items item3" key={index}>
								<div className="news-img-wpr">
									<img src={inf.image} alt="" />
								</div>
								<div className="image-details">
									<div className="inner-details">
										<h3><Link to="#">{inf.title}</Link></h3>
										<div>
											<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<g id="calc" transform="translate(-485 -1775)">
												  <rect id="Rectangle_4025" data-name="Rectangle 4025" width="16" height="16" transform="translate(485 1775)" fill="none"/>
												  <path id="Path_7969" data-name="Path 7969" d="M11.922-7.875A.328.328,0,0,0,12.25-8.2v-.984A1.312,1.312,0,0,0,10.938-10.5H9.625v-1.422A.328.328,0,0,0,9.3-12.25H8.2a.328.328,0,0,0-.328.328V-10.5h-3.5v-1.422a.328.328,0,0,0-.328-.328H2.953a.328.328,0,0,0-.328.328V-10.5H1.313A1.312,1.312,0,0,0,0-9.187V-8.2a.328.328,0,0,0,.328.328ZM.328-7A.328.328,0,0,0,0-6.672V.438A1.312,1.312,0,0,0,1.313,1.75h9.625A1.312,1.312,0,0,0,12.25.438V-6.672A.328.328,0,0,0,11.922-7ZM9.442-4.376a.328.328,0,0,1,0,.464L5.531-.035a.328.328,0,0,1-.464,0L2.808-2.314a.328.328,0,0,1,0-.464l.776-.77a.328.328,0,0,1,.464,0L5.308-2.279l2.9-2.876a.328.328,0,0,1,.464,0Z" transform="translate(487 1788)" fill="#ffffff"/>
												</g>
											  </svg>calendar-check</span>
											<span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<g id="category" transform="translate(-602 -1775)">
												  <path id="Path_7970" data-name="Path 7970" d="M8.094-11.375a.656.656,0,0,0-.656.656v4.375a.656.656,0,0,0,.656.656h5.25A.656.656,0,0,0,14-6.344v-4.375a.656.656,0,0,0-.656-.656Zm-2.187,0H.656A.656.656,0,0,0,0-10.719v4.375a.656.656,0,0,0,.656.656h5.25a.656.656,0,0,0,.656-.656v-4.375A.656.656,0,0,0,5.906-11.375ZM0-4.156V.219A.656.656,0,0,0,.656.875h5.25A.656.656,0,0,0,6.563.219V-4.156a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0-4.156ZM8.094.875h5.25A.656.656,0,0,0,14,.219V-4.156a.656.656,0,0,0-.656-.656H8.094a.656.656,0,0,0-.656.656V.219A.656.656,0,0,0,8.094.875Z" transform="translate(603 1788)" fill="#ffffff"/>
												  <rect id="Rectangle_4026" data-name="Rectangle 4026" width="16" height="16" transform="translate(602 1775)" fill="none"/>
												</g>
											  </svg> {moment(new Date(inf.news_date)).format("MMMM D, YYYY")}</span>
										</div>
									</div>
								</div>
							</div>
							):<div className="news-items border-0 item3">
								<div className="news-img-wpr">
									<Skeleton  height={298} width={360} />
								</div>
							</div>}
							{store.news.length !== 0 ? store.news.slice(3,4).map((inf,index) =>
							<div className="news-items item4" key={index}>
								<div className="news-img-wpr">
									<img className="news-img" src={inf.image} alt="" />
								</div>
								<div className="image-details">
									<div className="inner-details">
										<h3><Link to="#">{inf.title}</Link></h3>
										<div>
											<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<g id="calc" transform="translate(-485 -1775)">
												  <rect id="Rectangle_4025" data-name="Rectangle 4025" width="16" height="16" transform="translate(485 1775)" fill="none"/>
												  <path id="Path_7969" data-name="Path 7969" d="M11.922-7.875A.328.328,0,0,0,12.25-8.2v-.984A1.312,1.312,0,0,0,10.938-10.5H9.625v-1.422A.328.328,0,0,0,9.3-12.25H8.2a.328.328,0,0,0-.328.328V-10.5h-3.5v-1.422a.328.328,0,0,0-.328-.328H2.953a.328.328,0,0,0-.328.328V-10.5H1.313A1.312,1.312,0,0,0,0-9.187V-8.2a.328.328,0,0,0,.328.328ZM.328-7A.328.328,0,0,0,0-6.672V.438A1.312,1.312,0,0,0,1.313,1.75h9.625A1.312,1.312,0,0,0,12.25.438V-6.672A.328.328,0,0,0,11.922-7ZM9.442-4.376a.328.328,0,0,1,0,.464L5.531-.035a.328.328,0,0,1-.464,0L2.808-2.314a.328.328,0,0,1,0-.464l.776-.77a.328.328,0,0,1,.464,0L5.308-2.279l2.9-2.876a.328.328,0,0,1,.464,0Z" transform="translate(487 1788)" fill="#ffffff"/>
												</g>
											  </svg>calendar-check</span>
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<g id="category" transform="translate(-602 -1775)">
												  <path id="Path_7970" data-name="Path 7970" d="M8.094-11.375a.656.656,0,0,0-.656.656v4.375a.656.656,0,0,0,.656.656h5.25A.656.656,0,0,0,14-6.344v-4.375a.656.656,0,0,0-.656-.656Zm-2.187,0H.656A.656.656,0,0,0,0-10.719v4.375a.656.656,0,0,0,.656.656h5.25a.656.656,0,0,0,.656-.656v-4.375A.656.656,0,0,0,5.906-11.375ZM0-4.156V.219A.656.656,0,0,0,.656.875h5.25A.656.656,0,0,0,6.563.219V-4.156a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0-4.156ZM8.094.875h5.25A.656.656,0,0,0,14,.219V-4.156a.656.656,0,0,0-.656-.656H8.094a.656.656,0,0,0-.656.656V.219A.656.656,0,0,0,8.094.875Z" transform="translate(603 1788)" fill="#ffffff"/>
												  <rect id="Rectangle_4026" data-name="Rectangle 4026" width="16" height="16" transform="translate(602 1775)" fill="none"/>
												</g>
											  </svg> {moment(new Date(inf.news_date)).format("MMMM D, YYYY")}</span>
										</div>
									</div>
								</div>
							</div>
							):
							<div className="news-items border-0 item4">
								<div className="news-img-wpr">
									<Skeleton  height={298} width={360} />
								</div>
							</div>}
							{store.news.length !== 0 ? store.news.slice(4,5).map((inf,index) =>
							<div className="news-items item5" key={index}>
								<div className="news-details">
									<h3><Link to="#">{inf.title}</Link></h3>
									{ReactHtmlParser(inf.description)}
									<div>
										<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
											<g id="calc" transform="translate(-485 -1775)">
												<rect id="Rectangle_4025" data-name="Rectangle 4025" width="16" height="16" transform="translate(485 1775)" fill="none"/>
												<path id="Path_7969" data-name="Path 7969" d="M11.922-7.875A.328.328,0,0,0,12.25-8.2v-.984A1.312,1.312,0,0,0,10.938-10.5H9.625v-1.422A.328.328,0,0,0,9.3-12.25H8.2a.328.328,0,0,0-.328.328V-10.5h-3.5v-1.422a.328.328,0,0,0-.328-.328H2.953a.328.328,0,0,0-.328.328V-10.5H1.313A1.312,1.312,0,0,0,0-9.187V-8.2a.328.328,0,0,0,.328.328ZM.328-7A.328.328,0,0,0,0-6.672V.438A1.312,1.312,0,0,0,1.313,1.75h9.625A1.312,1.312,0,0,0,12.25.438V-6.672A.328.328,0,0,0,11.922-7ZM9.442-4.376a.328.328,0,0,1,0,.464L5.531-.035a.328.328,0,0,1-.464,0L2.808-2.314a.328.328,0,0,1,0-.464l.776-.77a.328.328,0,0,1,.464,0L5.308-2.279l2.9-2.876a.328.328,0,0,1,.464,0Z" transform="translate(487 1788)" fill="#6b6a77"/>
											</g>
											</svg> calendar-check</span>
										<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
											<g id="category" transform="translate(-602 -1775)">
												<path id="Path_7970" data-name="Path 7970" d="M8.094-11.375a.656.656,0,0,0-.656.656v4.375a.656.656,0,0,0,.656.656h5.25A.656.656,0,0,0,14-6.344v-4.375a.656.656,0,0,0-.656-.656Zm-2.187,0H.656A.656.656,0,0,0,0-10.719v4.375a.656.656,0,0,0,.656.656h5.25a.656.656,0,0,0,.656-.656v-4.375A.656.656,0,0,0,5.906-11.375ZM0-4.156V.219A.656.656,0,0,0,.656.875h5.25A.656.656,0,0,0,6.563.219V-4.156a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0-4.156ZM8.094.875h5.25A.656.656,0,0,0,14,.219V-4.156a.656.656,0,0,0-.656-.656H8.094a.656.656,0,0,0-.656.656V.219A.656.656,0,0,0,8.094.875Z" transform="translate(603 1788)" fill="#6b6a77"/>
												<rect id="Rectangle_4026" data-name="Rectangle 4026" width="16" height="16" transform="translate(602 1775)" fill="none"/>
											</g>
											</svg>  {moment(new Date(inf.news_date)).format("MMMM D, YYYY")}</span>
									</div>
								</div>
							</div>
							):
							<div className="news-items border-0 item5">
								<div className="news-details">
									<Skeleton count={14}/>
								</div>
							</div>}
						</div>
					</div>
				</section>
				{/* News Section End */}
			</React.Fragment>
		)
	}
}

export default inject('NewsStore')(observer(News));