import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { helpResult } from '../api/HelpApi';
import { profileImageResult } from '../api/ProfileimageApi';

class HelpStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	isLoader = false;
	wentWrong = false;
	isModalOpen = false;
	errImage = false;
	files = '';
	fileLoader = false;
	
	helpFetch = (data,e) => {
		this.setIsLoader(true)
		let param
		if(this.userId){
			param = {
				name: this.auth.first+' '+this.auth.last,
				email: this.auth.email,
				message: data.comment,
				ownerId:this.userId,
				help_pic_url:this.files ? this.files : 'NA'
			}
		}else{
			param = {
				name: data.name,
				email: data.email,
				message: data.comment,
				help_pic_url:this.files ? this.files : 'NA'
			}
		}
		helpResult(param)
			.then(res => {
				if(res.data.error === false){
					this.setIsLoader(false);		
					this.setIsModalOpen(true);
					this.setwentWrong(false)
					e.target.reset();
					this.filesFetch('')
				}else{
					this.setIsLoader(false);		
					this.setIsModalOpen(false);
					this.setwentWrong(true)
				}		
			}).catch(error=>this.setwentWrong(true))	
	}
	
	getFiles = (files) => {
		this.fileLoaderFetch(true)
		let size = files.size;
		let sizeSplit = size.split(" ");
		let image1 = ["image/jpg", "image/jpeg", "image/png"];

		if(image1.find(element => element === files.type)){ 
			if(sizeSplit[0] < 10 && sizeSplit[1] === 'kB'){
				
				let profileUpdate = {
					base64ImageData: files.base64,
				}
				profileImageResult(profileUpdate)
					.then(res => {
						const {status,data} = res
						if(status===200 && !data.error ){
							this.filesFetch(res.data.path)
							this.fileLoaderFetch(false)
						}
					}).catch(error=>[console.log(error),this.fileLoaderFetch(false)])
			}else{
				this.errImageFetch(true);
				this.fileLoaderFetch(false)
			}
		}else{
			this.errImageFetch(true);
			this.fileLoaderFetch(false)
		}
	}
	
	setIsLoader = (data) => {
		this.isLoader = data;
	}
	setwentWrong = (data) => {
		this.wentWrong = data;
	}
	setIsModalOpen = (data) => {
		this.isModalOpen = data;
	}
	errImageFetch 	= (data) => { this.errImage = data; }
	filesFetch 		= (data) => { this.files = data; }
	fileLoaderFetch = (data) => { this.fileLoader = data; }
	
}

decorate(HelpStore, {
	auth: observable,
	userId: observable,
	fileLoader: observable,
	wentWrong: observable,
	isUserId: observable,
	isLoader: observable,
	isCount: observable,
	isModalOpen: observable,
	errImage: observable,
	files: observable,
	helpFetch: action,
	setIsLoader: action,
	setwentWrong: action,
	setIsModalOpen: action,
	getFiles: action,
	errImageFetch: action,
	filesFetch: action,
	fileLoaderFetch: action
});

export const helpUser = createContext(new HelpStore());
export default HelpStore;