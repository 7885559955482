import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { featuredInfluencerResult } from '../api/InfluencerFeatureApi';
import { categoryResult } from '../api/CampaignCategoryApi';

class InfluencerFeatureStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	featured = [];
	categoryFilter = [];
	required = '';
	
	featuredInfluencer = () => {
		featuredInfluencerResult()
			.then(res => {
				this.featuredFetch(res.data.message);
			}).catch(error=>console.log(error))
	}
	
	featuredFetch = (data) => {
		this.featured = data;
	}
	
	fetchCategories = () => {
		let param = {where:{categoryStatus:1}}
		categoryResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.categoryFilterFetch(res.data);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	categoryFilterFetch = (data) => {this.categoryFilter = data;}
	requiredFetch = (data) => {this.required = data;}
	
}

decorate(InfluencerFeatureStore, {
	featured: observable,
	categoryFilter: observable,
	required: observable,
	featuredFetch: action,
	categoryFilterFetch: action,
	fetchCategories: action,
	requiredFetch: action,
});

export const category = createContext(new InfluencerFeatureStore());
export default InfluencerFeatureStore;