import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import EllipsisText from "react-ellipsis-text";
import Skeleton from 'react-loading-skeleton';
import Slider from "react-slick";
import moment from 'moment';
import NumberFormat from 'react-number-format';

class ProductList extends Component {
  
  componentDidMount() {
	this.props.ProductNewStore.newProduct('Featured Collaborations');;
  }
  
  render() {
	var settings = {
		className: "slider ",
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: false,
		swipeToSlide: false,
		draggable : false,
		adaptiveHeight: false,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			}
		  },
		  {
			breakpoint: 600,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  initialSlide: 1
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  infinite: false,
			}
		  }
		]
	  };
	  
	const store = this.props.ProductNewStore;
	const todayDate = moment();
    return (
			<section className="productlist-campaign">
			<div className="container custom-container">
			<div className="heading">
				<h2>New Products</h2>
			</div>
			{store.product.length !== 0  ? 
				(<div className="product-list">
					<div className="product-slider" >
						<Slider {...settings}>
							{store.product.map((inf,index) =>
							<div key={index}>
								<div className="feature-item">
									<div className="feature-image">
									<Link to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}><img src={inf.productImage} alt={inf.productTitle} /></Link>
									</div>
									<div className="feature-details productlist-details">
									<h2><Link to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>{inf.productTitle}</Link></h2>
									<div className="campaign-details-mask">
										{inf.productDescription && <p>{inf.productDescription}</p>}
										<div className="productpriceoffer">
											<div className="productactprice">
												{inf.productDiscount > 0 ? 
													<> {inf.productAmountCurrency} <NumberFormat value={(inf.productAmount-(inf.productAmount*inf.productDiscount/100))} displayType={'text'} thousandSeparator={true} /> </>
												: 
													<> {inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /> </>
												} 
											</div>
											{inf.productDiscount > 0 && 
												<div className="productprevprice">{inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /></div>
											}
											{inf.productDiscount > 0 && <div className="productdiscount">{inf.productDiscount}% Off</div>}
										</div>
										<Link className="campaign-detail-btn" to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Buy Now</Link>
									</div>
										
									</div>
								</div>
							</div>
							)}
						</Slider>
					</div>
					<div className="see-all-featured"><Link to="/products" className="viewall-btn">View All</Link></div>
				</div>)
			 : (
				<div className="feature-list">
					<div className="feature-slider" >
						<Slider {...settings}>

							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={380} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={380} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={380} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={380} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			 
			 )}
			 </div>
		</section>
    );
  }
}
export default inject('ProductNewStore')(observer(ProductList));