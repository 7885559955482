import React, { Component } from 'react';
import {observer, inject, action} from 'mobx-react';
import Popup from "reactjs-popup";
import { Link, NavLink , withRouter} from 'react-router-dom';
import { flow } from 'mobx';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import EllipsisText from "react-ellipsis-text";
import ShowMoreText from 'react-show-more-text';
import { profileUpdateResult } from '../api/ProfileupdateApi';

class Navigation extends Component {
	
	constructor(props) {
		super(props);
		this.state={open:false};
		this.openCreateCampPopup = this.openCreateCampPopup.bind(this);	
		this.openDraftCampPopup = this.openDraftCampPopup.bind(this);	 
		this.changeSearch = this.changeSearch.bind(this);	 
	
	}
	
	componentDidMount(){
		this.props.CreatecampaignListStore.fetchNotificationCount('Notification count');
		this.props.CreatecampaignListStore.fetchNotificationList('Notification List');	
		this.props.CreatecampaignListStore.DraftCampaignDetail('Campaign Draft Detail');
		this.props.UserProfileStore.profileFetchData('Profile data');	
		this.props.CreatecampaignListStore.searchFetch('');
		if(sessionStorage.getItem("user")){
			const urlParams = new URLSearchParams(window.location.search);
			const myParam = urlParams.get('code');
			if(myParam){
				if(sessionStorage.getItem("instaprofile")){
					window.location.assign(`${process.env.PUBLIC_URL}/#/profile?code=${myParam}`)
				}else{
					window.location.assign(`${process.env.PUBLIC_URL}/#/edit-profile?code=${myParam}`)
				}
			}
			setInterval(this.userActiveCheck.bind(this), 20000);
		}
	}
	
	userActiveCheck = () =>{
		let userId = JSON.parse(sessionStorage.getItem("userId"));
		let profileUpdate = {
			lastSeen: new Date()
		}
		profileUpdateResult(userId,profileUpdate)
			.then(res2 => {

			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}

	userLogout = () =>{
		this.setState({open:true});
	}

	logout = () =>{	
		sessionStorage.removeItem("user")
		sessionStorage.removeItem("userId")
		sessionStorage.clear();
		this.userActiveCheck()
		window.location.assign(`${process.env.PUBLIC_URL}/#/`)
		window.location.reload();
	}

	closeModal=()=> {
		this.setState({open:false});
    }

	openCreateCampPopup = () =>{
		this.props.CreatecampaignListStore.openCreateCampPopupNew('new');
		this.props.CreatecampaignListStore.DraftCampaignDetail('Campaign Draft Detail');
	}

	openDraftCampPopup = () =>{
		//alert('dssd')
		this.props.CreatecampaignListStore.openDraftCampPopup();
	}
	
	isReadFetchData = () =>{
		this.props.CreatecampaignListStore.isReadFetch();
	}
	
	changeSearch = (e) => {
		this.props.CreatecampaignListStore.searchFetch(e.target.value);
	}
	
	onSubmit = (event) => {
		event.preventDefault();
		let search = this.props.CreatecampaignListStore.search
		if(search !==''){
			window.location.replace(`${process.env.PUBLIC_URL}/#/search-influencer/${search}`)
		}else{
			console.log('Field is required!')
			this.props.CreatecampaignListStore.searchFetch('')
			window.location.replace(`${process.env.PUBLIC_URL}/#/search-influencer`)
		}
		this.props.InfluencerListStore.searchFetch(search);
		this.props.InfluencerListStore.searchInfluencer();
	}
	
	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
	
  render(){
  		let blogURL = "http://blog.koli.ai/"
	    const auth = sessionStorage.getItem("user");
		const authGet = JSON.parse(auth);
		const store = this.props.CreatecampaignListStore;
		const profileStore = this.props.UserProfileStore;
		const curdate = moment().format("YYYY-MM-DD")
		const yesterday = moment().add(-1, 'days').format("YYYY-MM-DD");
		//if (window.location.hash == '#/help') return null;
		let hideShow;
		if (window.location.hash == '#/'){
			hideShow = 'navbar';
		}else{
			hideShow = 'navbar loginuser';
		}
		if (window.location.hash == '#/username') return null;
		
		
      return(
        <div className="headertop-info">
          <nav className={hideShow}>
              <div className="logo">
              <Link to="/">
                <img src={`${process.env.PUBLIC_URL}./images/logo-noscroll.svg`} alt="" className="nonscroll-logo"/>
                <img src={`${process.env.PUBLIC_URL}./images/logo-scroll.svg`} alt="" className="scroll-logo"/>
              </Link>
              </div>
              <ul className="menu-list">
			  {/* <form onSubmit={this.onSubmit} className="form-inline">
                  <div className="navsearchtop_wrapper">
                    <input className="form-control mr-sm-2 navsearchtop offeramountreset" onChange={this.changeSearch} value={store.search} name="search" type="text" placeholder="Search influencer" aria-label="Search"/>
                    <button className="btn btn-search my-2 my-sm-0" type="submit">Search</button>
                  </div>
                </form>
                <div className="icon cancel-btn">
                  <i className="fas fa-times"></i>
			  </div> */}
                <li className="navmenu-text makeofferreset"><a href={blogURL}>Blogs</a></li>

					{/* <li className="navmenu-text makeofferreset"><NavLink to="/search-influencer">Influencers</NavLink></li>

                <li className="navmenu-text makeofferreset"><NavLink to="/products">Store</NavLink></li>

					<li className="navmenu-text makeofferreset"><NavLink to="/campaigns">Campaigns</NavLink></li> */}
                {auth ? <>
                <li className="navmenu-text makeofferreset">
					<div className="create-camp-wrapper">
						<Link to="#" onClick={store.draftDetail.length !== 0 ? this.openDraftCampPopup : this.openCreateCampPopup} className="create-btn"> + Campaigns</Link>
						{/* <button className="btn create-btn " onClick={store.draftDetail.length !== 0 ? this.openDraftCampPopup : this.openCreateCampPopup}> <span className="d-none pulse-notification"></span> + Create Campaign </button>*/}
						{store.draftDetail.length !== 0 && <span className="savedraft-circle"></span>} 
					</div>
                </li>

                <li className="navmenu-text makeofferreset">
                  <ul className="dropdown notification-dropdown"> 
                  <li className="dropbtn belicon" onClick={()=>this.isReadFetchData()}>{store.notifyCount ? <span className="count-notification">{store.notifyCount}</span> : null}<svg xmlns="http://www.w3.org/2000/svg" width="13.982" height="17.099" viewBox="0 0 13.982 17.099"> 
                    <g id="Group_14837" data-name="Group 14837" transform="translate(-256.46 -224.968)" className="hovercolor notoification-bell">
                      <path id="Path_7975" data-name="Path 7975" d="M262.683,226.575c0-.3-.012-.6,0-.905a.77.77,0,0,1,1.534.007c.012.2.006.4.007.6,0,.1,0,.193,0,.277.365.1.72.178,1.06.3a5.386,5.386,0,0,1,3.593,4.992c.021,1.426.007,2.852,0,4.277a.385.385,0,0,0,.126.3c.407.4.816.8,1.208,1.211a.767.767,0,0,1-.339,1.282,1.138,1.138,0,0,1-.306.031q-6.125,0-12.25,0a.785.785,0,0,1-.572-1.388c.372-.366.735-.741,1.11-1.1a.478.478,0,0,0,.159-.38c-.006-1.377-.014-2.755,0-4.132a5.408,5.408,0,0,1,4.125-5.256C262.315,226.647,262.488,226.617,262.683,226.575Zm5.072,10.818a1.548,1.548,0,0,1-.417-1.34c0-1.353.01-2.707,0-4.06a3.885,3.885,0,1,0-7.771.01c-.006,1.456-.01,2.913,0,4.369a1.091,1.091,0,0,1-.382.952c-.015.012-.022.036-.041.068Z" transform="translate(0)" />
                      <path id="Path_7976" data-name="Path 7976" d="M368.533,545.177h3.1a1.551,1.551,0,0,1-3.1,0Z" transform="translate(-106.632 -304.664)"/>
                    </g>
                  </svg>
                    <ul className="dropdown-content">
                      <div className="dropdown-content-inner">
                        <div className="notification-cover inner">
						<div className="row">
							<div className="col-12 col-sm-7 col-md-7">
								<h2>Notifications</h2>
							</div>
							<div className="col-12 col-sm-5 col-md-5">
								<Link className="readall-notification">Read All</Link>
							</div>
						</div>
                          
                          <div className="notification-scroll-bar">
							{store.notifyList.length !== 0 && <>{Object.entries(store.notifyList).map((key,val) =>
								<div className="notification-section">
								<h4>{curdate === moment(new Date(key[0])).format("YYYY-MM-DD") ? 'Today' : yesterday === moment(new Date(key[0])).format("YYYY-MM-DD") ? 'Yesterday' : <p>{moment(new Date(key[0])).format("ddd, D MMM YYYY")}</p>}</h4>
								{key[1].map((inf,index) => 
										<div className={inf.readStatus == 0 ? 'notifyNotRead redirect-check' : 'notifyRead redirect-check'} key={index}>
											{inf.campaignId ? <Link className="row" onClick={()=>this.props.CampaignDetailStore.campaignDetailFetch(inf.campaignId)} to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.campaignId}`}>
												<div className="col-md-2">
												{(inf.type === "ADMIN_CAMPAIGN_APPROVE" || inf.type === "ADMIN_CAMPAIGN_REJECT" || inf.type === "OWNER_DO_PAYMENT" || inf.type === "OWNER_OFFER_APPLY" || inf.type === "KOLITEAM" || inf.type === "KOLI_SEND_MESSAGE" || inf.type === "KOLI_RESOLVE_QUERY" || inf.type === "ADMIN_PRODUCT_INACTIVE") &&
												
													<> {inf.image && inf.image !== 'NA' ?
														<img src={inf.image} alt="" style={{borderRadius:'25px'}} />
													:
														<img src={`${process.env.PUBLIC_URL}/images/notify_admin.svg`} alt="" />
													}</>
													
												}
												
												{(inf.type === "OWNER_PAYMENT_RELEASED" || inf.type === "CAMPAIGN_EXPIRE_REMINDER") &&  
													<> {inf.campaignImage && inf.campaignImage !== 'NA' ?
														<img src={inf.campaignImage} alt="" style={{borderRadius:'25px'}} />
													:
														<img src={`${process.env.PUBLIC_URL}/images/notifications-two.svg`} alt="" />
													}</>
												}
												
												{(inf.type === "USER_CAMPAIGN_APPLY" || inf.type === "OWNER_OFFER_ACCEPT" || inf.type === "OWNER_OFFER_DECLINED" || inf.type === "OWNER_OFFER_MARK_AS_COMPLETED") &&  
													<> {inf.image && inf.image !== 'NA' ?
														<img src={inf.image} alt="" style={{borderRadius:'25px'}} />
													:
														<img src={`${process.env.PUBLIC_URL}/images/notifications-two.svg`} alt="" />
													}</>
												}
												</div>
												<div className="col-md-10">
													<p><ShowMoreText
														lines={2}
														more='Show more'
														less='Show less'
														anchorClass='showMoreText'
														onClick={this.executeOnClick}
														expanded={false}
														width={250}
													>
													{inf.message}
													</ShowMoreText></p>
													<div className="row">
													<div className="col-md-4">
														<div className="notification-time" style={{textAlign:'left'}}>{moment(new Date(inf.createdAt)).format("h:mm A")}</div>
													</div>
													{/* <div className="col-md-7">
														{inf.campaignId ? <div className="notification-id">Campaign ID: {inf.campaignId}</div> : null}
														</div>
														<div className="col-md-5">
															<div className="notification-time">{moment(new Date(inf.createdAt)).format("hh:mm A")}</div>
													</div> */}
													</div>
												</div>
											</Link> : 
											<Link to="#" className="row">
												<div className="col-md-2">
												{(inf.type === "ADMIN_CAMPAIGN_APPROVE" || inf.type === "ADMIN_CAMPAIGN_REJECT" || inf.type === "OWNER_DO_PAYMENT" || inf.type === "OWNER_OFFER_APPLY" || inf.type === "KOLITEAM" || inf.type === "KOLI_SEND_MESSAGE" || inf.type === "KOLI_RESOLVE_QUERY" || inf.type === "ADMIN_PRODUCT_INACTIVE") &&
												
													<> {inf.image && inf.image !== 'NA' ?
														<img src={inf.image} alt="" style={{borderRadius:'25px'}} />
													:
														<img src={`${process.env.PUBLIC_URL}/images/notify_admin.svg`} alt="" />
													}</>
													
												}
												
												{(inf.type === "OWNER_PAYMENT_RELEASED" || inf.type === "CAMPAIGN_EXPIRE_REMINDER" ) &&  
													<> {inf.campaignImage && inf.campaignImage !== 'NA' ?
														<img src={inf.campaignImage} alt="" style={{borderRadius:'25px'}} />
													:
														<img src={`${process.env.PUBLIC_URL}/images/notifications-two.svg`} alt="" />
													}</>
												}
												
												{(inf.type === "USER_CAMPAIGN_APPLY" || inf.type === "OWNER_OFFER_ACCEPT" || inf.type === "OWNER_OFFER_DECLINED" || inf.type === "OWNER_OFFER_MARK_AS_COMPLETED") &&  
													<> {inf.image && inf.image !== 'NA' ?
														<img src={inf.image} alt="" style={{borderRadius:'25px'}} />
													:
														<img src={`${process.env.PUBLIC_URL}/images/notifications-two.svg`} alt="" />
													}</>
												}
												</div>
												<div className="col-md-10">
													<p><ShowMoreText
														lines={2}
														more='Show more'
														less='Show less'
														anchorClass='showMoreText'
														onClick={this.executeOnClick}
														expanded={false}
														width={250}
													>
													{inf.message}
													</ShowMoreText></p>
													<div className="row">
													<div className="col-md-4">
														<div className="notification-time" style={{textAlign:'left'}}>{moment(new Date(inf.createdAt)).format("h:mm A")}</div>
													</div>
													{/* <div className="col-md-7">
														{inf.campaignId ? <div className="notification-id">Campaign ID: {inf.campaignId}</div> : null}
														</div>
														<div className="col-md-5">
															<div className="notification-time">{moment(new Date(inf.createdAt)).format("hh:mm A")}</div>
													</div> */}
													</div>
												</div>
											</Link>}
										</div>
								)}
								</div> )}</>
								
							}
							<>{store.listNotFound && <div className="text-center"> 
								<div className="nonotificationmask">
									<img src={`${process.env.PUBLIC_URL}/images/no-notifications.svg`} alt="" />
									<p>Sorry no result found</p>
								</div>
							</div>}</>
                          </div>
                        </div>
                      </div>
                    </ul>
                    </li>
                  </ul>
                </li> </>
				: <>
				{ /*  <li className="navmenu-text makeofferreset"><Link to="#" data-toggle="modal" data-target="#signUpModal"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" className="hovercolor">
                  <path id="Path_8263" data-name="Path 8263" d="M9.8-4.5C8.9-4.5,8.472-4,7-4s-1.9-.5-2.8-.5A4.2,4.2,0,0,0,0-.3V.5A1.5,1.5,0,0,0,1.5,2h11A1.5,1.5,0,0,0,14,.5V-.3A4.2,4.2,0,0,0,9.8-4.5Zm2.7,5H1.5V-.3A2.7,2.7,0,0,1,4.2-3,12.235,12.235,0,0,0,7-2.5,12.117,12.117,0,0,0,9.8-3,2.7,2.7,0,0,1,12.5-.3ZM7-5a4.5,4.5,0,0,0,4.5-4.5A4.5,4.5,0,0,0,7-14,4.5,4.5,0,0,0,2.5-9.5,4.5,4.5,0,0,0,7-5Zm0-7.5a3,3,0,0,1,3,3,3,3,0,0,1-3,3,3,3,0,0,1-3-3A3,3,0,0,1,7-12.5Z" transform="translate(0 14)" />
                  </svg>Signup</Link>
                </li>

                <li className="navmenu-text makeofferreset"><Link to="#" data-toggle="modal" data-target="#loginModal"><svg version="1.1" id="Layer_1" width="14" height="16" viewBox="0 0 16.2 16" className="hovercolor">
                  <path id="Path_8208" className="st0" d="M11.6,7.4L7.1,2.8C6.8,2.5,6.3,2.5,6,2.8S5.7,3.6,6,3.9l3.3,3.3H0.8C0.4,7.2,0,7.5,0,7.9
                    c0,0.4,0.3,0.8,0.7,0.8c0,0,0,0,0.1,0h8.4L6,12c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0l4.6-4.6C11.9,8.2,11.9,7.7,11.6,7.4z"/>
                  <path id="Path_8209" className="st0" d="M15.4,0h-5.1C9.9,0,9.5,0.3,9.5,0.7s0.3,0.8,0.7,0.8c0,0,0,0,0.1,0h4.3v12.9h-4.3
                    c-0.4,0-0.8,0.3-0.8,0.7S9.8,16,10.2,16c0,0,0,0,0.1,0h5.1c0.4,0,0.8-0.3,0.8-0.8V0.8C16.2,0.3,15.8,0,15.4,0z"/>
                  </svg>Login</Link>
                </li> */}
				</>}
				{auth &&
                <li className="navmenu-text makeofferreset">
                <ul className="dropdown"> 
                <li className="dropbtn">
					Hi, {(profileStore.profile.first || profileStore.profile.last) ?
						<>{profileStore.profile.first && profileStore.profile.first} {profileStore.profile.last && profileStore.profile.last}</>
						: 
						<>{authGet.first && authGet.first} {authGet.last && authGet.last}</>
						}
                  <ul className="dropdown-content">
                    <div className="dropdown-content-inner">
                    <li className="makeofferreset"><Link to="/profile"><svg version="1.1" id="Layer_1" width="14" height="16" viewBox="0 0 18 18" className="subhovercolor">
                    <path id="Path_5360" className="st0" d="M9.1,0.5c-4.6,0-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4C17.5,4.3,13.7,0.5,9.1,0.5z
                      M9.1,3c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4c0,0,0,0,0,0C5.1,4.8,6.9,3,9.1,3z M9.1,15.9c-2.2,0-4.3-1-5.6-2.8
                    c1.7-0.9,3.6-1.3,5.6-1.4c1.9,0,3.9,0.5,5.6,1.4C13.4,14.9,11.3,15.9,9.1,15.9z"/>
                  </svg>Profile</Link></li>
                    <li><Link to="/my-campaigns"><svg version="1.1" id="Layer_1" width="14" height="16" viewBox="0 0 18 18" className="subhovercolor">
                    <path id="Path_5383" className="st0" d="M14.3,6.5c-0.2-0.2-0.2-0.6,0-0.8l1.1-1.3c0.1-0.2,0.4-0.2,0.6-0.1c0,0,0.1,0.1,0.1,0.1
                      c0.2,0.2,0.2,0.6,0,0.8l-1.1,1.3C14.8,6.7,14.5,6.8,14.3,6.5C14.3,6.6,14.3,6.6,14.3,6.5z M15.5,8.7c-0.1,0-0.3-0.1-0.3-0.2
                      C15.1,8.4,15,8.3,15,8.1c0-0.3,0.2-0.6,0.5-0.6h1.6c0.3,0.1,0.5,0.4,0.5,0.7c0,0.2-0.2,0.4-0.5,0.5H15.5z M14.3,9.7
                      c0.1-0.2,0.4-0.2,0.6-0.1c0,0,0.1,0.1,0.1,0.1L16,11c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.2-0.4,0.2-0.6,0.1c0,0-0.1-0.1-0.1-0.1l-1.1-1.3
                      C14.1,10.3,14.1,9.9,14.3,9.7L14.3,9.7z M4.7,14.5c0,0.4-0.1,0.9-0.4,1.2c-0.4,0.6-1.2,0.7-1.8,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
                      C2,15.4,1.9,15,1.9,14.5v-2.6l2.9,0.8V14.5z M0.8,5.8l8.6-2.5v9.7l-8.6-2.5c-0.1,0-0.2-0.1-0.2-0.2C0.5,10.2,0.4,10,0.4,9.9V6.4
                      C0.4,6.1,0.6,5.9,0.8,5.8z M11.7,1.7c0.9,0.1,1.5,0.9,1.4,1.7v9.3c0.1,0.9-0.6,1.7-1.4,1.7c-0.4,0-0.8-0.2-1-0.5
                      c-0.3-0.4-0.4-0.8-0.4-1.2V3.5C10.2,2.6,10.9,1.8,11.7,1.7L11.7,1.7z"/>
                  </svg>My Campaign</Link></li>
                    <li className="makeofferreset"><Link to="/my-application"><svg version="1.1" id="Layer_1"  width="14" height="16" viewBox="0 0 18 18" className="subhovercolor">
                  <path id="Path_7949" className="st0" d="M8.9,4.4c3.3,0,5.9,2.6,5.9,5.9s-2.6,5.9-5.9,5.9c-3.3,0-5.9-2.6-5.9-5.9c0-1.6,0.6-3.1,1.7-4.2
                    C5.9,5,7.4,4.4,8.9,4.4 M8.9,2.8c-4.1,0-7.4,3.3-7.4,7.4s3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4S13,2.8,8.9,2.8L8.9,2.8z"/>
                  <circle id="Ellipse_101" className="st0" cx="9" cy="3.4" r="3.1"/>
                  <circle id="Ellipse_102" className="st0" cx="14.9" cy="13.6" r="3.1"/>
                  <circle id="Ellipse_103" className="st0" cx="3.1" cy="13.6" r="3.1"/>
                  </svg>My Applications</Link></li>
				  <li className="makeofferreset"><Link to="#"><svg version="1.1" id="Layer_1" viewBox="0 0 18 18" width="14" height="16" className="subhovercolor">
                  <path id="Path_5533" className="st0" d="M13.5,1.3h-9c-0.7,0-1.3,0.6-1.3,1.3v13.2c0,0.5,0.5,0.9,1,0.9c0.2,0,0.5-0.1,0.6-0.3L9,12.3
                    l4.1,4.1c0.4,0.4,1,0.4,1.4,0c0.2-0.2,0.3-0.4,0.3-0.6V2.6C14.8,1.9,14.2,1.3,13.5,1.3z"/>
                  </svg>My Favorites</Link></li>
				  <li className="makeofferreset"><Link to="/transaction-history"><svg xmlns="http://www.w3.org/2000/svg" width="18.043" height="18" viewBox="0 0 18.043 18" className="subhovercolor">
				<g id="Transactions" transform="translate(-1108.957 -295)">
					<rect id="Rectangle_11407" data-name="Rectangle 11407" width="18" height="18" transform="translate(1109 295)" fill="#fff"/>
					<g id="transaction_1_" data-name="transaction (1)" transform="translate(1108.957 266.461)">
					<g id="Group_15797" data-name="Group 15797" transform="translate(0 34.212)">
						<g id="Group_15796" data-name="Group 15796" transform="translate(0 0)">
						<path id="Path_10618" data-name="Path 10618" d="M15.577,170.076a1.557,1.557,0,0,1-1.284-.675l-.762-1.109a5.2,5.2,0,0,1-9.7-2.024h.493a.519.519,0,0,0,.428-.813L4,164.354l-1.147-1.668a.519.519,0,0,0-.856,0l-1.9,2.769a.519.519,0,0,0,.428.813h.536a7.962,7.962,0,0,0,13.575,5.11,8.015,8.015,0,0,0,1.058-1.307C15.651,170.075,15.614,170.076,15.577,170.076Z" transform="translate(0 -162.461)" className="subhovercolor"/>
						</g>
					</g>
					<g id="Group_15799" data-name="Group 15799" transform="translate(7.294 34.212)">
						<g id="Group_15798" data-name="Group 15798">
						<path id="Path_10619" data-name="Path 10619" d="M209.575,165.232h-.791a.272.272,0,0,1-.272-.272v-.4a.272.272,0,0,1,.272-.272h.791a.272.272,0,0,1,.272.272.519.519,0,0,0,1.038,0,1.312,1.312,0,0,0-1.187-1.3v-.278a.519.519,0,1,0-1.038,0v.278a1.312,1.312,0,0,0-1.187,1.3v.4a1.312,1.312,0,0,0,1.31,1.31h.791a.272.272,0,0,1,.272.272v.4a.272.272,0,0,1-.272.272h-.791a.272.272,0,0,1-.272-.272.519.519,0,0,0-1.038,0,1.312,1.312,0,0,0,1.187,1.3v.278a.519.519,0,1,0,1.038,0v-.278a1.312,1.312,0,0,0,1.187-1.3v-.4A1.312,1.312,0,0,0,209.575,165.232Z" transform="translate(-207.473 -162.463)" className="subhovercolor"/>
						</g>
					</g>
					<g id="Group_15801" data-name="Group 15801" transform="translate(2.313 29.539)">
						<g id="Group_15800" data-name="Group 15800" transform="translate(0 0)">
						<path id="Path_10620" data-name="Path 10620" d="M81.412,37.259a.519.519,0,0,0-.46-.278h-.536a7.962,7.962,0,0,0-13.575-5.11,8.016,8.016,0,0,0-1.058,1.307c.037,0,.073,0,.11,0a1.557,1.557,0,0,1,1.284.675l.762,1.109a5.2,5.2,0,0,1,9.7,2.024h-.493a.519.519,0,0,0-.428.813l.757,1.1,1.147,1.668a.519.519,0,0,0,.856,0l.687-1,1.216-1.769A.519.519,0,0,0,81.412,37.259Z" transform="translate(-65.784 -29.539)" className="subhovercolor"/>
						</g>
					</g>
					</g>
				</g>
				</svg>Transactions History</Link></li>
                    <li className="makeofferreset"><Link to="/edit-profile/setting"><svg version="1.1" id="Layer_1" viewBox="0 0 18 18" width="14" height="16" className="subhovercolor">
                    <path id="ic_settings_24px" className="st0" d="M15.2,9.8c0-0.3,0.1-0.5,0.1-0.8c0-0.3,0-0.5-0.1-0.8L17,6.9c0.2-0.1,0.2-0.3,0.1-0.5
                      l-1.7-2.8c-0.1-0.2-0.3-0.2-0.5-0.2l-2.1,0.8c-0.4-0.3-0.9-0.6-1.4-0.8l-0.3-2.2c0-0.2-0.2-0.3-0.4-0.3H7.3C7.1,0.8,6.9,1,6.9,1.2
                      L6.6,3.4c-0.5,0.2-1,0.5-1.4,0.8L3.1,3.3c-0.2-0.1-0.4,0-0.5,0.2L0.9,6.3C0.8,6.5,0.9,6.7,1,6.9l1.8,1.3c0,0.3-0.1,0.5-0.1,0.8
                      c0,0.3,0,0.5,0.1,0.8L1,11.1c-0.2,0.1-0.2,0.3-0.1,0.5l1.7,2.8c0.1,0.2,0.3,0.2,0.5,0.2l2.1-0.8c0.4,0.3,0.9,0.6,1.4,0.8l0.3,2.2
                      c0,0.2,0.2,0.3,0.4,0.3h3.3c0.2,0,0.4-0.1,0.4-0.3l0.3-2.2c0.5-0.2,1-0.5,1.4-0.8l2.1,0.8c0.2,0.1,0.4,0,0.5-0.2l1.7-2.8
                      c0.1-0.2,0.1-0.4-0.1-0.5L15.2,9.8z M9,11.8c-1.6,0-2.8-1.4-2.8-2.9s1.4-2.8,2.9-2.8c1.5,0,2.8,1.3,2.8,2.8
                      C11.9,10.6,10.6,11.9,9,11.8z"/>
					</svg>Settings</Link></li>
                    <li className="makeofferreset"><Link to="#"onClick={this.userLogout}><svg version="1.1" id="Layer_1" viewBox="0 0 18 18" width="14" height="16" className="subhovercolor">
                  <path id="Path_7476" className="st0" d="M9.6,7.7h6.2C16.4,7.8,17,8.3,17,9c0,0.7-0.5,1.2-1.2,1.2H9.6c-0.7,0-1.3-0.5-1.3-1.2
                    C8.3,8.3,8.8,7.8,9.6,7.7C9.5,7.7,9.5,7.7,9.6,7.7L9.6,7.7z M15.2,13.9c0.3-0.4,0.2-1-0.2-1.3s-0.9-0.2-1.3,0.1
                    c-2.1,2.6-5.9,3.1-8.5,1s-3.1-5.9-1-8.5s5.9-3.1,8.5-1c0.4,0.3,0.7,0.6,1,1C14,5.6,14.6,5.7,15,5.4s0.5-0.9,0.2-1.3c0,0,0,0,0-0.1
                    C12.5,0.6,7.5,0,4,2.8S0,10.5,2.7,14s7.7,4,11.2,1.3C14.3,14.8,14.8,14.4,15.2,13.9L15.2,13.9z"/>
                  </svg>Logout</Link></li>
                  </div>
                  </ul>
                  </li>
                </ul>
              </li>
				}
              </ul>
              <div className="icon menu-btn">
                <i className="fas fa-bars"></i>
              </div>
            
          </nav>
		  <div className="payoutSuccess">
		  <Popup
			 open={this.state.open}
			 closeOnDocumentClick
			 onClose={this.closeModal}
			>
			<div className="custom-popup text-center logoutmodel-popup">
			   <div className="popup-details">
					<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
					<p className="popup-details mb-4">Are you sure you want to Logout?</p>
					<button onClick={this.closeModal} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
					<button onClick={this.logout} className="btn btn-primary">Ok</button>
				</div>                        
			</div>
		  </Popup>
		  </div>
      </div>
	  
    )
  }
}
export default inject('CreatecampaignListStore','UserProfileStore','CampaignDetailStore','InfluencerListStore') (observer((Navigation)))