import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { InstagramPost } from '../api/InstagrampostApi';
import { usernameCheckResult } from '../api/UsernameCheckApi';
import { userCampResult } from '../api/UserCampaignApi';

class ApplicationProfileStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	instaPosts = '';
	instaPostCount = '';
	instaFollowersCount = '';
	notFound = false;
	username = '';
	avatarUrl = '';
	fullName = '';
	preloader = true;
	campHide = false;
	userCamp = '';
	userCampCount = '';
	profile = '';
	instaUsername = '';
	postPrivate = false;
	
	profileFetchData = (userId) => { 
		this.preloaderFetch(true)
		this.instaPostsFetch('');
		this.instaPostCountFetch('');
		this.instaFollowersCountFetch('');
			let param = {where:{ownerId:userId}}
			usernameCheckResult(JSON.stringify(param))
					.then(res1 => {
						if(res1.data.length !== 0){
							this.profileFetch(res1.data[0])
							this.instaUsernameFetch(res1.data[0].instaUsername)
							if(this.instaUsername){
								InstagramPost(this.instaUsername)
									.then(res => {
										let result = res.data.graphql.user;
										if(result.edge_owner_to_timeline_media.edges.length > 0){
											this.instaPostsFetch(result.edge_owner_to_timeline_media.edges);
											this.postPrivateFetch(false)
											this.notFoundFetch(false)
										}else{
											this.postPrivateFetch(true)
											this.notFoundFetch(false)
										}
										this.instaPostCountFetch(result.edge_owner_to_timeline_media.count);
										this.instaFollowersCountFetch(result.edge_followed_by.count);
										this.preloaderFetch(false)
									}).catch(error=>[this.notFoundFetch(true),this.preloaderFetch(false),this.postPrivateFetch(false)]) 
							}else{
								this.notFoundFetch(true)
								this.preloaderFetch(false)
								this.postPrivateFetch(false)
							}
						}else{
							this.preloaderFetch(false)
						}
					
					if(userId !== 0){
						userCampResult(userId)
							.then(res2 => {
								if(res2.data.data.length > 0){
									this.userCampFetch(res2.data.data)
									this.userCampCountFetch(res2.data.data.length)
									this.campHideFetch(false)
								}else{
									this.campHideFetch(true)
								}
								
							}).catch(error=>[this.campHideFetch(true)]) 
					}else{
						this.campHideFetch(true)
					}
					
				}).catch(error=>console.log(error)) 
	}
	
	instaPostsFetch = (data) => {
		this.instaPosts = data;
	}
	instaPostCountFetch = (data) => {
		this.instaPostCount = data;
	}
	instaFollowersCountFetch = (data) => {
		this.instaFollowersCount = data;
	}
	notFoundFetch = (data) => {
		this.notFound = data;
	}
	postPrivateFetch = (data) => {
		this.postPrivate = data;
	}
	usernameFetch = (data) => {
		this.username = data;
	}
	avatarUrlFetch = (data) => {
		this.avatarUrl = data;
	}
	fullNameFetch = (data) => {
		this.fullName = data;
	}
	preloaderFetch  = (data) =>{
		this.preloader = data;
	}
	campHideFetch  = (data) =>{
		this.campHide = data;
	}
	userCampFetch  = (data) =>{
		this.userCamp = data;
	}
	userCampCountFetch  = (data) =>{
		this.userCampCount = data;
	}
	profileFetch = (data) => {
		this.profile = data;
	}
	instaUsernameFetch = (data) => {
		this.instaUsername = data;
	}
	
}

decorate(ApplicationProfileStore, {
	auth: observable,
	instaPosts: observable,
	instaPostCount: observable,
	instaFollowersCount: observable,
	notFound: observable,
	username: observable,
	avatarUrl: observable,
	preloader: observable,
	campHide: observable,
	fullName: observable,
	userCamp: observable,
	userCampCount: observable,
	profile: observable,
	instaUsername: observable,
	postPrivate: observable,
	instaPostsFetch: action,
	instaPostCountFetch: action,
	instaFollowersCountFetch: action,
	notFoundFetch: action,
	usernameFetch: action,
	avatarUrlFetch: action,
	fullNameFetch: action,
	preloaderFetch: action,
	campHideFetch: action,
	userCampFetch: action,
	userCampCountFetch: action,
	profileFetch: action,
	instaUsernameFetch: action,
	postPrivateFetch: action,
});

export default ApplicationProfileStore;