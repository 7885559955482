import {action, observable, decorate} from 'mobx';
import { productListResult } from '../api/ProductListApi';

class ProductsDetailStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	product = [];
	preloader = false;
	toggler = false;

	
	productDetailFetch = (ownerid) => {
		this.preloaderFetch(true)
		let param = {include:['profile'],where:{productOwnerId:ownerid,productStatus:1}}
		productListResult(JSON.stringify(param))
			.then(res => {
				console.log(res)
				this.productFetch(res.data);
				this.preloaderFetch(false)
			}).catch(error=>console.log(error))
	}

	productFetch	= (data) => {this.product = data;}	
	preloaderFetch 	= (data) => {this.preloader = data;}
	togglerFetch 	= (data) => {this.toggler = data;}
	
}

decorate(ProductsDetailStore, {
	product: observable,
	preloader:observable,
	toggler:observable,
	preloaderFetch:action,
	productFetch:action,
	productDetailFetch:action,
	togglerFetch:action
});

export default ProductsDetailStore;