import {action, observable, decorate} from 'mobx';
import { privacyResult } from '../api/PrivacyApi';

class PrivacyStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	privacy = [];
	
	privacyGet = () =>{
		privacyResult()
			.then(res => this.setPrivacy(res.data.message))
			.catch(error => error)
	}
	
	setPrivacy = (data) => {
		this.privacy = data;
	}
	
}

decorate(PrivacyStore, {
	privacy: observable,
	setPrivacy: action
});

export default PrivacyStore;