import React from 'react';
import 'mobx-react-lite/batchingOptOut';
import { Route, Switch, HashRouter as Router } from 'react-router-dom';
import Navigation from './components/Navigation';
import Newprofile from './components/NewProfile';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';	
import Campaigns from './components/Campaigns/Campaigns';
import CampaignDetail from './components/Campaigns/CampaignDetail';
import Login from './components/Login';
import Signup from './components/Signup';
import Forget from './components/Forget';
import TermsCondition from './components/TermsCondition/TermsCondition'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import Faq from './components/Faq/Faq'
import SearchInfluencer from './components/Influencers/SearchInfluencer';
import FeaturedInfluencer from './components/Influencers/FeaturedInfluencer';
import InfluencerProfile from './components/Influencers/InfluencerProfile';
import Profile from './components/User/Profile';
import ApplicationUserProfile from './components/User/ApplicationUserProfile';
import Username from './components/Username';
import EditProfile from './components/User/EditProfile';
import MyCampaigns from './components/Campaigns/MyCampaigns';
import MyFavourites from './components/Campaigns/MyFavourites';
import ApplicationUserList from './components/User/ApplicationUserList';
import MyApplication from './components/User/MyApplication';
import Landing from './components/Landing';
import Help from './components/Help'
import CreateCampaigns from './components/CreateCampaigns'
import DraftCampaigns from './components/DraftCampaigns'
import Payment from './components/Payment'
import Products from './components/Products/Products';
import ProductsDetail from './components/Products/ProductsDetail';
import TransactionHistory from './components/Transaction/TransactionHistory';
import ReactGA from 'react-ga';

function initializeReactGA() {
    ReactGA.initialize('G-ZF6QHSKLBW');
    ReactGA.pageview('/homepage');
}
class App extends React.Component {

	componentDidMount() {
			// window.addEventListener("scroll", function () {
			// 	let top = this.scrollY;
			// 	var el = document.getElementsByClassName('all-header')[0];
			// 	var elm = document.getElementsByClassName('after-header')[0];
			// 	if (top > 0) {
			// 		elm.classList.add("marginTop72");
			// 		el.classList.add("header-sticky");
			// 	} else {
			// 		elm.classList.remove("marginTop72");
			// 		el.classList.remove("header-sticky");
			// 	}
			// });

			// Mobile Menu Open on click
			var mobileMenu = document.getElementsByClassName('mobile-menu')[0],
				closeMenu = document.getElementsByClassName('close-menu-wpr')[0],
				menu = document.getElementsByClassName('menu')[0];

			// mobileMenu.addEventListener('click', function () {
			// 	menu.classList.add("menu-slide");
			// });

			// closeMenu.addEventListener('click', function () {
			// 	menu.classList.remove("menu-slide");
			// });
		
	}

	render() {
		
		return (
			<React.Fragment>
				<Router>
					
					<Route exact path="/" component={Home} />
					<Route exact path="/home" component={Home} />
					<Navigation />
					<Switch>
					{/*<Route path="/campaigns/:category" component={Campaigns} />
						<Route path="/campaigns" component={Campaigns} />
						<Route path="/search-influencer/:name" component={SearchInfluencer} />
						<Route path="/search-influencer" component={SearchInfluencer} />
						<Route path="/edit-profile/:id" component={EditProfile} />
						<Route path="/edit-profile" component={EditProfile} />
						<Route path="/influencer-profile/:username" component={InfluencerProfile} />
						<Route path="/my-campaigns" component={MyCampaigns} />
						<Route path="/my-favourites" component={MyFavourites} />
						<Route path="/application-user-list/:applicants/:id" component={CampaignDetail} />
						<Route path="/applicationdetail/:applicantdetail/:id" component={CampaignDetail} />
						<Route path="/profile/:id" component={Profile} />
						<Route path="/profile" component={Profile} />
						<Route path="/application-profile/:id" component={ApplicationUserProfile} />
						<Route path="/campaigndetail/:id" component={CampaignDetail} />
						<Route path="/featured-influencer" component={FeaturedInfluencer} />
						<Route path="/payment/:campid/:userid" component={Payment} />
						<Route path="/my-application" component={MyApplication} />
						<Route path="/landing-page" component={Landing} />
						<Route path="/products" component={Products} />
						<Route path="/productsdetail/:ownerid/:id" component={ProductsDetail} />
						<Route path="/transaction-history" component={TransactionHistory} />
						<Route path="/NewProfile" component={Newprofile} />*/}
						<Route path="/help" component={Help} />
						<Route path="/about" component={About} />
						<Route path="/terms-condition" component={TermsCondition} />
						<Route path="/privacy-policy" component={PrivacyPolicy} />
						<Route path="/faq" component={Faq} />
					</Switch>
					<Footer /> 
					{/*<Login />
					<Signup />
					<Forget />
					<CreateCampaigns />
					<DraftCampaigns />
					<Route path="/username" component={Username} />*/}
				</Router>
			</React.Fragment>
		)
	}
}

export default App;
