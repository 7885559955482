import React, { Component } from "react";
import { observer, inject } from 'mobx-react';
import NumericLabel from 'react-pretty-numbers';
import Skeleton from 'react-loading-skeleton';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

var optionnumberformat = {
  'justification': 'C',
  'percentage': false,
  'precision': 0,
  'wholenumber': null,
  'commafy': true,
  'shortFormat': true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title': true,
  'cssClass': ['red']
};

class InfluencerList extends Component {

  componentDidMount() {
    this.props.InfluencerFeatureStore.featuredInfluencer('Featured Influencer');
  }
  render() {
    var settings = {
      className: "slider variable-width",
      infinite: true,
      dots: true,
      speed: 300,
      slidesToShow: 6,
      slidesToScroll: 6,
      variableWidth: true,
      swipeToSlide: false,
      draggable: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: false,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: false,
            infinite: true,
          }
        }
      ]
    };
    const store = this.props.InfluencerFeatureStore;
    return (
      <section className="influencer-wpr">
        <div className="heading">
          <h2>Meet some of our influencers</h2>
        </div>
        {store.featured.length !== 0
          ? (
            <div className="influencer-slider">
              <Slider {...settings}>
                {store.featured.map((inf,index) =>
                  <div key={index}>
                    <div className="influencer-item">
                      <div className="inf-img">
					  {inf.avatarUrl ? <img src={inf.avatarUrl} alt="" /> : <img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />}
                      </div>
                      <div className="influencer-details">
                        <Link to={`/influencer-profile/${inf.ownerId}`}><span>@{inf.username.toString().replace(/@/g, "")}</span></Link>
                        <h3><Link to={`/influencer-profile/${inf.ownerId}`}> {inf.first} {inf.last}</Link></h3>
                        <h4><NumericLabel params={optionnumberformat}>{inf.followersCount}</NumericLabel> followers</h4>
                      </div>
                    </div>
                  </div>
                )}
              </Slider>
			  <div className="see-all-featured">
				<Link to="featured-influencer" className="btn btn-white">View All</Link>
			  </div>
            </div>
          )
          :
          (
            <div className="influencer-slider">
              <Slider {...settings}>
                <div>
                  <div className="influencer-item">
                    <div className="inf-img">
                      <Skeleton circle={true} height={150} width={150} />
                    </div>
                    <Skeleton count={4} />
                  </div>
                </div>
                <div>
                  <div className="influencer-item">
                    <div className="inf-img">
                      <Skeleton circle={true} height={150} width={150} />
                    </div>
                    <Skeleton count={4} />
                  </div>
                </div>
                <div>
                  <div className="influencer-item">
                    <div className="inf-img">
                      <Skeleton circle={true} height={150} width={150} />
                    </div>
                    <Skeleton count={4} />
                  </div>
                </div>
                <div>
                  <div className="influencer-item">
                    <div className="inf-img">
                      <Skeleton circle={true} height={150} width={150} />
                    </div>
                    <Skeleton count={4} />
                  </div>
                </div>
                <div>
                  <div className="influencer-item">
                    <div className="inf-img">
                      <Skeleton circle={true} height={150} width={150} />
                    </div>
                    <Skeleton count={4} />
                  </div>
                </div>
                <div>
                  <div className="influencer-item">
                    <div className="inf-img">
                      <Skeleton circle={true} height={150} width={150} />
                    </div>
                    <Skeleton count={4} />
                  </div>
                </div>
              </Slider>
            </div>
          )}
      </section>
    );
  }
}
export default inject('InfluencerFeatureStore')(observer(InfluencerList));