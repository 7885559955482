import {action, observable, decorate} from 'mobx';
import { campaignCountResult } from '../api/CampaignCountApi';
import { remarkListResult } from '../api/RemarkListApi';
import { campaignListResult } from '../api/CampaignListApi';

class MyapplicationListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	campaign = [];
	offset = 0;
	totalCount = '';
	limit = 8;
	preloader = false;
	search = '';
	priceSort = '';
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	
	applicationListFetch = () => {
		this.preloaderFetch(true);

		let param;
		let count;
		let search;
		let priceOrder;
		
		let pattern =  '%25'+this.search+'%25'; 
		
		if(this.priceSort === 'lth'){
			priceOrder = "campaignAmount ASC";
		}else if(this.priceSort === 'htl'){
			priceOrder = "campaignAmount DESC";
		}else{
			priceOrder = "id DESC";
		}

		param = {where:{ownerId:this.userId}}
		
		remarkListResult(JSON.stringify(param))
			.then(res => {
				let campaignId = [];
				res.data.map((inf,index) =>{
					campaignId.push(inf.campaignId)
				});
				
				let param1;
				if(this.search){
					param1 = {include:['remarks','profile'],where:{id: {inq: campaignId},campaignTitle:{like:pattern}},order:priceOrder,offset:this.offset,limit:this.limit}
					count = {id: {inq: campaignId},campaignTitle:{like:pattern}}
				}else{
					param1 = {include:['remarks','profile'],where:{id: {inq: campaignId}},order:priceOrder,offset:this.offset,limit:this.limit}
					count = {id: {inq: campaignId}}
				}

				campaignListResult(JSON.stringify(param1))
					.then(res2 => {
						campaignCountResult(JSON.stringify(count))
							.then(res1 => {
								console.log(res2)
								this.campaignList(res2.data);
								this.pageCountFetch(Math.ceil(res1.data.count / this.limit));
								this.totalCountFetch(res1.data.count);
								this.preloaderFetch(false);
							}).catch(error=>console.log(error))
					}).catch(error=>console.log(error))
			}).catch(error=>console.log(error))
	}

	campaignList	 		= (data) => {this.campaign = data;}
	preloaderFetch 			= (data) => {this.preloader = data;}
	searchFetch 			= (data) => {this.search = data;}
	priceSortFetch 			= (data) => {this.priceSort = data;}
	offsetFetch 			= (data) => {this.offset = data;}
	pageCountFetch 			= (data) => {this.pageCount = data;}
	totalCountFetch			= (data) => {this.totalCount = data;}
	
}

decorate(MyapplicationListStore, {
	userId:observable,auth: observable,campaign: observable,preloader:observable,search: observable,priceSort:observable,offset: observable,totalCount: observable,pageCount:observable,limit: observable,campaignList: action,preloaderFetch:action,searchFetch:action,priceSortFetch:action,offsetFetch: action,totalCountFetch: action,pageCountFetch: action
});

export default MyapplicationListStore;