import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import NumericLabel from 'react-pretty-numbers';
import ShowMoreText from 'react-show-more-text';
import {observer, inject} from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import Popup from "reactjs-popup";
import InstagramLogin from 'instagram-login-react';
import { baseUrl } from '../../baseUrl';
import { APP_ID } from '../../instaConfig';
import queryString from 'query-string';
	

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class Profile extends Component {
	
	constructor(props){
		super(props);
		this.id = this.props.match.params.id
		this.parsed = queryString.parse(this.props.location.search);
	}
	
	componentDidMount = ()=>{
		if(this.parsed.code){
			sessionStorage.removeItem("instaprofile")
			this.props.UserProfileStore.responseInstagram(this.parsed.code);
		}
        this.props.UserProfileStore.profileFetchData('Profile data');
		if(this.id){
			this.props.UserProfileStore.instaPopFetch(true)
			this.props.UserProfileStore.notFoundFetch(true)
		}
		sessionStorage.setItem("instaprofile", 1)
    }
	
	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
    render() {
		const store = this.props.UserProfileStore;
        return (
            <>	{store.auth ?
				<>
				{store.preloader ? 
				<section className="inf-profile-wpr after-header">
                    <div className="container-fluid pl-0 pr-0" >
                        <div className="inf-profile-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-7 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div className="profile-img-wpr">
                                            <div className="profile-img">
											    <Skeleton circle={true} height={190} width={190} />
                                            </div>
                                            {/* <span className="heart-wpr">
                                                <i className="fas fa-heart"> </i>
                                            </span> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                        <div className="right-top">
                                            <div className="profile-name">
												<h2><Skeleton width={144} /></h2>
                                                <h3><Skeleton width={144} /></h3>
                                            </div>
                                        </div>
                                        <div className="right-bottom">
                                            <div className="right-bottom-left">
                                                <div className="d-flex">
                                                    <div className="followers">
                                                        <span className="f-num"><Skeleton /></span>
                                                        <span className="f-text"><Skeleton width={100} height={20} /></span>
                                                    </div>
                                                    {/*<div className="post">
                                                        <span className="f-num"><Skeleton /></span>
                                                        <span className="f-text"><Skeleton width={100} height={20} /></span>
                                                    </div>*/}
												</div>
                                                <div className="right-social">
													<Skeleton width={147} height={42} />			
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bg_al">
                            <div className="row">
                                <div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
								<div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
								<div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
                                <div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
								<div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
								<div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
                                <div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
								<div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
								<div className="col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={280} width={280} />
                                </div>
                            </div>
                        </div>
                    </div>
				</section> :
                <section className="inf-profile-wpr my-profile-new after-header">
                    <div className="container-fluid pl-0 pr-0" >
                        <div className="inf-profile-header">
                            <div className="container">
                                <div className="row">
                                    <div className=" col-7 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div className="profile-img-wpr">
                                            <div className="profile-img">
											{(store.profile.avatarUrl && store.profile.avatarUrl !== 'NA') ? <img src={store.profile.avatarUrl} alt="" /> : <img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />}
                                            </div>
											{/* <span className="heart-wpr">
                                                <i className="fas fa-heart"> </i>
                                            </span>*/}
                                        </div>
                                    </div>
                                    <div className=" col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                        <div className="right-top">
                                            <div className="profile-name">
                                                <h2>{store.profile.first} {store.profile.last}</h2>
                                                <h3>{store.profile.username}</h3>
												{store.profile.city || store.profile.country ? <h4><span className="fas fa-map-marker-alt"></span>{store.profile.city && store.profile.city+','} {store.profile.country}</h4> : null}
                                            </div>
                                            <div className="report-menu-wpr">
                                                <span className="settings">
                                                    <i className="fas fa-cog"> </i>
                                                </span>
                                                <ul className="report-menu">
												{/* <li><Link to="#"> <img src={`${process.env.PUBLIC_URL}/images/icons/block.svg`} alt="" />Block</Link></li>
												<li><Link to="#"> <img src={`${process.env.PUBLIC_URL}/images/icons/report.svg`} alt="" />Report the user </Link></li>*/}
												<li><Link to="/edit-profile"> <img src={`${process.env.PUBLIC_URL}/images/icons/block.svg`} alt="" />Edit Profile</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="right-bottom">
                                            <div className="right-bottom-left">
                                                <div className="d-flex">
                                                    <div className="followers">
                                                        <span className="f-num"><NumericLabel params={optionnumberformat}>{store.instaFollowersCount}</NumericLabel></span>
                                                        <span className="f-text">Followers</span>
                                                    </div>
                                                    {/*<div className="post">
                                                        <span className="f-num"><NumericLabel params={optionnumberformat}>{store.instaPostCount ? store.instaPostCount : '0'}</NumericLabel></span>
                                                        <span className="f-text">Posts</span>
                                                    </div>*/}
                                                </div>
												
                                                <div className="right-social">
													{store.profile.facebookUsername ?
														<a href={`https://www.facebook.com/${store.profile.facebookUsername}`} rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f"></i> facebook</a>
													: <Link to="#"><i className="fab fa-facebook-f"></i> facebook</Link>
													}
													{store.profile.instaUsername ?
														<a href={`https://www.instagram.com/${store.profile.instaUsername}`} rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i> instagram</a>
													: <Link to="#"><i className="fab fa-instagram"></i> instagram</Link>					
													}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
								{store.profile.bio &&
                                <div className="row bio">
                                    <h4 className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">Bio</h4>
                                    <p className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<ShowMoreText
										lines={3}
										more='Show more'
										less='Show less'
										anchorClass=''
										onClick={this.executeOnClick}
										expanded={false}
										width={900}
									>
									{store.profile.bio}
									</ShowMoreText>
									</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bg_al">
						{store.instaUsername ? '' :
                            <div className="instatconnect-alert">
                                <p>Connect your account with us so that we can feature you for more campaigns</p>
								<InstagramLogin
									clientId={APP_ID}
									buttonText="Login"
									onSuccess={store.responseInstagram}
									onFailure={store.responseInstagram}
									useRedirect={true}
									redirectUri={baseUrl}
									scope="user_profile,user_media"
									render={renderProps => (
									<button onClick={renderProps.onClick}>Connect</button>
									)}
								/>
                                
                            </div>
						}
						{/*<>{store.notFound || store.postPrivate ? '' :<h2 className="page-heading text-left">IG Posts</h2>}</>*/}
                            <div className="row">
							{store.instaPosts.length !== 0 ? store.instaPosts.map((inf,index)=>
                                <div className="col-md-4 col-lg-4 mb_30 " key={index}>
                                    <div className="inf_content" id="profile-post-number" data-toggle="modal" data-target={`.profileimg${index}`}>
                                        <div className="content-overlay"></div>
                                        <span><img  src={`${process.env.PUBLIC_URL}/images/icons/copy.svg`} alt="" /></span>
                                        <img className="inf-image" src={inf.node.display_url} alt="" />
                                        <div className="content-details fadeIn-bottom">
                                            <p className="content-text number-post">
                                                <Link to="#"><img src={`${process.env.PUBLIC_URL}/images/category-icon/heart-hover.svg`} alt="" /> <NumericLabel params={optionnumberformat}>{inf.node.edge_media_preview_like.count}</NumericLabel></Link>
                                                <Link to="#"><i className="fa fa-comment"></i> <NumericLabel params={optionnumberformat}>{inf.node.edge_media_to_comment.count}</NumericLabel></Link>
                                            </p>
                                        </div>
										{/* insta profile modal */}
										<div className={`modal fade custom-modal login-modal profileimg${index}`} id="profileimg">
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<div className="row">
															<div className="col-12 col-md-7 col-lg-7 pr-lg-0">
															<img className="img-fluid inf-image" src={inf.node.display_url} alt="" />
															</div>
															<div className="col-12 col-md-5 col-lg-5 pl-lg-0 pl-md-0">
																<div className="modal-right-info">
																	<div className="modal-right-text">
																		<p className="insta-right-text">{inf.node.edge_media_to_caption.edges.length > 0 ? inf.node.edge_media_to_caption.edges[0].node.text : null}</p>
																	</div>
																	<div className="footer-insta-bottom">
																		<div className="col-4 col-md-3 col-lg-3 pl-lg-0">
                                                                            <div className="insta-text">
                                                                                <img src={`${process.env.PUBLIC_URL}/images/heart-icon.svg`} className="img-fluid" alt="" />
                                                                                <span><NumericLabel params={optionnumberformat}>{inf.node.edge_media_preview_like.count}</NumericLabel></span></div>
																		</div>
																		<div className="col-4 col-md-3 col-lg-3 pr-lg-0">
																			<div className="insta-text"><img src={`${process.env.PUBLIC_URL}/images/chaticon.svg`}className="img-fluid" alt="" /><span><NumericLabel params={optionnumberformat}>{inf.node.edge_media_to_comment.count}</NumericLabel></span></div>
																		</div>
																		<div className="col-4 col-md-6 col-lg-6 pr-lg-0">
																			<div className="modal-insta-img">
																				<img src={`${process.env.PUBLIC_URL}/images/Instagram_logo.svg`} className="img-fluid" alt="" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{/* insta profile modal */}
                                    </div>
                                </div>
							): null}
								{!store.instaUsername && <div className="col-12 mt-5 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center account-not-linked">
                                    <img src={`${process.env.PUBLIC_URL}/images/no-post.png`} alt=""/>
                                    <h3 className="page-heading text-capitalize">Instagram account not linked</h3>
                                    <p >Please link your Instagram account to make your posts visible.</p> 
                                </div>}
								{store.postPrivate &&
										<div className="col-12 mt-5  col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center account-not-linked">
                                            <img src={`${process.env.PUBLIC_URL}/images/private.png`} alt=""/>
                                            <h3 className="page-heading text-capitalize">This Account is Private</h3>
                                            <p>Make your account public so that users can view your work.</p> 
									</div>}
                            </div>
                        </div>
                    </div>
					{(store.instaPop && store.notFound) ?
					<Popup
					  open={store.instaPop}
					  closeOnDocumentClick
					  onClose={() => store.instaPopFetch(false)}
					>
						<div className="custom-modal add-instagram">
							<div className="modal-dialog">
							  <div className="modal-content">
								<div className="modal-body text-center">
									<h2 className="modal-heading mb-4">Instagram</h2>
									<form onSubmit={store.addAccountInsta} method="post"> 
										<div className="form-group custom-form-group">
											<input className="form-control" name="instaUsername" placeholder="Instagram username" />
										</div>
										<p className="errorcolor mb-3">{store.required && store.required}</p>
										<button type="submit" className="btn btn-primary w-100" >Add Account {store.instaLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
									</form>
								</div>
							  </div>
							</div>
						</div>
					</Popup> : ''}
				</section> } </> : <Redirect to="/" />}
            </>
        )
    }
}

export default inject('UserProfileStore')(observer(Profile));
