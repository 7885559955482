import React, { Component } from 'react'
import EllipsisText from "react-ellipsis-text";
import { Link } from 'react-router-dom';

class MyFavourites extends Component{
    render(){
        return (
            <>
                <div className="blank-wpr container-fuild border-separator after-header"></div>
                <section className="container w1280">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <h2 className="page-heading">My Favourites</h2>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="custom-sort float-right">
                                <div className="form-group">
                                    <span className="fas down-arrow fa-chevron-down"></span>
                                    <label htmlFor="sort By">Sort By :</label>
                                    <select className="form-control"   name="sortBy">
                                        <option>Price : Low to High</option>
                                        <option>Price :  High to Low</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="my-campaign-search ">
                                <form action="#" method="post" className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group custom-form-group">
                                            <label className="title-search" htmlFor="search-title">Title</label>
                                            <input type="search" name="search-title" placeholder="Type your word here" className="form-control camp-search"/>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className="form-group custom-group">
                                            <span className="fas down-arrow fa-chevron-down"></span>
                                            <label htmlFor="category">Category</label>
                                            <select className="form-control custom-select" id="category" >
                                                <option>All</option>
                                                <option>Fitness</option>
                                                <option>Game</option>
                                                <option>Travel</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className="form-group custom-group">
                                            <span className="fas down-arrow fa-chevron-down"></span>
                                            <label htmlFor="price">Price(SGD)</label>
                                            <select className="form-control custom-select" id="price" >
                                                <option>$10 - $50</option>
                                                <option>$50 - $60</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                                        <button className=" w-100 btn btn-primary">Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                       
                    </div>
                    <div className="campaign-page my-campaigns">
                        <div className="row feature-list">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item">
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/pagoda-temple@2x.png`} alt="" />
                                        <span className="category-tag"> Fitness</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been  Lorem Ipsum has been the.Lorem Ipsum has been the. the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item"   >
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/woman-wearing-blue@2x.png`} alt="" />
                                        <span className="category-tag"> Game</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item"   >
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/green-vegetable@2x.png`} alt="" />
                                        <span className="category-tag"> Fashion</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item"   >
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/glass-bowl@2x.png`} alt="" />
                                        <span className="category-tag"> Food</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item"   >
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/woman@2x.png`} alt="" />
                                        <span className="category-tag"> Fitness</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item"   >
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/woman-wearing-blue@2x.png`} alt="" />
                                        <span className="category-tag"> Game</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="feature-item"   >
                                    <div className="feature-image">
                                        <img src={`${process.env.PUBLIC_URL}/images/feature/pagoda-temple@2x.png`} alt="" />
                                        <span className="category-tag"> Travel</span>
                                    </div>
                                    <div className="feature-details">
                                        <h3>New York Home Fashion Week</h3>
                                        <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /> Olympia, Washington</p>
                                        <h4>S$45.00</h4>
                                        <p><EllipsisText text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...'} length={110} /></p>
                                        <Link to="/">View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
    
}

export default MyFavourites
