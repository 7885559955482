import {action, observable, decorate} from 'mobx';
import { categoryResult } from '../api/CategoryApi';
import { countriesResult } from '../api/CountryApi';
import { sortingResult } from '../api/SortingApi';

class FeaturedInfluencerListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	influencer = [];
	offset = 0;
	search = '';
	totalCount = '';
	categoryFilter = [];
	category = '';
	countryFilter = [];
	country = '';
	gender = '';
	age = '';
	checkSearch = false;
	preloader = false;
	searchKeyword = false;
	limit = 50;
	checkedItems = {};
	like = 1;
	required = false;
	
	searchInfluencer = (event) => {
		this.preloaderFetch(true);

		let param = "?name="+this.search+"&country="+this.country+"&isFeatured=1&followers="+this.like+"&offset="+this.offset

		sortingResult(param)
			.then(res => {
				console.log(res)
				if(res.data.message !== 0){
					this.influencerFetch(res.data.message)
					this.pageCountFetch(Math.ceil(res.data.count / this.limit));
					this.totalCountFetch(res.data.count);
					this.preloaderFetch(false);
				}else{
					console.log('Result not found')
				}
			}).catch(error=>console.log(error))
	}
	
	fetchCategories = () => {
		let param = {where:{categoryStatus:1},order:"id DESC"}
		categoryResult(JSON.stringify(param))
			.then(res => {
				this.categoryFilterFetch(res.data)
			}).catch(error=>console.log(error))
	}
	
	fetchCountries = () => {
		let param = {where:{},order:"name ASC"}
		countriesResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.countryFilterFetch(res.data);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	changeCategory = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const {checkedItems} = this
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
			let inreress = []
			for (let [key, value] of Object.entries(this.checkedItems)){
				if(value)
				inreress.push(key)
			}
			
		this.categoryFetch(inreress);
		this.offsetFetch(0);
		this.searchInfluencer();
	}
	
	categoryUnset = (cat) => {
		const item = cat;
		const isChecked = false;
		const {checkedItems} = this
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
		let inreress = []
			for (let [key, value] of Object.entries(this.checkedItems)){
				if(value)
				inreress.push(key)
			}
			
		this.categoryFetch(inreress);
		this.offsetFetch(0);
		this.searchInfluencer();
	}
	
	categoryFilterFetch = (data) => {this.categoryFilter = data;}
	countryFilterFetch 	= (data) => {this.countryFilter = data;}
	offsetFetch 		= (data) => {this.offset = data;}
	influencerFetch     = (data) => {this.influencer = data;}
	searchFetch     	= (data) => {this.search = data;}
	pageCountFetch 		= (data) => {this.pageCount = data;}
	totalCountFetch		= (data) => {this.totalCount = data;}
	preloaderFetch 		= (data) => {this.preloader = data;}
	countryFetch 		= (data) => {this.country = data;}
	categoryFetch 		= (data) => {this.category = data;}
	searchKeywordFetch 	= (data) => {this.searchKeyword = data;}
	checkedItemsFetch 	= (data) => {this.checkedItems = data;}
	genderFetch 		= (data) => {this.gender = data;}
	ageFetch 			= (data) => {this.age = data;}
	likeFetch 			= (data) => {this.like = data;}
	requiredFetch 		= (data) => {this.required = data;}
}

decorate(FeaturedInfluencerListStore, {
	categoryFilter: observable,
	countryFilter: observable,
	offset: observable,
	influencer: observable,
	search: observable,
	totalCount: observable,
	pageCount:observable,
	preloader:observable,
	country:observable,
	searchKeyword:observable,
	category:observable,
	checkedItems:observable,
	gender:observable,
	age:observable,
	like:observable,
	required:observable,
	categoryFilterFetch: action,
	countryFilterFetch: action,
	offsetFetch: action,
	influencerFetch: action,
	searchFetch: action,
	totalCountFetch: action,
	pageCountFetch: action,
	preloaderFetch: action,
	countryFetch: action,
	searchKeywordFetch:action,
	categoryFetch:action,
	checkedItemsFetch:action,
	changeCategory:action,
	genderFetch:action,
	ageFetch:action,
	categoryUnset:action,
	likeFetch:action,
	requiredFetch:action,
});

export default FeaturedInfluencerListStore;