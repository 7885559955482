let backendHost;
let appId;
let appSecret;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  backendHost = 'https://www.instagram.com';
} else {
  backendHost = 'https://www.instagram.com';
}

appId = '236949734660419';
appSecret = '67393d532686e73737bf423489d6c83d';

export const INSTA_ROOT = `${backendHost}`;
export const APP_ID 	= `${appId}`;
export const APP_SECRET = `${appSecret}`;