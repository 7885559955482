import React from 'react';
import Navigation from '../Navigation';
import Carousel from './Carousel';
//import Search from './Search';
//import Category from './Category';
import News from './News';
import Download from './Download';
import FeatureList from './FeatureList';
import SponsoredList from './SponsoredList';
import ShoutoutExcList from './ShoutoutExcList';
import ProductList from './ProductList';
import InfluencerList from './InfluencerList';



class Home extends React.Component{

	render(){
		return (
			<React.Fragment>
				<Navigation/>
				<Carousel/>
			{/*	<Search/>
				<Category/>
				<FeatureList />
				<SponsoredList />
				<ShoutoutExcList/>
				<ProductList />
				<InfluencerList/>*/}
				<Download/>
			</React.Fragment>
		)
	}
}

export default Home;