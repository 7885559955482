import React, { Component } from 'react';
import {observer, inject} from 'mobx-react';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton';
import NumericLabel from 'react-pretty-numbers';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Popup from "reactjs-popup";
import moment from 'moment';

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class SearchInfluencer extends Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
		this.name = this.props.match.params.name;
		this.changeCountry = this.changeCountry.bind(this)
		this.changeSearch = this.changeSearch.bind(this)
		this.changeInstagramFollower = this.changeInstagramFollower.bind(this)
	}
	
	componentDidMount() {
		this.props.InfluencerListStore.profileOwnFetchData('My profile');
		this.props.InfluencerListStore.fetchCategories('Category');
		this.props.InfluencerListStore.fetchCountries('Country Fetch');
		if(this.props.match.params.name){
			this.props.InfluencerListStore.searchFetch(this.props.match.params.name);
		}else{
			this.props.InfluencerListStore.searchFetch('');
		}
		this.props.InfluencerListStore.searchInfluencer();
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	
	handlePageClick = data => {
		let selected = data.selected;
		let limits = this.props.InfluencerListStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.InfluencerListStore.offsetFetch(offset); 
		this.props.InfluencerListStore.searchInfluencer('Influencer List');
		this.props.InfluencerListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	onSubmit = (event) => {
		event.preventDefault();
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.searchInfluencer();
		this.props.history.push(`${process.env.PUBLIC_URL}/search-influencer`)
	}
	
	changeCountry = (e) => {
		this.props.InfluencerListStore.countryFetch(e.target.value);
	}
	
	changeSearch = (e) => {
		this.props.InfluencerListStore.searchFetch(e.target.value);
	}
	
	genderFilter = (gender) => {
		this.props.InfluencerListStore.genderFetch(gender);
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.searchInfluencer();
	}

	ageFilter = (age) => {
		this.props.InfluencerListStore.ageFetch(age);
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.searchInfluencer();
	}
	
	changeInstagramFollower = (e) => {
		this.props.InfluencerListStore.likeFetch(e.target.value);
		this.props.InfluencerListStore.searchInfluencer();
	}
	
	countryUnset = () => {
		this.props.InfluencerListStore.countryFetch('');
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.searchInfluencer();
	}
	
	genderUnset = () => {
		this.props.InfluencerListStore.genderFetch('');
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.searchInfluencer();
	}
	ageUnset = () => {
		this.props.InfluencerListStore.ageFetch('');
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.searchInfluencer();
	}
	
	clearAllFilter = () => {
		this.props.InfluencerListStore.countryFetch('');
		this.props.InfluencerListStore.categoryFetch('');
		this.props.InfluencerListStore.ageFetch('');
		this.props.InfluencerListStore.checkedItemsFetch([]);
		this.props.InfluencerListStore.offsetFetch(0);
		this.props.InfluencerListStore.genderFetch('');
		this.props.InfluencerListStore.likeFetch(1);
		this.props.InfluencerListStore.searchInfluencer();
	}
	
	linkConnectClose = () =>{
		sessionStorage.removeItem('instalink')
		this.props.InfluencerListStore.notFoundMyFetch(false)
	}
	
    render(){
		const store = this.props.InfluencerListStore;
		const instalinkcheck = sessionStorage.getItem('instalink');
		let todayDate = moment().format('DD/MM/YYYY HH:mm:ss');
        return (
            <>
                <div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
                <div id="influencer_list">
                <section className="container w1280 search-influencer-wpr">
					{store.auth && <>
						{store.instaUsernameOwn || !instalinkcheck || !store.notFoundMy ? '' :
						<div className="instatconnect-alert">
							<p>Connect your account with us so that we can feature you for more campaigns</p>
							<Link to="/profile">Connect</Link>
							<span onClick={this.linkConnectClose}><img src={`${process.env.PUBLIC_URL}/images/alert-close.svg`} alt="" /></span>
						</div> 
						}
					</>
					}
                    <h2 className="page-heading">Influencers</h2>
                    {/* <div className="search-inf-wpr">
                        <form onSubmit={this.onSubmit} method="post" className="search-inf">
                            <span className="fa fa-search search-icon"></span>
							<input type="text" defaultValue={store.search} onChange={this.changeSearch} className=" form-control" placeholder="Search"/>
                            <button type="submit" className="btn btn-primary">Search</button>
                        </form>
                    </div> */}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="my-campaign-search">
                                <form onSubmit={this.onSubmit} method="post" className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                        <div className="form-group custom-form-group">
                                            <label className="title-search" htmlFor="search-title">Search Keyword</label>
											<input type="text" name="search-title" value={store.search} onChange={this.changeSearch} className="form-control camp-search" placeholder="Type your word here"/>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                        <div className="form-group custom-group">
                                            <span className="fas down-arrow fa-chevron-down"></span>
                                            <label htmlFor="category">Country</label>
                                            <select className="form-control custom-select" id="countries" onChange={this.changeCountry}>
											<option value="">Select Countries</option>
											{store.countryFilter.length !== 0 ? store.countryFilter.map((c,index) =>
												<option value={c.name} selected={store.country === c.name} key={index}>{c.name}</option>
											):null}
										</select>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                                        <button type="submit" className="w-100 btn btn-primary">Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="find-result-sort-wpr">	
					<p className="found-inf"></p>
						{/*<p className="found-inf">You found <span className="found-number">{store.totalCount}</span> independent Influencers who belong to <span className="found-type">"{store.search}"</span></p> */}
                        <div className="custom-sort">
                            <div className="form-group">
                                <span className="fas down-arrow fa-chevron-down"></span>
                                <label htmlFor="sort By">Sort By :</label>
                                <select className="form-control" id="sortBy" name="sortBy" onChange={this.changeInstagramFollower}>
                                    <option value="">Select Sort By</option>
                                    <option selected={store.like === '2'} value="2">Most Followers</option>
                                    <option selected={store.like === '4'} value="4">Last Active</option>
                                    <option selected={store.like === '3'} value="3">Nearest</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row inf-form-result-wpr">
                        {/* <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 ">
                            <div className="left-search">
								<div className="influen-search-wpr" id="campSticky">
                                    <div className="search-clear-wpr">
                                        <span className="seacrh-by">Search By</span>
                                        <span className="clear-all" id="clearAllFilter" onClick={this.clearAllFilter}>Clear</span>
                                    </div>
                                    
                                    <div className="searched-cat">
                                        {store.category ? store.category.map((cat,index)=>
											<span key={index}>{cat} <i className="fa fa-times-circle" onClick={()=>store.categoryUnset(cat)}></i></span>) : null}
										{store.country ? <span>{store.country} <i className="fa fa-times-circle" onClick={this.countryUnset}></i></span> : null}
										{store.gender ? <span>{store.gender} <i className="fa fa-times-circle" onClick={this.genderUnset}></i></span> : null}
										{store.age ? <span>{store.age} <i className="fa fa-times-circle" onClick={this.ageUnset}></i></span> : null}
                                    </div> */}
                                    {/* <div className="separator"></div>
									<div className="form-group custom-group">
                                        <label htmlFor="Category">Category</label>
                                        <div className="cat-list">
											{store.categoryFilter.length !== 0 ? store.categoryFilter.map((c,index) =>
                                            <div className="form-check" key={index}>
                                                <label className="form-check-label">
												<input type="checkbox" className="form-check-input" name={c.categoryName} id={`checkbox${index}`}  
													checked={store.checkedItems[c.categoryName]} 
													onChange={store.changeCategory} />
                                                    <span className="checkbox-text">
                                                        <span>
														{c.categoryName}
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
											):null}
                                        </div>
                                    </div> */}
                                    {/* <div className="separator"></div>
                                    <div className="form-group custom-group">
                                        <span className="fas down-arrow fa-chevron-down"></span>
                                        <label htmlFor="countries" className="select-heading">Location</label>
                                        <select className="form-control custom-select" id="countries" onChange={this.changeCountry}>
											<option>Select Countries</option>
											{store.countryFilter.length !== 0 ? store.countryFilter.map((c,index) =>
												<option value={c.name} selected={store.country === c.name} key={index}>{c.name}</option>
											):null}
										</select>
                                    </div>

                                    <div className="separator"></div>
                                    <h4 className="form-heading">Gender</h4>
                                    <div className="gender-list" id="campaignGender">
										<span className={store.gender === '' && 'active'} onClick={()=>this.genderFilter('')}>All</span>
										<span className={store.gender === 'Male' && 'active'} onClick={()=>this.genderFilter('Male')}>Male</span>
										<span className={store.gender === 'Female' && 'active'} onClick={()=>this.genderFilter('Female')}>Female</span>
									</div> */}
                                    {/*<div className="separator"></div>
                                    <h4 className="form-heading">Age</h4>

                                     <div className="age-list">
                                        <span className={store.age === '' && 'active'} onClick={()=>this.ageFilter('')}>All</span>
                                        <span className={store.age === '18-24' ? 'active' : ''} onClick={()=>this.ageFilter('18-24')}>18-24</span>
                                        <span className={store.age === '25-34' ? 'active' : ''} onClick={()=>this.ageFilter('25-34')}>25-34</span>
                                        <span className={store.age === '35-44' ? 'active' : ''} onClick={()=>this.ageFilter('35-44')}>35-44</span>
                                        <span className={store.age === '45-54' ? 'active' : ''} onClick={()=>this.ageFilter('45-54')}>45-54</span>
                                        <span className={store.age === '55-64' ? 'active' : ''} onClick={()=>this.ageFilter('55-64')}>55-64</span>
									</div> */}
								{/* </div>
                            </div>
                        </div> */}
						{store.preloader ?
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                            <div className="row">
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton  circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
								<div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="applicants_bx pt-3">
                                        <Skeleton circle={true} height={150} width={150} />
                                        <p><Skeleton count={3} width={200} /></p>
                                        <div className="ap_social text-center">
                                            <div className="social-count" >
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                                <div className="social">
                                                    <Skeleton count={1} width={100} />
                                                    <Skeleton count={1} width={100} />
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>
							</div>
						</div> :
						<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                            <div className="row influencer-lists">
								{store.influencer.length !== 0 ? store.influencer.map((info,index)=>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12" key={index}>
                                    <div className="applicants_bx">
									{/* <div className="dotsbul">
                                            <span className=" dot-menu fas fa-ellipsis-h"></span>
                                            <ul className="report-menu">
                                                <li><Link to="#"> <img src={`${process.env.PUBLIC_URL}/images/icons/block.svg`} alt="" />Block</Link></li>
                                                <li><Link to="#"> <img src={`${process.env.PUBLIC_URL}/images/icons/report.svg`} alt="" />Report the user </Link></li>
                                            </ul>
									</div> */}
                                        
                                        <div className="editp">
                                            <div className="editpro-wrapper">
                                            <Link to={`/influencer-profile/${info.ownerId}`}>{info.avatarUrl ? <img src={info.avatarUrl} alt="" /> : <img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />}</Link>
                                            </div>
                                        </div>
                                        {/*  <span className="search-influencer-offline"></span>  
                                            <span className="search-influencer-online"></span> */}
										{(info.lastSeen && (moment.utc(moment(todayDate,"DD/MM/YYYY HH:mm:ss").diff(moment(moment(info.lastSeen).format('DD/MM/YYYY HH:mm:ss'),"DD/MM/YYYY HH:mm:ss"))).format("m") > 1)) ? 
												<p className="offline-content"> <span>Last Active {moment(info.lastSeen).fromNow()}</span> </p> 
											: info.lastSeen == null ? 
												'' 
											: <p className="online-content">Online</p>}
										{store.userId == info.ownerId ? <>
											<h2><Link to={`/profile`}>{info.first} {info.last}</Link></h2>
											<span className="username"><Link to={`/profile`}>{info.username && '@'+info.username.toString().replace(/@/g, "")}</Link></span> </>
										: <>
											<h2><Link to={`/influencer-profile/${info.ownerId}`}>{info.first} {info.last}</Link></h2>
											<span className="username"><Link to={`/influencer-profile/${info.ownerId}`}>{info.username && '@'+info.username.toString().replace(/@/g, "")}</Link></span> </>
										}
                                        <p>{info.country ? info.country.toString().replace(/"/g, "") : null}</p>
										{/* <div className="cuss">
                                            <span>Cooking</span>
                                            <span>Fashion</span>
                                            <span>Fitness</span>
                                            <span className="show-more-hobby"><i className="fa fa-chevron-right"></i></span>
										</div> */}
                                        <div className="ap_social">
                                            <div className="social-count" >
												<div className="social">
                                                   <div className="m-0 s-count"><NumericLabel params={optionnumberformat}>{info.followersCount}</NumericLabel></div>
												   <div className="m-0 s-text">Followers</div>
												</div>
                                                {/*<div className="insta-wpr social">*/}
                                                   {/* <div className="m-0 s-count"><NumericLabel params={optionnumberformat}>{info.totalPosts}</NumericLabel></div>
													<div className="m-0 s-text">Posts</div>*/}
                                                {/* </div>*/}
                                            </div>			
                                        </div>	
                                        <div className="overlay-text">
                                            <div className="overlay-close"><i className="fa fa-times"></i></div>
                                            <div className="inner-overlay-wpr">
                                                <div className="cuss">
                                                    <span>Cooking</span>
                                                    <span>Fashion</span>
                                                    <span>Fitness</span>
                                                    <span>Cooking</span>
                                                    <span>Fashion</span>
                                                    <span>Fitness</span>
                                                    <span>Cooking</span>
                                                    <span>Fashion</span>
                                                    <span>Fitness</span>
                                                </div>
                                            </div>
                                        </div>		  
                                    </div>
                                </div>):
									<div className="col-lg-12">
										 <div className="no-result-wpr">
											<div className="no-result-found">
												<div className="no-result-img">
													<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
												</div>
												<p className="no-result">Sorry, no result found</p>
											</div>
										</div>
									</div>
								}
                            </div>
						</div>}
                    </div>
                </section>
				<section className="container w1280">
                    <div className="pg-wpr row">
                    {store.totalCount > store.limit ?
                        <>
                        <div className="pg-left col-12 col-lg-6 col-xl-6 col-md-6 col-sm-12 ">
						<p>Showing 1 of {store.limit} of {store.totalCount} entries</p>
                        </div>
                        <div className="pg-right col-12 col-lg-6 col-xl-6 col-md-6 col-sm-12 ">
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={store.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'custom-pagination'}
                                subContainerClassName={''}
                                activeClassName={'active'}
                            />
                        </div>
                        </>
                        :null}
                    </div>
				</section>
                </div>
				<Popup
					 open={store.errorCheck}
					 closeOnDocumentClick
					 onClose={() => store.errorCheckFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">Koli would like to access your location to show nearby Influencers</p>
							<button onClick={ () => [store.errorCheckFetch(false),store.likeFetch('2'),store.searchInfluencer()]} className="btn btn-primary">Ok </button>
						</div>
						
					</div>
				</Popup>
            </>
        )
    }
}

export default inject('InfluencerListStore','CreatecampaignListStore')(observer(SearchInfluencer));
