import React, { useEffect, useContext, useState } from 'react';
import { baseUrl } from '../../baseUrl';
import { APP_ID } from '../../instaConfig';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {observer} from 'mobx-react-lite';
import { useForm } from "react-hook-form";
import { Link, Redirect} from 'react-router-dom';
import { useParams} from "react-router";
import { userProfile } from "../../stores/EditprofileStore";
import { category } from "../../stores/InfluencerFeatureStore";
import BankAccount from "./BankAccount";
import Popup from "reactjs-popup";
import FileBase64 from 'react-file-base64';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import InstagramLogin from 'instagram-login-react';



const EditProfile = observer((props) => {
	
	const { id } = props.match.params;
	const queryString = require('query-string');
	const parsed = queryString.parse(props.location.search);

	const [showUnblock, setShowUnblock] = useState('')
	const showUnBlockMenu = (data) => {
		if(data){
			setShowUnblock(data);
			
		}else{
			setShowUnblock('');
		} 
	}

	const store = useContext(userProfile);
	const store2 = useContext(category);
	const { handleSubmit, register, errors } = useForm({});
	const {register: register2, errors: errors2, handleSubmit: handleSubmit2} = useForm({});

	useEffect(() => {
		sessionStorage.removeItem("instaprofile")
		if(store.auth){
			store.responseInstagram(parsed.code);
			store.fetchUserData('User Data Fetch');
			store.fetchCountries('Country Fetch');
			store.fetchGeoPhoneCode('Fetch Geo PhoneCode');
			store.fetchInterest('Interest data');
			store.bankFetchData('Bank Detail');
			store.fetchblockedUserData('Blocked User')
			if(id && id == 'setting'){
				store.tabChange('setting')
			}else{
				store.tabChange('profile')
			}
		}
		
		
	}, []);
	
	return (
		<>	
			<div className="blank-wpr container-fuild border-separator after-header"></div>
			{store.auth ?
			<div className="container edit-profile-main">
				<h1 className="page-heading">Account Settings</h1>
				<div className="tab-top-wpr">
					<ul className="nav nav-tabs custom-nav">
						<li className="nav-item">
							<Link className={store.tab.profileTab} onClick={()=>store.tabChange('profile')} data-toggle="tab" to="#">Profile</Link>
						</li>
						<li className="nav-item">
							<Link className={store.tab.blockedTab} onClick={()=>store.tabChange('blocked')} data-toggle="tab" to="#">Blocked User</Link>
						</li>
						<li className="nav-item">
							<Link className={store.tab.settingTab} onClick={()=>store.tabChange('setting')}  data-toggle="tab" to="#">Settings</Link>
						</li>
					</ul>
					<span className="change-password"><i className="fas fa-lock"></i>Change Password</span>
				</div>

				<div className="tab-content account-tab-content">
					<div id="profile" className={store.section.profile}>
						<div className="profile-continer">
							<div className="row">
							<h2 className="form-main-heading col-12 col-xl-12 col-lg-12 col-md-12 col-md-12">User Information</h2>
								<div className="edit-left col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12">
									<form className="row" onSubmit={handleSubmit(store.handleSubmit)} method="post">
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-form-group">
												<label htmlFor="username">Username</label>
												<input type="text" className="form-control" defaultValue={store.profile.username} disabled name="username" />
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-form-group">
												<label htmlFor="useremail">Email address</label>
												<input type="email" className="form-control" defaultValue={store.profile.email} disabled name="email" />
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-form-group">
												<label htmlFor="firstname">First name</label>
												<input type="text" className="form-control" defaultValue={store.profile.first} name="first" placeholder="First name" ref={register({
												  required: 'First name is required',
												})} />
												<span className="errorcolor" >{errors.first && errors.first.message}</span>
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-form-group">
												<label htmlFor="lastname">Last name</label>
												<input type="text" className="form-control" defaultValue={store.profile.last} name="last" placeholder="Last name" ref={register({
												  required: 'Last name is required',
												})} />
												<span className="errorcolor" >{errors.last && errors.last.message}</span>
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
											<div className="mobile-number custom-form-group">
												<label htmlFor="mobile">Mobile Number</label>
												<div className="btn-group ">
													<div className="form-group custom-form-group m-left">
														<select className="form-control m-select" name="countryCode" ref={register}>
															{store.phoneCode ? store.phoneCode.map((c,index) =>
																<option value={c.slice(1)} selected={store.profile.countryCode ? '+'+store.profile.countryCode === c : store.geoPhoneCode === c} key={index}>{c}</option>
															):null}
														</select>
													</div>
													<div className="form-group custom-form-group m-right">
														<div className="customtelno">
														<input type="text" className="form-control" name="mobile" maxLength="10" defaultValue={store.profile.mobile} placeholder="Mobile number" ref={register({
														  required: 'Mobile number is required',
														  pattern: {
															value: /^[0-9]+$/,
															message: "Invalid mobile number"
														  }
														})} />
														{/*<span className="verfiednoornot">VERIFIED</span>*/}
														</div>
														<span className="errorcolor">{errors.mobile && errors.mobile.message}</span>
													</div>
												</div>
												<p className="not-public">This will not be displayed to public</p>
											</div>
										</div>
										<input type="hidden" id="checkid" value={id} />
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-select-box">
												<label htmlFor="gender">Gender</label>
												<div className="form-group custom-form-group m-left">
													<select className={(id && id != 'setting' && !store.profile.gender) ? 'form-control errorinput' : 'form-control'} name="gender" ref={register}>
														<option value="">Select gender</option>
														<option selected={store.profile.gender == 'Female' || store.profile.gender == 'female'} value="Female">Female</option>
														<option selected={store.profile.gender == 'Male' || store.profile.gender == 'male'} value="Male">Male</option>	
													</select>
												</div>
											</div>
										</div>

										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
												<div className="form-group custom-select-box">
													<label htmlFor="country">Country</label>
													<div className="form-group custom-form-group m-left">
														<select name="country" className={(id && id != 'setting' && !store.profile.country) ? 'form-control errorinput' : 'form-control'} ref={register}>
															<option value="">Select country</option>
															{store.country.length !== 0 ? store.country.map((c,index) =>
															<option value={c.name} selected={store.profile.country === c.name} key={index}>{c.name}</option>
															):null}
														</select>
													</div>
												</div>
											</div>

										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-form-group custom-date">
												<span className="fas cal-icon fa-calendar"></span>
												<label htmlFor="firstname">DOB</label>
												<DatePicker
													selected={store.date}
													dateFormat="dd/MM/yyyy" className={(id && id != 'setting' && !store.date) ? 'form-control errorinput' : 'form-control'}
													name="dob"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													autoComplete="off"
													placeholderText="DOB"
													maxDate={new Date()}
													onChange={store.changeDate}
												/>
											</div>
										</div>
										
										{/* <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="form-group custom-select-box">
												<label htmlFor="country">Country</label>
												<div className="form-group custom-form-group m-left">
													<select name="country" className={id && !store.profile.country ? 'form-control errorinput' : 'form-control'} ref={register}>
														<option value="">Select country</option>
														{store.country.length !== 0 ? store.country.map((c,index) =>
														<option value={c.name} selected={store.profile.country === c.name} key={index}>{c.name}</option>
														):null}
													</select>
													
													{id && !store.profile.country ? <span className="errorcolor" >Country is required</span> : null}
												</div>
											</div>
										</div> */}
										<div className="formtopborder">
											<h2 className="form-main-heading  col-lg-12 mt-4 mb-3">About</h2>
										</div>
										
										<div className="col-lg-12">
											<div className="form-group custom-form-group">
												<label htmlFor="bio" className="form-modal-heading">Bio</label>
												<textarea className={(id && id != 'setting' && !store.profile.bio) ? 'form-control errorinput' : 'form-control'} name="bio" ref={register} defaultValue={store.profile.bio} rows="5" id="bio" placeholder="Enter Your Comment"></textarea>
											</div>
										</div>

										{/* <div className="formtopborder">
											<h2 className="form-main-heading  col-lg-12 mt-4 mb-3">Location</h2>
										</div> */}

										{/* <div className="formaddresswrapper">
											<div className="col-lg-12">
												<div className="form-group custom-form-group">
													<label htmlFor="bio" className="form-modal-heading">Address</label>
													<textarea className={id && !store.profile.bio ? 'form-control errorinput' : 'form-control'} name="bio" ref={register} defaultValue={store.profile.bio} rows="5" id="bio" placeholder="Enter Address"></textarea>
													
													{id && !store.profile.bio ? <span className="errorcolor" >Bio is required</span> : null}
												</div>
											</div>
											<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
												<div className="form-group custom-select-box">
													<label htmlFor="country">Country</label>
													<div className="form-group custom-form-group m-left">
														<select name="country" className={id && !store.profile.country ? 'form-control errorinput' : 'form-control'} ref={register}>
															<option value="">Select country</option>
															{store.country.length !== 0 ? store.country.map((c,index) =>
															<option value={c.name} selected={store.profile.country === c.name} key={index}>{c.name}</option>
															):null}
														</select>
														
														{id && !store.profile.country ? <span className="errorcolor" >Country is required</span> : null}
													</div>
												</div>
											</div>
										</div> */}

										
										<div className="form-divider col-lg-12 mt-4 mb-2"><p></p> </div>
										<div className="col-lg-12 ">
											<label htmlFor="Interest" className="insterest-label">Your Interest</label>
											<div className="interest-wpr">
												{store.interest.length !== 0 ? store.interest.map((person,index) =>
													<label htmlFor={`checkbox${index}`} className={store.checkedItems[person] ? 'active interestClick' : 'interestClick'} key={index} >{person} <input type="checkbox" name={person} id={`checkbox${index}`}  
													checked={store.checkedItems[person]} 
													onChange={store.handleChangeInterest} /></label>
												):null}
											</div>
										</div>
										<div className="form-divider col-lg-12 mt-4 mb-2"><p></p> </div>

										<div className="col-lg-12 ">
											<label htmlFor="Instagram" className="insta-label">Link Your Social Account</label>
											
											<div className="row">
												{store.instaUsername === "" || store.instaUsername === null || store.instaUsername === undefined ? 
												(<div className="col-lg-7">
													<div className="insta-wpr">
														<div className="mt-2">
														{/*<span className={(id && id != 'setting' && !store.instaUsername) ? 'errorinput' : ''} onClick={()=>store.instaPopFetch(true)}><img src={`${process.env.PUBLIC_URL}/images/icons/instagram.png`} alt="" /> Link Now </span>*/}
														<InstagramLogin
															clientId={APP_ID}
															buttonText="Login"
															onSuccess={store.responseInstagram}
															onFailure={store.responseInstagram}
															useRedirect={true}
															redirectUri={baseUrl}
															scope="user_profile,user_media"
															render={renderProps => (
															<span className={(id && id != 'setting' && !store.instaUsername) ? 'errorinput' : ''}  onClick={renderProps.onClick}><img src={`${process.env.PUBLIC_URL}/images/icons/instagram.png`} alt="" /> Link Now </span>
															)}
														/>
														
														</div>
													</div>
												</div>) :(
													<div className="col-lg-7">
														<div className="insta-wpr">
															<div className="mt-2">
															<span> <img src={`${process.env.PUBLIC_URL}/images/icons/instagram.png`} alt="" /> {store.instaUsername} </span>
															<span className="remove-insta-btn" onClick={()=>store.removeInstaAccount()}> Remove {store.instaLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</span>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="col-lg-12"><p></p> </div>
										<div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
											<div className="form-group custom-form-group">
												<label htmlFor="lastname">Price Per Post</label>
												<input type="text" className={(id && id != 'setting' && !store.profile.instaPerPost > 0) ? 'form-control errorinput' : 'form-control'} defaultValue={store.profile.instaPerPost > 0 ? store.profile.instaPerPost : ''} name="instaPerPost" placeholder="Price Per Post" ref={register}/>
											</div>
										</div>
										<div className="col-lg-12 ">
											<div className="row">
												{store.facebookUsername === "" || store.facebookUsername === null || store.facebookUsername === undefined ? 
												(<div className="col-lg-7">
													<div className="insta-wpr">
														<div className="mt-2">
															<FacebookLogin
															  appId="406815239962657"
															  autoLoad={false}
															  fields="name,id"
															  callback={store.responseFacebook}
															  render={renderProps => (
																<span onClick={renderProps.onClick}><img src={`${process.env.PUBLIC_URL}/images/icons/facebook-sq.png`} alt="" /> Link Now </span>
															  )}
															/>
														</div>
													</div>
												</div>) :(
													<div className="col-lg-7">
														<div className="insta-wpr">
															<div className="mt-2">
															<span> <img src={`${process.env.PUBLIC_URL}/images/icons/facebook-sq.png`} alt="" /> {store.facebookUsername} </span>
															<span className="remove-insta-btn" onClick={()=>store.removeFacebookAccount()}> Remove {store.facebookLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</span>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="col-lg-12"><p></p> </div>
										<div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
											<div className="form-group custom-form-group">
												<label htmlFor="lastname">Price Per Post</label>
												<input type="text" className="form-control" defaultValue={store.profile.facebookPerPost > 0 ? store.profile.facebookPerPost : ''} name="facebookPerPost" placeholder="Price Per Post" ref={register}/>
											</div>
										</div>
										<div className="col-lg-12 mt-4">
											<button type="submit" className="btn btn-primary">Update Account {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
										</div>
									</form>
								</div>
								<div className="edit-right col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 text-right">
									<div className="img-container">
										<div className="img-wpr float-right clearfix">
										{store.isLoader && <i className="fa fa-spinner photo-loader fa-pulse" style={{fontSize:'40px'}}></i>}
											{(store.avatarUrl && store.avatarUrl !== 'NA') ? <img src={store.avatarUrl} alt="" /> : <img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />}
										</div>
										<span className="plus-icon" ><i className="fas fa-plus"></i> <FileBase64 onDone={ store.getFiles } /></span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="blockedUser" className={store.section.blocked}>
						<div className="row mb-2 blocked-search">
						{/*<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
								<div className="form-group custom-form-group ">
									<input type="search" name="search-title" placeholder="Search here" className="form-control"/>
								</div>
						</div>*/}
							{/*<div  className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
								<div className="successfully-unblock">
									<i className="far fa-check-circle"></i> <span className="ml-2">"Mollie Runte" Successfully Unblock</span>
								</div>
							</div>*/}
						</div>
						<div className="row blocked-user-list">
						{store.blockeduser.length !== 0 ? store.blockeduser.map((person,index) => <>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12" key={index}>
                                <div className="applicants_bx blocked-user">
									<div className="ellipsis-menu-wpr">
										<span className={showUnblock === person.id ? "fas ellipsis-menu fa-ellipsis-v active": "fas ellipsis-menu fa-ellipsis-v"} onClick={()=>showUnBlockMenu(person.id)}></span>
										{ showUnblock === person.id ? <div className="unblock" data-toggle="modal" data-target={`#blockshowModal${person.id}`} onClick={()=>store.unblockCheckFetch(person.id)}>Unblock</div> : null }
									</div>
									<div className="editp">
									{(person.profile.avatarUrl && person.profile.avatarUrl !== 'NA') ? <img src={person.profile.avatarUrl} alt="" /> : <img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />}
									</div>
									<h2><Link to={`/`}>{person.profile.first} {person.profile.last}</Link></h2>
									<span className="username">{person.profile.instaUsername}</span>
									<p>{person.profile.city} {person.profile.country}</p>	  
                                </div>
                            </div>
							<div className={store.unblockCheck === person.id ? 'modal fade applicantspaymentModal show' : 'modal fade applicantspaymentModal'} id={`blockshowModal${person.id}`} style={{display: store.unblockCheck === person.id ? 'block' : 'none'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
								<div className="modal-dialog modal-dialog-centered" role="document">
									<div className="modal-content">
										<div className="modal-body">
											<div className="custom-popup text-center">
												<div className="popup-details">
													<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
													<p className="popup-details mb-4">Are you sure you want to unblock?</p>
													<button data-dismiss="modal" onClick={() => [store.unblockCheckFetch(''),showUnBlockMenu(person.id),setShowUnblock('')]} className="btn cancel-btn btn-secondary mr-3">No</button>
													<button onClick={() => store.UnblockUser(person.id)} className="btn btn-primary">Yes {store.unblockLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>):
							<div className="col-lg-12">
								 <div className="no-result-wpr">
									<div className="no-result-found">
										<div className="no-result-img">
											<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
										</div>
										<p className="no-result">Sorry, no result found</p>
									</div>
								</div>
							</div>
						}
						</div>
						
					</div>
					<div id="settings" className={store.section.setting}>
						<BankAccount />
						<div className="setting-bankinfo">
						<div className="notification-text">Notification Settings</div>
							<div className="form-check bank-account" style={{paddingBottom:'20px'}}>
								<input type="checkbox" name="emailnotify" value={store.emailNotify} checked={store.emailNotify} onClick={store.emailNotifySetting} className="form-check-input" id="exampleCheck1" />
								<label className="form-check-label" htmlFor="exampleCheck1">Email Notifications</label>
							</div>
						</div>
						</div>
					</div>
				<Popup
					open={store.enableDisableMail}
					closeOnDocumentClick
					onClose={() => store.enableDisableMailFetch(false)}
				>
				<div className="custom-popup text-center">
					<div className="popup-details">
						<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
						{store.emailNotify === false ?
							<p className="popup-details mb-4">Are you sure you want to enable email notification settings?</p>
						:
							<p className="popup-details mb-4">Are you sure you want to disable email notification settings?</p>
						}
						<button onClick={ () => [store.enableDisableMailFetch(false), store.emailNotify === false ? store.emailNotifyFetch(false) : store.emailNotifyFetch(true)]} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
						<button onClick={() => store.submitEmailSetting()} className="btn btn-primary">Yes {store.bankloader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
					</div>
					
				</div>
				</Popup>
				<Popup
					open={store.bankDeletePop}
					closeOnDocumentClick
					onClose={() => store.bankDeletePopFetch(false)}
				>
				<div className="custom-popup text-center">
					<div className="popup-details">
						<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
						<p className="popup-details mb-4">Are you sure you want to Delete?</p>
						<button onClick={ () => store.bankDeletePopFetch(false)} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
						<button onClick={() => store.bankDelete()} className="btn btn-primary">Yes {store.bankloader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
					</div>
					
				</div>
				</Popup>

				<Popup
				  open={store.done}
				  closeOnDocumentClick
				  className="custom-popup"
				  onClose={() => store.doneFetch(false)}
				>
					<div className="custom-modal update-successfully">
						<div className="modal-dialog">
						  <div className="modal-content">
							<div className="modal-body text-center">
								<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/icons/success.png`} alt="" /> 
								<h2 className="modal-heading">Profile Updated!</h2>
								<p>Your account has been updated successfully.</p>
								{(id && id != 'setting') ?
									<Link to={`${process.env.PUBLIC_URL}/campaigndetail/${id}`} className="btn btn-primary" onClick={() => store.doneFetch(false)} >Done</Link>
								:
									<Link to="/profile" className="btn btn-primary" onClick={() => store.doneFetch(false)} >Done</Link>
								}
							</div>
						  </div>
						</div>
					</div>
				</Popup>
				<Popup
				  open={store.errImage}
				  closeOnDocumentClick
				  onClose={() => store.errImageFetch(false)}>
					<div className="custom-modal max-upload">
						<div className="modal-dialog">
						  <div className="modal-content">
							<div className="modal-body text-center">
								<h2 className="modal-heading">Warning!!</h2>
								<ul>
									<li>The maximum allowed size for uploads is 2mb.</li>
									<li>Only image with the following extensions are allowed: jpg, jpeg, png.</li>
								 </ul>
								<Link to="#" className="btn btn-primary" onClick={() => store.errImageFetch(false)} >Done</Link>
							</div>
						  </div>
						</div>
					</div>
				</Popup>
				{store.instaPop &&
				<Popup
				  open={store.instaPop}
				  closeOnDocumentClick
				  onClose={() => store.instaPopFetch(false)}
				>
					<div className="custom-modal add-instagram">
						<div className="modal-dialog">
						  <div className="modal-content">
							<div className="modal-body text-center">
								<h2 className="modal-heading mb-4">Instagram</h2>
								<form onSubmit={handleSubmit2(store.addAccountInsta)} method="post"> 
									<div className="form-group custom-form-group">
										<input className="form-control" name="instaUsername" defaultValue={store.instaUsername}  placeholder="Instagram username" ref={register2} />
									</div>
									<p className="errorcolor mb-3">{store.required && store.required}</p>
									<button type="submit" className="btn btn-primary w-100" >Add Account {store.instaLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
								</form>
							</div>
						  </div>
						</div>
					</div>
				</Popup>}
			</div> : <Redirect to="/" />}
		</>
	);  
});

export default EditProfile;
