import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { categoryResult } from '../api/CampaignCategoryApi';

class HomeSearchStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	categoryFilter = [];
	
	fetchCategories = () => {
		let param = {where:{categoryStatus:1}}
		categoryResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.categoryFilterFetch(res.data);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	categoryFilterFetch = (data) => {this.categoryFilter = data;}
	
}

decorate(HomeSearchStore, {
	categoryFilter: observable,
	categoryFilterFetch: action,
	fetchCategories: action
});

export const category = createContext(new HomeSearchStore());
export default HomeSearchStore;