import {action, observable, decorate} from 'mobx';
import { categoryResult } from '../api/CategoryApi';

class CategoryStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	category = [];
	
	categoryGet = () =>{
		let param = {where:{isFeatured:1},order:"id DESC"}
		categoryResult(JSON.stringify(param))
			.then(res => this.setCategory(res.data))
			.catch(error => error)
	}
	
	setCategory = (data) => {
		this.category = data;
	}
	
}

decorate(CategoryStore, {
	category: observable,
	setCategory: action
});

export default CategoryStore;