import React, { Component } from 'react';
import {observer, inject} from 'mobx-react';
import EllipsisText from "react-ellipsis-text";
import Skeleton from 'react-loading-skeleton';
import NumericLabel from 'react-pretty-numbers';
import { Link, Redirect } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import NumberFormat from 'react-number-format';

var optionnumberformat = {
    'justification': 'C',
    'percentage': false,
    'precision': 0,
    'wholenumber': null,
    'commafy': true,
    'shortFormat': true,
    'shortFormatMinValue': 1000,
    'shortFormatPrecision': 1,
    'title': true,
    'cssClass': ['red']
  };

class MyApplication extends Component{
    constructor(props){
		super(props);
		this.myRef = React.createRef();
		
    }
    componentDidMount() {
		this.props.MyapplicationListStore.applicationListFetch('Collaborations List');	
		this.props.MyapplicationListStore.preloaderFetch(true);	
    }
	
    changePriceSort = (e) => {
		this.props.MyapplicationListStore.priceSortFetch(e.target.value);
		this.props.MyapplicationListStore.applicationListFetch('Collaborations List');
		this.props.MyapplicationListStore.preloaderFetch(true);
	}

	onSubmit = (event) => {
		event.preventDefault();
		this.props.MyapplicationListStore.applicationListFetch('Collaborations List');
		this.props.MyapplicationListStore.offsetFetch(0);
		this.props.MyapplicationListStore.preloaderFetch(true);
	}
	
	changeSearch = (e) => {
		this.props.MyapplicationListStore.searchFetch(e.target.value);
	}
	
	handlePageClick = data => {
		let selected = data.selected;
		let limits = this.props.MyapplicationListStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.MyapplicationListStore.offsetFetch(offset); 
		this.props.MyapplicationListStore.applicationListFetch('Collaborations List');
		this.props.MyapplicationListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
    render(){
        const store = this.props.MyapplicationListStore;
        const skelrow = [1,2,3,4,5,6,7,8];
		const todayDate = moment();
        return (
            <>
                <div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
				{store.auth ? <>
                <section className="container w1280">
                    <div id="my_application">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <h2 className="page-heading">My Applications</h2>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="custom-sort float-right">
                                <div className="form-group">
                                    <span className="fas down-arrow fa-chevron-down"></span>
                                    <label htmlFor="sort By">Sort By :</label>
                                    <select className="form-control" id="sortBy" name="sortBy" onChange={this.changePriceSort}>
                                        <option selected={store.priceSort === ''} value="">Sort By</option>
										<option selected={store.priceSort === ''} value="">Default</option>
										<option selected={store.priceSort === 'lth'} value="lth">Price Low to High</option>
										<option selected={store.priceSort === 'htl'} value="htl">Price High to Low</option>
									</select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="my-campaign-search ">
                                <form onSubmit={this.onSubmit} method="post" className="row">
                                    <div className="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                                        <div className="form-group custom-form-group">
                                            <label className="title-search" htmlFor="search-title">Title</label>
                                            <input type="text" name="search-title" placeholder="Type your word here" onChange={this.changeSearch} className="form-control camp-search"/>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className="form-group custom-group">
                                            <span className="fas down-arrow fa-chevron-down"></span>
                                            <label htmlFor="category">Category</label>
                                            <select className="form-control custom-select" id="category" >
                                                <option>All</option>
                                                <option>Awarded</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className="form-group custom-group">
                                            <span className="fas down-arrow fa-chevron-down"></span>
                                            <label htmlFor="price">Price(SGD)</label>
                                            <select className="form-control custom-select" id="price" >
                                                <option>$10 - $50</option>
                                                <option>$50 - $60</option>
                                            </select>
                                        </div>
									</div> */}
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
                                        <button type="submit" className=" w-100 btn btn-primary">Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                       
                    </div>
                    <div className="campaign-page my-campaigns">
                       {store.preloader?
                           <div className="row feature-list">   
                            {skelrow.map((skelrow,index) => (                                       
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                                 <div className="feature-item">
                                 <div className="feature-image">
                                    <Skeleton height={228} width={290} />
                                 </div>
                                 <div className="feature-details">
                                    <Skeleton count={4}/>
                                 </div>
                                 </div>
                                </div>
                              ))}
 
							</div>:                        
                    <div className="row feature-list">
                        {store.campaign.length !== 0 ? store.campaign.map((inf,index) =>   
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                            <div className="feature-item">
                                <div className="feature-image">
                                <Link to={`${process.env.PUBLIC_URL}/applicationdetail/applicantdetail/${inf.id}`}><img src={inf.campaignImage} alt={inf.campaignTitle} /></Link>
									{/* {inf.campaignType == 'paid' ?
										<div className="image-top-price">
											{inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} />
										</div>
									: inf.campaignType == 'sponsored' ?
										<div className="sponsored_label">Sponsored {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></div>
									:
										<div className="exchange_label">Shoutout Exchange</div>
									} */}
                                </div>
                                <div className="feature-details">
									{/*<p>Posted On : {moment(new Date(inf.createdAt)).format("MMM D, YYYY")}</p>
                                    <h3>{inf.campaignTitle}</h3>
                                    <p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt={inf.campaignTitle} /> {inf.country === 'All' ? 'All Country' : inf.country}</p>
                                    <h4>{inf.campaignAmountCurrency} {inf.campaignAmount}</h4>
                                    <p><EllipsisText text={inf.campaignDetails} length={110} /></p>
                        <Link to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.id}`}>View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>*/}
                                    <p>Posted On : {moment(new Date(inf.createdAt)).format("MMM D, YYYY")}</p>
                                    <h2>{inf.campaignTitle}</h2>
                                    <div className="applicentlistcountfoll">
                                        <span className="applicationtext">{inf.remarks.length} Applications {inf.campaignType == 'shoutout' && <span className="followerstext"> | <NumericLabel params={optionnumberformat}>{inf.profile.followers}</NumericLabel>Followers</span> }</span>
                                    </div>
                                    <h3>{inf.campaignType == 'shoutout' ? 'Shoutout Exchange'  : <span>{inf.campaignType}</span> }{inf.campaignType != 'shoutout' && <span>: {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></span>}</h3>
                                        <div className="campaign-details-mask">
                                        {/* <div className="show-area"> */}
                                       {/* <div className="canp-details-lable">
                                            <div className="campaign-label">Country:</div>
                                            <div className="campaign-info">{inf.country === 'All' ? 'All Country' : inf.country}</div>
                                        </div>
                                         <div className="canp-details-lable">
                                            <div className="campaign-label">Language:</div>
                                            <div className="campaign-info">Chinese</div>
										</div> 
                                        <div className="canp-details-lable">
                                            <div className="campaign-label">Status:</div>
                                            <div className="campaign-info" style={{fontWeight:'500'}}>{moment(inf.endStoryPostDate)>=todayDate?<span className="text-success">Open</span>:<span className="text-danger">Expired</span>}</div>
                                        </div>*/}
										{/* {inf.campaignCategories ? <> {inf.campaignCategories.length !== 0 &&
                                        <div className="canp-details-lable">
                                            <div className="campaign-label">Campaign Type:</div>
                                            <div className="campaign-info">
												{inf.campaignCategories.slice(0,5).map((inf,index) =>
													<span key={index}>{inf.charAt(0).toUpperCase() + inf.slice(1)}, </span>
												)}
											</div>
                                        </div>} </> : null }

                                        <div className="canp-details-lable">
                                            <div className="campaign-label">Posting Platform:</div>
                                            <ul className="campaign-social-icons">
                                                <li><img src={`${process.env.PUBLIC_URL}/images/insta.svg`} alt="Instagram" /></li>
                                                <li><img src={`${process.env.PUBLIC_URL}/images/facebook.svg`} alt="Facebook" /></li>
                                            </ul>
                                        </div>	
                                        </div> */}
                                        <Link className="campaign-detail-btn" to={`${process.env.PUBLIC_URL}/applicationdetail/applicantdetail/${inf.id}`}>More Info </Link>
                                    </div>

                                </div>
								{/* {inf.remarks.length !== 0 ? inf.remarks.map((info,index)=> <>
								{store.userId == info.ownerId ? <>
									{info.offerStatus === 1?
									<div className="received awaiting">
										 <span className="bookmark-text" style={{background:'#ffc107'}}>Request Pending </span>
									</div>:(info.offerStatus===3)?
									<div className="received declined">                                   
										<span className="bookmark-text" style={{background:'#fb2626'}}>Offer Declined</span>
									</div>: (info.offerStatus===2) ?
									<div className="received awarded"><span className="bookmark-text" style={{background:'#58dc72'}}>Offer Accepted</span></div> : (info.offerStatus===0) && ''
									}
								</> : ''}</>)  : null} */}
							</div>
                        </div>):
							<div className="col-lg-12">
								<div className="no-result-wpr">
									<div className="no-result-found">
										<div className="no-result-img">
											<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
										</div>
										<p className="no-result">Sorry, no result found</p>
									</div>
								</div>
							</div> }
                        </div> 
						}
                    </div>
                    </div>
                </section>
				
				<section className="container w1280">
					{/* <div className="row"> */}
                        {/*<div className="col-lg-9 col-12 offset-3 pr-1">*/}
                        <div className="col-lg-12 col-12 pr-1">
							<div className="pg-wpr row">
							{store.totalCount > store.limit ?
							<>
							<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
								Showing 1 to {store.limit} of {store.totalCount} entries
							</div>
							<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
								<ReactPaginate
									previousLabel={'Previous'}
									nextLabel={'Next'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={store.pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={1}
									onPageChange={this.handlePageClick}
									containerClassName={'custom-pagination'}
									subContainerClassName={''}
									activeClassName={'active'}
								/> 
							</div>
							</>
							: null }
							</div>
						</div>
					{/* </div> */}
				</section>
				</>
				: <Redirect to="/" />}
            </>
        )
    }
    
}

export default inject('MyapplicationListStore') (observer(MyApplication))
