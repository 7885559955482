import React,{Component} from 'react';
import './Faq.scss'

class Faq extends Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
	}
	
	componentDidMount() {
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
    render(){
        return(
            <>
            <div className="after-header" ref={this.myRef}>
                    <div className="blank-wpr border-separator  container-fuild "></div>
                    <div className="container">
                        <div className="accordion" id="accordion-container">
                            <h1 className="hedfx_h1">Frequently Ask Question</h1>
                            <div className="option">
                                <input type="checkbox" id="toggle1" className="toggle"/>
                                <label className="title" htmlFor="toggle1">What is KOLI?</label>
                                <div className="content">
                                <p>KOLI stands for Key Opinion Leaders and Influencers. The purpose of KOLI is to provide a platform to connect Influencers with Brands for Greater Campaign. This allows entrepreneurs and brands to get more exposure, leads and sales through working with social media influencers and Key Opinion Leaders.</p>
                                
                                <p>We provide a platform where social media influencers, key opinion leaders, celebrities, and artists are able to post their portfolio and provide their Instagram follower and engagement statistics. They can also browse for suitable Campaigns by the brands and apply for campaigns. This allows the influencer to be able to make more money, compared to the traditional influencer marketing agencies which might be charging a high agency fee.</p>
                                
                                <p>The brands can post the campaigns to allow influencers to apply. Brands can see the follower statistics and portfolio of the influencer so that they have greater information about them before hiring. The brands do not have to pay the typical expensive agency fees, thus resulting in greater ROI from influencer marketing.</p>
                                
                                <p>As a Brand, What are the types of influencers can you look for in KOLI?</p>
                                
                                <div className="ulli">
                                <ul>
                                    <li>Instagram And Other Social Media Influencers</li>
                                    <li>Content Creators</li>
                                    <li>Micro Influencers </li>
                                    <li>Key Opinion Leaders (KOL) </li>
                                    <li>Celebrities </li>
                                    <li>Models </li>
                                    <li>Speakers </li>
                                    <li>Marketers </li>
                                    <li>Leaders </li>
                                    <li>Authors </li>
                                </ul>
                            </div>
                            
                            <p>Are you an influencer who wants to promote other brands?</p>
                            
                            <p className="pb_5">Benefits of being an influencer on KOLI:</p>
                            <div className="ulli">
                            <ul>
                                <li>More Income</li>
                                <li>Greater Credibility</li>
                                <li>More Connection </li>
                                <li>Greater Exposure</li>
                                <li>More Recognition </li>
                                <li>Data Of Your Followers</li>
                            </ul>
                            </div>
                            </div>
                            </div>

                            <div className="option">
                            <input type="checkbox" id="toggle2" className="toggle"/>
                            <label className="title" htmlFor="toggle2">
                                Do I need to have a certain number of followers to join KOLI?
                            </label>
                            <div className="content">
                                <p>Currently no, we welcome everyone. For influencers we suggest that you should have a minimum of 10,000 followers for greater credibility.</p>
                            </div>
                            </div>

                            <div className="option">
                            <input type="checkbox" id="toggle3" className="toggle"/>
                            <label className="title" htmlFor="toggle3">
                                Is it free to join KOLI?
                            </label>
                            <div className="content">
                                <p>Yes</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle4" className="toggle"/>
                            <label className="title" htmlFor="toggle4">
                                How do I showcase my influencer profile?
                            </label>
                            <div className="content">
                                <p>Link your social media to KOLI so that the brands can know more about your work.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle5" className="toggle"/>
                            <label className="title" htmlFor="toggle5">
                                Interested to be featured on KOLI’s youtube and become a featured influencer?
                            </label>
                            <div className="content">
                                <p>Email us at info@koli.co for application.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle6" className="toggle"/>
                            <label className="title" htmlFor="toggle6">
                                How is using KOLI good for my business?
                            </label>
                            <div className="content">
                                <p>Brands are able to see the analysis of the influencer’s followers/audiences to determine if the influencer is right for the brand. We are developing direct and easy access to the influencer where Brands can offer the job over KOLI Chat. (stay tuned for the next version)</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle7" className="toggle"/>
                            <label className="title" htmlFor="toggle7">
                                How do I know which influencer to engage?
                            </label>
                            <div className="content">
                                <p>We have an AI data-driven approach to let you filter and targeting the particular type of influencer you'd like to use. On top of that, you will be able to see the analysis of the influencers’ followers best suited for your brand.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle8" className="toggle"/>
                            <label className="title" htmlFor="toggle8">
                                What analytics does KOLI provide?
                            </label>
                            <div className="content">
                                <p>Using Ai, KOLI will be able to know the breakdown of your followers’ locations, language, education level, age group, gender, interest, income and many others.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle9" className="toggle"/>
                            <label className="title" htmlFor="toggle9">
                                How do I see the full analytics?
                            </label>
                            <div className="content">
                                <p>Click on purchase and you will be notified when the full analytics is completed.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle10" className="toggle"/>
                            <label className="title" htmlFor="toggle10">
                                Is KOLI an influencer agency?
                            </label>
                            <div className="content">
                                <p>We are not an agency. We are an open platform for influencers to post their profile and get searched and hired by companies and brands.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle11" className="toggle"/>
                            <label className="title" htmlFor="toggle11">
                                Where are the influencers from?
                            </label>
                            <div className="content">
                                <p>Most of our influencers reside in South East Asia. We will expand the influencers coverage gradually to rest of Asia and globally.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle12" className="toggle"/>
                            <label className="title" htmlFor="toggle12">
                                What should I take note off when hiring influencers?
                            </label>
                            <div className="content">
                                <p>Do your due diligence before hiring one. Make sure you only pay them in full after you are satisfied with their work.</p>
                            </div>
                            </div>
                            
                            <div className="option">
                            <input type="checkbox" id="toggle13" className="toggle"/>
                            <label className="title" htmlFor="toggle13">
                                How do I become an influencer?
                            </label>
                            <div className="content">
                                <p>Post relevant social media content Everyday. Go to our IG @koli.co and FB page @koliapp to learn more.</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </>
        )
    }
}

export default Faq;
