import React, { Component } from 'react';
import { Link  } from 'react-router-dom';


class MyCollaboration extends Component {
  render() {
    return (
      <div className="mycollaboration-wrapper">
      <div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
        <section className="newlanding-carousel">
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={`${process.env.PUBLIC_URL}/images/carousel-image/carousel-one.jpg`} className="img-fluid" alt="" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Grow Your Business Online with Smart Campaigns</h5>
                  <p>Influencer marketing is a form of social media marketing involving endorsements and product placement from influencers, people and organizations who have...</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={`${process.env.PUBLIC_URL}/images/carousel-image/carousel-three.jpg`} className="img-fluid" alt="" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Grow Your Business Online with Smart Campaigns</h5>
                  <p>Influencer marketing is a form of social media marketing involving endorsements and product placement from influencers, people and organizations who have...</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={`${process.env.PUBLIC_URL}/images/carousel-image/carousel-two.jpg`} className="img-fluid" alt="" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Grow Your Business Online with Smart Campaigns</h5>
                  <p>Influencer marketing is a form of social media marketing involving endorsements and product placement from influencers, people and organizations who have...</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={`${process.env.PUBLIC_URL}/images/carousel-image/carousel-four.jpg`} className="img-fluid" alt="" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Grow Your Business Online with Smart Campaignss</h5>
                  <p>Influencer marketing is a form of social media marketing involving endorsements and product placement from influencers, people and organizations who have...</p>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </section>
        <section className="cd-header-details">
            <div className="container custom-container">
              <div className="row">
                
              </div>
            </div>
        </section>
      </div>
    );
  }
}

export default MyCollaboration;