import {action, observable, decorate} from 'mobx';
import { campaignDetailResult } from '../api/CampaignDetailApi';
import { remarkListResult } from '../api/RemarkListApi';
import { remarkCountResult } from '../api/RemarkCountApi';

class ApplicationReceivedStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	campaign = [];
	campaignUser = [];
	preloader = true;
	endStory = '';
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	loader = '';
	offset = 0;
	totalCount = '';
	limit = 10;
	
	campaignDetailFetch = (id) => {
		this.preloaderFetch(true)
		campaignDetailResult(id)
			.then(res => {
				this.campaignFetch(res.data.campaigndetails);
				this.endStoryFetch(res.data.campaigndetails[0].endStoryPostDate);
				
				let campId = res.data.campaigndetails[0].id;
				let param = {include: ["profile"], where:{campaignId:campId},offset:this.offset,limit:this.limit};

				remarkListResult(JSON.stringify(param))
					.then(res1 => {
						let count1 = {campaignId:campId};
					remarkCountResult(JSON.stringify(count1))
						.then(res2 => {
							this.campaignUserFetch(res1.data);
							this.pageCountFetch(Math.ceil(res2.data.count / this.limit));
							this.totalCountFetch(res2.data.count);
							this.preloaderFetch(false);
						}).catch(error=>console.log(error))
						
					}).catch(error=>console.log(error))
				
			}).catch(error=>console.log(error))
	}
	
	campaignFetch 		 = (data) =>{this.campaign = data;}
	campaignUserFetch    = (data) =>{this.campaignUser = data;}
	preloaderFetch 		 = (data) =>{this.preloader = data;}
	endStoryFetch	 	 = (data) =>{this.endStory = data;}
	loaderFetch 		 = (data) =>{this.loader = data;}
	offsetFetch 		= (data) => {this.offset = data;}
	pageCountFetch 		= (data) => {this.pageCount = data;}
	totalCountFetch		= (data) => {this.totalCount = data;}

}

decorate(ApplicationReceivedStore, {
	auth:observable,campaign: observable,campaignUser:observable,offset: observable,totalCount: observable,pageCount:observable,limit: observable,preloader: observable,endStory: observable,loader: observable,campaignFetch: action,preloaderFetch: action,endStoryFetch: action,loaderFetch: action,campaignUserFetch:action,offsetFetch: action,totalCountFetch: action,pageCountFetch:action
});

export default ApplicationReceivedStore;