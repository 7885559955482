import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import EllipsisText from "react-ellipsis-text";
import Skeleton from 'react-loading-skeleton';
import Slider from "react-slick";
import moment from 'moment';
import NumberFormat from 'react-number-format';

class SponsoredList extends Component {
  
  componentDidMount() {
	this.props.FeatureListStore.featureSponsoredCampaign('Featured Collaborations');
  }
  
  render() {
	var settings = {
		className: "slider ",
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: false,
		swipeToSlide: false,
		draggable : false,
		adaptiveHeight: true,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			}
		  },
		  {
			breakpoint: 600,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  initialSlide: 1
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  infinite: false,
			}
		  }
		]
	  };
	  
	const store = this.props.FeatureListStore;
	const todayDate = moment();
    return (
			<section className="feature-campaign">
			<div className="container custom-container">
			{store.sponsoredCampaign.length !== 0  &&
			<div className="heading">
				<h2>Sponsored Campaign</h2>
			</div>
			}
			{store.sponsoredCampaign.length !== 0  && 
				(<div className="feature-list">
					<div className="feature-slider" >
						<Slider {...settings}>	
							{store.sponsoredCampaign.map((inf,index) =>
							<div key={index}>
								<div className="feature-item">
									<div className="feature-image">
									<Link to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.id}`}><img src={inf.campaignImage} alt={inf.campaignTitle} /></Link>
										{/* {inf.campaignType == 'paid' ?
											<div className="image-top-price">
												{inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} />
											</div>
										: inf.campaignType == 'sponsored' ?
											<div className="sponsored_label">Sponsored {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></div>
										:
											<div className="exchange_label">Shoutout Exchange</div>
										}
										{inf.campaignStatus==2 && inf.remarks.length>0 &&
										<div className="received homecampaigns">                                   
											<Link to={`${process.env.PUBLIC_URL}/application-user-list/applicants/${inf.id}`}> <span className="bookmark-text" style={{background:'#689dff'}}>{inf.remarks.length} Applications</span></Link>
										</div>
										} */}
									</div>
									<div className="feature-details">
										<p>Posted On : {moment(new Date(inf.createdAt)).format("MMM D, YYYY")}</p>
										<h2><EllipsisText text={inf.campaignTitle} length={110} /></h2>
										<span>{inf.remarks.length == 0 ? <span>New Listing</span> : <span> {inf.remarks.length} Applications </span> }</span>
										<h3>Sponsored: {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></h3>
										<div className="campaign-details-mask">
											<Link className="campaign-detail-btn" to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.id}`}>More Info </Link>
										</div>
									</div>
								</div>
							</div>
							)}
						</Slider>
					</div>
					<div className="see-all-featured"><Link to="/campaigns/sponsored" className="viewall-btn" href="#">View All</Link></div>
				</div>
			 )}
			 {store.preloader &&
				<div className="feature-list">
					<div className="feature-slider" >
						<Slider {...settings}>

							<div >
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={290} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={290} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={290} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
							<div>
								<div className="feature-item ">
									<div className="feature-image">
										<Skeleton height={227} width={290} />
									</div>
									<div className="feature-details">
										<Skeleton count={4}/>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			 }
			 
			 </div>
		</section>
    );
  }
}
export default inject('FeatureListStore')(observer(SponsoredList));