import { configure } from 'mobx';

import LoginStore from './LoginStore';
import SignupStore from './SignupStore';
import CategoryStore from './CategoryStore';
import InfluencerFeatureStore from './InfluencerFeatureStore';
import CampaignListStore from './CampaignListStore';
import FeatureListStore from './FeatureListStore';
import NewsStore from './NewsStore';
import CampaignDetailStore from './CampaignDetailStore';
import UserProfileStore from './UserProfileStore';
import UsernameStore from './UsernameStore';
import InfluencerProfileStore from './InfluencerProfileStore';
import EditprofileStore from './EditprofileStore';
import InfluencerListStore from './InfluencerListStore';
import FeaturedInfluencerListStore from './FeaturedInfluencerListStore';
import CreatecampaignListStore from './CreatecampaignListStore';
import MycampaignListStore from './MycampaignListStore';
import TermsStore from './TermsStore';
import PrivacyStore from './PrivacyStore';
import ApplicationReceivedStore from './ApplicationReceivedStore';
import ApplicationProfileStore from './ApplicationProfileStore';
import HelpStore from './HelpStore';
import MyapplicationListStore from './MyapplicationListStore';
import HomeSearchStore from './HomeSearchStore';
import ProductNewStore from './ProductNewStore';
import ProductListStore from './ProductListStore';
import ProductsDetailStore from './ProductsDetailStore';
import TransactionHistoryStore from './TransactionHistoryStore';

configure({ enforceActions: 'observed' })

class RootStore {
	constructor() {
		this.LoginStore = new LoginStore(this);
		this.SignupStore = new SignupStore(this);
		this.CategoryStore = new CategoryStore(this);
		this.InfluencerFeatureStore = new InfluencerFeatureStore(this);
		this.CampaignListStore = new CampaignListStore(this);
		this.FeatureListStore = new FeatureListStore(this);
		this.NewsStore = new NewsStore(this);
		this.CampaignDetailStore = new CampaignDetailStore(this);
		this.UserProfileStore = new UserProfileStore(this);
		this.UsernameStore = new UsernameStore(this);
		this.InfluencerProfileStore = new InfluencerProfileStore(this);
		this.EditprofileStore = new EditprofileStore(this);
		this.InfluencerListStore = new InfluencerListStore(this);
		this.FeaturedInfluencerListStore = new FeaturedInfluencerListStore(this);
		this.CreatecampaignListStore = new CreatecampaignListStore(this);
		this.MycampaignListStore = new MycampaignListStore(this);
		this.TermsStore = new TermsStore(this);
		this.PrivacyStore = new PrivacyStore(this);
		this.ApplicationReceivedStore = new ApplicationReceivedStore(this);
		this.ApplicationProfileStore = new ApplicationProfileStore(this);
		this.HelpStore = new HelpStore(this);
		this.MyapplicationListStore = new MyapplicationListStore(this);
		this.HomeSearchStore = new HomeSearchStore(this);
		this.ProductNewStore = new ProductNewStore(this);
		this.ProductListStore = new ProductListStore(this);
		this.ProductsDetailStore = new ProductsDetailStore(this);
		this.TransactionHistoryStore = new TransactionHistoryStore(this);
	}
}

const rootStore = new RootStore();

export default rootStore;