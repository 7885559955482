import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import { useForm } from "react-hook-form";
import { forgetPassword } from "../stores/ForgetStore";

const Forget = observer(() => {
	
	const store = useContext(forgetPassword);
	const { handleSubmit, register, errors } = useForm({});
	
	const onSubmit = (data,e) => {
		store.forgetFetchData(data,e);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			store.emailNotMsgFetch(false)
		}, 5000);
		return () => clearInterval(interval);
	}, []);
  
    return ( 
	<div>
		{store.isModalOpen ?
		<div className="modal fade custom-modal email-sent" id="emailSentModal" style={{display:'block',opacity:'1'}}>
			<div className="modal-dialog modal-dialog-centered">
			  <div className="modal-content signup-success-content">
				<div className="modal-body text-center signup-success-body">
					<h2 className="modal-heading">Password Reset Email Sent</h2>
					<p>An email has been sent to your rescue email address, <strong>{store.emailForget}. </strong> 
						Follow the directions in the email to reset your password.</p>
					<button className="btn btn-primary" onClick={() => store.isModalOpenFetch(false)} data-dismiss="modal">Done</button>
				</div>
			  </div>
			</div>
		</div> :
		<div className="modal fade custom-modal forgot-modal" id="forgotModal">
			<div className="modal-dialog modal-dialog-centered">
			  <div className="modal-content">
				<div className="modal-body">
					<h2 className="modal-heading">Forgot Password</h2>
					<p className="heading-text"> We just need your registered email address to send you password
						reset.</p>
					<form onSubmit={handleSubmit(onSubmit)} >
						<div className="custom-form resetEmail">      
							<input type="text" name="email" ref={register({
							  required: 'Email address is required',
							  pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
								message: "Invalid email address"
							  }
							})} />
							<span className="highlight"></span>
							<span className="bar"></span>
							<label htmlFor="resetEmail">Email Address</label>
							<span className="errorcolor">{errors.email && errors.email.message} {store.emailNotMsg && 'Email address is incorrect'}</span>
						</div>
						<button className="btn btn-primary w-100" id="validforgot">Reset Password {store.isLoader && <i className="fa fa-spinner fa-spin"></i>}</button>
					</form>
					<p className="form-bottom-text text-center">Need a KOLI Account? <Link to="" className="sign-up-link" data-toggle="modal" data-target="#signUpModal">Create an account</Link></p>
				</div>
			  </div>
			</div>
		</div>}
	</div>
    );
});
export default Forget;