import React, { useEffect, useContext, useRef } from 'react';
import {observer} from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { helpUser } from "../stores/HelpStore";
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import FileBase64 from 'react-file-base64';

const Help = observer(() => {
	
	const store = useContext(helpUser);
	const { handleSubmit, register, errors } = useForm({});
	const myRef = useRef();
	
	const onSubmit = (data,e) => {
		store.helpFetch(data,e);	
	}
	
	useEffect(() => {
		store.setIsModalOpen(false);
		window.scrollTo(0,myRef.current.offsetTop);
	}, []);
	
    return (
		<>
			<div className="blank-wpr container-fuild border-separator after-header" ref={myRef}></div>
			<div className="container help-container">
				<div className="row">
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 help-left d-none d-sm-none d-lg-block d-xl-block d-md-block">
						<div className="">
						{/*<Link to={`/`}><img className="img-one" src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" /></Link>*/}
						   <img className="img-two" src={`${process.env.PUBLIC_URL}/images/help/group-two.png`} alt="" />
							<img className="img-three" src={`${process.env.PUBLIC_URL}/images/help/group-one.png`} alt="" />
							<img className="img-four" src={`${process.env.PUBLIC_URL}/images/help/dot-one.svg`} alt="" />
							<img className="img-five" src={`${process.env.PUBLIC_URL}/images/help/dot-two.svg`} alt="" />
							<img className="img-six"src={`${process.env.PUBLIC_URL}/images/help/square.svg`} alt="" />
							<img className="img-seven" src={`${process.env.PUBLIC_URL}/images/help/line.svg`} alt="" />
							<img className="img-eight" src={`${process.env.PUBLIC_URL}/images/help/dot-square.svg`} alt="" />
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
						<div  className="help-right">
							<div className="help-form-wpr">
								<h2 className="page-heading">Reach Us</h2>
								<p className="help-para">Feel free to send us any message at any given time</p>
								<span className="text-danger">{store.wentWrong ? 'Something went wrong. Please try again later' : null}</span>
								<form onSubmit={handleSubmit(onSubmit)}>
									{store.auth ? null :<>
									<div className="form-group custom-form-group">
										<label className="help-form-label" htmlFor="name">Name</label>
										<input type="text" className="form-control" placeholder="Enter Name" name="name" ref={register({
												required: 'Name is required'
												})} />
												<span className="text-danger">{errors.name && errors.name.message}</span>
									</div>
									<div className="form-group custom-form-group">
										<label className="help-form-label" htmlFor="email">Email</label>
										<input type="email" className="form-control" placeholder="Enter Email Address" name="email" ref={register({
											  required: 'Email address is required',
											  pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: "Invalid email address"
											  }
											})} />
											<span className="text-danger">{errors.email && errors.email.message}</span>
									</div>
									</>}
									<div className="form-group custom-form-group">
										<label className="help-form-label" htmlFor="message">Message</label>
										<textarea className="form-control" placeholder="Hi there.. write your message here" rows="5" name="comment" id="message" ref={register({
										required: 'Message is required'
										})} ></textarea>
										<span className="text-danger">{errors.comment && errors.comment.message}</span>
									</div>
									<div className="form-group custom-form-group">
										<label className="help-form-label" htmlFor="Image">Upload Image</label>
										<p className="form-control"><FileBase64 onDone={ store.getFiles } />{store.fileLoader && <i className="fa fa-circle-o-notch fa-spin" style={{fontSize:'15px',marginLeft:'-7.8rem',color:'red'}}></i>}</p>
									</div>
									<button type="submit" disabled={store.fileLoader} className="btn btn-primary send-btn"> Send <span>{store.isLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'12px'}}></i>}</span></button>
								</form>
								
							</div>
						</div>
					</div>
					<div id="successpopup">
						<Popup
							open={store.isModalOpen}
							closeOnDocumentClick
							onClose={() => store.setIsModalOpen(false)}
						>
							<div style={{ padding:'25px' }}>
								<h5 style={{ fontSize:'1rem',lineHeight:'3' }}>Request Submitted Successfully!!</h5>
								<Link to="/" style={{ float:'right',color:'blue',fontWeight: '600',paddingBottom:'18px' }}> Okay </Link>
							</div>
						</Popup>
					</div>
					<Popup
					  open={store.errImage}
					  closeOnDocumentClick
					  onClose={() => store.errImageFetch(false)}>
						<div className="custom-modal max-upload">
							<div className="modal-dialog">
							  <div className="modal-content">
								<div className="modal-body text-center">
									<h2 className="modal-heading">Warning!!</h2>
									<ul>
										<li>The maximum allowed size for uploads is 2mb.</li>
										<li>Only image with the following extensions are allowed: jpg, jpeg, png.</li>
									 </ul>
									<Link to="#" className="btn btn-primary" onClick={() => store.errImageFetch(false)} >Done</Link>
								</div>
							  </div>
							</div>
						</div>
					</Popup>
				</div>
			</div>
		</>	
    );
});
export default Help