import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import NumericLabel from 'react-pretty-numbers';
import ShowMoreText from 'react-show-more-text';
import {observer, inject} from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import Popup from "reactjs-popup";

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class Profilesss extends Component {
	
	
    render() {
		
        return (
           <section className="updateprfile-wrapper" id="updateprfile-new">
                <div class="container w1280  pl-0 pr-0">
                <h2 class="page-heading">Profile</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Product</li>
                        </ol>
                    </nav>
                </div>
           </section>
        )
    }
}

export default Profilesss;
