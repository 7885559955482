import React,{Component} from 'react';
import './About.scss'

class About extends Component{
	constructor(props){
		super(props);
		this.myRef = React.createRef()
	}
	
	componentDidMount() {
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	render(){
		return (
			<>
				<div className="after-header" ref={this.myRef}>
                    <div className="blank-wpr border-separator  container-fuild "></div>
                    <div className="container">
						<div className="aboutus-container">
							<h1 className="page-heading hedfx_h1">About KOLI</h1>
							<div className="row mb-5">
								<div className="ab-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
									<p className="conp">KOLI® means Key Opinion Leaders &amp; Influencers. We connect the world’s social media influencers to make them more influential, productive and successful. We are an ecosystem where social media influencers, KOL, celebrities, artists, models and thought leaders build up their profile, portfolio, credibility, authority and get hired by Brands.</p>
									
									<p className="conp">Every successful influencer marketing campaign is empowered by a personal connection with the product, and a sincere sharing of the experience. We put influencers at the heart of the platform, enhancing their credibility, matching opportunities based on their niches and partnering them in their growth.</p>
									
									<p className="conp">KOLI, a platform created by Influencer Pte Ltd help provide data-driven insight for brands to select the most suitable KOLs and Influencers for their campaigns. We also help provide data-driven insight for brands to monitor and maximise their returns on marketing investments.</p>
									
									<p className="conp">We aim to build the world’s largest community of influencers on a single platform in 5 years time. Our goal is to become the go-to platform for Brands before hiring any influencer.</p>

									<p className="contact-mail"><span className="fas fa-envelope"></span>Please contact us at <a href="mailto:info@koli.co">info@koli.co</a></p>
									
								</div>
								<div className="ab-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
									<div className="">
										<img className="w-100" src={`${process.env.PUBLIC_URL}/images/aboutkoli.png`} alt="AboutImage" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default About;