import {action, observable, decorate} from 'mobx';
import { createContext } from 'react';
import { phoneCodeResult } from '../api/GeoPhoneCodeApi';
import { createAccountResult } from '../api/SignupApi';
import { countriesResult } from '../api/CountryApi';
import { usernameCheckResult } from '../api/UsernameCheckApi';

class SignupStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	emailAlreadyMsg = '';
	userAlreadyMsg = '';
	usernameAlreadyMsg = '';
	isModalOpen = false;
	isLoader = false;
	wentWrong = false;
	getEmail = '';
	phoneCode = '';
	captcha = '';
	geoPhoneCode = '';
	disabled = true;

	contactFetchData = (data,e) => {
		this.setIsLoader(true)
		this.disabledFetch(true)
		
		let profilePram = {
			first: data.first,
			last: data.last,
			displayedEmail:data.email,
			countryCode: data.countrycode,
			mobile: data.mobile,
			username: data.username,
		}
		let newPram = {
			email: data.email,
			password: data.password,
			profile: profilePram
		}
		let finalData = {data: newPram}

		if(data.username){

			let param = {where:{username:data.username}}

			usernameCheckResult(JSON.stringify(param))
				.then(res => {
					if(res.data.length !== 0){
						this.usernameAlreadyMsgFetch(true)
						this.setIsLoader(false)
						this.captcha.reset();
					}else{
						this.usernameAlreadyMsgFetch(false)

						createAccountResult(finalData)
						.then(res => {
							const {data} = res
							if(data.statusCode===200 && !data.error ){
								this.setIsModalOpen(true);
								this.setIsLoader(false);
								this.setEmail(newPram.email);
								this.captcha.reset();
								e.target.reset();
								this.setUserAlreadyMsg('')
							}else if(data.statusCode===422 && !data.error ){
								this.setEmailAlreadyMsg(res.data.details.messages.email)
								this.setIsLoader(false);
								this.captcha.reset();
								this.setUserAlreadyMsg('')
							}
						}).catch(error=>[this.setWentWrong(true),this.setIsLoader(false),this.setUserAlreadyMsg('')])
					}
				}).catch(error=>[this.setwentWrong(true),this.setIsLoader(false)])
		}
	}
	
	usernameCheckData = (e) => {
		let username = e.target.value
		if(username){

			let param = {where:{username:username}}

			usernameCheckResult(JSON.stringify(param))
				.then(res => {
					if(res.data.length !== 0){
						this.usernameAlreadyMsgFetch(true)
						this.setIsLoader(false)
						this.captcha.reset();
					}else{
						this.usernameAlreadyMsgFetch(false)
					}
				}).catch(error=>[this.setwentWrong(true),this.setIsLoader(false)])
		}
	}
	
	fetchCountries = () => {
		countriesResult()
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					let count = [];
					res.data.sort(function(a,b){
						count.push(b.phonePrefix)
					});
					this.phoneCodeFetch([...new Set(count.sort())]);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	fetchGeoPhoneCode = () => {
		phoneCodeResult()
			.then(res => {
				this.geoPhoneCodeFetch(res.data.country_calling_code);
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	setEmailAlreadyMsg = (data) => {
		this.emailAlreadyMsg = data;
	}
	setUserAlreadyMsg = (data) => {
		this.userAlreadyMsg = data;
	}
	setIsModalOpen = (data) => {
		this.isModalOpen = data;
	}
	setIsLoader = (data) => {
		this.isLoader = data;
	}
	setWentWrong = (data) => {
		this.wentWrong = data;
	}
	setEmail = (data) => {
		this.getEmail = data;
	}
	phoneCodeFetch = (data) => { 
		this.phoneCode = data; 
	}
	geoPhoneCodeFetch = (data) => { 
		this.geoPhoneCode = data; 
	}
	disabledFetch = (data) => {
		this.disabled = data;
	}
	usernameAlreadyMsgFetch = (data) => {
		this.usernameAlreadyMsg = data;
	}
}

decorate(SignupStore, {
	emailAlreadyMsg: observable,
	userAlreadyMsg: observable,
	usernameAlreadyMsg: observable,
	isModalOpen: observable,
	isLoader: observable,
	wentWrong: observable,
	getEmail: observable,
	captcha:observable,
	disabled:observable,
	phoneCode: observable,
	geoPhoneCode: observable,
	contactFetchData: action,
	setEmailAlreadyMsg: action,
	setUserAlreadyMsg: action,
	setIsModalOpen: action,
	setIsLoader: action,
	setWentWrong: action,
	setEmail: action,
	phoneCodeFetch: action,
	geoPhoneCodeFetch: action,
	disabledFetch:action,
	usernameAlreadyMsgFetch:action,
	usernameCheckData:action,
});

export const registerUser = createContext(new SignupStore());
export default SignupStore;