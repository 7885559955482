import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { category } from "../../stores/InfluencerFeatureStore";
import queryString from 'query-string'

const Search = observer((props) => {
	
	const store = useContext(category);
	let history = useHistory();
	const { handleSubmit, register, errors } = useForm({});
	
	useEffect(() => {
		store.fetchCategories('category');
	});
	
	const onSubmit = (data,e) => {
		if(data.influencer !==''){
			store.requiredFetch('')
			history.push(`search-influencer/${data.influencer}`);
		}else{
			store.requiredFetch('Field is required!')
		}
	}
	
	return (
		<React.Fragment>
			<section className="carousel-search-wrapper">
			  <div className="container custom-container">
				<div className="row">
				  <div className="search-wrapper">
					<form onSubmit={handleSubmit(onSubmit)} method="post">
					  <div className="form-row">
						<div className="col-12 col-md-2 col-lg-2"></div>
						<div className="col-12 col-md-6 col-lg-6">
						  <div className="searchinput-wrapper">
							<input type="text" className="form-control" name="influencer" id="campaign" id="carouselSearchInput" placeholder="Search Influencer" ref={register} />
								<img src={`${process.env.PUBLIC_URL}/images/header-search-glass.svg`} alt="Search" />
						  </div>
						</div>
						<div className="col-md-2">
						  <button type="submit" className="btn carousel-search-btn">Search</button>
						</div>
					  </div> 
					  {store.required && <span className="text-danger homesearch-required">{store.required}</span>}
					</form>
				  </div>
				</div>
			  </div>
			</section>
		</React.Fragment>
	)
});

export default Search;