import React,{Component} from 'react';
import './PrivacyPolicy.scss'
import { PageTitle } from '../../Title';
import {observer, inject} from 'mobx-react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';

class PrivacyPolicy extends Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
	}
	
	componentDidMount() {
		this.props.PrivacyStore.privacyGet('Privacy policy Page');
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	render(){
		const skelrow = [1,2,3,4,5,6];
		return (
			<>
				<div className="after-header" ref={this.myRef}>
                    <div className="blank-wpr border-separator container-fuild "></div>
                    <div className="container pp-container">
                        <div className="allcon">
							{ this.props.PrivacyStore.privacy.length != 0 ? this.props.PrivacyStore.privacy.map(pri =>
								<div key={pri}>
									<h2>{pri.name}</h2>
									<p>{ReactHtmlParser(pri.description)}</p>
								</div>
							) :
							<div>
								{skelrow.map((skelrow,index) => (
								<div key={index}>
									<h2><Skeleton height={28} width={200} /></h2>
									<p><strong><Skeleton height={28} width={250} /></strong></p>
									<p><Skeleton count={6} /></p>
								</div>
								))}
							</div>}
                        </div>
                    </div>
                </div>
			</>
		)
	}
}
export default inject('PrivacyStore')(observer(PrivacyPolicy));
