import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { profileResult } from '../api/ProfileApi';
import { userLoginResult } from '../api/LoginApi';
import { profileUpdateResult } from '../api/ProfileupdateApi';

class LoginStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	toEmailNot = '';
	successLogin = '';
	isLoader = false;
	
	onSubmit = (data,e) => {	
		this.setIsLoader(true);
		let userLogin = {
			email: data.email,
			password: data.password
		} 
        userLoginResult(userLogin)
			.then(res => {
				const {data} = res
				if(data.error === true){
					this.settoEmailNot(res.data.message);
					this.setIsLoader(false);
				}else{	
					sessionStorage.setItem("userId", JSON.stringify(res.data.userId));
					profileResult(data.userId)
						.then(res1 => {
							const {status,data} = res1
							let result = JSON.parse(data.message[0].profile);
							if(status===200 && !data.error ){	
								sessionStorage.setItem("user", JSON.stringify(result));
								sessionStorage.setItem("instalink", 'yes');
								let username = result.username;
								let display = result.display;
								let userId = res.data.userId
								let profileUpdate = {
										lastSeen: new Date()
									}
								// if(navigator.geolocation) {
								// 	navigator.geolocation.watchPosition(function(position) {
								// 		let lati  = position.coords.latitude
								// 		let longi = position.coords.longitude
								// 		let profileUpdate = {
								// 			latitude: lati,
								// 			longitude: longi
								// 		}

								// 		profileUpdateResult(userId,profileUpdate)
								// 			.then(res2 => {
								// 				if(username === display){
								// 					window.location.href = `${process.env.PUBLIC_URL}/#/username`;	
								// 					window.location.reload();
								// 				}else{
								// 					window.location.href = `${process.env.PUBLIC_URL}/#/profile`;
								// 					window.location.reload();
								// 				}
								// 				this.setSuccessLogin(true);
								// 				e.target.reset();
								// 				this.setIsLoader(false);
								// 			}).catch(error=>console.log('error  ',JSON.stringify(error)))
								// 	});
								// }else{
									
								profileUpdateResult(userId,profileUpdate)
									.then(res2 => {
										
										window.location.href = `${process.env.PUBLIC_URL}/#/`;
										window.location.reload();
										
										this.setSuccessLogin(true);
										e.target.reset();
										this.setIsLoader(false);
									}).catch(error=>console.log('error  ',JSON.stringify(error)))
								//}
							}
				
						}).catch(error=>[this.settoEmailNot('Something went wrong. Please try again later!'),this.setIsLoader(false)])
				}
			}).catch(error=>[this.settoEmailNot('Something went wrong. Please try again later!'),this.setIsLoader(false)])
	}

	settoEmailNot = (data) => {
		this.toEmailNot = data;
	}
	setIsLoader = (data) => {
		this.isLoader = data;
	}
	setSuccessLogin = (data) => {
		this.successLogin = data;
	}
}

decorate(LoginStore, {
	toEmailNot: observable,
	isLoader: observable,
	successLogin: observable,
	onSubmit: action,
	settoEmailNot: action,
	setIsLoader: action,
	setSuccessLogin: action,
});

export const login = createContext(new LoginStore());
export default LoginStore;