import React from 'react';
import { Link } from 'react-router-dom';
import { shareUrl } from '../../shareUrl';
import {observer, inject} from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Slider from "react-slick";
import NumericLabel from 'react-pretty-numbers';
import Popup from "reactjs-popup";
import { FacebookShareButton, LinkedinShareButton,TwitterShareButton } from "react-share";
import NumberFormat from 'react-number-format';
import ShowMoreText from 'react-show-more-text';
import FileBase64 from 'react-file-base64';

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class CampaignDetail extends React.Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
		this.app = this.props.match.params.applicants
		this.id = this.props.match.params.id
		this.applicantdetail = this.props.match.params.applicantdetail
		this.remarkChange = this.remarkChange.bind(this)
		this.reportChange = this.reportChange.bind(this)
		this.openCreateCampPopup = this.openCreateCampPopup.bind(this)
		this.sortingChange = this.sortingChange.bind(this)
		this.sortingHiredChange = this.sortingHiredChange.bind(this)
	}
	
	componentDidMount() {
		this.props.CampaignDetailStore.campaignDetailFetch(this.id);
		this.props.CampaignDetailStore.campaignIdFetch(this.id);
		this.props.CampaignDetailStore.minimumFollowerFetch(false);
		this.props.CampaignDetailStore.offerPopFetch('');
		if(this.props.CampaignDetailStore.auth){
			this.props.CampaignDetailStore.remarkDoneCheckFetch('Remark done');
		}
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	
	remarkChange = (e) =>{
		this.props.CampaignDetailStore.remarkFetch(e.target.value);
	}

	reportChange = (e) =>{
		this.props.CampaignDetailStore.reportFetch(e.target.value);
	}
	capitalize = (s) => {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}
	
	openCreateCampPopup = () =>{
		this.props.CreatecampaignListStore.openCreateCampPopup();
		this.props.CreatecampaignListStore.updateCampaign(this.id);
	}
	sortingChange = (e) =>{
		this.props.CampaignDetailStore.offerPopFetch('')
		let sorting = e.target.value;
		if(sorting === 'hightolow'){
			this.props.CampaignDetailStore.sortingApplicantFetch('hightolow')
		}else if(sorting === 'lowtohigh'){
			this.props.CampaignDetailStore.sortingApplicantFetch('lowtohigh')
		}else if(sorting === 'recent'){
			this.props.CampaignDetailStore.sortingApplicantFetch('recent')
		}else if(sorting === 'distance'){
			this.props.CampaignDetailStore.sortingApplicantFetch('distance')
		}else{
			this.props.CampaignDetailStore.sortingApplicantFetch('')
		}
	}
	sortingHiredChange = (e) =>{
		let sorting = e.target.value;
		if(sorting === 'hightolow'){
			this.props.CampaignDetailStore.sortingHiredFetch('hightolow')
		}else if(sorting === 'lowtohigh'){
			this.props.CampaignDetailStore.sortingHiredFetch('lowtohigh')
		}else if(sorting === 'recent'){
			this.props.CampaignDetailStore.sortingHiredFetch('recent')
		}else if(sorting === 'distance'){
			this.props.CampaignDetailStore.sortingApplicantFetch('distance')
		}else{
			this.props.CampaignDetailStore.sortingHiredFetch('')
		}
	}
	
	sortingSuggestChange = (e) =>{
		let sorting = e.target.value;
		console.log(sorting)
		if(sorting === 'hightolow'){
			this.props.CampaignDetailStore.sortingSuggestFetch('hightolow')
		}else if(sorting === 'lowtohigh'){
			this.props.CampaignDetailStore.sortingSuggestFetch('lowtohigh')
		}else if(sorting === 'recent'){
			this.props.CampaignDetailStore.sortingSuggestFetch('recent')
		}else if(sorting === 'distance'){
			this.props.CampaignDetailStore.sortingApplicantFetch('distance')
		}else{
			this.props.CampaignDetailStore.sortingSuggestFetch('')
		}
	}
	
	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }

	render(){

		var settings = {
			className: "slider variable-width",
			infinite: true,
			speed: 300,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: false,
			swipeToSlide: false,
			draggable : false,
			adaptiveHeight: false,
			responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1,
				  infinite: true,
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1,
				  initialSlide: 1
				}
			  },
			  {
				breakpoint: 480,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1,
				  infinite: true,
				}
			  }
			]
		  };
			

		const store = this.props.CampaignDetailStore
		const store2 = this.props.CreatecampaignListStore;
		
		let dateFetch = this.props.CampaignDetailStore.endStory;
		let eventDate = moment(dateFetch);
		let todayDate = moment();
		let lastDate = eventDate.diff(todayDate, 'days');

		return (
			<>
				{store.preloader ?
				<>
				<section className="cd-header-back after-header"  ref={this.myRef}>
					<Skeleton height={275}  />
				</section>
				<section className="cd-header-details">
					<div className="container custom-container">
						<div className="cd-details">
						   <div className="row">
							  <div className="col-12 col-md-10 col-lg-10">
								 <h2><Skeleton /></h2>
							  </div>
							  <div className="col-12 col-md-2 col-lg-2"> </div>
						   </div>
						   <div className="cd-header-image">
							  <div className="row">
								 <div className="col-12 col-md-8 col-lg-8">
									<div className="cd-header-img-wpr">
										<Skeleton width={790} height={420} />
									</div>
								 </div>
								 <div className="col-12 col-md-4 col-lg-4">
									<div className="interest-wpr">
									   <div className="price-wpr">
										  <p className="price"><Skeleton /></p>
									   </div>
									   <p className="price"><Skeleton /></p>
									   <div className="share-friends-wpr text-center">
										  <h3>Share With Friends</h3>
										  <ul className="cd-social">
											 <li><Skeleton circle={true} width={46} height={46} /></li>
											 <li><Skeleton circle={true} width={46} height={46} /></li>
											 <li><Skeleton circle={true} width={46} height={46} /></li>
										  </ul>
									   </div>
									</div>
								 </div>
							  </div>
							  <div className="cd-btn-group"></div>
						   </div>
						   <div className="cd-description row">
							  <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
								 <div className="row">
									<p className="posted col-12 col-xl-12 col-lg-12  col-md-12 col-sm-12"><Skeleton /></p>
									<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
									   <div clssname="campaign-type-mask">
										  <div className="hashtag-wpr">
											 <h4>Campaign Type</h4>
											 <div>
												<Skeleton />
											 </div>
										  </div>
									   </div>
									</div>
									<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
									   <div className="campaign-info-mask">
										  <div className="row">
											 <div className="col-12 col-lg-4 col-md-4">
												<div className="remain-wpr">
												   <h4>Preferred Gender</h4>
												   <p><Skeleton /></p>
												</div>
											 </div>
											 <div className="col-12 col-lg-4 col-md-4">
												<div className="remain-wpr">
												   <h4>Influencer's Age</h4>
												   <p><Skeleton /></p>
												</div>
											 </div>
											 <div className="col-12 col-lg-4 col-md-4">
												<div className="remain-wpr">
												   <h4>Min. Followers</h4>
												   <p className="min-followers-camp">
												   <div className="red " title="1000"><Skeleton /></div>
												   </p>
												</div>
											 </div>
										  </div>
									   </div>
									</div>
								 </div>
								 <div className="hashtag-wpr">
									<h4>Hashtag</h4>
									<div>
										<Skeleton />
									</div>
								 </div>
							  </div>
							  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
								 <div className="campaign-description-mask">
									<div className="cd-des">
									   <h4>Description</h4>
									   <p><Skeleton count={4} /></p>
									</div>
								 </div>
							  </div>
							  <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-xs-12">
								 <div></div>
							  </div>
						   </div>
						</div>
					</div>
				</section>
				</> :
				<>
				<section className="cd-header-back after-header" ref={this.myRef}>
					<img src={`${process.env.PUBLIC_URL}/images/header-image.png`} alt="" />
				</section>
				<section className="cd-header-details">
				{store.campaign.length !== 0 && store.campaign.map((inf,index) =>
					<div className="container custom-container" key={index}>
						{store.reportDoneMsg ?
						<div className="modal fade custom-modal interested-modal" id="emailSentModal" style={{display:'block',opacity:'1'}}>
							<div className="modal-dialog modal-dialog-centered" style={{paddingTop:'300px'}}>
							  <div className="modal-content">
								<div className="modal-body text-center">
									<h2 className="modal-heading">Congratulations!</h2>
									<p>Reported Successfully</p>
									<button className="btn btn-primary" onClick={() => store.reportDoneMsgFetch(false)} data-dismiss="modal">Ok</button>
								</div>
							  </div>
							</div>
						</div> :
						<div className="modal fade custom-modal report-modal" id="reportModal">
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-body ">
										<h2 className="modal-heading">Report</h2>
										<p className="get-det">Get details about a specific abuse report</p>
										<form onSubmit={store.reportSubmit} method="post"> 
											<div className="form-group">
												<label htmlFor="comment" className="form-modal-heading">Comment</label>
												<textarea className="form-control offeramountreset" rows="5" id="comment" onChange={this.reportChange} placeholder="Enter Your Comment"></textarea>
												<span className="text-danger">{store.enterRemark && store.enterRemark}</span>
											</div>
											<div className="form-group">
												<label htmlFor="comment" className="form-modal-heading">Image (Optional) {store.imageLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</label>
												<p className="form-control report"><FileBase64 onDone={ store.getFiles } /></p>
												{store.errImage && <span className="text-danger">The maximum allowed size for uploads is 2mb. Only image with the following extensions are allowed: jpg, jpeg, png.</span>}
											</div>
											<button type="submit" disabled={store.errImage} className="btn btn-primary w-100" >Report Campaign {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
										</form>
									</div>
								</div>
							</div>
						</div>}
						{store.remarkDoneMsg ?
						<div className="modal fade custom-modal interested-modal" id="emailSentModal" style={{display:'block',opacity:'1'}}>
							<div className="modal-dialog modal-dialog-centered">
							  <div className="modal-content">
								<div className="modal-body text-center">
									<h2 className="modal-heading">Congratulations!</h2>
									<p>You have successfully applied this campaign.Owner will review the application once available.</p>
									<button className="btn btn-primary" onClick={() => store.remarkDoneMsgFetch(false)} data-dismiss="modal">Ok</button>
								</div>
							  </div>
							</div>
						</div> :
						<div className="modal fade custom-modal interested-modal" id="interestedModal">
							<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body ">
									<h2 className="modal-heading">Remark</h2>
									<form onSubmit={store.remarkSubmit} method="post"> 
										<div className="form-group">
											<label htmlFor="remark" className="form-modal-heading">Message (Optional)</label>
											<textarea className="form-control" rows="5" id="remark" onChange={this.remarkChange} placeholder="Enter remark"></textarea>
												{store.enterRemark ? <span className="errorcolor">{store.enterRemark}</span> : null}
										</div>
										<button type="submit" className="btn btn-primary w-100" >Apply {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
									</form>
								</div>
							</div>
							</div>
						</div>}
						
						{/* Mark as Completed Modal*/}
						{store.markDoneMsg ?
						<div className="modal fade custom-modal interested-modal" id="emailSentModal" style={{display:'block',opacity:'1'}}>
							<div className="modal-dialog modal-dialog-centered">
							  <div className="modal-content">
								<div className="modal-body text-center">
									<h2 className="modal-heading">Congratulations!</h2>
									<p>Job has been successfully mark as completed.</p>
									<button className="btn btn-primary" onClick={() => store.markDoneMsgFetch(false)} data-dismiss="modal">Ok</button>
								</div>
							  </div>
							</div>
						</div> :
						<div className="modal fade custom-modal interested-modal" id="markAsModal">
							<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body ">
									<h2 className="modal-heading">Mark as Completed</h2>
									<form onSubmit={store.markAsCompletedSubmit} method="post"> 
										<div className="form-group">
											<textarea className="form-control" rows="5" name="markas" id="markas" placeholder="Please any suggestion for campaign owner"></textarea>
												{store.enterRemark ? <span className="errorcolor">{store.enterRemark}</span> : null}
										</div>
										<button type="submit" className="btn btn-primary w-100" >Submit {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
									</form>
								</div>
							</div>
							</div>
						</div>}
						
						<div className="cd-details">
							<a href="#" onClick={()=>window.history.back()}  className="backbtn"><i className="fa fa-chevron-left"></i> Back</a>
							<div className="row">
								<div className="col-9 col-md-10 col-lg-10"><h2>{inf.campaignTitle}</h2></div>
								<div className="col-3 col-md-2 col-lg-2">
								{store.auth ? 
									<>	{store.auth.ownerId == inf.ownerId ? '' :
											store.remarkDone && this.applicantdetail == 'applicantdetail' ? '' :
												<span className="cd-report titlebackbtn makeofferreset" onClick={()=>[store.enterRemarkFetch(''),store.errImageFetch(false)]} data-toggle="modal" data-target="#reportModal">Report</span>
											
										}
									</>
								: 	
									<span className="cd-report log-in-link backbtn  titlebackbtn">Report</span>
								}
								</div>
							</div>
							
							<div className="cd-header-image">
							<div className="row">
								<div className="col-12 col-md-8 col-lg-8">
								<div className="cd-header-img-wpr">
									<Slider {...settings}>
									{inf.campaignGallery.length !== 0 ? inf.campaignGallery.map((gallery,index)=>
										<div key={index}>
											<img src={gallery} alt="" />
										</div> 
									) : <div >
											<img src={inf.campaignImage} alt="" />
										</div> }
									</Slider>
								</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4">
								<div className="interest-wpr">
								<div className="price-wpr">
								{inf.campaignType == 'paid' ? <>
									<h4>Price</h4>
									<p className="price">{inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></p></>
								: inf.campaignType == 'sponsored' ? <>
									<h4></h4>
									{/* <div className="sponsored_label">Sponsored {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></div></> */}

									<div className="sponsored_label"><div className="showmycolor"><span style={{paddingRight: '4px'}}>Sponsored  {inf.campaignAmountCurrency}</span> <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></div></div></> 
								: <>
									<h4></h4>
									<div className="exchange_label"><div className="shoutoutcolor">Shoutout Exchange</div></div></>
								}
								</div>

								{store.auth ?
									store.remarkDone && store.auth.ownerId != inf.ownerId ? 
										(store.offerDetail.isHired === 0 && (inf.campaignType == 'paid' || inf.campaignType == 'shoutout' || inf.campaignType == 'sponsored')) ?
											<button type="button"  className="btn btn-primary disabled w-100 applied-disable">Applied</button>
											
										:(store.offerDetail.isHired === 1 && (inf.campaignType == 'shoutout' || inf.campaignType == 'sponsored')) ?
											<button type="button"  className="btn btn-primary disabled w-100 applied-disable">Applied</button>
											
										: (store.offerDetail.isMarkAsDone === 0 && inf.campaignType == 'paid' && store.profileCheck.stripeAccountNumber !== null && store.profileCheck.stripeBankAccountId !== null) ?
											<button type="button" data-toggle="modal" data-target="#markAsModal" id="markAsCompleted" className="btn btn-primary w-100"> Mark as Completed</button>
											
										:(store.offerDetail.isMarkAsDone === 0 && inf.campaignType == 'paid' && store.profileCheck.stripeAccountNumber === null && store.profileCheck.stripeBankAccountId === null) ?
											<button type="button" onClick={()=>store.bankInfoMarkCheckFetch(true)} className="btn btn-primary w-100"> Mark as Completed</button>
										
											
										: store.offerDetail.isMarkAsDone === 1 && <button type="button"  className="btn btn-primary disabled w-100 applied-disable">Job Completed</button>
										
									: (store.auth.ownerId == inf.ownerId && inf.campaignStatus >= 2) ?
										'' 
										:(store.auth.ownerId == inf.ownerId && inf.campaignStatus < 2) ? <button type="button" onClick={this.openCreateCampPopup} className="btn btn-primary w-100"><img src={`${process.env.PUBLIC_URL}/images/edit-profile.svg`} alt="" className="edit-applied"/> Edit Campaign</button> 
									: (store.profileCheck.dob && store.profileCheck.gender && store.profileCheck.email && store.profileCheck.first && store.profileCheck.last && store.profileCheck.country && store.profileCheck.bio && store.profileCheck.instaPerPost > 0) ?
										(inf.minimumFollowers > 0 && store.instaFollowers < inf.minimumFollowers) ? 
											<button type="button" onClick={()=>store.minimumFollowerFetch(true)} className="btn btn-primary w-100 applyforcamp-btn"> Apply for Campaign</button> 
										:
											<button type="button" data-toggle="modal" data-target="#interestedModal" id="iAmInterested" className="btn btn-primary w-100 applyforcamp-btn"> Apply for Campaign</button>
									:
										<button type="button" data-toggle="modal" data-target="#updateProfile" className="btn btn-primary w-100 applyforcamp-btn"> Apply for Campaign</button>
									
								: 
									<button type="button" className="btn btn-primary w-100 log-in-link applyforcamp-btn"> Apply for Campaign</button>
									
								}
								
								{(store.offerDetail.isMarkAsDone === 1 && inf.campaignType == 'paid' && store.profileCheck.stripeAccountNumber === null && store.profileCheck.stripeBankAccountId === null) ? 
									<p className="bankinfonote"> Note : We are currently processing payment with only Singapore Bank Accounts. If you are based on Singapore then kindly proceed by adding bank account in settings section. Else your payment will not be release. </p>
								: null}
							
								{store.minimumFollower && <span className="text-danger minfollowersshould">Minimum followers count should be <NumberFormat value={inf.minimumFollowers} displayType={'text'} thousandSeparator={true} /> to apply for this campaign. </span>}
								<div className="share-friends-wpr text-center">
								<h3>Share With Friends</h3>
								<ul className="cd-social">
									<li><FacebookShareButton url={`${shareUrl}campaigndetail/${this.id}`}><Link to="#"><i className="fa fa-facebook"></i></Link></FacebookShareButton></li>
									<li><LinkedinShareButton url={`${shareUrl}campaigndetail/${this.id}`}><Link to="#"><i className="fab fa-linkedin-in"></i></Link></LinkedinShareButton></li>
									<li><TwitterShareButton url={`${shareUrl}campaigndetail/${this.id}`}><Link to="#"><i className="fab fa-twitter"></i></Link></TwitterShareButton></li>
								</ul>
							</div>
							
							</div>
							{store.auth && <>
								{store.auth.ownerId == inf.ownerId ? <>
									{store.campNotify ? 
										<div className="disablecamp-text">If you do not want to show this campaign then you can disable it</div>
									:
										<div className="disablecamp-text">If you want to show this campaign then you can enable it</div>
									}
									<input className="edit-campaign-check" type="checkbox" id="switch" value={store.campNotify} checked={store.campNotify} onClick={store.campNotifySetting}  />
									<label className="edit-campaign-label" htmlFor="switch">Toggle </label>
									<span className="toggleswitch">{store.campNotify ? 'Enable' : 'Disable' }</span>
								</>:null} </>
							}
								</div>
							</div>

							{/*<div className="cd-header-img-wpr">
								<Slider {...settings}>
									<div>
										<img src={inf.campaignImage} alt="" />
									</div>
									<div>
										<img src={inf.campaignImage} alt="" />
									</div>
									<div>
										<img src={inf.campaignImage} alt="" />
									</div>
								</Slider>
						</div>*/}

								{/* <div className="cd-header-img-wpr">
									<img src={inf.campaignImage} alt="" />
								</div> */}
								{/*{store.auth ?
									<span className="cd-report" data-toggle="modal" data-target="#reportModal">Report</span>
								: 	<span className="cd-report log-in-link">Report</span>
								}*/}
								{/*<span className="cd-cat">Food</span>*/}
								<div className="cd-btn-group">
								{/*<span className="upload-btn" data-toggle="modal" data-target="#shareModal"><img src={`${process.env.PUBLIC_URL}/images/icons/up-arrow.svg`} alt="" /></span>
									<span className="heart-btn"><i className="fa fa-heart"></i></span>*/}
								</div>
							</div>

							<div className="cd-description row">
								<div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
									<div className="row">
										<p className="posted col-12 col-xl-12 col-lg-12  col-md-12 col-sm-12">Posted On : {moment(new Date(inf.createdAt)).format("MMMM D, YYYY")}</p>
										{store.auth && store.remarkDone && <>
											{store.offerDetail.offerStatus === 1 ?
												<div className="paymentaccept-infowrapper">
													<h2><span>Offer:</span> The campaign creator has offered to do this company for $<NumberFormat value={store.offerDetail.offerAmount} displayType={'text'} thousandSeparator={true} />. If your are agree say yes or no.</h2>
													<div className="payment-action">
													{(store.profileCheck.stripeAccountNumber !== null && store.profileCheck.stripeBankAccountId !== null) ?
														<Link to="#" onClick={()=>store.offerAcceptFetch(true)} className="paymentaccept">Accept</Link>
													:
														<Link to="#" onClick={()=>store.bankInfoCheckFetch(true)} className="paymentaccept">Accept</Link>
													}
														<Link to="#" onClick={()=>store.offerRejectFetch(true)} className="paymentreject">Reject</Link>
													</div>
												</div> : store.offerDetail.offerStatus === 2 ? 
												<div className="paymentaccept-infowrapper1">
													<div className="payment-action1">
														<span className="paymentaccept1">Offer Accepted</span> 
													</div>
													<h2> You have accepted offer of $<NumberFormat value={store.offerDetail.offerAmount} displayType={'text'} thousandSeparator={true} />. Now you have to complete the task and upon completion you have been paid out. Please make sure to update your bank details in settings section of the app</h2>
												</div> : ''
											}
										</>
										}
	
											<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
												<div clssName="campaign-type-mask">
													{store.category.length !== 0 &&
														<div className="hashtag-wpr">
															<h4>Campaign Type</h4>
															<div className="hashtag-list">
															{store.category.map((inf,index) =>
																<span key={index}>{inf.charAt(0).toUpperCase() + inf.slice(1)}</span>
															)}
															</div>
														</div>
														}
												</div>
											</div>

											<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
													<div className="campaign-info-mask">
														<div className="row">
															<div className="col-12 col-lg-3 col-md-3">
																<div className="remain-wpr">
																	<h4>Preferred Gender</h4>
																	<p>{this.capitalize(inf.lookingInfluencerGender)}</p>
																</div>
															</div>
															<div className="col-12 col-lg-3 col-md-3">
																<div className="remain-wpr">
																	<h4>Influencer's Age</h4>
																	{(inf.minAge == 0 && inf.maxAge == 0) ? <p>Any</p> : (inf.minAge >= 1 && inf.maxAge <= 12) ? <p>Below 13</p> : (inf.minAge >= 45 && inf.maxAge <= 100) ? <p>Above 44</p> : <p>{inf.minAge}-{inf.maxAge}</p>}
																</div>
															</div>
															{inf.minimumFollowers > 0 ?
															<div className="col-12 col-lg-3 col-md-3">
																<div className="remain-wpr">
																	<h4>Min. Followers</h4>
																	<p className="min-followers-camp"><NumericLabel params={optionnumberformat}>{inf.minimumFollowers}</NumericLabel></p>
																</div>
															</div> : 
															<div className="col-12 col-lg-3 col-md-3">
																<div className="remain-wpr">
																	<h4>Min. Followers</h4>
																	<p className="min-followers-camp">Any</p>
																</div>
															</div>
															}
															<div className="col-12 col-lg-3 col-md-3">
																<div className="remain-wpr">
																	<h4>Ending On</h4>
																	<p>{moment(new Date(inf.endStoryPostDate)).format("MMMM D, YYYY")}</p>
																</div>
															</div>
														</div>
													</div>
											</div>

									
									
										{/*<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="location-wpr">
												<h4>Location</h4>
												<p> <span className="fa fa-map-marker"></span>{inf.country === 'All' ? 'All Country' : inf.country}</p>
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="remain-wpr">
												<h4>Preferred Gender</h4>
												<p>{this.capitalize(inf.lookingInfluencerGender)}</p>
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="remain-wpr">
												<h4>Remaining time</h4>
												{eventDate > todayDate ? <p> {lastDate} Days Left</p> : <p> Expired</p> }
											</div>
										</div>
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="remain-wpr">
												<h4>Delivered On</h4>
												<p>{moment(new Date(inf.endStoryPostDate)).format("MMMM D, YYYY")}</p>
											</div>
										</div>
										{inf.minimumFollowers ?
										<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<div className="remain-wpr">
												<h4>Minimum Followers</h4>
												<p id="minimumFollowers"><NumericLabel params={optionnumberformat}>{inf.minimumFollowers}</NumericLabel></p>
											</div>
										</div> : null}*/}
										{/*<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
										{store.category.length !== 0 &&
											<div className="hashtag-wpr">
												<h4>Category</h4>
												<div className="hashtag-list">
												{store.category.map((inf,index) =>
													<span key={index}>{inf.charAt(0).toUpperCase() + inf.slice(1)}</span>
												)}
												</div>
											</div>
											}
										</div>*/}
									</div>
									{/*<div className="cd-des">
										<h4>Description</h4>
										{ReactHtmlParser(inf.campaignDetails)}
									</div>*/}
								
									
									{store.hash.length !== 0 &&
									<div className="hashtag-wpr">
										<h4>Hashtag</h4>
										<div className="hashtag-list">
										{store.hash.map((inf,index) =>
											<span key={index}>{inf}</span>
										)}
										</div>
									</div>
									}
								</div>
								<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div className="campaign-description-mask">
										<div className="cd-des">
											<h4>Description</h4>
											<p className="createcampaign-moreless"><ShowMoreText
										lines={3}
										more='Show more'
										less='Show less'
										anchorClass=''
										onClick={this.executeOnClick}
										expanded={false}
										width={1050}
									>
									{ReactHtmlParser(inf.campaignDetails)}
									</ShowMoreText></p>
										</div>
									</div>
								</div>
								<div className="col-12 col-xl-4 col-lg-4 col-md-4 col-xs-12">
									<div >
									{	/*<div className="interest-wpr">
											<div className="price-wpr">
												<h4>Price</h4>
												<p className="price">{inf.campaignAmountCurrency} {inf.campaignAmount}</p>
											</div>
											<div className="shipped-wpr">
												<h4>Product to be shipped</h4>
												<p>{inf.shipping === true ? 'Yes' : 'No'}</p>
											</div>
											{store.auth ?
												store.remarkDone ?
													<button type="button"  className="btn btn-primary disabled w-100">Applied</button>
												: store.auth.ownerId == inf.ownerId ? '' : 
													store.instaFollowers < inf.minimumFollowers ? <button type="button" onClick={()=>store.minimumFollowerFetch(true)} className="btn btn-primary w-100">I'm Interested</button> :
												<button type="button" data-toggle="modal" data-target="#interestedModal" id="iAmInterested" className="btn btn-primary w-100">I'm Interested</button>
												
											: 
											<button type="button" className="btn btn-primary w-100 log-in-link">I'm Interested</button>
												
											}
											{store.minimumFollower && <span className="text-danger">Minimum followers count should be {inf.minimumFollowers} to apply for this campaign. </span>}
										</div>
										<div className="share-friends-wpr text-center">
											<h3>Share With Friends</h3>
											<ul className="cd-social">
												<li><FacebookShareButton url={`${shareUrl}campaigndetail/${this.id}`}><Link to="#"><i className="fa fa-facebook"></i></Link></FacebookShareButton></li>
												<li><LinkedinShareButton url={`${shareUrl}campaigndetail/${this.id}`}><Link to="#"><i className="fab fa-linkedin-in"></i></Link></LinkedinShareButton></li>
												<li><TwitterShareButton url={`${shareUrl}campaigndetail/${this.id}`}><Link to="#"><i className="fab fa-twitter"></i></Link></TwitterShareButton></li>
											</ul>
										</div>*/}
									</div>
								</div>
							</div>
						</div>
						{store.auth ? <>
						{/* Applicants list start */}
							{this.app &&
							<div className="toogletab-section">
								<div className="tab-top-wpr">
									<ul className="nav nav-tabs custom-nav" id="myTab" role="tablist">
										<li className="nav-item">
											<a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Applications</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Suggested</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Hired</a>
										</li>
									</ul>
								</div>
								<div className="tab-content" id="application-user-list-tab">
									<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
										{store.applicants.filter(inf => inf.isHired === 0).length !== 0 &&
										<div className="row">
												<div className="col-7 col-md-9 col-lg-8">
												<h3>Application Received ({store.applicants.filter(inf => inf.isHired === 0).length})</h3>
												</div>
												<div className="col-5 col-md-3 col-lg-4 pl-lg-0">
													<div id="btnContainer" className="showlistgrid">
														
														<form className="application-filter-form">
															<div className="form-group">
																<select className="form-control" name="sorting" onChange={this.sortingChange}  id="shortbyCategory">
																	<option value="">Sort By</option>
																	<option value="lowtohigh">Followers Low to high</option>
																	<option value="hightolow">Followers High to low</option>
																	<option value="recent">By Date Applied</option>
																	<option value="distance">By Distance</option>
																</select>
															</div>
														</form>
														<button className="responsive-filter"><i className="fa fa-filter" aria-hidden="true"></i></button>
														<button className={store.listGrid ? 'btn active' : 'btn'} onClick={()=>store.listGridFetch(true)}><i className="fa fa-bars"></i></button> 
														<button className={store.listGrid ? 'btn' : 'btn active'} onClick={()=>store.listGridFetch(false)}><i className="fa fa-th-large"></i></button>
														<form className="application-filter-form responsive-sorting">
															<div className="form-group">
																<select className="form-control" name="sorting" onChange={this.sortingChange}  id="shortbyCategory">
																	<option value="">Sort By</option>
																	<option value="lowtohigh">Followers Low to high</option>
																	<option value="hightolow">Followers High to low</option>
																	<option value="recent">By Date Applied</option>
																	<option value="distance">By Distance</option>
																</select>
															</div>
														</form>
													</div>
												</div>
										</div>
										}
										<div className="row">
										{store.applicants.filter(info => info.isHired === 0).length !== 0 ? store.applicants.sort((a, b) => store.sortingApplicant === 'hightolow' ? a.profile.followers < b.profile.followers ? 1:-1 : store.sortingApplicant === 'lowtohigh' ? a.profile.followers > b.profile.followers ? 1:-1: store.sortingApplicant === 'recent' ? a.createdAt < b.createdAt ? 1:-1 : store.sortingApplicant === '' ? a.offerStatus < b.offerStatus ? 1:-1 : '').map((info,index) => <>
										{info.isHired === 0 && <>
										{store.listGrid ?
											<div className="col-12 col-md-12 col-lg-12" key={index}>
												<div className="applicant-list-wrapper">
													<div className="row">
														<div className="col-12 col-md-5 col-lg-3">
															<div className="row">
																<div className="col-12 col-md-4 col-lg-4">
																{info.profile.avatarUrl && info.profile.avatarUrl !== 'NA' ?
																	<img src={info.profile.avatarUrl} alt="" className="img-fluid" style={{height:'70px',width:'70px',borderRadius: '50%'}} /> 
																:
																	<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid" style={{borderRadius: '50%'}} />
																}
																</div>
																<div className="col-12 col-md-8 col-lg-8">
																	<div className="applicantlist-infosection">
																		<h2 className="applicabt-name"><Link className="application-name" to={`/application-profile/${info.profile.ownerId}`}>{info.profile.first} {info.profile.last}</Link></h2>
																		<div className="applicant-username"><Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${info.profile.ownerId}`}>@{info.profile.username.toString().replace(/@/g, "")}</Link></div>
																		<div className="followers-wrapper">
																			<img src={`${process.env.PUBLIC_URL}/images/instagram.svg`} alt="" className="img-fluid"/>
																			<div className="follower-text" style={{display: 'inline-flex'}}><NumericLabel params={optionnumberformat}>{info.profile.followers}</NumericLabel><span style={{paddingLeft: '2px'}}> Followers</span></div>
																		</div>
																		{info.offerStatus === 1 ? 
																			<div className="acceptoffer-label pendingoffer-label">
																				<img src={`${process.env.PUBLIC_URL}/images/clockicon.svg`} alt=""/>
																				<span>Pending offer for ${info.offerAmount}</span>
																			</div>
																		: info.offerStatus === 2 ?
																			<div className="acceptoffer-label">
																				<img src={`${process.env.PUBLIC_URL}/images/accept-payment-check.svg`} alt=""/>
																				<span>Accepted offer for ${info.offerAmount}</span>
																			</div>
																		: ''}
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12 col-md-7 col-lg-9">
															<div className="applicantlist-inforight">
																	<div className="applicant-list-text">{decodeURIComponent(info.remarkText.replace('% ', 'percent'))}</div>
																<button className="list-message-btn">Message</button>
																{info.offerStatus === 1 ?
																	<button className="list-message-btn applicant-awaiting">Awaiting</button>
																: ''}
																{(info.offerStatus === 0 && inf.campaignType == 'paid') ?
																	<button className="list-message-btn" style={{cursor:'pointer'}} onClick={()=>[store.offerSuccessFetch(''),store.enterRemarkFetch(''),store.offerPopFetch(info.id)]} data-toggle="modal" data-target={`#makeofferModal${info.id}`}>Make Offer</button>
																: (info.offerStatus === 2) ?
																	<button className="list-message-btn" style={{cursor:'pointer'}} data-toggle="modal" data-target={`#hireinfluencerModal${info.id}`}>Make Payment</button>
																: ''}
																
																{(inf.campaignType === 'sponsored' || inf.campaignType == 'shoutout') && <button className="make-offer-hover-btn makeofferreset" onClick={()=>[store.hireNowFetch(info.id)]}>Hire Now</button>}
																
																{info.profile.stripeAccountNumber === null && info.profile.stripeBankAccountId === null && <p className="text-left">Your offer is accepted by applicant but no payment details added by applicant. You connect  the applicant to discuss further</p>}
															</div>
														</div>
													</div>
												</div>
											</div>
											:
											<div className="col-12 col-md-4 col-lg-3" key={index}>
												<div className="applicant-wrapper">
													<div className="applicant-shift">
														<div className="applicant-img-wrapper">
															{info.profile.avatarUrl && info.profile.avatarUrl !== 'NA' ?
															<Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${info.profile.ownerId}`}><img src={info.profile.avatarUrl} alt="" className="img-fluid applicant-img" /> </Link>
															:
															<Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${info.profile.ownerId}`}><img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid applicant-img" /></Link>
															}
															{info.offerStatus === 1 ? 
																<div className="acceptoffer-label pendingoffer-label">
																	<img src={`${process.env.PUBLIC_URL}/images/clockicon.svg`} alt=""/>
																	<span>Pending offer for ${info.offerAmount}</span>
																</div>
															: info.offerStatus === 2 ?
																<div className="acceptoffer-label">
																	<img src={`${process.env.PUBLIC_URL}/images/accept-payment-check.svg`} alt=""/>
																	<span>Accepted offer for ${info.offerAmount}</span>
																</div>
																
															: ''}
															
														</div>
														<h2 className="applicabt-name"><Link className="application-name" to={`/application-profile/${info.profile.ownerId}`}>{info.profile.first} {info.profile.last}</Link></h2>
														<div className="applicant-username"><Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${info.profile.ownerId}`}>@{info.profile.username.toString().replace(/@/g, "")}</Link></div>
													
													</div>

													{info.offerStatus === 1 ?
														<button className="make-offer-hover-btn applicant-awaiting">Awaiting</button>
														: ''}
													{(info.offerStatus === 0 && inf.campaignType == 'paid')?
														<button className="make-offer-hover-btn makeofferreset" onClick={()=>[store.offerSuccessFetch(''),store.enterRemarkFetch(''),store.offerPopFetch(info.id)]} data-toggle="modal" data-target={`#makeofferModal${info.id}`}>Make Offer</button>
													: (info.offerStatus === 2) ?
														<button className="make-offer-hover-btn" data-toggle="modal" data-target={`#hireinfluencerModal${info.id}`}>Make Payment</button>
													: ''}
													{(inf.campaignType === 'sponsored' || inf.campaignType == 'shoutout') && <button className="make-offer-hover-btn makeofferreset" onClick={()=>store.hireNowFetch(info.id)} data-toggle="modal" data-target={`#hireshowModal${info.id}`}>Hire Now</button>}
													
													<div className="followers-mask">
														<div className="followers-wrapper">
															<img src={`${process.env.PUBLIC_URL}/images/instagram.svg`} alt="" className="img-fluid"/>
															<div className="follower-text" style={{display: 'inline-flex'}}><NumericLabel params={optionnumberformat}>{info.profile.followers}</NumericLabel><span style={{paddingLeft: '2px'}}> Followers</span></div>
														</div>
													</div>
													
												</div>
											</div> } </> } 
											
												<div className={store.offerPop === info.id ? 'modal fade applicantspaymentModal show' : 'modal fade applicantspaymentModal'} id={`makeofferModal${info.id}`} style={{display: store.offerPop === info.id ? 'block' : 'none'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered" role="document">
														<div className="modal-content">
															<div className="modal-body">
																<h5 className="modal-title" id="exampleModalLabel">Make Offer</h5>
																<div className="row">
																	<div className="col-12 col-md-2 col-lg-2 pr-lg-0">
																		<div className="makepayment-avater">
																		{info.profile.avatarUrl && info.profile.avatarUrl !== 'NA' ?
																			<img src={info.profile.avatarUrl} alt="" className="img-fluid" /> 
																		:
																			<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid" />
																		}
																		</div>
																	</div>
																	<div className="col-12 col-md-10 col-lg-10">
																		<div className="makepayment-infowrapper">
																			<h2>{info.profile.first} {info.profile.last}</h2>
																			<p>@{info.profile.username.toString().replace(/@/g, "")}</p>
																			<div className="makepayment-instafollower" style={{display: 'inline-flex'}}><NumericLabel params={optionnumberformat}>{info.profile.followers}</NumericLabel><span style={{paddingLeft: '2px'}}> Followers</span></div>
																		</div>
																	</div>
																</div>
																<form onSubmit={store.submitOfferAmount} method="post" className="makeoffer-modal-form">
																	<div className="form-group">
																		<label htmlFor="enterOfferamount">Enter Offer Amount</label>
																		<input type="text" className="form-control offeramountreset" name="offerAmount" placeholder="Enter Offer Amount" />
																		<input type="hidden" name="applicantId" value={info.id} />
																		{store.enterRemark ? <span className="text-danger"> {store.enterRemark} </span> : null}
																		{store.offerSuccess ? <span className="text-success"> {store.offerSuccess} </span> : null}
																	</div>
																	<button type="submit" className="paybutton" style={{cursor:'pointer'}}>Submit {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
																</form>
															</div>
														</div>
													</div>
												</div>

												<div className="modal fade applicantspaymentModal" id={`hireinfluencerModal${info.id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered" role="document">
														<div className="modal-content">
															<div className="modal-body">
																<h5 className="modal-title" id="exampleModalLabel">Hire Influencer</h5>
																<div className="row">
																	<div className="col-12 col-md-2 col-lg-2 pr-lg-0">
																		<div className="makepayment-avater">
																		{info.profile.avatarUrl && info.profile.avatarUrl !== 'NA' ?
																			<img src={info.profile.avatarUrl} alt="" className="img-fluid" /> 
																		:
																			<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid" />
																		}
																		</div>
																	</div>
																	<div className="col-12 col-md-10 col-lg-10">
																		<div className="row">
																			<div className="col-12 col-md-6 col-lg-6">
																				<div className="makepayment-infowrapper">
																					<h2>{info.profile.first} {info.profile.last}</h2>
																					<p>@{info.profile.username.toString().replace(/@/g, "")}</p>
																					<div className="makepayment-instafollower" style={{display: 'inline-flex'}}><NumericLabel params={optionnumberformat}>{info.profile.followers}</NumericLabel><span style={{paddingLeft: '2px'}}> Followers</span></div>
																				</div>
																			</div>
																			<div className="col-12 col-md-6 col-lg-6">
																				{info.offerStatus === 1 ? 
																					<div className="acceptoffer-label pendingoffer-label">
																						<img src={`${process.env.PUBLIC_URL}/images/clockicon.svg`} alt=""/>
																						<span>Pending offer for ${info.offerAmount}</span>
																					</div>
																				: info.offerStatus === 2 ?
																					<div className="acceptoffer-label">
																						<img src={`${process.env.PUBLIC_URL}/images/accept-payment-check.svg`} alt=""/>
																						<span>Accepted offer for ${info.offerAmount}</span>
																					</div>
																				: ''}
																			</div>
																		</div>
																	</div>
																</div>
																<form className="makeoffer-modal-form">
																	<div className="form-group">
																		<div className="row">
																			<div className="col-6 col-md-6 col-lg-6">
																				<label htmlFor="enterOfferamount">Your Offered Amount</label>
																			</div>
																			<div className="col-6 col-md-6 col-lg-6">
																				<div className="whatisescrow-wrapper">
																				
																					<div className="report-menu-wpr shiredtab-right">
																						<span className="settings">
																						<div className="whatisescrow">What is Escrow ? <img src={`${process.env.PUBLIC_URL}/images/escrow-icon.svg`} alt="" /></div>
																						</span>
																						<div className="report-menu">
																						
																								<h2>What is Escrow ?</h2>
																								<p>Being in escrow is a contractual arrangement in which a third party receives and disburses money or property for the primary transacting parties, most generally, used with plentiful terms that conduct the rightful actions that follow. The disbursement is dependent on conditions agreed to by the transacting parties.</p>
																						
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<input type="text" className="form-control" id={`enterOfferamount1${index}`} placeholder={`${info.offerAmount}`} disabled/>
																	</div>
																	<Link to={`${process.env.PUBLIC_URL}/payment/${this.id}/${info.profile.ownerId}`}><button style={{cursor:'pointer'}} className="paybutton">Pay Now</button></Link>
																</form>
															</div>
														</div>
													</div>
												</div>
												
												<div className={store.hireNow === info.id ? 'modal fade applicantspaymentModal show' : 'modal fade applicantspaymentModal'} id={`hireshowModal${info.id}`} style={{display: store.hireNow === info.id ? 'block' : 'none'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered" role="document">
														<div className="modal-content">
															<div className="modal-body">
																<div className="custom-popup text-center">
																	<div className="popup-details">
																		<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
																		<p className="popup-details mb-4">Are you sure you want to hire this applicant?</p>
																		<button data-dismiss="modal" className="btn cancel-btn btn-secondary mr-3">No</button>
																		<button onClick={() => store.hireNowSubmit(info.id)} className="btn btn-primary">Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												
											</>): <div className="no-hiredapplicant"> <div className="no-hired-center"><div><img src={`${process.env.PUBLIC_URL}/images/leader.svg`} alt="" className="img-fluid" /></div><h2>Yet no applicants applied on campaigns</h2></div></div>}
										</div>
									</div>
									{/* Suggested User Start */}
									<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
										{store.suggested.length !== 0 &&
										<div className="row">
											<div className="col-7 col-md-9 col-lg-8">
												
											</div>
											<div className="col-5 col-md-3 col-lg-4 pl-lg-0">
												<div id="btnContainer" className="showlistgrid">
													<button className="responsive-filter"><i className="fa fa-filter" aria-hidden="true"></i></button>
													<form className="application-filter-form">
														<div className="form-group">
															<select className="form-control" name="sorting" onChange={this.sortingSuggestChange}  id="shortbyCategory2">
																<option value="">Sort By</option>
																<option value="lowtohigh">Followers Low to high</option>
																<option value="hightolow">Followers High to low</option>
																<option value="distance">By Distance</option>
															</select>
														</div>
													</form>
													<form className="application-filter-form responsive-sorting suggested-sorting">
														<div className="form-group">
															<select className="form-control" name="sorting" onChange={this.sortingSuggestChange}  id="shortbyCategory">
																<option value="">Sort By</option>
																<option value="lowtohigh">Followers Low to high</option>
																<option value="hightolow">Followers High to low</option>
																<option value="recent">By Date Applied</option>
																<option value="distance">By Distance</option>
															</select>
														</div>
													</form>
												</div>
											</div>
										</div>
										}
										<div className="row">
											{store.suggested.length !== 0 ? store.suggested.sort((a, b) => store.sortingSuggest === 'hightolow' ? a.followers < b.followers ? 1:-1 : store.sortingSuggest === 'lowtohigh' ? a.followers > b.followers ? 1:-1: '').map((inf,index)=>
											<div className="col-12 col-md-4 col-lg-3" key={index}>
												<div className="applicant-wrapper">
													<div className="applicant-shift">
														<div className="applicant-img-wrapper">
															{inf.avatarUrl && inf.avatarUrl !== 'NA' ?
															<Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${inf.ownerId}`}><img src={inf.avatarUrl} alt="" className="img-fluid applicant-img" /></Link>
															:
															<Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${inf.ownerId}`}><img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid applicant-img" /></Link>
															}
														</div>
														<h2 className="applicabt-name"><Link className="application-name" to={`/application-profile/${inf.ownerId}`}>{inf.first} {inf.last}</Link></h2>
														<div className="applicant-username"><Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${inf.ownerId}`}>@{inf.username.toString().replace(/@/g, "")}</Link></div>
														
													</div>
													<button className="make-offer-hover-btn makeofferreset">Message</button>
													<div className="followers-mask">
														<div className="followers-wrapper suggested-wrapper">
															<img src={`${process.env.PUBLIC_URL}/images/instagram.svg`} alt="" className="img-fluid"/>
															<div className="follower-text" style={{display: 'inline-flex'}}><NumericLabel params={optionnumberformat}>{inf.followers}</NumericLabel><span style={{paddingLeft: '2px'}}> Followers</span></div>
														</div>
													</div>
												</div>
											</div>) : 'Suggested User not found'}
										</div>
									</div>
									{/* Suggested User End */}
									{/* Hired Applicants Start */}
									<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
										{store.applicants.filter(inf => inf.isHired === 1).length !== 0 &&
										<div className="row">
											<div className="col-7 col-md-9 col-lg-8">
												
											</div>
											<div className="col-5 col-md-3 col-lg-4 pl-lg-0">
												<div id="btnContainer" className="showlistgrid">
													<button className="responsive-filter"><i className="fa fa-filter" aria-hidden="true"></i></button>
													<form className="application-filter-form">
														<div className="form-group">
															<select className="form-control" name="sorting" onChange={this.sortingHiredChange}  id="shortbyCategory1">
																<option value="">Sort By</option>
																<option value="lowtohigh">Followers Low to high</option>
																<option value="hightolow">Followers High to low</option>
																<option value="recent">By Date Applied</option>
																<option value="distance">By Distance</option>
															</select>
														</div>
													</form>
													<form className="application-filter-form responsive-sorting suggested-sorting">
														<div className="form-group">
															<select className="form-control" name="sorting" onChange={this.sortingHiredChange}  id="shortbyCategory">
																<option value="">Sort By</option>
																<option value="lowtohigh">Followers Low to high</option>
																<option value="hightolow">Followers High to low</option>
																<option value="recent">By Date Applied</option>
																<option value="distance">By Distance</option>
															</select>
														</div>
													</form>
												</div>
											</div>
										</div>}
										<div className="row">
										{store.applicants.filter(info => info.isHired === 1).length !== 0 ? store.applicants.sort((a, b) => store.sortingHired === 'hightolow' ? a.profile.followers < b.profile.followers ? 1:-1 : store.sortingHired === 'lowtohigh' ? a.profile.followers > b.profile.followers ? 1:-1: store.sortingHired === 'recent' ? a.createdAt < b.createdAt ? 1:-1 : '').map((info,index) => <>
											{info.isHired === 1 &&
											<div className="col-12 col-md-4 col-lg-3" key={index}>
												<div className="hired-wrapper">
													<div className="applicant-infosection">
														{info.offerAmount > 0 &&
														<div className="hiredtab-left">
															<h2>$<NumberFormat value={info.offerAmount} displayType={'text'} thousandSeparator={true} /></h2>
															<p>Service Fee</p>
														</div>}
														
														<div className="report-menu-wpr shiredtab-right">
															<span className="settings">
																<img src={`${process.env.PUBLIC_URL}/images/more-options.svg`} alt="" />
															</span>
															<ul className="report-menu hired-menu">
																<li>
																	<Link to="" data-toggle="modal" data-target="#writeareviewModal">Message</Link>
																</li>
																<li>
																	<Link to="" data-toggle="modal" data-target="#cenclejobModal">Cancel Job</Link>
																</li>
																{info.isMarkAsDone === 1 &&
																<li>
																	<Link to="" data-toggle="modal" data-target="#raiseisdputeModal">Raise Dispute ?</Link>
																</li>
																}
															</ul>
														</div>
													</div>
													
													<div className="applicant-shift">
														<div className="applicant-img-wrapper">
															{info.profile.avatarUrl && info.profile.avatarUrl !== 'NA' ?
															<Link className="application-name" to={`/application-profile/${info.profile.ownerId}`}><img src={info.profile.avatarUrl} alt="" className="img-fluid applicant-img" /></Link> 
															:
															<Link className="application-name" to={`/application-profile/${info.profile.ownerId}`}><img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid applicant-img" /></Link>
															}
															{(info.isMarkAsDone === 1 && info.isPaymentReleased === 0 && inf.campaignType === 'paid') ?
															<div className="completed-labe">
																<span>Completed</span>
															</div> 
															: (inf.campaignType === 'sponsored' || inf.campaignType === 'shoutout') ?
															<div className="completed-labe">
																<span>Completed</span>
															</div> 
															:(info.isMarkAsDone === 1 && info.isPaymentReleased === 1 && inf.campaignType === 'paid')?
																<div className="completed-labe">
																	<span>Payment Done</span>
																</div> 
															:
															<div className="ongoing-labe">
																<span>Ongoing</span>
															</div>
															}
														</div>
														<h2 className="applicabt-name"><Link className="application-name" to={`/application-profile/${info.profile.ownerId}`}>{info.profile.first} {info.profile.last}</Link></h2>
														<div className="applicant-username"><Link className="application-name" style={{color:'#1658D3'}} to={`/application-profile/${info.profile.ownerId}`}>@{info.profile.username.toString().replace(/@/g, "")}</Link></div>
														{/*<div className="engaged-wrapper">
														<div className="engaged-text">Engaged on: 24 Jun, 2020</div>
														</div>*/}
														{/*
														<div className="followers-wrapper">
															<img src="/images/payment-done.svg" alt="" className="img-fluid"/>
															<div className="follower-text">Payment Done</div>
														</div>*/}
														
													</div>
													<div className="hiredbtn-wrapper">
														{info.isMarkAsDone === 1 && info.profile.stripeAccountNumber === null && info.profile.stripeBankAccountId === null && <p className="text-center bankinfonote">Your offer is accepted by applicant but no payment details added by applicant. You connect  the applicant to discuss further</p>}
														
														{info.offerStatus === 2 && info.isMarkAsDone === 1 && info.profile.stripeAccountNumber !== null && info.profile.stripeBankAccountId !== null && info.isPaymentReleased === 0 &&
															<button className="hired-message-btn" style={{minWidth: '150px'}} data-toggle="modal" data-target={`.releasepaymentsModal${info.id}`}>Release Payment</button>
														}
														
														{/*<button className="hired-canclejob-btn" >Write a Review</button>*/}
													</div>
												</div>
											</div> }
											{store.payoutSuccess ? null : 
											<div className={`modal fade releasepaymentsModal${info.id}`} id="releasepaymentsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
												<div className="modal-dialog modal-dialog-centered" role="document">
													<div className="modal-content">
														<div className="modal-body">
															<h2>Release Payment</h2>
															<p>By Clicking this, You are asking KOLI to release payment to <br/> {info.profile.first} {info.profile.last} for completed Job.</p>
															{store.bankCheck && <p className="text-danger">{store.bankCheck}</p>}
															
															<div className="canclejob-btns-wrapper">
																<button className="cancle-job-btns" data-dismiss="modal">No</button>
																<button className="cancle-job-btns" onClick={()=>store.releasePaymentSubmit(info.profile.ownerId,info.offerAmount)}>Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
															</div>
														</div>
													</div>
												</div>
											</div>}

											</>) : <div className="no-hiredapplicant"> <div className="no-hired-center"><div>	<img src={`${process.env.PUBLIC_URL}/images/leader.svg`} alt="" className="img-fluid" /></div><h2>Not yet appointed any of their influencers</h2></div></div>}
										</div>
									</div>
									{/* Hired Applicants End */}
								</div>
							</div>}

						{/* Applicants list end */}
						</>:null}
					</div>)}
					
					<div className="modal fade" id="cenclejobModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-body">
									<h2>Cancel Job</h2>
									<p>Are you sure, you want to cancel this job?</p>
									<div className="canclejob-btns-wrapper">
										<button className="cancle-job-btns">No</button>
										<button className="cancle-job-btns	">Yes</button>
									</div>
								</div>
							</div>
						</div>
					</div>

				<div className="modal fade" id="raiseisdputeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<h2>Raise Dispute</h2>
								<div class="row">
									<div class="col-12 col-md-2 col-lg-2 pr-lg-0 pr-md-0">
										<div class="makepayment-avater">
										<img src={`${process.env.PUBLIC_URL}/images/lim.png`} alt="" />
										</div>
									</div>
									<div class="col-12 col-md-10 col-lg-10">
										<div class="makepayment-infowrapper">
											<h2>Juanita Hoeger</h2>
											<p>@userkolihandler</p>
										</div>
									</div>
								</div>
								<p>Please provide a reason for dispute</p>
								<form className="raisedispute-form">
									<div className="form-group">
    								<textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Enter your reason"></textarea>
									</div>
									<Link to="#/payment/620/1552"><button className="paybutton">Submit</button></Link>
								</form>
							</div>
						</div>
					</div>
				</div>


				<div className="modal fade" id="writeareviewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<h2>Raise Dispute</h2>
							<div class="row">
								<div class="col-12 col-md-2 col-lg-2 pr-lg-0 pr-md-0">
									<div class="makepayment-avater">
										<img src={`${process.env.PUBLIC_URL}/images/lim.png`} alt="" />
									</div>
								</div>
								<div class="col-12 col-md-10 col-lg-10">
									<div class="makepayment-infowrapper">
										<h2>Juanita Hoeger</h2>
										<p>@userkolihandler</p>
									</div>
								</div>
							</div>
							<div className="writereview-rating">
								<img src={`${process.env.PUBLIC_URL}/images/modal-rating-img.png`} alt="" />
								<span>4.0 Ratings</span>
							</div>
							<p>Please provide a reason for dispute</p>
							<form className="raisedispute-form">
								<div className="form-group">
									<textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Enter comment"></textarea>
								</div>
								<Link to="#/payment/620/1552"><button className="paybutton">Submit</button></Link>
							</form>
						</div>
					</div>
				</div>
			</div>

				</section>
				<Popup
					 open={store.enableDisableCamp}
					 closeOnDocumentClick
					 onClose={() => store.enableDisableCampFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							{store.campNotify === false ?
								<p className="popup-details mb-4">Are you sure you want to enable this campaign?</p>
							:
								<p className="popup-details mb-4">Are you sure you want to disable this campaign?</p>
							}
							<button onClick={ () => [store.enableDisableCampFetch(false), store.campNotify === false ? store.campNotifyFetch(false) : store.campNotifyFetch(true)]} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
							<button onClick={() => store.submitCampSetting()} className="btn btn-primary">Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
						</div>
						
					</div>
				</Popup>
				
				{/* Bank Info Check */}
				<Popup
					 open={store.bankInfoCheck}
					 closeOnDocumentClick
					 onClose={() => store.bankInfoCheckFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">We are currently processing payment with only Singapore Bank Accounts. If you are based on Singapore then kindly proceed by adding bank account in settings section. Else continue without payment.</p>
							<button onClick={ () => store.bankInfoCheckFetch(false)} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
							<button onClick={() => [store.offerAcceptFetch(true),store.bankInfoCheckFetch(false)]} className="btn btn-primary">Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
						</div>
						
					</div>
				</Popup>
				
				<Popup
					 open={store.bankInfoMarkCheck}
					 closeOnDocumentClick
					 onClose={() => store.bankInfoMarkCheckFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">We are currently processing payment with only Singapore Bank Accounts. If you are based on Singapore then kindly proceed by adding bank account in settings section. Else your payment will not be release.</p>
							<button onClick={ () => store.bankInfoMarkCheckFetch(false)} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
							<button onClick={() => store.bankInfoMarkCheckFetch(false)} data-toggle="modal" data-target="#markAsModal" className="btn btn-primary">Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
						</div>
						
					</div>
				</Popup>
				
				{/* Offer Accept */}
				<Popup
					 open={store.offerAccept}
					 closeOnDocumentClick
					 onClose={() => store.offerAcceptFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">Are you sure you want to submit your response?</p>
							<button onClick={ () => store.offerAcceptFetch(false)} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
							<button onClick={() => store.submitOfferStatus(2)} className="btn btn-primary">Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
						</div>
						
					</div>
				</Popup>
				{/* Offer Reject */}
				<Popup
					 open={store.offerReject}
					 closeOnDocumentClick
					 onClose={() => store.offerRejectFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">Are you sure you want to submit your response?</p>
							<button onClick={ () => store.offerRejectFetch(false)} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
							<button onClick={() => store.submitOfferStatus(3)} className="btn btn-primary">Yes {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
						</div>
						
					</div>
				</Popup>
				<div className="payoutSuccess">
				<Popup
					 open={store.payoutSuccess}
					 closeOnDocumentClick
					 onClose={() => store.payoutSuccessFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">Payout Successfully Done</p>
							<button onClick={() => store.payoutSuccessFetch(false)} className="btn btn-primary">Ok</button>
						</div>
					</div>
				</Popup>
				</div>
				
				<Popup
					 open={store.hiredSuccess}
					 closeOnDocumentClick
					 onClose={() => store.hiredSuccessFetch(false)}
					>
					<div className="custom-popup text-center">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
							<p className="popup-details mb-4">You now hired this influencer, you can chat with the influencer to discuss further on this campaign.</p>
							<button className="btn btn-primary" onClick={() => store.hiredSuccessFetch(false)} >Ok</button>
						</div>
					</div>
				</Popup>
				
				<div className="modal fade custom-modal interested-modal" id="updateProfile">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="custom-popup text-center">
									<div className="popup-details">
										<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" />
										<p className="popup-details mb-4">Please update missing details from your Profile to continue applying on Campaigns Jobs?</p>
										<button data-dismiss="modal" className="btn cancel-btn btn-secondary mr-3">Do It Later</button> 
										<Link to={`${process.env.PUBLIC_URL}/edit-profile/${store.campaignId}`} className="btn btn-primary">Update Now</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</>}
				
			</>
		)
	}
}
export default inject('CampaignDetailStore','CreatecampaignListStore')(observer(CampaignDetail));