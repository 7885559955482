import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { InstagramPost } from '../api/InstagrampostApi';
import { influencerDetailResult } from '../api/InfluencerDetailApi';
import { userCampResult } from '../api/UserCampaignApi';
import { usernameCheckResult } from '../api/UsernameCheckApi';
import { reportResult } from '../api/ReportApi';
import { profileImageResult } from '../api/ProfileimageApi';
import { profileUpdateResult } from '../api/ProfileupdateApi';
import { userblockResult } from '../api/UserblockApi';
import { userblockedResult } from '../api/UserblockedApi';


class InfluencerProfileStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	instaPosts = '';
	instaPostCount = '';
	instaFollowersCount = '';
	notFound = false;
	notFoundMy = false;
	postPrivate = false;
	username = '';
	blockUserID = '';
	avatarUrl = '';
	fullName = '';
	preloader = true;
	campHide = false;
	userCamp = '';
	userCampCount = '';
	reportDoneMsg = false;
	report = '';
	errImage = false;
	files = '';
	loader = false;
	imageLoader = false;
	lastSeen = '';
	enterReport = '';
	instaUsername = '';
	instaUsernameOwn = '';
	blockSuccess = false;
	blockPop = false;
	blockLoader = false;
	
	profileFetchData = (influencerId) => { 
		this.preloaderFetch(true)
		this.instaPostsFetch('');
		this.instaPostCountFetch('');
		this.instaFollowersCountFetch('');
		if(influencerId){
			let param = {where:{ownerId:influencerId}}
			usernameCheckResult(JSON.stringify(param))
				.then(res1 => {
					let result1 = res1.data[0]
					this.usernameFetch(result1.username)
					this.avatarUrlFetch(result1.avatarUrl)
					this.fullNameFetch(result1.first+' '+result1.last)
					this.lastSeenFetch(result1.lastSeen)
					this.instaUsernameFetch(result1.instaUsername)
					this.userIDFetch(result1.ownerId)
					let ownerId = result1.ownerId
					if(result1.instaUsername){
						InstagramPost(result1.instaUsername)
							.then(res => {
								if(res.data.graphql){
									let result = res.data.graphql.user;
									if(result.edge_owner_to_timeline_media.edges.length > 0){
										this.instaPostsFetch(result.edge_owner_to_timeline_media.edges);
										this.postPrivateFetch(false);
										this.notFoundFetch(false)
									}else{
										this.postPrivateFetch(true);
										this.notFoundFetch(false)
									}

									let instaUser1 = {
										followers: result.edge_followed_by.count,
										totalPosts: result.edge_owner_to_timeline_media.count
									}

									profileUpdateResult(ownerId,instaUser1)
										.then(res5 => {
											let resultprofile = res5.data.message.profile;
											this.instaFollowersCountFetch(resultprofile.followers);
											this.instaPostCountFetch(resultprofile.totalPosts);
											this.preloaderFetch(false)
										}).catch(error=>console.log(error))
									
								}else{
									this.instaFollowersCountFetch(result1.followers);
									this.instaPostCountFetch(result1.totalPosts);
									this.notFoundFetch(true)
									this.preloaderFetch(false)
								}
							}).catch(error=>[this.notFoundFetch(true),this.preloaderFetch(false),this.postPrivateFetch(false),this.instaFollowersCountFetch(result1.followers),this.instaPostCountFetch(result1.totalPosts)])
					}else{
						this.preloaderFetch(false)
						this.notFoundFetch(true)
					}
					
					if(ownerId !== 0){
						userCampResult(ownerId)
							.then(res2 => {
								if(res2.data.data.length > 0){
									this.userCampFetch(res2.data.data)
									this.userCampCountFetch(res2.data.data.length)
									this.campHideFetch(false)
								}else{
									this.campHideFetch(true)
								}
								
							}).catch(error=>[this.campHideFetch(true)]) 
					}else{
						this.campHideFetch(true)
					}
					
				}).catch(error=>console.log(error)) 
		}
	}

	BlockUser = (influencerid) => {
		this.blockLoaderFetch(true)
		let blockUserData = {blockUserId:influencerid, ownerId:this.userId}
		userblockResult(blockUserData)
			.then(res => { 
			console.log(res)
				this.blockSuccessFetch(true);
				this.blockLoaderFetch(false)
				window.location.assign(`${process.env.PUBLIC_URL}/#/search-influencer`)
				
			}).catch(error=>console.log(error))
	}
	
	profileOwnFetchData = () => {
		if(this.auth){
			let param = {where:{ownerId:this.userId}}
			usernameCheckResult(JSON.stringify(param))
				.then(res1 => {
					if(res1.data.length !== 0){
						let instaUsername = res1.data[0].instaUsername
						this.instaUsernameOwnFetch(instaUsername)
						if(instaUsername){
							InstagramPost(instaUsername)
								.then(res => {
									let result = res.data.graphql.user;
									if(result){
										this.notFoundMyFetch(false)
									}else{
										this.notFoundMyFetch(false)
									}
								}).catch(error=>[this.notFoundMyFetch(false)]) 
						}else{
							this.notFoundMyFetch(true)
						}
					}
				}).catch(error=>console.log(error)) 
		}
	}
	
	reportSubmit = (event) => {
		event.preventDefault()

		if(this.report){
			let influencerId = event.target.influencerId.value
			this.loaderFetch(true)
			let paramReport = {
				reportedownerId: influencerId,
				campaignId: 0,
				reportText: this.report,
				ownerId: this.userId,
				reportPicUrl: this.files ? this.files : 'NA'
			}

			reportResult(paramReport)
				.then(res1 => {
					this.reportDoneMsgFetch(true)
					this.loaderFetch(false)
					this.reportFetch('')
					this.filesFetch('')
					this.enterReportFetch('')
				}).catch(error=>console.log(error))
		}else{
			this.enterReportFetch('Comment is required!')
		}
	}

	getFiles = (files) => {
		let size = files.size;
		let sizeSplit = size.split(" ");
		let image1 = ["image/jpg", "image/jpeg", "image/png"];
		this.imageLoaderFetch(true)
		if(image1.find(element => element === files.type)){ 
			if(sizeSplit[0] < 10 && sizeSplit[1] === 'kB'){
				
				let profileUpdate = {
					base64ImageData: files.base64,
				}
				profileImageResult(profileUpdate)
					.then(res => {
						const {status,data} = res
						if(status===200 && !data.error ){
							this.filesFetch(res.data.path)
							this.imageLoaderFetch(false)
							this.errImageFetch(false);
						}
					}).catch(error=>console.log(error))
			}else{
				this.errImageFetch(true);
				this.imageLoaderFetch(false)
			}
		}else{
			this.errImageFetch(true);
			this.imageLoaderFetch(false)
		}
	}
	
	instaPostsFetch = (data) => {
		this.instaPosts = data;
	}
	instaPostCountFetch = (data) => {
		this.instaPostCount = data;
	}
	instaFollowersCountFetch = (data) => {
		this.instaFollowersCount = data;
	}
	notFoundFetch = (data) => {
		this.notFound = data;
	}
	postPrivateFetch = (data) => {
		this.postPrivate = data;
	}
	usernameFetch = (data) => {
		this.username = data;
	}
	avatarUrlFetch = (data) => {
		this.avatarUrl = data;
	}
	fullNameFetch = (data) => {
		this.fullName = data;
	}
	preloaderFetch  = (data) =>{
		this.preloader = data;
	}
	campHideFetch  = (data) =>{
		this.campHide = data;
	}
	userCampFetch  = (data) =>{
		this.userCamp = data;
	}
	userCampCountFetch  = (data) =>{
		this.userCampCount = data;
	}
	notFoundMyFetch = (data) => {
		this.notFoundMy = data;
	}
	reportDoneMsgFetch 	= (data) =>{this.reportDoneMsg = data;}
	reportFetch 		= (data) =>{this.report = data;}
	errImageFetch 		= (data) =>{ this.errImage = data; }
	filesFetch 			= (data) =>{ this.files = data; }
	loaderFetch 		= (data) =>{this.loader = data;}
	imageLoaderFetch 	= (data) =>{this.imageLoader = data;}
	lastSeenFetch 	    = (data) =>{this.lastSeen = data;}
	enterReportFetch 	= (data) =>{this.enterReport = data;}
	instaUsernameFetch 	= (data) =>{this.instaUsername = data;}
	userIDFetch 	    = (data) =>{this.blockUserID = data;}
	instaUsernameOwnFetch 	= (data) =>{this.instaUsernameOwn = data;}
	blockSuccessFetch 	= (data) =>{this.blockSuccess = data;}
	blockPopFetch 		= (data) =>{this.blockPop = data;}
	blockLoaderFetch 	= (data) =>{this.blockLoader = data;}
}

decorate(InfluencerProfileStore, {
	auth: observable,
	userId: observable,
	instaPosts: observable,
	instaPostCount: observable,
	instaFollowersCount: observable,
	notFound: observable,
	notFoundMy: observable,
	username: observable,
	blockUserID: observable,
	avatarUrl: observable,
	preloader: observable,
	campHide: observable,
	fullName: observable,
	userCamp: observable,
	postPrivate: observable,
	userCampCount: observable,
	reportDoneMsg: observable,
	report:observable,
	errImage: observable,
	files: observable,
	loader: observable,
	imageLoader: observable,
	lastSeen: observable,
	enterReport: observable,
	instaUsername: observable,
	instaUsernameOwn: observable,
	blockSuccess: observable,
	blockPop: observable,
	blockLoader: observable,
	instaPostsFetch: action,
	instaPostCountFetch: action,
	instaFollowersCountFetch: action,
	notFoundFetch: action,
	usernameFetch: action,
	avatarUrlFetch: action,
	fullNameFetch: action,
	preloaderFetch: action,
	campHideFetch: action,
	userCampFetch: action,
	userCampCountFetch: action,
	postPrivateFetch: action,
	notFoundMyFetch: action,
	profileOwnFetchData: action,
	reportDoneMsgFetch: action,
	reportFetch:action,
	instaUsernameOwnFetch:action,
	getFiles: action,errImageFetch: action,filesFetch: action,loaderFetch:action,imageLoaderFetch:action,lastSeenFetch:action,enterReportFetch:action,instaUsernameFetch:action, userIDFetch:action, BlockUser:action,blockSuccessFetch:action,blockPopFetch:action,blockLoaderFetch:action
});

export default InfluencerProfileStore;