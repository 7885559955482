import React from 'react';
import {observer, inject} from 'mobx-react';
import EllipsisText from "react-ellipsis-text";
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import moment from 'moment';
import MultiSelect from "react-multi-select-component";
import NumberFormat from 'react-number-format';
import queryString from 'query-string'

class Products extends React.Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
		this.changePriceSort = this.changePriceSort.bind(this)
		this.changePrice = this.changePrice.bind(this)
		this.changeSearch = this.changeSearch.bind(this)
	}
	
	componentDidMount() {
		this.props.ProductListStore.offsetFetch(0);
		this.props.ProductListStore.productListFetch('Product List');
		this.props.ProductListStore.fetchCategories('Category');
		this.props.ProductListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	
	handlePageClick = data => {
		let selected = data.selected;
		let limits = this.props.ProductListStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.ProductListStore.offsetFetch(offset); 
		this.props.ProductListStore.selectedFetch(selected); 
		this.props.ProductListStore.productListFetch('Product List');
		this.props.ProductListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	handlePageClickFilter = data => {
		let selected = data.selected;
		let limits = this.props.ProductListStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.ProductListStore.offsetFetch(offset); 
		this.props.ProductListStore.handleSubmit();
		this.props.ProductListStore.checkSearchFetch(true);
		this.props.ProductListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	changePriceSort = (e) => {
		this.props.ProductListStore.priceSortFetch(e.target.value);
		this.props.ProductListStore.offsetFetch(0);
		this.props.ProductListStore.pageCountFilterFetch('');
		this.props.ProductListStore.totalCountFilterFetch('');
		this.props.ProductListStore.handleSubmit();
		this.props.ProductListStore.preloaderFetch(true);
	}
	changePrice = (value) => {
		this.props.ProductListStore.valuePriceFetch(value);
		this.props.ProductListStore.offsetFetch(0);
		this.props.ProductListStore.pageCountFilterFetch('');
		this.props.ProductListStore.totalCountFilterFetch('');
		this.props.ProductListStore.handleSubmit();
		this.props.ProductListStore.preloaderFetch(true);
	}

	clearAllFilter = () => {
		this.props.ProductListStore.categoryFetch([]);
		this.props.ProductListStore.checkedItemsFetch({});
		this.props.ProductListStore.offsetFetch(0);
		this.props.ProductListStore.priceSortFetch('');
		this.props.ProductListStore.pageCountFilterFetch('');
		this.props.ProductListStore.totalCountFilterFetch('');
		this.props.ProductListStore.valuePriceFetch({min:0, max:10000});
		this.props.ProductListStore.handleSubmit();
		this.props.ProductListStore.preloaderFetch(true);
	}
	
	changeSearch = (e) => {
		this.props.ProductListStore.searchFetch(e.target.value);
		if(this.props.ProductListStore.search){
		}else{
			this.props.ProductListStore.offsetFetch(0);
			this.props.ProductListStore.handleSubmit();
		}
	}
	
	searchSubmit = (event) => {
		event.preventDefault();
		
		this.props.ProductListStore.pageCountFilterFetch('');
		this.props.ProductListStore.totalCountFilterFetch('');
		if(this.props.ProductListStore.search){
			this.props.ProductListStore.searchKeywordFetch(true);
			this.props.ProductListStore.requiredFetch(false);
			this.props.ProductListStore.offsetFetch(0);
			this.props.ProductListStore.handleSubmit();
		}else{
			this.props.ProductListStore.requiredFetch(true);
			this.props.ProductListStore.searchKeywordFetch(false);
			this.props.ProductListStore.offsetFetch(0);
		}
		this.props.history.push(`${process.env.PUBLIC_URL}/products`)
		
	}
	
	render(){
		const store = this.props.ProductListStore;
		const todayDate = moment().format('YYYY-MM-DD');
		const skelrow = [1,2,3,4,5,6];
		const catrow = ['business','food','fashion','gaming','Skin care','cosmetic','fitness','travel','comedy'];
		return (
			<>
				<div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
				<section className="products-page " id="products_list">
					<div className="container w1280  pl-0 pr-0">
					<h2 className="page-heading">Store</h2>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="#">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">Product</li>
						</ol>
					</nav>
					</div>
					<div className="container w1280 pl-0 pr-0">
						<div className="row m-0">
							<div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 camp-left">
								<div className="campaign-search-wpr" id="campSticky">
										<div className="search-clear-wpr">
											<span className="seacrh-by">Filter</span>
											<span className="clear-all" id="clearAllFilter" onClick={this.clearAllFilter}>Clear</span>
										</div>
										<div className="searched-cat">
										{store.category.length !==0? store.category.map((item,index) =>
										<span key={index}>{item && item.charAt(0).toUpperCase() + item.slice(1)} <i className="fa fa-times-circle removeFilter" onClick={()=>store.categoryUnset(item)}></i></span>								
										):null}
										</div>
										
										<div className="form-group custom-group">
											<label htmlFor="countries" className="leftpanel-title">Category</label>
											<div className="tc-checkbox campaigns-checkbox">
	
											{store.categoryFilter.length !==0? store.categoryFilter.map((inf,index) =>
												<div className="form-check" key={index}>
													<input type="checkbox" className="form-check-input" onChange={store.changeCategory} id={`checkbox${index}`}  
													checked={store.checkedItems[inf.categoryName]}  name={inf.categoryName} />
													<label className="form-check-label">
														<span className="checkbox-text">{inf.categoryName && inf.categoryName.charAt(0).toUpperCase() + inf.categoryName.slice(1)}</span>
													</label>
												</div>
											) : 
											
												<>
												{catrow.map((catrow,index) => (
												<div className="form-check" key={index}>
													<input type="checkbox" className="form-check-input" onChange={store.changeCategory} checked={store.checkedItems[catrow]} name={catrow} />
													<label className="form-check-label">
														<span className="checkbox-text">{catrow && catrow.charAt(0).toUpperCase() + catrow.slice(1)}</span>
													</label>
												</div>
												))}
												</>
											
											}
											</div>
										</div>
										
										<div className="left-section-group">
										<h4 className="form-heading">Price</h4>
										<div className="price-slider">
											<InputRange
												maxValue={10000}
												minValue={0}
												step={1}
												value={store.valuePrice}
												onChange={this.changePrice} />
										</div>
										</div>
								</div>
							</div>
							{store.preloader ?
								<div className="product-list col-12 col-lg-9 col-xl-9 col-md-9 col-sm-12 ">
									<div className="product-header">
										<div className="campaign-header-left">
											<div className="search-inf-wpr">
												<form onSubmit={this.searchSubmit} className="search-inf">
													<span className="fa fa-search search-icon"></span>
													<input type="text" onChange={this.changeSearch} defaultValue={store.search} className="form-control" placeholder="Search product"/>
													<button type="submit" className="btn btn-primary">Search</button>
												</form>
											</div>
										{store.searchKeyword && store.search !== '' && <p className="searchkeylabel-text">Search Keyword <span>"{store.search}"</span></p>}
										</div>
										<div className="campaign-header-right">
											<div className="shortingselect-custom">
												<div className="form-group">
												<span className="fas down-arrow fa-chevron-down"></span>
												<label htmlFor="sort By">Sort By :</label>
												<select className="form-control" id="sortBy" name="sortBy" onChange={this.changePriceSort}>
													<option value="">Select Sort By</option>
													<option selected={store.priceSort === '1'} value="1">Price Low to High</option>
													<option selected={store.priceSort === '2'} value="2">Price  High to Low</option>
												</select>
												</div>
											</div>
										</div>
									</div>
									{skelrow.map((skelrow,index) => ( 
										<div className="product-item" key={index}>
											<div className="product-image">
											<Skeleton width={302} height={200} />
											</div>
											<div className="product-details">
												<h2><Skeleton /></h2>
												<div className="campaign-details-mask">
													<p><Skeleton count={4} /></p>
												</div>
											</div>
										</div>
									))}
								</div> : 	
								
								<div className="product-list col col-md-9 col-lg-9 pr-0 pl-md-0">
									<div className="product-header">
										<div className="campaign-header-left">
											<div className="search-inf-wpr">
												<form onSubmit={this.searchSubmit} className="search-inf">
													<span className="fa fa-search search-icon"></span>
													<input type="text" onChange={this.changeSearch} defaultValue={store.search} className="form-control" placeholder="Search product"/>
													<button type="submit" className="btn btn-primary">Search</button>
												</form>
											</div>
											{store.required && <p className="text-danger">Field is required</p>}
										{store.searchKeyword && store.search !== '' && <p className="searchkeylabel-text">Search Keyword <span>"{store.search}"</span></p>}
										</div>
										<div className="campaign-header-right">
											<div className="shortingselect-custom">
												<div className="form-group">
												<span className="fas down-arrow fa-chevron-down"></span>
												<label htmlFor="sort By">Sort By :</label>
												<select className="form-control" id="sortBy" name="sortBy" onChange={this.changePriceSort}>
													<option value="">Select Sort By</option>
													<option selected={store.priceSort === '1'} value="1">Price Low to High</option>
													<option selected={store.priceSort === '2'} value="2">Price  High to Low</option>
												</select>
												</div>
											</div>
										</div>
									</div>
									{store.checkSearch ? <>
										{store.productListFilter.length !== 0 ? store.productListFilter.map((inf,index) =>
											<div className="product-item" key={index}>
												<div className="product-image">
												 <img src={inf.productImage} alt={inf.productTitle} />
												</div>
												<div className="product-details">
													<h2><Link to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>{inf.productTitle}</Link></h2>
													<div className="campaign-details-mask">
														{inf.productDescription && <p><EllipsisText text={inf.productDescription} length={"120"} /></p>}
														<div className="productpriceoffer">
															<div className="productactprice">
																{inf.productDiscount > 0 ? 
																	<> {inf.productAmountCurrency} <NumberFormat value={(inf.productAmount-(inf.productAmount*inf.productDiscount/100))} displayType={'text'} thousandSeparator={true} /> </>
																: 
																	<> {inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /> </>
																} 
															</div>
															{inf.productDiscount > 0 && 
																<div className="productprevprice">{inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /></div>
															}
															{inf.productDiscount > 0 && <div className="productdiscount">{inf.productDiscount}% Off</div>}
														</div>
														{inf.stockCount > 0 ?
															<Link className="campaign-detail-btn" to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Buy Now</Link>
														:
															<Link className="campaign-detail-btn outofstock" to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Out Of Stock</Link>
														}
													</div>
												</div>
											</div>
										): 
										<div className="col-lg-12">
											 <div className="no-result-wpr">
												<div className="no-result-found">
													<div className="no-result-img">
														<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
													</div>
													<p className="no-result">Sorry, no result found</p>
												</div>
											</div>
										</div>
										}
									</>:<>
									
										{store.product.length !== 0 ? store.product.map((inf,index) =>
											<div className="product-item" key={index}>
												<div className="product-image">
												<Link to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}><img src={inf.productImage} alt={inf.productTitle} /></Link>
												</div>
												<div className="product-details">
													<h2><Link to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>{inf.productTitle}</Link></h2>
													<div className="campaign-details-mask">
														{inf.productDescription && <p><EllipsisText text={inf.productDescription} length={"120"} /></p>}
														<div className="productpriceoffer">
															<div className="productactprice">
																{inf.productDiscount > 0 ? 
																	<> {inf.productAmountCurrency} <NumberFormat value={(inf.productAmount-(inf.productAmount*inf.productDiscount/100))} displayType={'text'} thousandSeparator={true} /> </>
																: 
																	<> {inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /> </>
																} 
															</div>
															{inf.productDiscount > 0 && 
																<div className="productprevprice">{inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /></div>
															}
															{inf.productDiscount > 0 && <div className="productdiscount">{inf.productDiscount}% Off</div>}
														</div>
														{inf.stockCount > 0 ?
															<Link className="campaign-detail-btn" to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Buy Now</Link>
														:
															<Link className="campaign-detail-btn outofstock" to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Out Of Stock</Link>
														}
													</div>
												</div>
											</div>
										):
										<div className="col-lg-12">
											 <div className="no-result-wpr">
												<div className="no-result-found">
													<div className="no-result-img">
														<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
													</div>
													<p className="no-result">Sorry, no result found</p>
												</div>
											</div>
										</div>
										}
									
									</>}
								</div>
							}
						</div>
					</div>
				</section>
				
				{store.checkSearch ?
				<section className="container w1280">
					{/* <div className="row"> */}
						<div className="col-lg-9 col-12 offset-3 pr-1">
							<div className="pg-wpr row">
							{store.totalCountFilter > store.limit ?
							<>
							<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
								Showing 1 to {store.limit} of {store.totalCountFilter} entries
							</div>
							<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
								<ReactPaginate
									previousLabel={'Previous'}
									nextLabel={'Next'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={store.pageCountFilter}
									marginPagesDisplayed={2}
									pageRangeDisplayed={1}
									onPageChange={this.handlePageClickFilter}
									containerClassName={'custom-pagination'}
									subContainerClassName={''}
									activeClassName={'active'}
								/> 
							</div>
							</>
							: null }
							</div>
						</div>
					{/* </div> */}
				</section> :
				<section className="container w1280">
					{/* <div className="row"> */}
						<div className="col-lg-9 col-12 offset-lg-3 pr-1">
							<div className="pg-wpr row">
							{store.totalCount > store.limit ?
								<>
								<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
									Showing 1 to {store.limit} of {store.totalCount} entries
								</div>
								<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
									<ReactPaginate
										previousLabel={'Previous'}
										nextLabel={'Next'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={store.pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={1}
										onPageChange={this.handlePageClick}
										containerClassName={'custom-pagination'}
										subContainerClassName={''}
										activeClassName={'active'}
									/>
								</div>
								</>
								:null}
							</div>
						</div>
					{/* </div> */}
				</section>}
				
			</>
		)
	}
}
export default inject('ProductListStore')(observer(Products));