import {action, observable, decorate} from 'mobx';
import { newsResult } from '../api/NewsApi';

class NewsStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	news = [];
	
	newsGet = () =>{
		let param = {where:{status:1},order:"news_date DESC"}
		newsResult(JSON.stringify(param))
			.then(res => this.setNews(res.data))
			.catch(error => error)
	}
	
	setNews = (data) => {
		this.news = data;
	}
	
}

decorate(NewsStore, {
	news: observable,
	setNews: action
});

export default NewsStore;