import React from 'react';
import ReactDOM from 'react-dom';
import {autorun} from 'mobx';
import {Provider} from 'mobx-react';
import './index.scss';
import App from './App';
import store from './stores';
import * as serviceWorker from './serviceWorker';

function render() {
	ReactDOM.render(
	  <React.StrictMode>
		<Provider {...store} >
			<App />
		</Provider>
	  </React.StrictMode>,
	  document.getElementById('root')
	);
}

autorun(render);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); 
