import React, {Component} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {observer, inject} from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import { Redirect, Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import { bankResult } from '../../api/UserBankApi';
import { bankAccountRegisterResult } from '../../api/bankAccountRegisterApi';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
 
class CheckoutForm extends React.Component {
	
	constructor(props){
		super(props);
		this.state={
			errMessage:'',
			modalHide:false,
			bank:[],
			bankloader:false,
			saving:true,
			date:'',
			idnumber:'',
			country:'SG',
			states:'',
			city:'',
			postalcode:'',
			addressone:'',
			addresstwo:'',
			day:'',
			month:'',
			year:'',
			currency:'',
			bankinfo:'tab-pane fade',
			personal:'tab-pane fade show active',
			bankinfotab:'nav-link',
			personaltab:'nav-link active',
			done:false
		}
	}

 changeDate = (data) =>{
	this.setState({date:data})
	let check = moment(data, 'YYYY/MM/DD');
	let month = check.format('M');
	let day   = check.format('D');
	let year  = check.format('YYYY');

	this.setState({
		day    : day,
		month  : month,
		year   : year
	})

 } 
 changeCountry = (e) =>{
	this.setState({country:e.target.value})
 }

 handlePersonalSubmit = (event) => {
	event.preventDefault();
	this.setState({
		idnumber : event.target.idnumber.value,
		country  : event.target.country.value,
		states   : event.target.states.value,
		city     : event.target.city.value,
		postalcode:event.target.postalcode.value,
		addressone: event.target.addressone.value,
		addresstwo: event.target.addresstwo.value
	})

	if(event.target.idnumber.value && event.target.country.value === 'SG' && event.target.states.value && event.target.city.value && event.target.postalcode.value && event.target.addressone.value && event.target.addresstwo.value && event.target.dob.value){
		this.setState({
			bankinfo:"tab-pane fade show active",
			personal:'tab-pane fade',
			bankinfotab:'nav-link active',
			personaltab:'nav-link'
		})
	}

 }

 tabChange = (data) => {
	if(data === "personal"){
		this.setState({
			bankinfo:"tab-pane fade",
			personal:'tab-pane fade show active',
			bankinfotab:'nav-link',
			personaltab:'nav-link active'
		})
	}else{
		if(this.state.idnumber && this.state.country === 'SG' && this.state.states && this.state.city && this.state.postalcode && this.state.addressone && this.state.addresstwo && this.state.date){
			this.setState({
				bankinfo:"tab-pane fade show active",
				personal:'tab-pane fade',
				bankinfotab:'nav-link active',
				personaltab:'nav-link'
			})
		}
	}
 }
	
  handleSubmit = async (event) => {
    event.preventDefault();
	const {stripe, elements,ownerId} = this.props;
   if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
	}
	
	let countryShortCode 	= this.state.country
	let city 				= this.state.city || 'Singapore'
	let country 			= this.state.country || 'SG'
	let line1 				= this.state.addressone || 'Mandalay Road'
	let line2 				= this.state.addresstwo || 'Mandalay Road'
	let postal_code 		= this.state.postalcode || '308225'
	let state 				= this.state.states || 'Singapore'
	let id_number 			= this.state.idnumber || 562323562635
	let day 				= this.state.day || 12
	let month 				= this.state.month || 12
	let year 				= this.state.year || 1993
	let accountType 		= event.target.accountType.value || 'saving'
	let bankName 			= event.target.bankName.value || 'JJJ Bank'
	let accountNumber 		= event.target.accountNumber.value || '000123456'
	let accountHolderName 	= event.target.accountHolderName.value || 'Bhuvan Bam'
	let swiftCode 			= event.target.swiftCode.value || '004544545'
	let rountingNumber 		= event.target.rountingNumber.value || '1100-000'
	let bankAddress 		= event.target.bankAddress.value

	this.setState({bankloader:true})

	let data = {
		country: this.state.country || 'SG',
		currency: 'sgd',
		routing_number: rountingNumber,
		account_number: accountNumber,
		account_holder_name: accountHolderName,
		account_holder_type: 'individual',
	}
		
	const result = await stripe.createToken('bank_account',data)
	if(result.error){
		this.setState({errMessage:result.error.message});
		this.setState({bankloader:false})
	}else{
		const register = {
			ownerId:ownerId,
			bankTokenId:result.token.id,
			countryShortCode : countryShortCode,
			city : city,
		  	country : country,
			line1 : line1,
			line2 : line2,
			postal_code : postal_code,
			state : state,
			id_number : id_number,
		    day : day,
			month : month,
		    year : year,
		    bankName: bankName,
		    accountType: accountType,
		    accountNumber: accountNumber,
		    accountHolderName: accountHolderName,
		    swiftCode: swiftCode,
		    rountingNumber: rountingNumber,
		    bankAddress: bankAddress
		}

		bankAccountRegisterResult(register)
			.then(res => {
				if(res.data.error === false){
					let param = {where:{ownerId:ownerId}}
					bankResult(JSON.stringify(param))
						.then(res1 => {
							this.setState({modalHide:false,errMessage:'',bankloader:false,done:true}) 
					}).catch(error=>console.log(error))
				}else{
					this.setState({errMessage:res.data.message});
					this.setState({bankloader:false})
				}
				
		}).catch(error=>console.log(error))

	}
	


		/*bankAccountRegisterResult(register)
			.then(res => {
				if(res.data.error === false){
					
					if(result.error){
						this.setState({errMessage:result.error.message});
					}else{
										
						let profileUpdate = {stripeAccountNumber:res.data.data.id,stripeBankAccountId:result.token.bank_account.id}
						
						profileUpdateResult(ownerId,profileUpdate)
							.then(res1 => {
								if(res1.data.message.error){
									this.setState({errMessage:res1.data.message.error});
								}else{
									if(res1.data.message.profile.stripeAccountNumber){
										
										let param = {bankTokenId:result.token.id,ownerId:ownerId} 
										addBankResult(param)
											.then(res2 => {
												if(res.data.error === false){
													let bankUpdate;
													if(bankId){
														bankUpdate = {
															ownerId: ownerId,
															accountType: accountType,
															bankName: bankName,
															accountNumber: accountNumber,
															accountHolderName: accountHolderName,
															swiftCode: swiftCode,
															rountingNumber: rountingNumber,
															bankAddress: bankAddress,
															id:bankId
														}
													}else{
														bankUpdate = {
															ownerId: ownerId,
															accountType: accountType,
															bankName: bankName,
															accountNumber: accountNumber,
															accountHolderName: accountHolderName,
															swiftCode: swiftCode,
															rountingNumber: rountingNumber,
															bankAddress: bankAddress
														}
													}

													bankUpdateResult(bankUpdate)
														.then(res => {
															if(res.data){
																this.setState({bank:res.data})
																if(res.data.accountType === 'saving'){
																	this.setState({saving:true})
																	this.setState({current:false})
																}else{
																	this.setState({saving:false})
																	this.setState({current:true})
																}
																this.setState({modalHide:false})
																this.setState({bankloader:false})
															}else{
																this.setState({bank:''})
																this.setState({modalHide:false})
																this.setState({bankloader:false})
															}
														}).catch(error=>console.log(error))
													this.setState({errMessage:''});
												}
										}).catch(error=>console.log(error))
									}
								}
						}).catch(error=>console.log(error))
					}
				}else{
					this.setState({errMessage:res.data.message});
				}
				
		}).catch(error=>console.log(error))*/
		
		
		// Handle result.error or result.token


  };
  
  	bankFetchData = () =>{	
	this.setState({bankloader:true})
		let bankUser = {where:{ownerId:this.props.ownerId}}
		bankResult(JSON.stringify(bankUser))
		.then(res => {
			if(res.data.length > 0){
				this.setState({bank:res.data[0],bankloader:false,done:false})
			}
		}).catch(error=>console.log(error))
	}
  
  render() {
    const {stripe,bank,saving,current,country} = this.props;
    return (
	<>
	{this.state.bank.length !== 0 || bank.length !==0 ?  
		<div className="setting-bankinfo">
			<h2>Bank Information</h2>
			<div className="row">
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Bank Name</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">{this.state.bank.bankName ? this.state.bank.bankName : bank.bankName}</div>
				</div>
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Account Type</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">
						{this.state.bank.accountType ? this.state.bank.accountType.charAt(0).toUpperCase() + this.state.bank.accountType.slice(1) : bank.accountType && bank.accountType.charAt(0).toUpperCase() + bank.accountType.slice(1)}
					</div>
				</div>
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Account Holder Name</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">{this.state.bank.accountHolderName ? this.state.bank.accountHolderName : bank.accountHolderName}</div>
				</div>
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Account Number</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">{this.state.bank.accountNumber ? this.state.bank.accountNumber : bank.accountNumber}</div>
				</div>
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Swift Code</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">{this.state.bank.swiftCode ? this.state.bank.swiftCode : bank.swiftCode}</div>
				</div>
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Routing Number</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">{this.state.bank.rountingNumber ? this.state.bank.rountingNumber : bank.rountingNumber}</div>
				</div>
				<div className="col-12 col-md-2 col-lg-2">
						<div className="formtitle">Bank Address</div>
				</div>
				<div className="col-12 col-md-10 col-lg-10">
					<div className="formdescription">{this.state.bank.bankAddress ? this.state.bank.bankAddress : bank.bankAddress ? bank.bankAddress : '---'}</div>
				</div>
				{/* <div className="col-12 col-md-12 col-lg-12">
					<button className="editaccount-btn	" style={{cursor:'pointer'}} onClick={()=>this.setState({modalHide:true})}><img src={`${process.env.PUBLIC_URL}/images/edit-btn.svg`} alt="" />Edit</button>
					<button className="deleteaccount-btn" style={{cursor:'pointer'}}><img src={`${process.env.PUBLIC_URL}/images/delete-btn.svg`} alt="" />Delete</button>
				</div> */}
			</div>
		</div>
	:
		<div className="setting-bankinfo">
			<h2>Bank Information</h2>
			<p>Currently no bank details have been added by you.</p>
			<button className="add-bank-btn" onClick={()=>this.setState({modalHide:true})} style={{cursor:'pointer'}}>Add Bank</button>
		</div>
	}

	<div id="addBank">
		<Popup
			open={this.state.modalHide}
			closeOnDocumentClick
			onClose={() => this.setState({modalHide:false})}
		>
		<div className="addbankaccountmodal editBank" id="addbankaccountmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-body">
					<h5 className="modal-title text-center" id="exampleModalLongTitle">Add Bank</h5>
					{this.state.personaltab === 'nav-link active' && <p className="text-danger text-center">We are currently processing payment with only Singapore Bank Accounts. If you are based on Singapore then kindly proceed by adding Singapore bank account.</p>}
						<nav className="bankinfowrapper">
							<div className="nav nav-tabs" id="nav-tab" role="tablist">
								<a className={this.state.personaltab} id="nav-home-tab" data-toggle="tab" onClick={()=>this.tabChange('personal')} href="#" role="tab" aria-controls="nav-home" aria-selected="true">Personal Info</a>
								<Link className={this.state.bankinfotab} to="#" role="tab" aria-controls="nav-profile" aria-selected="false">Bank Info</Link>
							</div>
						</nav>
						<div className="tab-content" id="nav-tabContent">
							<div className={this.state.personal} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
								<form onSubmit={this.handlePersonalSubmit} method="post">
									<div className="row">
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<div className="form-group custom-form-group custom-date">
													<span className="fas cal-icon fa-calendar"></span>
													<label htmlFor="firstname">DOB<span className="text-danger">*</span></label>
													<DatePicker
														selected={this.state.date}
														dateFormat="dd/MM/yyyy" className="form-control"
														name="dob"
														peekNextMonth
														showMonthDropdown
														showYearDropdown
														onChange={this.changeDate}
														required
														autoComplete="off"
														placeholderText="DOB"
														maxDate={new Date()}
														disabled={this.state.country == 'SG' ? false : true}
													/>
													
												</div>
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="acccountnumver">ID Number<span className="text-danger">*</span></label>
													<input type="text" className="form-control" disabled={this.state.country == 'SG' ? false : true} defaultValue={this.state.idnumber} id="idnumber" name="idnumber" placeholder="ID Number" required />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="exampleFormControlSelect1">Country<span className="text-danger">*</span></label>
												<select name="country" className="form-control" required onChange={this.changeCountry} name="country" id="exampleFormControlSelect1">
													<option value="">Select country</option>
													{country.length !== 0 ? country.map((c,index) =>
													<option value={c.countryCode} selected={this.state.country === c.countryCode} key={index}>{c.name}</option>
													):null}
												</select>
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="acccountnumver">State<span className="text-danger">*</span></label>
												<input type="text" className="form-control" disabled={this.state.country == 'SG' ? false : true} defaultValue={this.state.states} id="state" name="states" placeholder="State" required />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="acccountnumver">City<span className="text-danger">*</span></label>
												<input type="text" className="form-control" disabled={this.state.country == 'SG' ? false : true} defaultValue={this.state.city} id="city" name="city" placeholder="City" required />
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="acccountnumver">Postal Code<span className="text-danger">*</span></label>
												<input type="text" className="form-control" disabled={this.state.country == 'SG' ? false : true} defaultValue={this.state.postalcode} id="postalcode" name="postalcode" placeholder="Postal Code" required />
											</div>
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="acccountnumver">Address Line 1<span className="text-danger">*</span></label>
										<input type="text" className="form-control" disabled={this.state.country == 'SG' ? false : true} defaultValue={this.state.addressone} id="addressone" name="addressone" placeholder="Address Line 1" required />
									</div>
									<div className="form-group">
										<label htmlFor="acccountnumver">Address Line 2<span className="text-danger">*</span></label>
										<input type="text" className="form-control" disabled={this.state.country == 'SG' ? false : true} defaultValue={this.state.addresstwo} id="addresstwo" name="addresstwo" placeholder="Address Line 2" required />
									</div>
							
									<div className="form-group">
										<button type="submit" style={{cursor:'pointer'}} id="infobutton" className="addbankmodal-btn">Continue to Bank Info</button>
									</div>
								</form>
							</div>
							<div className={this.state.bankinfo} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
								<form onSubmit={this.handleSubmit} method="post">
									<label>Account Type<span className="text-danger">*</span></label>
									<div className="custom-views">
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="radio" name="accountType" id="inlineRadio1" value="saving" defaultChecked={this.state.saving == true ? this.state.saving : saving} />
											<label className="form-check-label" htmlFor="inlineRadio1">Saving</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="radio" name="accountType" id="inlineRadio2" value="current" defaultChecked={this.state.current == true ? this.state.current : current} />
											<label className="form-check-label" htmlFor="inlineRadio2">Current</label>
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="exampleFormControlSelect1">Bank Name<span className="text-danger">*</span></label>
										<input type="text" name="bankName" defaultValue={this.state.bank.bankName ? this.state.bank.bankName : bank.bankName} placeholder="Bank Name" className="form-control" required />
									</div>
									<div className="form-group">
										<label htmlFor="acccountnumver">Account Number<span className="text-danger">*</span></label>
										<input type="text" className="form-control" id="acccountnumver" defaultValue={this.state.bank.accountNumber ? this.state.bank.accountNumber : bank.accountNumber} name="accountNumber" placeholder="Account Number" required />
									</div>
									<div className="form-group">
										<label htmlFor="accountholdername">Account Holder Name<span className="text-danger">*</span></label>
										<input type="text" className="form-control" id="accountholdername" defaultValue={this.state.bank.accountHolderName ? this.state.bank.accountHolderName : bank.accountHolderName} name="accountHolderName" placeholder="Account Holder Name" required />
									</div>
									<div className="form-group">
										<label htmlFor="swiftcode">Swift Code<span className="text-danger">*</span></label>
										<input type="text" className="form-control" name="swiftCode" defaultValue={this.state.bank.swiftCode ? this.state.bank.swiftCode : bank.swiftCode} id="swiftcode" placeholder="Swift Code" required />
									</div>
									<div className="form-group">
										<label htmlFor="routingnumber">Routing Number<span className="text-danger">*</span></label>
										<input type="text" className="form-control" required id="routingnumber" defaultValue={this.state.bank.rountingNumber ? this.state.bank.rountingNumber : bank.rountingNumber} name="rountingNumber" placeholder="Routing Number"  />
									</div>
									<div className="form-group">
										<label htmlFor="bankaddress">Bank Address</label>
										<input type="text" className="form-control" id="bankaddress" defaultValue={this.state.bank.bankAddress ? this.state.bank.bankAddress : bank.bankAddress} name="bankAddress" placeholder="Bank Address" />
									</div>
									{this.state.errMessage && <p className="text-danger">{this.state.errMessage}</p>}
									<div className="form-group">
										<button type="submit" style={{cursor:'pointer'}} className="addbankmodal-btn">Submit {this.state.bankloader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
									</div>
								</form>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</Popup>
		<Popup
		  open={this.state.done}
		  closeOnDocumentClick
		  className="custom-popup"
		  onClose={() => this.setState({done:false})}
		>
			<div className="custom-modal update-successfully">
				<div className="modal-dialog">
				  <div className="modal-content">
					<div className="modal-body text-center">
						<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/icons/success.png`} alt="" /> 
						<h2 className="modal-heading">Congratulations!</h2>
						<p>Your bank account has been saved successfully.</p>
							<button className="btn btn-primary" onClick={() => this.bankFetchData()} >Ok {this.state.bankloader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
					</div>
				  </div>
				</div>
			</div>
		</Popup>
	</div>
	</>
    );
  }
}

class InjectedCheckoutForm extends Component {
	constructor(props){
		super(props);
	}
  render() {
    return (
		<ElementsConsumer>
			{({stripe, elements, ownerId}) => (
			  <CheckoutForm stripe={stripe} elements={elements} country={this.props.country} bank={this.props.bank} bankloader={this.props.bankloader} saving={this.props.saving} ownerId={this.props.ownerId} current={this.props.current} />
			)}
		</ElementsConsumer>
	);
  }
}
 
const stripePromise = loadStripe('pk_test_kYxI1uwoMn3Nesv2MeSIu3n200nwgmf3wA');

class BankAccount extends Component {
	constructor(props){
		super(props);

	}
	
	componentDidMount = () =>{
		if(this.props.EditprofileStore.auth){
			this.props.EditprofileStore.bankFetchData('Bank Detail');
			this.props.EditprofileStore.fetchCountries('Country');
		}
	}
	
  render() {
	const store = this.props.EditprofileStore;
    return (
	<>
	{store.userId ? 
		<Elements stripe={stripePromise}>
			<InjectedCheckoutForm bank={store.bank} country={store.country} bankloader={store.bankloader} saving={store.saving} current={store.current} ownerId={store.userId} />
		</Elements>
	: <Redirect to="/" />} </>
	);
  }
}
export default inject('EditprofileStore')(observer(BankAccount));