import {action, observable, decorate} from 'mobx';
import { campaignStore } from '../api/CampaignCreateApi';
import { campaignDraftStore } from '../api/CampaignDraftApi';
import { campaignsimageApiResult } from '../api/CampaignsimageApi';
import { countriesResult } from '../api/CountryApi';
import { notificationCountResult } from '../api/NotificationCountApi';
import { isReadResult } from '../api/NotificationIsReadApi';
import { notificationListResult } from '../api/NotificationListApi';
import { categoryResult } from '../api/CampaignCategoryApi';
import { campaignDetailResult } from '../api/CampaignDetailApi';
import { campaignDraftDetailResult } from '../api/CampaignDraftDetailApi';
import { campaignUpdateResult } from '../api/CampaignUpdateApi';
import { campaignDraftUpdateResult } from '../api/CampaignDraftUpdateApi';
import { CampaignDraftDeleteResult } from '../api/CampaignDraftDeleteApi';
import Moment from 'moment';

class CreatecampaignListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	userId = JSON.parse(sessionStorage.getItem("userId")); 
	preloader = false;
	open= false;
	forgotopen= '';
	discardOpen = false ;	
	draftOpen = false ;	
	response={};
	countries=[];
	categories=[];
	savnextbtn=true;
	campaignsTabclass='nav-link show active';
	campaignsDraftPopup='container-fluid campaigns-drafts d-none';
	//hashtagTabclass='nav-link';
	campaignsInfoclass='row tab-pane active show';
	//addHashtagclass='row tab-pane';
	createCampaignsPopup='container-fluid create-campaigns d-none';
	createCampaigns='add-campaingns d-none';
	CampaignsPreview='camp-preview d-none';
	CampAddedSuccessfull='camp-added-success d-none';
	draftCampaigns='draft-camp d-none';
	errors={
            campaignsTitle:'',
            campaignAmount:'',
			campaignFollowers:'',
            endStoryPostDate:'',
            lookingInfluencerGender:'',
            country:'',
			campaignType:'',
            age:'',
            campaignDetails:'',
			image:[]
        };
		
	formvalue={campaignsTitle:'',
				 campaignDetails:'',
				 lookingInfluencerGender:'any',
				 country:[],
				 endStoryPostDate:'',
				 campaignType:'paid',
				 age:'',
				 campaignAmount:'',
				 campaignFollowers:'',
				 taghtml:'',
				 cfiles:[],
				 tags: [],
				 category:[],
			   };
	notifyCount = '';
	notifyList = [];
	listNotFound = false;
	campaignDetail = '';
	campaignId = '';
	isloader = false;
	galleryStore = [];
	draftDetail = [];
	draftId = '';
	imageLoader=[];
	search = '';
	countryCount = '';
	oneClick = false;
		
	 openModal=(data)=> {
        this.open=true;
     }	 
	 
    closeModal=(data)=>  {
        this.open= false;
		
    }
	
	 forgotopenModal=(data)=> {
        this.forgotopen=true;
     }	 
	 
    forgotcloseModal=(data)=>  {
		
        this.forgotopen=false;
		console.log(this.forgotopen);
				
	}
	
	openDraftModal=(data)=>  {
		this.draftOpen= true;
		this.discardOpen=false;
    }
    closeDraftModal=(data)=>  {
        this.draftOpen= false;
		this.closeCreateCampPopup();
    }

    openDiscardModal=(data)=>  {
        this.discardOpen= true;
    }
    closeDiscardModal=(data)=>  {
        this.discardOpen= false;
    }
	
	onTagsChanged=(tags)=> {
        this.formvalue.tags=tags;  
		console.log(tags)
    }
	
	onCategoryChanged=(category)=> {
        this.formvalue.category=category;  		
    }
	
	onCountryChanged=(country)=> {
        this.formvalue.country=country;  
		this.errors.country=''; 
    }
	
	 handleChange=(event)=>{      
        const name=event.target.name;
        this.formvalue[event.target.name]=event.target.value;
       
        let errors=this.errors;
        errors[name]='';
        this.errors=errors;
      } 
	
	onAgeChange = (event) =>{
		this.formvalue.age=event.target.value;       
        this.errors.age='';  
	}
	   
    handleChangeDate=(data)=>{ 
        let date=new Date(data);
        this.formvalue.endStoryPostDate=date;       
        this.errors.endStoryPostDate='';  		
      }

      handleChangePrice=(event)=>
      {  
        if (isNaN(Number(event.target.value))) {			
            return false;
        }else
        {   
         this.formvalue.campaignAmount=event.target.value;        
         this.errors.campaignAmount='';        
        }
      }
	  
	  handleChangeFollowers=(event)=>
      {  
        if (isNaN(Number(event.target.value))) {			
            return false;
        }else
        {   
         this.formvalue.campaignFollowers=event.target.value;        
         this.errors.campaignFollowers='';        
        }
      }
  

    openCreateCampPopup = () =>{
		this.campaignsTabclass='nav-link show active';
		//this.hashtagTabclass='nav-link';
		this.campaignsInfoclass='row tab-pane active show';
	    //this.addHashtagclass='row tab-pane';
        this.savnextbtn=true;
		
		this.createCampaignsPopup='container-fluid create-campaigns';
	    this.createCampaigns='add-campaingns';
	    this.CampaignsPreview='camp-preview d-none';
	    this.CampAddedSuccessfull='camp-added-success d-none';
	    this.draftCampaigns='draft-camp d-none';		
	}
	
	openCreateCampPopupDraft = () =>{
		this.campaignsTabclass='nav-link show active';
		//this.hashtagTabclass='nav-link';
		this.campaignsInfoclass='row tab-pane active show';
	    //this.addHashtagclass='row tab-pane';
        this.savnextbtn=true;
		
		this.createCampaignsPopup='container-fluid create-campaigns';
	    this.createCampaigns='add-campaingns';
	    this.CampaignsPreview='camp-preview d-none';
	    this.CampAddedSuccessfull='camp-added-success d-none';
	    this.draftCampaigns='draft-camp d-none';
		this.DraftCampaignDetail()
		this.closeDraftCampPopup()
		this.isloaderFetch(true)
	}
	
	openCreateCampPopupNew = (data) =>{
		
		if(data === 'draftnew'){
			let param = {where:{ownerId:this.userId}}
			campaignDraftDetailResult(JSON.stringify(param))
				.then(res => {
					if(res.data.length !== 0){
						let draftId = res.data[0].id
						CampaignDraftDeleteResult(draftId)
							.then(res => {
							this.DraftCampaignDetail()			
						}).catch(error=>console.log(error))
					}			
				}).catch(error=>console.log(error))
		}
		
		this.campaignsTabclass='nav-link show active';
		//this.hashtagTabclass='nav-link';
		this.campaignsInfoclass='row tab-pane active show';
	    //this.addHashtagclass='row tab-pane';
        this.savnextbtn=true;
		
		this.createCampaignsPopup='container-fluid create-campaigns';
	    this.createCampaigns='add-campaingns';
	    this.CampaignsPreview='camp-preview d-none';
	    this.CampAddedSuccessfull='camp-added-success d-none';
	    this.draftCampaigns='draft-camp d-none';

		this.formvalue={campaignsTitle:'',
			 campaignDetails:'',
			 lookingInfluencerGender:'any',
			 country:[],
			 endStoryPostDate:'',
			 campaignType:'paid',
			 campaignAmount:'',
			 campaignFollowers:'',
			 taghtml:'',
			 cfiles:[],
			 tags: [],
			 category:[],
		};	
		this.campaignIdFetch('')
		this.draftIdFetch('')
		
	}

	openDraftCampPopup = () =>{
		this.campaignsDraftPopup='container-fluid campaigns-drafts';
	}

    showCampTab = () =>{
		this.campaignsTabclass='nav-link show active';
		//this.hashtagTabclass='nav-link';
		this.campaignsInfoclass='row tab-pane active show';
	    //this.addHashtagclass='row tab-pane';
        this.savnextbtn=true;
    }

    showHashTab = () =>{
       
            this.errors.campaignAmount=(this.formvalue.campaignAmount==='' && (this.formvalue.campaignType==='paid' || this.formvalue.campaignType==='sponsored')) ?'Please enter price':'';
            this.errors.endStoryPostDate=this.formvalue.endStoryPostDate===''?'Please select date':'';
			this.errors.country=this.formvalue.country.length===0?'Please select country':'';
            this.errors.age=this.formvalue.age===''?'Please select age':'';
            this.errors.campaignsTitle=this.formvalue.campaignsTitle===''?'Please enter title':'';
            this.errors.campaignDetails=this.formvalue.campaignDetails===''?'Please enter detail':'';
			this.errors.image=this.formvalue.cfiles.length===0?'Please select image':'';
      
        if(this.errors.campaignAmount!=='' || this.errors.age!== '' || this.errors.campaignsTitle!==''||this.errors.campaignDetails!=='' ||this.errors.image!==''||this.errors.country!=='')
        {
			setTimeout(() =>{ this.resetMsg();  }, 4000);
            return false;
        }
        this.campaignsTabclass='nav-link';
		this.hashtagTabclass='nav-link show active';
		this.campaignsInfoclass='row tab-pane';
	    this.addHashtagclass='row tab-pane  active show';
        this.savnextbtn=false;
    }
	
	updateCampaign = (campId) =>{
		this.campaignIdFetch(campId)
		this.isloaderFetch(true)
		campaignDetailResult(campId)
			.then(res => {
			let result = res.data.campaigndetails[0];
			this.campaignDetailFetch(result);
			this.isloaderFetch(false)			
		}).catch(error=>console.log(error))
	}

    postCampaings = () =>{
        
		this.oneClickFetch(true)
		
		let userId = JSON.parse(sessionStorage.getItem("userId"));  
		let imgurl=this.formvalue.cfiles.length?this.formvalue.cfiles[0]:'NA'; 
		let stag=[];
		let scat=[];
		let scountry=[];
		let scountrycode=[];
	  
		this.formvalue.tags.map(function(item,index){
			stag[index]=item.displayValue;          
        });
		
		this.formvalue.category.map(function(item,index){
			scat[index]=item.value;          
        });
		
		if(this.formvalue.country.length == this.countryCount){
			scountry[0] = 'All'
			scountrycode[0] = 'All'
		}else{
			this.formvalue.country.map(function(item,index){
				scountry[index]=item.label;          
				scountrycode[index]=item.value;  			
			});
		}
		
		let age = this.formvalue.age.split("-")
		let minAge = age[0]
		let maxAge = age[1]
		let amount
		if(this.formvalue.campaignType == 'paid' || this.formvalue.campaignType == 'sponsored'){
			amount = this.formvalue.campaignAmount
		}else{
			amount = 0
		}
		
		let endStoryPostDate = this.formvalue.endStoryPostDate
		let enddate = Moment(endStoryPostDate).format('YYYY-MM-DD')
	  
	  if(this.campaignId){
		  
		let data = {
          ownerId: userId,
          campaignTitle: this.formvalue.campaignsTitle,
          campaignImage:imgurl,
          campaignGallery:this.formvalue.cfiles,
		  campaignCategories:scat,
          campaignDetails:this.formvalue.campaignDetails,
          campaignAmount:amount,
          campaignType:this.formvalue.campaignType,
          minimumFollowers:this.formvalue.campaignFollowers,
          campaignAmountCurrency:'USD',
          endStoryPostDate:enddate,
          lookingInfluencerGender:this.formvalue.lookingInfluencerGender,
          country:scountry,
          countryCode:scountrycode,
          hashtags:'',
		  minAge: minAge,		  
		  maxAge: maxAge		  
		}
		  
		   let campUpdate = data
			let param = {id:this.campaignId}

			campaignUpdateResult(JSON.stringify(param),campUpdate)
				.then(res => {
					
				this.campaddedSuccess();
				this.oneClickFetch(false)
				
			}).catch(error=>[console.log(error),this.oneClickFetch(false)])
			
	  }else{
		  
		let data = {
          ownerId: userId,
          campaignTitle: this.formvalue.campaignsTitle,
          campaignImage:imgurl,
          campaignGallery:this.formvalue.cfiles,
		  campaignCategories:scat,
          campaignDetails:this.formvalue.campaignDetails,
          campaignType:this.formvalue.campaignType,
          campaignAmount:amount,
          minimumFollowers:this.formvalue.campaignFollowers,
          campaignAmountCurrency:'USD',
          endStoryPostDate:enddate,
          lookingInfluencerGender:this.formvalue.lookingInfluencerGender,
          country:scountry,
          countryCode:scountrycode,
          shipping:'false',
          productUrl:'string',
          tags:'',
          hashtags:'',
          isFavourite:false,
          isFeatured:false,
          campaignStatus:1,
          isDisabled:0,
		  minAge: minAge,		  
		  maxAge: maxAge		  
		} 
		
		let param = {where:{ownerId:this.userId}}
		campaignDraftDetailResult(JSON.stringify(param))
			.then(res => {
				if(res.data.length !== 0){
					let draftId = res.data[0].id
					CampaignDraftDeleteResult(draftId)
						.then(res => {
						this.DraftCampaignDetail()			
					}).catch(error=>[console.log(error),this.oneClickFetch(false)])
				}			
			}).catch(error=>[console.log(error),this.oneClickFetch(false)])
		  
		campaignStore(data)
		  .then(res => { 
		  
			this.campaddedSuccess();
			this.oneClickFetch(false)

		}).catch(error=>[console.log(error),this.oneClickFetch(false)]) 
	  }
		 
      
    }
	
	draftCampaings = () =>{
           
		let  userId = JSON.parse(sessionStorage.getItem("userId"));  
		let imgurl=this.formvalue.cfiles.length?this.formvalue.cfiles[0]:'NA'; 
		let stag=[];
		let scat=[];
		let scountry=[];
		let scountrycode=[];
		
		this.formvalue.tags.map(function(item,index){
			stag[index]=item.displayValue;          
        });
		
		if(this.formvalue.country.length == this.countryCount){
			scountry[0] = 'All'
			scountrycode[0] = 'All'
		}else{
			this.formvalue.country.map(function(item,index){
				scountry[index]=item.label;          
				scountrycode[index]=item.value;  			
			});
		}
		
		this.formvalue.category.map(function(item,index){
			scat[index]=item.value;          
        });
		let age;
		let minAge;
		let maxAge;
		if(this.formvalue.age){
			age = this.formvalue.age.split("-")
			minAge = age[0]
			maxAge = age[1]
		}else{
			minAge = ''
			maxAge = ''
		}
		let amount
		if(this.formvalue.campaignType == 'paid' || this.formvalue.campaignType == 'sponsored'){
			amount = this.formvalue.campaignAmount
		}else{
			amount = 0
		}
		
		let endStoryPostDate = this.formvalue.endStoryPostDate
		let enddate
		if(endStoryPostDate){
			enddate = Moment(endStoryPostDate).format('YYYY-MM-DD')
		}else{
			enddate = '2099-12-31';
		}
		if(this.draftId){
 
			let data = {
			  ownerId: userId,
			  campaignTitle: this.formvalue.campaignsTitle ? this.formvalue.campaignsTitle : 'NA',
			  campaignImage:imgurl,
			  campaignGallery:this.formvalue.cfiles,
			  campaignCategories:scat,
			  campaignDetails:this.formvalue.campaignDetails ? this.formvalue.campaignDetails : 'NA',
			  campaignType:this.formvalue.campaignType,
			  campaignAmount:amount,
			  minimumFollowers:this.formvalue.campaignFollowers,
			  campaignAmountCurrency:'USD',
			  endStoryPostDate:enddate,
			  lookingInfluencerGender:this.formvalue.lookingInfluencerGender,
			  country:scountry,
			  countryCode:scountrycode,
			  shipping:'false',
			  productUrl:'string',
			  tags:'',
			  hashtags:'',
			  isFavourite:false,
			  isFeatured:false,
			  campaignStatus:1,
			  isDisabled:0,
			  minAge: minAge,		  
			  maxAge: maxAge		  
			} 
			  
			let campDraftUpdate = data
			let param = {id:this.draftId}

			campaignDraftUpdateResult(JSON.stringify(param),campDraftUpdate)
				.then(res => {
					this.DraftCampaignDetail()
					this.openDraftModal();
			}).catch(error=>console.log(error))  
			
		}else{
			let data = {
			  ownerId: userId,
			  campaignTitle: this.formvalue.campaignsTitle ? this.formvalue.campaignsTitle : 'NA',
			  campaignImage:imgurl,
			  campaignGallery:this.formvalue.cfiles,
			  campaignCategories:scat,
			  campaignDetails:this.formvalue.campaignDetails ? this.formvalue.campaignDetails : 'NA',
			  campaignType:this.formvalue.campaignType,
			  campaignAmount:amount,
			  endStoryPostDate:enddate,
			  minimumFollowers:this.formvalue.campaignFollowers,
			  campaignAmountCurrency:'USD',
			  lookingInfluencerGender:this.formvalue.lookingInfluencerGender,
			  country:scountry,
			  countryCode:scountrycode,
			  shipping:'false',
			  productUrl:'string',
			  tags:'',
			  hashtags:'',
			  isFavourite:false,
			  isFeatured:false,
			  campaignStatus:1,
			  isDisabled:0,
			  minAge: minAge,		  
			  maxAge: maxAge		  
			} 
			  
			campaignDraftStore(data)
			  .then(res => { 
				this.draftDetailFetch(res.data)
				if(res.data){
					this.campaignDetailFetch(res.data)
					this.draftIdFetch(res.data.id)
				}
				this.openDraftModal();
			}).catch(error=>{console.log(error)})
		}
      
    }
	
	DraftCampaignDetail = () =>{
		let param = {where:{ownerId:this.userId}}
		campaignDraftDetailResult(JSON.stringify(param))
			.then(res => {
				this.draftDetailFetch(res.data)
				if(res.data.length !== 0){
					this.campaignDetailFetch(res.data[0])
					this.draftIdFetch(res.data[0].id)
				}
				
				this.isloaderFetch(false)
		}).catch(error=>console.log(error))
	}
	
    showNextTab = (e) =>{
      const  errors={
            campaignAmount:(this.formvalue.campaignAmount==='' && (this.formvalue.campaignType==='paid' || this.formvalue.campaignType==='sponsored')) ?'Please enter price':'',
            endStoryPostDate:this.formvalue.endStoryPostDate===''?'Please select date':'',
			country:this.formvalue.country.length === 0?'Please select country':'',
            age:this.formvalue.age==''?'Please select age':'',
            campaignsTitle:this.formvalue.campaignsTitle===''?'Please enter title':'',
            campaignDetails:this.formvalue.campaignDetails===''?'Please enter detail':'',
			image:this.formvalue.cfiles.length===0?'Please select image':''
        }
        this.errors=errors;
        if(errors.campaignAmount!=='' || errors.age!=='' || errors.campaignsTitle!==''||errors.campaignDetails!==''||errors.image!==''||errors.endStoryPostDate!==''||errors.country!='')
        {
			setTimeout(() =>{ this.resetMsg();  }, 4000);
            return false;
        }
       
        this.campaignsTabclass='nav-link';
		//this.hashtagTabclass='nav-link show active';
		this.campaignsInfoclass='row tab-pane';
	    //this.addHashtagclass='row tab-pane  active show';
        this.savnextbtn=false;
		this.createCampaigns='add-campaingns d-none jgjhgj';
	    this.CampaignsPreview='camp-preview';
	    this.CampAddedSuccessfull='camp-added-success d-none';
	    this.draftCampaigns='draft-camp d-none';
    }
	
	showPreview = () =>{ 
       
	    this.createCampaigns='add-campaingns d-none jgjhgj';
	    this.CampaignsPreview='camp-preview';
	    this.CampAddedSuccessfull='camp-added-success d-none';
	    this.draftCampaigns='draft-camp d-none';
       	
    }

    closeCreateCampPopup = () =>{
		
		this.createCampaignsPopup='container-fluid create-campaigns d-none ss';        
		this.createCampaigns='add-campaingns d-none ss1';
        this.open=false;
        this.discardOpen= false;
		this.preloaderFetch(false)		
	} 
	
	closeCreateCampPopupDiscard = () =>{
		
		let param = {where:{ownerId:this.userId}}
		campaignDraftDetailResult(JSON.stringify(param))
			.then(res => {
				if(res.data.length !== 0){
					let draftId = res.data[0].id
					CampaignDraftDeleteResult(draftId)
						.then(res2 => {
							this.DraftCampaignDetail()		
					}).catch(error=>console.log(error))
				}			
			}).catch(error=>console.log(error))
		
		this.createCampaignsPopup='container-fluid create-campaigns d-none ss';        
		this.createCampaigns='add-campaingns d-none ss1';
        this.open=false;
        this.discardOpen= false;
		this.preloaderFetch(false)		
	}
	closeDraftCampPopup = () =>{
		
		this.campaignsDraftPopup='container-fluid campaigns-drafts d-none ss';        
	    
    }
    closeCreateCampaignsPopup=()=>
    {
       
		this.createCampaignsPopup='container-fluid create-campaigns d-none sss';      
		this.createCampaigns='add-campaingns d-none sss1';
        this.open=false;
        this.discardOpen= false;    
       
        window.location.href = `${process.env.PUBLIC_URL}/#/my-campaigns`;
		window.location.reload();
    }
	
    getFiles = (files) => {
		let imageType = [];
		let imageIndex = [];
		files.map(function(item,index){
			imageType.push(item.type)
			imageIndex.push(index)
		})
		this.imageLoaderFetch(imageIndex)
		let image1 = ["image/jpg", "image/jpeg", "image/png"];
		let existAnotherFile = imageType.filter(e => image1.indexOf(e)===-1);
		if(existAnotherFile.length > 0){
			console.log('not image')
		}else{
			this.preloaderFetch(true)
			files.map(async(item,index) => {
				let campaignsimage = {
					base64ImageData: item.base64,
				}  
				
				campaignsimageApiResult(campaignsimage)
					.then(res => {
						const {status,data} = res
						if(!data.error ){
							this.imageFetch(res.data.path);
							this.preloaderFetch(false)
						}						
				}).catch(error=>console.log(error))		
			});

		}
    }
	
	handleSubmit = (data) => {		
		this.preloaderFetch(true);
		this.checkSearchFetch(true);
		campaignStore(data)
			.then(res => {				        
			   this.preloaderFetch(false);
			}).catch(error=>console.log(error))
	}	

	fetchCountries = () => {
		countriesResult()
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.countryCountFetch(data.length)
					let options=[];
					res.data.map(function(item,index){
						let obj ={};
						obj['label']=item.name;          
						obj['value']=item.countryCode;
						options.push(obj);						
					});
				 
					this.countryFetch(options);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}

	fetchCategories = () => {
		let param = {where:{categoryStatus:1}}
		categoryResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					let options=[];
					res.data.map(function(item,index){
						let obj ={};
						obj['label']=item.categoryName.charAt(0).toUpperCase() + item.categoryName.slice(1);          
						obj['value']=item.categoryName;
						options.push(obj);						
					});
				 
					this.categoriesFetch(options);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	countryFetch 		= (data) => {this.countries = data;}	
	categoriesFetch 	= (data) => {this.categories = data;}	
	preloaderFetch 		= (data) => {this.preloader = data;}
	imageFetch 		    = (data) => {

		this.formvalue.cfiles.push(data)	
	}
	
	campaddedSuccess  = () => { 
       	this.CampAddedSuccessfull='camp-added-success p';       
		this.CampaignsPreview='camp-preview d-none p';       
		this.createCampaigns='add-campaingns d-none p';      
		this.draftCampaigns='draft-camp d-none p';
	}
		 
	resetMsg 	= () => {  this.errors.campaignAmount='';this.errors.age='';this.errors.campaignsTitle='';this.errors.campaignDetails='';this.errors.image='';this.errors.endStoryPostDate='';this.errors.country='';}
	
	/***** Notification List Show Start *****/
	
	fetchNotificationCount = () => {
		let count = {readStatus:0,ownerId:this.userId};
		notificationCountResult(JSON.stringify(count))
			.then(async res => {
				if(res.data.count > 0){
					this.notifyCountFetch(res.data.count)
				}else{
					this.notifyCountFetch('')
				}
					
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	fetchNotificationList = () => {
		let param = { where: {ownerId: this.userId}, order: "id DESC" }
		notificationListResult(JSON.stringify(param))
			.then(async res => {
				
				const NotifyGroupedByDate = res.data.reduce((groups, list) => {
					const date = list.createdAt.split('T')[0];
					  if (!groups[date]) {
						groups[date] = [];
					  }
					  groups[date].push(list);
					  return groups;
				}, {});
				this.notifyListFetch(NotifyGroupedByDate)
				if(res.data.length !== 0){
					this.listNotFoundFetch(false)
				}else{
					this.listNotFoundFetch(true)
				}
				
				
			}).catch(error=>this.listNotFoundFetch(true))
	}
	
	isReadFetch = () => {
		this.notifyCountFetch('')
		let isRead = {readStatus:1};
		let id = {ownerId:this.userId};
		isReadResult(JSON.stringify(id),isRead)
			.then(res1 => {
				let count = {readStatus:0,ownerId:this.userId};
				notificationCountResult(JSON.stringify(count))
					.then(res => {
						if(res.data.count > 0){
							this.notifyCountFetch(res.data.count)
						}else{
							this.notifyCountFetch('')
						}
						this.fetchNotificationList()
					}).catch(error=>console.log('error  ',JSON.stringify(error)))	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	notifyCountFetch = (data) => {this.notifyCount = data;}
	notifyListFetch = (data) => {this.notifyList = data;}
	listNotFoundFetch = (data) => {this.listNotFound = data;}
	
	campaignDetailFetch = (data) => {
		this.formvalue.cfiles = [];
		this.formvalue.campaignsTitle=data.campaignTitle
		this.formvalue.campaignDetails=data.campaignDetails
		this.formvalue.lookingInfluencerGender=data.lookingInfluencerGender
		this.formvalue.campaignFollowers=data.minimumFollowers
		if(data.endStoryPostDate){
			let checkdate = Moment(data.endStoryPostDate).format('YYYY-MM-DD')
			if(checkdate !== '2099-12-31'){
				this.formvalue.endStoryPostDate = new Date(data.endStoryPostDate)
			}
		}
		this.formvalue.campaignAmount=data.campaignAmount
		this.formvalue.campaignType=data.campaignType

		if(data.campaignCategories && data.campaignCategories.length > 0){
			let options=[];
			data.campaignCategories.map(function(item,index){
				let obj ={};
				obj['label']=item.charAt(0).toUpperCase() + item.slice(1);          
				obj['value']=item;
				options.push(obj);						
			})
			this.formvalue.category = options
		}
		
		if(data.country && data.country !== 'All' && data.countryCode && data.countryCode !== 'NA'){
			let setCountry = data.country.split(',')
			let setCode = data.countryCode.split(',')
			
			if(setCountry.length > 0 && setCode.length > 0){
				let options1=[];
				let options2=[];
				
				setCountry.map(function(item,index){
					let obj ={};
					obj['label']=item;
					options1.push(obj);
										
				})
				setCode.map(function(item1,index){	
					let obj ={};
					obj['value']=item1;          
					options2.push(obj);	
				})
				let arr3 = options1.map((item, i) => Object.assign({}, item, options2[i]));
				this.formvalue.country = arr3
			}
		}else if(data.country && data.country === 'All'){
			this.formvalue.country = this.countries
		}
		
		/*if(data.hashtags.length > 0){
			let hashtags = data.hashtags.split(',')
			let tagoptions=[];
			hashtags.map(function(item,index){
				let obj ={};
				obj['index']=index+1;          
				obj['displayValue']=item;
				tagoptions.push(obj);						
			})
			this.formvalue.tags=tagoptions
		}*/
		
		this.formvalue.cfiles=data.campaignGallery	
		this.formvalue.age=data.minAge+'-'+data.maxAge		
	}
	
	campaignIdFetch 	= (data) =>{this.campaignId = data;}
	draftIdFetch 		= (data) =>{this.draftId = data;}
	isloaderFetch 	 	= (data) =>{this.isloader = data;}
	draftDetailFetch 	= (data) =>{this.draftDetail = data;}
	imageLoaderFetch 	= (data) =>{this.imageLoader = data;}
	searchFetch 	    = (data) =>{this.search = data;}
	countryCountFetch 	= (data) =>{this.countryCount = data;}
	oneClickFetch 		= (data) =>{this.oneClick = data;}
	
	deleteImage = (data) =>{
		let removeImage = this.formvalue.cfiles.filter(e => data.indexOf(e)===-1)
		this.formvalue.cfiles = removeImage
	}
	
}

decorate(CreatecampaignListStore, 
{
	imageLoader:observable,
	galleryStore:observable,
	userId: observable,
	countries: observable,
	preloader:observable,
	open:observable,
	forgotopen:observable,
	discardOpen:observable,
	tags:observable,
	categories:observable,
	formvalue:observable,
	errors:observable,
	campaignsTabclass:observable,
	campaignsDraftPopup:observable,
	hashtagTabclass:observable,
	campaignsInfoclass:observable,
	addHashtagclass:observable,
	savnextbtn:observable,
	createCampaignsPopup:observable,
	createCampaigns:observable,
	CampaignsPreview:observable,
	CampAddedSuccessfull:observable,
	draftCampaigns:observable,
	notifyCount:observable,
	notifyList:observable,
	listNotFound:observable,
	campaignDetail:observable,
	campaignId: observable,
	isloader: observable,
	draftDetail: observable,
	draftOpen:observable,
	draftId:observable,
	search:observable,
	countryCount:observable,
	oneClick:observable,
	fetchCountries:action,
	countryFetch: action,
	handleSubmit: action,
	preloaderFetch:action,
	openModal:action,forgotopenModal:action,
	openDraftCampPopup:action,
	closeDraftCampPopup:action,
	onTagsChanged:action,onCategoryChanged:action,handleChange:action,handleChangePrice:action,handleChangeFollowers:action,handleChangeDate:action,getFiles:action,imageFetch:action,showNextTab:action,showHashTab:action,showCampTab:action,openCreateCampPopup:action,showPreview:action,closeCreateCampPopup:action,closeModal:action,forgotcloseModal:action,closeCreateCampaignsPopup:action,campaddedSuccess:action,resetMsg:action,notifyCountFetch:action,isReadFetch:action,notifyListFetch:action,listNotFoundFetch:action,categoriesFetch:action,fetchCategories:action,setSelected:action,updateCampaign:action,campaignDetailFetch:action,campaignIdFetch: action,isloaderFetch:action,onAgeChange:action,openCreateCampPopupNew:action,deleteImage:action,openDiscardModal:action,closeDiscardModal:action,openDraftModal:action,closeDraftModal:action,draftCampaings:action,draftDetailFetch:action,openCreateCampPopupDraft:action,draftIdFetch:action,closeCreateCampPopupDiscard:action,imageLoaderFetch:action,searchFetch:action,onCountryChanged:action,countryCountFetch:action,oneClickFetch:action
});

export default CreatecampaignListStore;