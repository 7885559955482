import React from 'react';
import {observer, inject} from 'mobx-react';
import EllipsisText from "react-ellipsis-text";
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import NumericLabel from 'react-pretty-numbers';
import 'react-input-range/lib/css/index.css';
import moment from 'moment';
import MultiSelect from "react-multi-select-component";
import NumberFormat from 'react-number-format';
import queryString from 'query-string'

var optionnumberformat = {
  'justification': 'C',
  'percentage': false,
  'precision': 0,
  'wholenumber': null,
  'commafy': true,
  'shortFormat': true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title': true,
  'cssClass': ['red']
};

class Campaigns extends React.Component{
	
	constructor(props){
		super(props);
		this.myRef = React.createRef()
		this.changePriceSort = this.changePriceSort.bind(this)
		this.changePrice = this.changePrice.bind(this)
		this.changeSearch = this.changeSearch.bind(this)
		this.category = this.props.match.params.category
	}
	
	componentDidMount() {
		this.props.CampaignListStore.searchFetch('');
		this.props.CampaignListStore.fetchCategories('Category');
		this.props.CampaignListStore.clearAllFilter(this.category);
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	
	handlePageClick = data => {
		let selected = data.selected;
		let limits = this.props.CampaignListStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.CampaignListStore.offsetFetch(offset); 
		this.props.CampaignListStore.selectedFetch(selected); 
		this.props.CampaignListStore.campaignListFetch('Collaboration List');
		this.props.CampaignListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	handlePageClickFilter = data => {
		let selected = data.selected;
		let limits = this.props.CampaignListStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.CampaignListStore.offsetFetch(offset); 
		this.props.CampaignListStore.handleSubmit();
		this.props.CampaignListStore.checkSearchFetch(true);
		this.props.CampaignListStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}

	genderFilter = (gender) => {
		this.props.CampaignListStore.genderFetch(gender);
		this.props.CampaignListStore.offsetFetch(0);
		this.props.CampaignListStore.pageCountFilterFetch('');
		this.props.CampaignListStore.totalCountFilterFetch('');
		this.props.CampaignListStore.handleSubmit();
		this.props.CampaignListStore.preloaderFetch(true);
	}
	changePriceSort = (e) => {
		this.props.CampaignListStore.priceSortFetch(e.target.value);
		this.props.CampaignListStore.offsetFetch(0);
		this.props.CampaignListStore.pageCountFilterFetch('');
		this.props.CampaignListStore.totalCountFilterFetch('');
		this.props.CampaignListStore.handleSubmit();
		this.props.CampaignListStore.preloaderFetch(true);
	}
	changePrice = (value) => {
		this.props.CampaignListStore.valuePriceFetch(value);
		this.props.CampaignListStore.offsetFetch(0);
		this.props.CampaignListStore.pageCountFilterFetch('');
		this.props.CampaignListStore.totalCountFilterFetch('');
		this.props.CampaignListStore.handleSubmit();
		this.props.CampaignListStore.preloaderFetch(true);
	}

	genderUnset = () => {
		this.props.CampaignListStore.genderFetch('');
		this.props.CampaignListStore.offsetFetch(0);
		this.props.CampaignListStore.pageCountFilterFetch('');
		this.props.CampaignListStore.totalCountFilterFetch('');
		this.props.CampaignListStore.handleSubmit();
		this.props.CampaignListStore.preloaderFetch(true);
	}
	
	changeSearch = (e) => {
		this.props.CampaignListStore.searchFetch(e.target.value);
		this.props.CampaignListStore.offsetFetch(0);
	}
	
	searchSubmit = (event) => {
		event.preventDefault();
		this.props.CampaignListStore.handleSubmit();
		this.props.CampaignListStore.pageCountFilterFetch('');
		this.props.CampaignListStore.totalCountFilterFetch('');
		if(this.props.CampaignListStore.search){
			this.props.CampaignListStore.searchKeywordFetch(true);
			this.props.CampaignListStore.offsetFetch(0);
		}else{
			this.props.CampaignListStore.searchKeywordFetch(false);
			this.props.CampaignListStore.offsetFetch(0);
		}
		this.props.history.push(`${process.env.PUBLIC_URL}/campaigns`)
	}

	
	render(){
		const store = this.props.CampaignListStore;
		const todayDate = moment().format('YYYY-MM-DD');
		const skelrow = [1,2,3,4,5,6];
		const catrow = ['business','food','fashion','gaming','Skin care'];
		return (
			<>
				<div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
				<section className="campaign-page " id="campaigns_list">
					{/* <div className="container w1280  pl-0 pr-0"> */}
					<div className="container custom-container">
						<div className="search-inf-wpr">
							<h2 className="page-heading">Campaigns</h2>
							<form onSubmit={this.searchSubmit} className="search-inf">
								<span className="fa fa-search search-icon"></span>
								<input type="text" onChange={this.changeSearch} value={store.search} className="form-control" placeholder="Search"/>
								<button type="submit" className="btn btn-primary">Search</button>
							</form>
						</div>
						<div className="campaign-header">
							<div className="campaign-header-left">
							{store.searchKeyword && store.search !== '' && <p className="found-inf">Search Keyword <span className="found-type">"{store.search}"</span></p>}
							</div>
							<div className="campaign-header-right">
								<div className="form-group">
									<span className="fas down-arrow fa-chevron-down"></span>
									<label htmlFor="sort By">Sort By :</label>
									<select className="form-control" id="sortBy" name="sortBy" onChange={this.changePriceSort}>
										<option value="">Select Sort By</option>
										<option value="">Default</option>
										<option selected={store.priceSort === '1'} value="1">Price Low to High</option>
										<option selected={store.priceSort === '2'} value="2">Price  High to Low</option>
									</select>
								</div>
							</div>
						</div>
					{/* </div>
					<div className="container w1280 pl-0 pr-0"> */}
						<div className="row m-0">
							<div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 camp-left">
								<div className="campaign-search-wpr" id="campSticky">
										<div className="search-clear-wpr">
											<span className="seacrh-by">Filter</span>
											<span className="clear-all" id="clearAllFilter" onClick={store.clearAllFilter}>Clear</span>
										</div>
										<div className="searched-cat">
										{store.category.length !==0? store.category.map((item,index) =>
										<span key={index}>{item && item.charAt(0).toUpperCase() + item.slice(1)} <i className="fa fa-times-circle removeFilter" onClick={()=>store.categoryUnset(item)}></i></span>								
										):null}
										
										{store.type.length !==0? store.type.map((item,index) =>
										<span key={index}>{item && item.charAt(0).toUpperCase() + item.slice(1)} <i className="fa fa-times-circle removeFilter" onClick={()=>store.typeUnset(item)}></i></span>								
										):null}
										
										{store.gender ? <span>{store.gender} <i className="fa fa-times-circle removeFilterGender" onClick={this.genderUnset}></i></span> : null}
										</div>
										{/*
										<div className="form-group custom-group">
											<label htmlFor="countries">Search Keyword</label>
											<input type="text" className="leftpannelsearch" placeholder="Search any keyword"></input>
										</div>*/}

										<div className="form-group custom-group">
											<label htmlFor="countries" className="leftpanel-title">By Category</label>
											<div className="tc-checkbox campaigns-checkbox-category">
												{store.categoryFilter.length !==0? store.categoryFilter.map((inf,index) =>
												<div className="form-check" key={index}>
													<input type="checkbox" className="form-check-input" onChange={store.changeCategory} id={`checkbox${index}`}  
													checked={store.checkedItems[inf.categoryName]}  name={inf.categoryName} />
													<label className="form-check-label">
														<span className="checkbox-text">{inf.categoryName && inf.categoryName.charAt(0).toUpperCase() + inf.categoryName.slice(1)}</span>
													</label>
												</div>
												) : <>
												{catrow.map((catrow,index) => (
												<div className="form-check" key={index}>
													<input type="checkbox" className="form-check-input" onChange={store.changeCategory} checked={store.checkedItems[catrow]} name={catrow} />
													<label className="form-check-label">
														<span className="checkbox-text">{catrow && catrow.charAt(0).toUpperCase() + catrow.slice(1)}</span>
													</label>
												</div>
												))}
												</>
												}
											</div>
										</div>

										<div className="form-group custom-group">
											<label htmlFor="countries" className="leftpanel-title">By Type</label>
											<div className="tc-checkbox campaigns-checkbox">
												<div className="form-check">
													<input type="checkbox" className="form-check-input" onChange={store.changeType} checked={store.checkedType['paid']} name="paid" value="paid"/>
													<label className="form-check-label">
														<span className="checkbox-text">Paid Campaigns</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input" onChange={store.changeType} checked={store.checkedType['sponsored']} name="sponsored" value="sponsored"/>
													<label className="form-check-label">
														<span className="checkbox-text">Sponsored</span>
													</label>
												</div>
												<div className="form-check">
													<input type="checkbox" className="form-check-input" onChange={store.changeType} checked={store.checkedType['shoutout']} name="shoutout" value="shoutout"/>
													<label className="form-check-label">
														<span className="checkbox-text">Shoutout Exchange</span>
													</label>
												</div>
											</div>
										</div>

										<div className="left-section-group">
										<h4 className="form-heading">By Price</h4>
										<div className="price-slider">
											<InputRange
												maxValue={1000000}
												minValue={0}
												step={100}
												value={store.valuePrice}
												onChange={this.changePrice} />
										</div>
										</div>
					
										{/* <h3 className="inf-text">Influencer</h3> */}
										<h4 className="form-heading mb-3">Influencer's Gender</h4>
										<div className="gender-list" id="campaignGender">
											<span className={store.gender === 'Any' && 'active'} onClick={()=>this.genderFilter('Any')}>Any</span>
											<span className={store.gender === 'Male' && 'active'} onClick={()=>this.genderFilter('Male')}>Male</span>
											<span className={store.gender === 'Female' && 'active'} onClick={()=>this.genderFilter('Female')}>Female</span>
										</div>
										<div className="gender-list" id="campaignGender">
											<span className={store.gender === 'Others' && 'active'} onClick={()=>this.genderFilter('Others')}>Others</span>
										</div>
								</div>
							</div>
							{store.preloader ?
								(<div className="feature-list col col-md-9 col-lg-9 pr-0 pl-md-0">
									{skelrow.map((skelrow,index) => (
									<div className="feature-item" key={index}>
										<div className="feature-image">
											<Skeleton width={290} height={227} />
										</div>
										<div className="feature-details">
											<Skeleton count={4} />
										</div>
									</div>
									))}
								</div>) : 	
								(
								<div className="feature-list col col-md-9 col-lg-9 pr-0 pl-md-0">
									{store.campaignListFilter.length !== 0 ? store.campaignListFilter.map((inf,index) =>
										<div className="feature-item" key={index}>
											<div className="feature-image">
											<Link to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.id}`}><img src={inf.campaignImage} alt={inf.campaignTitle} /></Link>
											{/* {inf.campaignType == 'paid' ?
												<div className="image-top-price">
													{inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} />
												</div>
											: inf.campaignType == 'sponsored' ?
												<div className="sponsored_label">Sponsored {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></div>
											:
												<div className="exchange_label">Shoutout Exchange</div>
											}
											 {inf.campaignStatus==2 && inf.remarks.length>0 &&
											<div className="received-campaignslist">                                   
												<span className="bookmark-text" style={{background:'#689dff'}}>{inf.remarks.length} Applications</span>
											</div>
											} */}
											</div>
											<div className="feature-details">
												<p className="posted-campaignlist">Posted On : {moment(new Date(inf.createdAt)).format("MMM D, YYYY")}</p>
												<h2>{inf.campaignTitle}</h2>

												<div className="applicentlistcountfoll">
													<span className="applicationtext">{inf.remarks.length == 0 ? <span>New Listing</span> : <span> {inf.remarks.length} Applications </span> }{inf.campaignType == 'shoutout' && inf.profile && <span className="followerstext"> | <NumericLabel params={optionnumberformat}>{inf.profile.followers}</NumericLabel>Followers</span> }</span>
												</div>
												
												<h3>{inf.campaignType == 'shoutout' ? 'Shoutout Exchange'  : <span>{inf.campaignType}</span> }{inf.campaignType != 'shoutout' && <span>: {inf.campaignAmountCurrency} <NumberFormat value={inf.campaignAmount} displayType={'text'} thousandSeparator={true} /></span>}</h3>
												<div className="campaign-details-mask">
													<Link className="campaign-detail-btn" to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.id}`}>More Info</Link>
												</div>

											</div>
										</div>
									):  
									<div className="col-lg-12">
										 <div className="no-result-wpr">
											<div className="no-result-found">
												<div className="no-result-img">
													<img src={`${process.env.PUBLIC_URL}/images/no-result.svg`} alt="No Result" />
												</div>
												<p className="no-result">Sorry, no result found</p>
											</div>
										</div>
									</div>
									}
								</div>)
								}
						</div>
					</div>
				</section>
				
				{store.campaignListFilter.length !== 0 &&
				<section className="container w1280">
					{/* <div className="row"> */}
						<div className="col-lg-9 col-12 offset-3 pr-1">
							<div className="pg-wpr row">
							{store.totalCountFilter > store.limit ?
							<>
							<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
								Showing 1 to {store.limit} of {store.totalCountFilter} entries
							</div>
							<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
								<ReactPaginate
									previousLabel={'Previous'}
									nextLabel={'Next'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={store.pageCountFilter}
									marginPagesDisplayed={2}
									pageRangeDisplayed={1}
									onPageChange={this.handlePageClickFilter}
									containerClassName={'custom-pagination'}
									subContainerClassName={''}
									activeClassName={'active'}
								/> 
							</div>
							</>
							: null }
							</div>
						</div>
					{/* </div> */}
				</section>}
				
			</>
		)
	}
}
export default inject('CampaignListStore')(observer(Campaigns));