import {action, observable, decorate} from 'mobx';
import { profileUpdateResult } from '../api/ProfileupdateApi';
import { usernameCheckResult } from '../api/UsernameCheckApi';

class UsernameStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	isLoader = false;
	wentWrong = false;
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	username = '';
	enterUsername = false;
	usernameAlready = false;
	redirect = false;
	usernameGo = false;
	
	loginUserFetch = (event) => {
		event.preventDefault()
		this.setIsLoader(true)
		this.setUsernameAlready(false)
		this.setEnterUsername(false)
		this.setwentWrong(false)
		if(this.username){
			let param = {where:{username:this.username}}
			usernameCheckResult(JSON.stringify(param))
				.then(res => {
					if(res.data.length !== 0){
						this.setUsernameAlready(true)
						this.setUsernameGo(false)
						this.setIsLoader(false)
						this.setwentWrong(false)
					}else{
						this.setUsernameAlready(false)
						this.setwentWrong(false)
						let userName = {
							username: this.username
						}
						profileUpdateResult(this.userId,userName)
							.then(res1 => {
								console.log(res1)
								const {status,data} = res1
								if(status===200 && !data.error ){
									this.setIsLoader(false)
									this.setRedirect(true)
									this.setUsernameGo(true)
								}
							}).catch(error=>[this.setwentWrong(true),this.setIsLoader(false)])
					}
				}).catch(error=>[this.setwentWrong(true),this.setIsLoader(false)])
		}else{
			this.setEnterUsername(true)
			this.setIsLoader(false)
			this.setwentWrong(false)
		}
	}
	
	setIsLoader = (data) => {
		this.isLoader = data;
	}
	setwentWrong = (data) => {
		this.wentWrong = data;
	}
	setUsername = (data) => {
		this.username = data;
	}
	setEnterUsername = (data) => {
		this.enterUsername = data;
	}
	setUsernameAlready = (data) => {
		this.usernameAlready = data;
	}
	setRedirect = (data) => {
		this.redirect = data;
	}
	setUsernameGo = (data) => {
		this.usernameGo = data;
	}
	
}

decorate(UsernameStore, {
	wentWrong: observable,
	isLoader: observable,
	username: observable,
	enterUsername: observable,
	usernameAlready: observable,
	redirect: observable,
	usernameGo: observable,
	loginUserFetch: action,
	setIsLoader: action,
	setwentWrong: action,
	setUsername: action,
	setEnterUsername: action,
	setUsernameAlready: action,
	setRedirect: action,
	setUsernameGo: action,
});

export default UsernameStore;