import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

class Footer extends React.Component{
	
	render(){
		//if (window.location.hash == '#/help') return null;
		if (window.location.hash == '#/username') return null;
		return (
			<React.Fragment>
				{/* Footer Start */}
				<footer className="container-fluid pl-0 pr-0 footer">
					<div className="footer-menu-section text-center">
						<ul className="footer-menu">
							<li className="makeofferreset"><NavLink to="/about">About</NavLink></li>
							<li className="text-uppercase makeofferreset"><NavLink to="/faq">FAQ</NavLink></li>
							<li className="makeofferreset"><NavLink to="/terms-condition">Terms &amp; conditions</NavLink></li>
							<li className="makeofferreset"><NavLink to="/privacy-policy">Privacy &amp; policy</NavLink></li>
							<li className="makeofferreset"><NavLink to="/help">Help</NavLink></li>
						</ul>
						<ul className="social-menu"> 
							<li className="makeofferreset"><a href="https://www.facebook.com/koliapp/" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/social/facebook.svg`} alt="facebook" /></a></li>
							<li className="makeofferreset"><a href="https://www.instagram.com/koli.ai/" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/social/instagram.svg`} alt="instagram" /></a></li>
						</ul>
					</div>
					<div className="copyright-wpr text-center">
						<Link to="#">Copyright © {(new Date().getFullYear())} KOLI. All rights reserved.</Link>
					</div>
				</footer>
				{/* Footer End */}
			</React.Fragment>
		)
	}
}

export default withRouter(Footer);