import {action, observable, decorate} from 'mobx';
import { termsResult } from '../api/TermsApi';

class TermsStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	terms = [];
	
	termsGet = () =>{
		termsResult()
			.then(res => this.setTerms(res.data.message))
			.catch(error => error)
	}
	
	setTerms = (data) => {
		this.terms = data;
	}
	
}

decorate(TermsStore, {
	terms: observable,
	setTerms: action
});

export default TermsStore;