import {action, observable, decorate} from 'mobx';
import { campaignCountResult } from '../api/CampaignCountApi';
import { campaignListResult } from '../api/CampaignListApi';

class MycampaignListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	campaign = [];
	offset = 0;
	totalCount = '';
	limit = 8;
	preloader = false;
	search = '';
	priceSort = '';
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	
	campaignListFetch = () => {
		this.preloaderFetch(true);

		let param;
		let count;
		let search;
		let priceOrder;
		
		if(this.priceSort === 'lth'){
			priceOrder = "campaignAmount ASC";
		}else if(this.priceSort === 'htl'){
			priceOrder = "campaignAmount DESC";
		}else{
			priceOrder = "id DESC";
		}
		
		let pattern =  '%25'+this.search+'%25'; 

		if(this.search){
			param = {include:['remarks','profile'],where:{ownerId:this.userId,campaignTitle:{like:pattern}},order:priceOrder,offset:this.offset,limit:this.limit}
			
			count = {ownerId:this.userId,campaignTitle:{like:pattern}}
			
		}else{
			param = {include:['remarks','profile'],where:{ownerId:this.userId},order:priceOrder,offset:this.offset,limit:this.limit}
			
			count = {ownerId:this.userId,order:"id DESC"}
		}
		
		campaignListResult(JSON.stringify(param))
			.then(res => {
				console.log(res)
				campaignCountResult(JSON.stringify(count))
					.then(res1 => {
						this.campaignList(res.data);
						this.pageCountFetch(Math.ceil(res1.data.count / this.limit));
						this.totalCountFetch(res1.data.count);
						this.preloaderFetch(false);
					}).catch(error=>console.log(error))
			}).catch(error=>console.log(error))
	}

	campaignList	 		= (data) => {this.campaign = data;}
	preloaderFetch 			= (data) => {this.preloader = data;}
	searchFetch 			= (data) => {this.search = data;}
	priceSortFetch 			= (data) => {this.priceSort = data;}
	offsetFetch 			= (data) => {this.offset = data;}
	pageCountFetch 			= (data) => {this.pageCount = data;}
	totalCountFetch			= (data) => {this.totalCount = data;}
	
}

decorate(MycampaignListStore, {
	userId:observable,auth: observable,campaign: observable,preloader:observable,search: observable,priceSort:observable,offset: observable,totalCount: observable,pageCount:observable,limit: observable,campaignList: action,preloaderFetch:action,searchFetch:action,priceSortFetch:action,offsetFetch: action,totalCountFetch: action,pageCountFetch: action
});

export default MycampaignListStore;