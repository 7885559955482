import React from 'react';
import {observer, inject} from 'mobx-react';
import { Redirect } from 'react-router-dom';

class Username extends React.Component{
	
	constructor(props){
		super(props);
		this.usernameChange = this.usernameChange.bind(this)
	}
	
	usernameChange = (e) =>{
		this.props.UsernameStore.setUsername(e.target.value);
	}
	
	render(){
		const store = this.props.UsernameStore
		return (
			<React.Fragment>
				{store.auth ? <div>
				<div className="blank-wpr container-fuild  after-header"></div>
				<div className="container-fuild smart-wpr username-main createuser-name"> 
					<section className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-12">
								<div className="createuser-form">
								<div className="createuser-form-inner">
									<h1>Grow Your Business Online with <br/> <span className="pink"> Smart Campaigns</span>  </h1>
									<p className="lets-text">Let's get started with your profile creation.</p>
									<div className="username-wpr">
										<form onSubmit={store.loginUserFetch} method="post" className="form-createuser">
											<div className="form-group">
												<label htmlFor="username" className="username">Username</label>
												<input type="text" className="form-control createuerinput" maxLength="30" name="username" onChange={this.usernameChange} id="username" placeholder="e.g. @username" />
												{store.enterUsername && <span className="errorcolor">Please enter username!</span>}
												{store.wentWrong && <span className="errorcolor">Something went wrong. Please try again later!</span>}
											</div>
											<button type="submit" className="btn btn-primary">Next {store.isLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
										</form>
										{store.usernameAlready && <p className="exist-username-text"><span className="far fa-times-circle"></span>Username is taken, Use different</p>}
										{store.usernameGo && <p className="go-ahead"><span className="fas fa-check"></span>Go ahead</p>}
									</div>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="row">
                            <div className="username-wpr  clearfix">
                                <form onSubmit={store.loginUserFetch} method="post">
                                    <div className="form-group">
                                        <label htmlFor="username" className="username">Username</label>
                                        <input type="text" className="form-control" maxLength="30" name="username" onChange={this.usernameChange} id="username" placeholder="e.g. @username" />
										{store.enterUsername && <span className="errorcolor">Please enter username!</span>}
										{store.wentWrong && <span className="errorcolor">Something went wrong. Please try again later!</span>}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Next {store.isLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
                                </form>
                                {store.usernameAlready && <p className="exist-username-text"><span className="far fa-times-circle"></span>Username is taken, Use different</p>}
								{store.usernameGo && <p className="go-ahead"><span className="fas fa-check"></span>Go ahead</p>}
                            </div>
						</div> */}
					</section>
					{store.redirect && <Redirect to="/profile" />}
				</div> </div>: <Redirect to="/" />}
			</React.Fragment>
		)
	}
}
export default inject('UsernameStore')(observer(Username));