import React, { Component } from 'react'
import {observer, inject} from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Slider from "react-slick";
import NumericLabel from 'react-pretty-numbers';
import ReactPaginate from 'react-paginate';

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class ApplicationUserList extends Component {
	constructor(props){
		super(props);
		this.myRef = React.createRef()
		this.id = this.props.match.params.id
	}
	
	componentDidMount() {
		if(this.props.ApplicationReceivedStore.auth){
			this.props.ApplicationReceivedStore.campaignDetailFetch(this.id);
			window.scrollTo(0,this.myRef.current.offsetTop);
		}
    }
	
	handlePageClick = data => {
		let selected = data.selected;
		let limits = this.props.ApplicationReceivedStore.limit;
		let offset = Math.ceil(selected * limits);
		this.props.ApplicationReceivedStore.offsetFetch(offset); 
		this.props.ApplicationReceivedStore.campaignDetailFetch(this.id);
		this.props.ApplicationReceivedStore.preloaderFetch(true);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
    render() {
		const store = this.props.ApplicationReceivedStore

		let dateFetch = this.props.ApplicationReceivedStore.endStory;
		let eventDate = moment(dateFetch);
		let todayDate = moment();
		let lastDate = eventDate.diff(todayDate, 'days');
        return (
            <> {store.auth ? <div ref={this.myRef} className="topspace-remove"> <div className="blank-wpr container-fuild border-separator after-header"></div>
				{store.preloader ? <> 
					<div className="container app-list-wpr w1200">
						<div className="app-list-header">
							<h2><Skeleton width={200} /></h2>
							<div className="row list-price-wpr">
								<div className="col-xl-2 col-lg-2 col-md-2 co-sm-12 col-12">
									<span>Price</span>
									<p><Skeleton /></p>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 co-sm-12 col-12">
									<span>Location</span>
									<p><img className="mr-2" src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" /><Skeleton width={100} /></p>
								</div>
								<div className="col-xl-2 col-lg-2 col-md-2 co-sm-12 col-12">
									<span>Remaining time</span>
									<p><Skeleton /></p>
								</div>
							</div>
						</div>
						<div className="application-list-container">
							<div className="application-list">
								<div className="row">
									<div className="app-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
										<div className="applicant-details">
											<div className="applicant-image">
												<Skeleton circle={true} width={70} height={70} />
											</div>
											<p style={{width:'200px'}} className="userCampaignskel"><Skeleton count={2} /></p>
										</div>
									</div>
								</div>
								<p><Skeleton count={2} /></p>
							</div>
						</div>
						<div className="application-list-container">
							<div className="application-list">
								<div className="row">
									<div className="app-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
										<div className="applicant-details">
											<div className="applicant-image">
												<Skeleton circle={true} width={70} height={70} />
											</div>
											<p style={{width:'200px'}} className="userCampaignskel"><Skeleton count={2} /></p>
										</div>
									</div>
								</div>
								<p><Skeleton count={2} /></p>
							</div>
						</div>
						<div className="application-list-container">
							<div className="application-list">
								<div className="row">
									<div className="app-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
										<div className="applicant-details">
											<div className="applicant-image">
												<Skeleton circle={true} width={70} height={70} />
											</div>
											<p style={{width:'200px'}} className="userCampaignskel"><Skeleton count={2} /></p>
										</div>
									</div>
								</div>
								<p><Skeleton count={2} /></p>
							</div>
						</div>
					</div>
				</> :
				<>
				<section className="cd-header-back after-header" ref={this.myRef}>
					<img src={`${process.env.PUBLIC_URL}/images/header-image.png`} alt="" />
				</section>
			<section className="cd-header-details">
			{store.campaign.length !== 0 && store.campaign.map((inf,index) =>
								<div className="container app-list-wpr w1200 custom-container">
								<div className="cd-details">
										<Link to={'/my-campaigns'} className="backbtn"><i className="fa fa-chevron-left"></i> Back</Link>
										<div className="row">
											<div className="col-12 col-md-10 col-lg-10"><h2>{inf.campaignTitle}</h2></div>
											<div className="col-12 col-md-2 col-lg-2">
											{store.auth ? 
												<>	{store.auth.ownerId == inf.ownerId ? '' :
														<span className="cd-report titlebackbtn" data-toggle="modal" data-target="#reportModal">Report</span>
													}
												</>
											: 	
												<span className="cd-report log-in-link backbtn  titlebackbtn">Report</span>
											}
											</div>
										</div>
										<div className="cd-header-image">
											<div className="row">
												<div className="col-12 col-md-8 col-lg-8">
													<div className="cd-header-img-wpr">
														<Slider>
															<div>
																<img src={`${process.env.PUBLIC_URL}/images/cd-details-image.png`} alt="" />
															</div>
															<div>
															<img src={`${process.env.PUBLIC_URL}/images/cd-details-image.png`} alt="" />
															</div>
															<div>
															<img src={`${process.env.PUBLIC_URL}/images/cd-details-image.png`} alt="" />
															</div>
														</Slider>
													</div>
												</div>
												<div className="col-12 col-md-4 col-lg-4">
													<div className="interest-wpr">
														<div className="price-wpr">
															<h4>Price</h4>
															<p className="price">USD 6</p>
														</div>
														<button type="button" className="btn btn-primary w-100 log-in-link">
															<img src="/images/edit-profile.svg" alt="" className="edit-applied"/> I'm Interested</button>
															<div className="share-friends-wpr text-center">
															<h3>Share With Friends</h3>
															<ul className="cd-social">
																<li>
																	<button aria-label="facebook" className="react-share__ShareButton">
																	<a href="#/campaigndetail/527">
																		<i class="fa fa-facebook"></i>
																	</a>
																	</button>
																</li>
																<li>
																	<button aria-label="linkedin" className="react-share__ShareButton">
																		<a href="#/campaigndetail/527">
																			<i className="fab fa-linkedin-in"></i>
																		</a>
																	</button>
																</li>
																<li>
																	<button aria-label="twitter" className="react-share__ShareButton">
																		<a href="#/campaigndetail/527">
																			<i className="fab fa-twitter"></i>
																		</a>
																	</button>
																</li>
															</ul>
														</div>
													</div>
													<div className="disablecamp-text">If you do not want to show this campaign then you can disable it</div>
													<input className="edit-campaign-check" type="checkbox" id="switch"/>
													<label className="edit-campaign-label" for="switch">Toggle </label>
													<span className="toggleswitch">Enable</span>
													</div>

											</div>
										</div>
										<section className="applicationuser-wrapper">
										<div className="cd-description row">
										<div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
											<div className="row">
												<p className="posted col-12 col-xl-12 col-lg-12  col-md-12 col-sm-12">Posted On : September 14, 2020</p>
												<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
												<div className="campaign-type-mask">
													<div className="hashtag-wpr">
														<h4>Campaign Type</h4>
															<div className="hashtag-list">
																<span>Gaming</span>
																<span>Skin care</span>
																<span>Fitness</span>
																<span>Comedy</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
													<div className="campaign-info-mask">
														<div className="row"><div className="col-12 col-lg-4 col-md-4">
															<div className="remain-wpr">
																<h4>Preferred Gender</h4>
																<p>Female</p>
															</div>
														</div>
														<div className="col-12 col-lg-4 col-md-4">
															<div className="remain-wpr">
																<h4>Influencer's Age</h4>
																<p>18-24, 35-44</p>
															</div>
														</div>
														<div className="col-12 col-lg-4 col-md-4">
															<div className="remain-wpr">
																<h4>Min. Followers</h4>
																	<p>55.3 millions</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div className="campaign-description-mask">
												<div className="cd-des">
													<h4>Description</h4>
													<p>Dance H</p>
												</div>
											</div>
										</div>
										<div className="col-12 col-xl-4 col-lg-4 col-md-4 col-xs-12"><div>
									</div>
								</div>
							</div>
							</section>
							</div>
							<div className="toogletab-section">
							<div className="tab-top-wpr">
								<ul class="nav nav-tabs custom-nav" id="myTab" role="tablist">
										<li class="nav-item">
											<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Applications</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Suggested</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Hired</a>
										</li>
									</ul>
								</div>
								<div class="tab-content" id="application-user-list-tab">
									<div className="row">
											<div className="col-6 col-md-10 col-lg-10">
												<h3>Application Recived: <span>(15)</span></h3>
											</div>
											<div className="col-6 col-md-2 col-lg-2">
												<div id="btnContainer" className="showlistgrid">
													<button class="btn" onclick="listView()"><i class="fa fa-bars"></i></button> 
													<button class="btn active" onclick="gridView()"><i class="fa fa-th-large"></i></button>
												</div>
											</div>
									</div>
									<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
										<div className="row">
											<div className="col-12 col-md-3 col-lg-3">
												<div className="applicant-wrapper">
														<div className="applicant-shift">
															<div className="applicant-img-wrapper">
																<img src="/images/applicant-avatar.svg" alt="" className="img-fluid applicant-img"/>
															</div>
															<h2 className="applicabt-name">Dillon Smitham</h2>
															<div className="applicant-username">@userkolihandler</div>
															<div className="followers-wrapper">
																<img src="/images/instagram.svg" alt="" className="img-fluid"/>
																<div className="follower-text">958k Followers</div>
															</div>
														</div>
														<button className="make-offer-hover-btn">Make Offer</button>
													</div>
												</div>
												<div className="col-12 col-md-3 col-lg-3">
												<div className="applicant-wrapper">
														<div className="applicant-shift">
															<div className="applicant-img-wrapper">
																<img src="/images/applicant-avatar.svg" alt="" className="img-fluid applicant-img"/>
																<div className="acceptoffer-label">
																	<img src="/images/accept-payment-check.svg" alt=""/>
																	<span>Accepted offer for $ 100</span>
																</div>
															</div>
															<h2 className="applicabt-name">Dillon Smitham</h2>
															<div className="applicant-username">@userkolihandler</div>
															<div className="followers-wrapper">
																<img src="/images/instagram.svg" alt="" className="img-fluid"/>
																<div className="follower-text">958k Followers</div>
															</div>
														</div>
														<button className="make-offer-hover-btn">Make Offer</button>
													</div>
												</div>
												<div className="col-12 col-md-3 col-lg-3">
												<div className="applicant-wrapper">
														<div className="applicant-shift">
															<div className="applicant-img-wrapper">
																<img src="/images/applicant-avatar.svg" alt="" className="img-fluid applicant-img"/>
															</div>
															<h2 className="applicabt-name">Dillon Smitham</h2>
															<div className="applicant-username">@userkolihandler</div>
															<div className="followers-wrapper">
																<img src="/images/instagram.svg" alt="" className="img-fluid"/>
																<div className="follower-text">958k Followers</div>
															</div>
														</div>
														<button className="make-offer-hover-btn">Make Offer</button>
													</div>
												</div>
												<div className="col-12 col-md-3 col-lg-3">
												<div className="applicant-wrapper">
														<div className="applicant-shift">
															<div className="applicant-img-wrapper">
																<img src="/images/applicant-avatar.svg" alt="" className="img-fluid applicant-img"/>
															</div>
															<h2 className="applicabt-name">Dillon Smitham</h2>
															<div className="applicant-username">@userkolihandler</div>
															<div className="followers-wrapper">
																<img src="/images/instagram.svg" alt="" className="img-fluid"/>
																<div className="follower-text">958k Followers</div>
															</div>
														</div>
														<button className="make-offer-hover-btn">Make Offer</button>
													</div>
												</div>
												<div className="col-12 col-md-12 col-lg-12">
													<div className="applicant-list-wrapper">
														<div className="row">
															<div className="col-12 col-md-3 col-lg-3">
																<div className="row">
																	<div className="col-12 col-md-4 col-lg-4">
																		<img src="/images/list-profile-img.svg" alt="" className="img-fluid"/>
																	</div>
																	<div className="col-12 col-md-8 col-lg-8">
																		<div className="applicantlist-infosection">
																			<h2 className="applicabt-name">Dillon Smitham</h2>
																			<div className="applicant-username">@userkolihandler</div>
																			<div className="followers-wrapper">
																				<img src="/images/instagram.svg" alt="" className="img-fluid"/>
																				<div className="follower-text">958k Followers</div>
																			</div>
																			<div className="acceptoffer-label">
																				<img src="/images/accept-payment-check.svg" alt=""/>
																				<span>Accepted offer for $ 100</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-12 col-md-9 col-lg-9">
																<div className="applicantlist-inforight">
																	<div className="applicant-list-text">Every successful influencer marketing campaign is empowered by a personal connection with the product, and a sincere sharing of the experience. We put influencers at the heart of the platform, enhancing their credibility, matching opportunities based on their niches and partnering them in their growth.</div>
																	<button className="list-message-btn">Message</button>
																	<button className="list-message-btn">Make Offer</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12 col-lg-12">
												<div className="applicant-list-wrapper">
													<div className="row">
														<div className="col-12 col-md-3 col-lg-3">
															<div className="row">
																<div className="col-12 col-md-4 col-lg-4">
																	<img src="/images/list-profile-img.svg" alt="" className="img-fluid"/>
																</div>
																<div className="col-12 col-md-8 col-lg-8">
																	<div className="applicantlist-infosection">
																		<h2 className="applicabt-name">Dillon Smitham</h2>
																		<div className="applicant-username">@userkolihandler</div>
																		<div className="followers-wrapper">
																			<img src="/images/instagram.svg" alt="" className="img-fluid"/>
																			<div className="follower-text">958k Followers</div>
																		</div>
																		<div className="acceptoffer-label">
																			<img src="/images/accept-payment-check.svg" alt=""/>
																			<span>Accepted offer for $ 100</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12 col-md-9 col-lg-9">
															<div className="applicantlist-inforight">
																<div className="applicant-list-text">Every successful influencer marketing campaign is empowered by a personal connection with the product, and a sincere sharing of the experience. We put influencers at the heart of the platform, enhancing their credibility, matching opportunities based on their niches and partnering them in their growth.</div>
																<button className="list-message-btn">Message</button>
																<button className="list-message-btn">Make Offer</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">B</div>
									<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">C</div>
								</div>
							</div>
					{/*08-10-20{store.campaign.length !== 0 && store.campaign.map((inf,index) =>
                    <div className="app-list-header" key={index}>
                        <h2>{inf.campaignTitle}</h2>
                        <div className="row list-price-wpr">
                            <div className="col-xl-2 col-lg-2 col-md-2 co-sm-12 col-12">
                                <span>Price</span>
                                <p>{inf.campaignAmountCurrency} {inf.campaignAmount}</p>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 co-sm-12 col-12">
                                <span>Location</span>
                                <p><img className="mr-2" src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt="" />{inf.country === 'All' ? 'All Country' : inf.country}</p>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 co-sm-12 col-12">
                                <span>Remaining time</span>
                                <p>{eventDate > todayDate ? <p> {lastDate} Days Left</p> : <p> Completed</p> }</p>
                            </div>
                        </div>
					</div>)} 08-10-20 */}

                   {/* <div className="find-result-sort-wpr row application-received">	
                        <h2 className="col-12 left col-xl-6 col-lg-6 col-md-6 col-sm-12">{store.totalCount} Applications Received</h2>
                        <div className="custom-sort right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 clearfix">
						<div className="form-group float-right ">
                                <span className="fas down-arrow fa-chevron-down"></span>
                                <label htmlFor="sort By">Sort By :</label>
                                <select className="form-control" id="sortBy" name="sortBy">
                                    <option value="1">Price : Low to High</option>
                                    <option value="2">Price : High to Low</option>
                                </select>
						</div>
                        </div>
                    </div>*/}

               { /* 8-10-20   <div className="application-list-container">
						{store.campaignUser.length !== 0 ? store.campaignUser.map((inf,index) =>
                        <div className="application-list" key={index}>
                            <div className="row">
                                <div className="app-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="applicant-details">
                                        <div className="applicant-image">
										{inf.profile.avatarUrl ?
                                            <img src={inf.profile.avatarUrl} alt="" /> 
										:
											<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />
										}
                                        </div>
                                        <div>
                                            <h3><Link className="application-name" to={`/application-profile/${inf.profile.ownerId}`}>{inf.profile.first} {inf.profile.last}</Link></h3>
                                            <span><Link to={`/application-profile/${inf.profile.ownerId}`}>{inf.profile.username}</Link></span>
											{inf.profile.followers ?
												<div className="followers-count application-followers">
													<img src={`${process.env.PUBLIC_URL}/images/insta-line.svg`} alt="" /><NumericLabel params={optionnumberformat}>{inf.profile.followers}</NumericLabel> <span>Followers</span>
												</div> 
											: null}
                                        </div>
                                    </div>
                                </div>
                                <div className="app-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 text-right">
                                    <button type="button" className="btn btn-outline-primary"><i className="fas mr-2 fa-envelope"></i> Send Message</button>
                                </div>
                            </div>
                            <p>{inf.remarkText}</p>
                        </div>) : 'Applications Not Received'}
											</div> 8-10-20*/}
								</div>)}
							</section>
				</>}
				<div className="container app-list-wpr w1200">
				<div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="pg-wpr row">
                                {store.totalCount > store.limit ?
								<>
								<div className="pg-left col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
									Showing 1 to {store.limit} of {store.totalCount} entries
								</div>
								<div className="pg-right col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
									<ReactPaginate
										previousLabel={'Previous'}
										nextLabel={'Next'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={store.pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={1}
										onPageChange={this.handlePageClick}
										containerClassName={'custom-pagination'}
										subContainerClassName={''}
										activeClassName={'active'}
									/>
								</div>
								</>
								:null}
                            </div>
                        </div>
                    </div>
				</div>
            </div> : <Redirect to="/" />} </>
        )
    }
}
export default inject('ApplicationReceivedStore')(observer(ApplicationUserList));
