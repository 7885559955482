import {action, observable, decorate} from 'mobx';
import { categoryResult } from '../api/CategoryApi';
import { countriesResult } from '../api/CountryApi';
import { sortingResult } from '../api/SortingApi';
import { usernameCheckResult } from '../api/UsernameCheckApi';
import { InstagramPost } from '../api/InstagrampostApi';

class InfluencerListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	influencer = [];
	offset = 0;
	search = '';
	totalCount = '';
	categoryFilter = [];
	category = '';
	countryFilter = [];
	country = '';
	gender = '';
	age = '';
	checkSearch = false;
	preloader = false;
	searchKeyword = false;
	limit = 50;
	checkedItems = {};
	like = '';
	errorCheck = false;
	instaUsernameOwn = '';
	notFoundMy = false;
	
	searchInfluencer = (event) => {
		this.preloaderFetch(true);
		let param
		if(this.like == '3'){
		
			const location = window.navigator && window.navigator.geolocation
		
			if (location) {
			  location.getCurrentPosition((position) => {
				  this.errorCheckFetch(false)
				  let latitude = position.coords.latitude
				  let longitude= position.coords.longitude
				
				if(this.auth){
					param = "?name="+this.search+"&country="+this.country+"&followers="+this.like+"&offset="+this.offset+"&latitude=" +latitude+"&longitude=" +longitude+"&ownerId="+this.userId
				}else{
					param = "?name="+this.search+"&country="+this.country+"&followers="+this.like+"&offset="+this.offset+"&latitude=" +latitude+"&longitude=" +longitude
				}
				

				sortingResult(param)
					.then(res => {
						if(res.data.message !== 0){
							this.influencerFetch(res.data.message)
							this.pageCountFetch(Math.ceil(res.data.count / this.limit));
							this.totalCountFetch(res.data.count);
							this.preloaderFetch(false);
						}else{
							console.log('Result not found')
						}
					}).catch(error=>console.log(error))
				
				
			  }, (error) => {
				  this.errorCheckFetch(true)
			  })
			}
			
		}else{
			
			if(this.like == '4'){
				if(this.auth){
					param = "?name="+this.search+"&country="+this.country+"&lastSeen=1&offset="+this.offset+"&ownerId="+this.userId
				}else{
					param = "?name="+this.search+"&country="+this.country+"&lastSeen=1&offset="+this.offset
				}
			}if(this.like == ''){
				if(this.auth){
					param = "?name="+this.search+"&country="+this.country+"&followers=2&offset="+this.offset+"&ownerId="+this.userId
				}else{
					param = "?name="+this.search+"&country="+this.country+"&followers=2&offset="+this.offset
				}
			}else{
				if(this.auth){
					param = "?name="+this.search+"&country="+this.country+"&followers="+this.like+"&offset="+this.offset+"&ownerId="+this.userId
				}else{
					param = "?name="+this.search+"&country="+this.country+"&followers="+this.like+"&offset="+this.offset
				}
			}

			sortingResult(param)
				.then(res => {
					if(res.data.message !== 0){
						this.influencerFetch(res.data.message)
						this.pageCountFetch(Math.ceil(res.data.count / this.limit));
						this.totalCountFetch(res.data.count);
						this.preloaderFetch(false);
					}else{
						console.log('Result not found')
					}
				}).catch(error=>console.log(error))
		}
	}
	
	fetchCategories = () => {
		let param = {where:{categoryStatus:1},order:"id DESC"}
		categoryResult(JSON.stringify(param))
			.then(res => {
				this.categoryFilterFetch(res.data)
			}).catch(error=>console.log(error))
	}
	
	fetchCountries = () => {
		let param = {where:{},order:"name ASC"}
		countriesResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.countryFilterFetch(res.data);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	changeCategory = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const {checkedItems} = this
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
			let inreress = []
			for (let [key, value] of Object.entries(this.checkedItems)){
				if(value)
				inreress.push(key)
			}
			
		this.categoryFetch(inreress);
		this.offsetFetch(0);
		this.searchInfluencer();
	}
	
	categoryUnset = (cat) => {
		const item = cat;
		console.log(item)
		const isChecked = false;
		console.log(isChecked)
		const {checkedItems} = this
		console.log({checkedItems})
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
		let inreress = []
			for (let [key, value] of Object.entries(this.checkedItems)){
				if(value)
				inreress.push(key)
			}
			
		this.categoryFetch(inreress);
		this.offsetFetch(0);
		this.searchInfluencer();
	}
	
	currentLocationEnable = () => {
		const location = window.navigator && window.navigator.geolocation
		
			if (location) {
			  location.getCurrentPosition((position) => {
				  this.errorCheckFetch(false)
				  let latitude = position.coords.latitude
				  let longitude= position.coords.longitude
			  })
			}
	}
	
	profileOwnFetchData = () => {
		if(this.auth){
			let param = {where:{ownerId:this.userId}}
			usernameCheckResult(JSON.stringify(param))
				.then(res1 => {
					if(res1.data.length !== 0){
						let instaUsername = res1.data[0].instaUsername
						this.instaUsernameOwnFetch(instaUsername)
						if(instaUsername){
							InstagramPost(instaUsername)
								.then(res => {
									let result = res.data.graphql.user;
									if(result){
										this.notFoundMyFetch(false)
									}else{
										this.notFoundMyFetch(false)
									}
								}).catch(error=>[this.notFoundMyFetch(false)]) 
						}else{
							this.notFoundMyFetch(true)
						}
					}
				}).catch(error=>console.log(error)) 
		}
	}
	
	categoryFilterFetch = (data) => {this.categoryFilter = data;}
	countryFilterFetch 	= (data) => {this.countryFilter = data;}
	offsetFetch 		= (data) => {this.offset = data;}
	influencerFetch     = (data) => {this.influencer = data;}
	searchFetch     	= (data) => {this.search = data;}
	pageCountFetch 		= (data) => {this.pageCount = data;}
	totalCountFetch		= (data) => {this.totalCount = data;}
	preloaderFetch 		= (data) => {this.preloader = data;}
	countryFetch 		= (data) => {this.country = data;}
	categoryFetch 		= (data) => {this.category = data;}
	searchKeywordFetch 	= (data) => {this.searchKeyword = data;}
	checkedItemsFetch 	= (data) => {this.checkedItems = data;}
	genderFetch 		= (data) => {this.gender = data;}
	ageFetch 			= (data) => {this.age = data;}
	likeFetch 			= (data) => {this.like = data;}
	errorCheckFetch 	= (data) => {this.errorCheck = data;}
	instaUsernameOwnFetch 	= (data) => {this.instaUsernameOwn = data; }
	notFoundMyFetch 		= (data) => {this.notFoundMy = data; }
}

decorate(InfluencerListStore, {
	categoryFilter: observable,
	countryFilter: observable,
	offset: observable,
	influencer: observable,
	search: observable,
	totalCount: observable,
	pageCount:observable,
	preloader:observable,
	country:observable,
	searchKeyword:observable,
	category:observable,
	checkedItems:observable,
	gender:observable,
	age:observable,
	like:observable,
	errorCheck:observable,
	notFoundMy:observable,
	instaUsernameOwn:observable,
	categoryFilterFetch: action,
	countryFilterFetch: action,
	offsetFetch: action,
	influencerFetch: action,
	searchFetch: action,
	totalCountFetch: action,
	pageCountFetch: action,
	preloaderFetch: action,
	countryFetch: action,
	searchKeywordFetch:action,
	categoryFetch:action,
	checkedItemsFetch:action,
	changeCategory:action,
	genderFetch:action,
	ageFetch:action,
	categoryUnset:action,
	likeFetch:action,
	errorCheckFetch:action,
	currentLocationEnable:action,
	searchInfluencer:action,
	profileOwnFetchData:action,instaUsernameOwnFetch:action,notFoundMyFetch:action
});

export default InfluencerListStore;