let backendHost;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  backendHost = 'https://api.koliapp.com';
} else {
  backendHost = 'https://api.koliapp.com';
}

export const API_ROOT = `${backendHost}`;