import React from 'react';
import { Link } from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

class ProductsDetail extends React.Component{
	
	constructor(props){
		super(props);
		this.myRef 	  = React.createRef();
		this.ownerid  = this.props.match.params.ownerid;
	}
	
	componentDidMount() {
		this.props.ProductsDetailStore.productDetailFetch(this.ownerid);
		window.scrollTo(0,this.myRef.current.offsetTop);
	}
	
	detailPageChange=()=>{
		window.scrollTo(0,this.myRef.current.offsetTop);
	}

	render(){
		const productId = this.props.match.params.id;
		const store = this.props.ProductsDetailStore;
		const skelrow = [1,2,3,4];
		const images = [];
		
		{store.product.filter(info => info.id == productId).length !== 0 && store.product.filter(info => info.id == productId).map(function(inf2,index){
			inf2.productGallery.map(function(inf,index){
				const options = {};
				options['original'] =inf;
				options['thumbnail']=inf;
				images.push(options)
			});
		})};

		return (
		<div className="productdetail_mask_wrapper">
			<div className="blank-wpr container-fuild border-separator after-header" ref={this.myRef}></div>
			{store.preloader ?
			<section className="products-page " id="products_list">
				<div className="container w1280 pl-0 pr-0">
					<div className="row">
						<h2 className="page-heading">Product Details</h2>
						<nav aria-label="breadcrumb" className="breadcrumb_wrapper">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/products">Store</Link></li>
								<li className="breadcrumb-item active" aria-current="page"><Skeleton /></li>
							</ol>
						</nav>
						<div className="productdetail-artical-wrapper">
							<div className="row">
								<div className="col-12 col-lg-5">
									<div className="productimgviewer">
										<div className="productlarg">
											<Skeleton width={500} height={500} />
										</div>
									</div>
								</div>
								<div className="col-12 col-lg-7">
									<div className="porduct-artical-info">
										<h2><Skeleton width={350} /></h2>
										<div className="porductby-wrapper">
											<Skeleton circle={true} width={37} height={37} />
											<div className="productby-info">
												<p>Added By</p>
												<span>Changyin</span>
											</div>
										</div>
										<p><Skeleton count={4} /></p>
										<ul className="diliverydetail-wrapper">
											<h2><Skeleton width={200} /></h2>
											<p><Skeleton count={4} width={500} /></p>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="productlist-section">
							<div className="row">
								<div className="col-12 col-lg-6">
									<h2 className="productgallery-title"></h2>
								</div>
								<div className="col-12 col-lg-6">
									<h2 className="productgallery-title"><Link to=""></Link></h2>
								</div>
								{skelrow.map((skelrow,index) => ( 
								<div className="col-lg-3 col-12" key={index}>
									<div className="productdetail_wrapper">
										<Skeleton width={305} height={225} />
										<div className="productdetail_mask">
											<h2><Skeleton count={4} /></h2>
										</div>
									</div>
								</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			:
			<section className="products-page " id="products_list">
				<div className="container w1280  pl-0 pr-0">
					<h2 className="page-heading">Product Details</h2>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><Link to="/">Home</Link></li>
							<li className="breadcrumb-item"><Link to="/products">Store</Link></li>
							{store.product.filter(info => info.id == productId).length !== 0 && store.product.filter(info => info.id == productId).slice(0,4).map((inf1,index) =>
								<li className="breadcrumb-item active" aria-current="page">{inf1.productTitle}</li>
							)}
						</ol>
					</nav>
					<div className="row">
						{store.product.filter(info => info.id == productId).length !== 0 && store.product.filter(info => info.id == productId).map((inf2,index) =>
						<div className="productdetail-artical-wrapper" key={index}>
							<div className="row">
								<div className="col-12 col-lg-5">
									<div className="productimgviewer">
										<div className="productlarg">
											<ImageGallery
												items={images}
												showPlayButton={false}
												showFullscreenButton={true}
												showNav={false}
												/>
										</div>
									</div>
								</div>
								<div className="col-12 col-lg-7">
									<div className="porduct-artical-info">
										<h2>{inf2.productTitle}</h2>
										<div className="porductby-wrapper">
											{(inf2.profile.avatarUrl && inf2.profile.avatarUrl !== 'NA') ?
												<img src={inf2.profile.avatarUrl} alt="rating" className="img-fluid addedby-product" />
											:
												<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid addedby-product" />
											}
											<div className="productby-info">
												<p>Added By</p>
												<span>{inf2.profile.first} {inf2.profile.last}</span>
											</div>
										</div>
										{inf2.productDescription && <p>{inf2.productDescription}</p>}
										<div className="product-rating">
											<img src={`${process.env.PUBLIC_URL}/images/overallrating.svg`} alt="rating" className="img-fluid"/>
											<span>79 reviews</span>
										</div>
										<div className="productpriceoffer">
											<div className="productactprice">
												{inf2.productDiscount > 0 ? 
													<> {inf2.productAmountCurrency} <NumberFormat value={(inf2.productAmount-(inf2.productAmount*inf2.productDiscount/100))} displayType={'text'} thousandSeparator={true} /> </>
												: 
													<> {inf2.productAmountCurrency} <NumberFormat value={inf2.productAmount} displayType={'text'} thousandSeparator={true} /> </>
												} 
											</div>
											{inf2.productDiscount > 0 && 
												<div className="productprevprice">{inf2.productAmountCurrency} <NumberFormat value={inf2.productAmount} displayType={'text'} thousandSeparator={true} /></div>
											}
											{inf2.productDiscount > 0 && <div className="productdiscount">{inf2.productDiscount}% Off</div>}
										</div>
										<div className="productbuynow-wrapper">
										{inf2.stockCount > 0 ?
											<button>Buy Now</button>
										:
											<button className="outofstock">Out Of Stock</button>
										}
										</div>
										
										<ul className="diliverydetail-wrapper">
											<h2>Delivery Details</h2>
											<li>- We deliver your order within 7-12 days of placing it across Singapore.</li>
											<li>- COD Available on all order below S$ 7500.</li>
											<li>- This product is Not eligible for the Return process.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>)}
						{store.product.filter(info => info.id != productId).length !== 0 &&
						<div className="productlist-section">
							<div className="row">
								<div className="col-12 col-lg-6">
									<h2 className="productgallery-title">More Product(s) from Seller</h2>
								</div>
								<div className="col-12 col-lg-6">
									<h2 className="productgallery-title"><Link to="/products">See All</Link></h2>
								</div>
								{store.product.filter(info => info.id != productId).slice(0,4).map((inf,index) =>
								<div className="col-lg-3 col-12" key={index}>
									<div className="productdetail_wrapper">
										<img src={inf.productImage} alt={inf.productTitle} className="img-fluid" />
										<div className="productdetail_mask">
											<h2 className="similar-product-redirect" onClick={()=>this.detailPageChange()}><Link to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>{inf.productTitle}</Link></h2>
											<div className="productpriceoffer">
												<div className="productactprice">
												{inf.productDiscount > 0 ? 
													<> {inf.productAmountCurrency} <NumberFormat value={(inf.productAmount-(inf.productAmount*inf.productDiscount/100))} displayType={'text'} thousandSeparator={true} /> </>
												: 
													<> {inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /> </>
												} 
												</div>
												{inf.productDiscount > 0 && 
													<div className="productprevprice">{inf.productAmountCurrency} <NumberFormat value={inf.productAmount} displayType={'text'} thousandSeparator={true} /></div>
												}
												{inf.productDiscount > 0 && <div className="productdiscount">{inf.productDiscount}% Off</div>}
											</div>
											{inf.stockCount > 0 ?
												<Link className="product-detail-btn" onClick={()=>this.detailPageChange()} to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Buy Now</Link>
											:
												<Link className="product-detail-btn outofstock" onClick={()=>this.detailPageChange()} to={`${process.env.PUBLIC_URL}/productsdetail/${inf.productOwnerId}/${inf.id}`}>Out Of Stock</Link>
											}
											
										</div>
									</div>
								</div>
								)}
							</div>
						</div>}
					</div>
					<div className="productreview_section">
						<h2>Reviews (79)</h2>
						<div className="row">
							<div className="col-12 col-lg-5">
								<div className="rating-left">
									<h2>4.9<img src={`${process.env.PUBLIC_URL}/images/overallrating.svg`} alt="rating-star" className="img-fluid"/></h2>
									<p>Based on 79 Reviews</p>
								</div>
								<div className="rating-right">
									<div className="ratingiven-img">
										<img src={`${process.env.PUBLIC_URL}/images/individual-rating.svg`} alt="rating-star" className="img-fluid"/>
										<div className="progress">
											<div className="progress-bar rating80" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div className="rating-count">(72)</div>
									</div>
									<div className="ratingiven-img">
										<img src={`${process.env.PUBLIC_URL}/images/individual-rating.svg`} alt="rating-star" className="img-fluid"/>
										<div className="progress">
											<div className="progress-bar rating10" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div className="rating-count">(7)</div>
									</div>
									<div className="ratingiven-img">
										<img src={`${process.env.PUBLIC_URL}/images/individual-rating.svg`} alt="rating-star" className="img-fluid"/>
										<div className="progress">
											<div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div className="rating-count">(0)</div>
									</div>
									<div className="ratingiven-img">
										<img src={`${process.env.PUBLIC_URL}/images/individual-rating.svg`} alt="rating-star" className="img-fluid"/>
										<div className="progress">
											<div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div className="rating-count">(0)</div>
									</div>
									<div className="ratingiven-img">
										<img src={`${process.env.PUBLIC_URL}/images/individual-rating.svg`} alt="rating-star" className="img-fluid"/>
										<div className="progress">
											<div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div className="rating-count">(0)</div>
									</div>
								</div>
								<div className="writeareview-btn-mask">
									<button className="writeareview-btn">Write a Review</button>
								</div>
							</div>
						</div>
					</div>
					<div className="productreview_list_section">
						<div className="productreview_mask">
							<div className="review-intro-section">
								<img src={`${process.env.PUBLIC_URL}/images/avatar-review.png`} alt="rating avatar" className="img-fluid"/>	
								<div className="review-intro-info">
									<h2>Cameron Batz</h2>
									<p>@userkolihandler</p>
									<span>23 November 2020, Singapore</span>
								</div>
							</div>
							<div className="review-desc-section">
								<h2>Improved skin!</h2>
								<p>I have dry to sensitive skin, I have been wearing the argon oil in the day before I apply my sunscreen. It has been helping to balance the oil on my face and also being very moisturising. I have heard great things abt it but didn’t expect it to be this good! It’s something u have to try yourself to experience the goodness it has :</p>
							</div>
						</div>
						<div className="productreview_mask">
							<div className="review-intro-section">
								<img src={`${process.env.PUBLIC_URL}/images/avatar-review.png`} alt="rating avatar" className="img-fluid"/>	
								<div className="review-intro-info">
									<h2>Cameron Batz</h2>
									<p>@userkolihandler</p>
									<span>23 November 2020, Singapore</span>
								</div>
							</div>
							<div className="review-desc-section">
								<h2>Improved skin!</h2>
								<p>I have dry to sensitive skin, I have been wearing the argon oil in the day before I apply my sunscreen. It has been helping to balance the oil on my face and also being very moisturising. I have heard great things abt it but didn’t expect it to be this good! It’s something u have to try yourself to experience the goodness it has :</p>
							</div>
						</div>
						<div className="productreview_mask">
							<div className="review-intro-section">
								<img src={`${process.env.PUBLIC_URL}/images/avatar-review.png`} alt="rating avatar" className="img-fluid"/>	
								<div className="review-intro-info">
									<h2>Cameron Batz</h2>
									<p>@userkolihandler</p>
									<span>23 November 2020, Singapore</span>
								</div>
							</div>
							<div className="review-desc-section">
								<h2>Improved skin!</h2>
								<p>I have dry to sensitive skin, I have been wearing the argon oil in the day before I apply my sunscreen. It has been helping to balance the oil on my face and also being very moisturising. I have heard great things abt it but didn’t expect it to be this good! It’s something u have to try yourself to experience the goodness it has :</p>
							</div>
						</div>
						<div className="productreview_mask">
							<div className="review-intro-section">
								<img src={`${process.env.PUBLIC_URL}/images/avatar-review.png`} alt="rating avatar" className="img-fluid"/>	
								<div className="review-intro-info">
									<h2>Cameron Batz</h2>
									<p>@userkolihandler</p>
									<span>23 November 2020, Singapore</span>
								</div>
							</div>
							<div className="review-desc-section">
								<h2>Improved skin!</h2>
								<p>I have dry to sensitive skin, I have been wearing the argon oil in the day before I apply my sunscreen. It has been helping to balance the oil on my face and also being very moisturising. I have heard great things abt it but didn’t expect it to be this good! It’s something u have to try yourself to experience the goodness it has :</p>
							</div>
						</div>
						<div className="productreview_mask">
							<div className="review-intro-section">
								<img src={`${process.env.PUBLIC_URL}/images/avatar-review.png`} alt="rating avatar" className="img-fluid"/>	
								<div className="review-intro-info">
									<h2>Cameron Batz</h2>
									<p>@userkolihandler</p>
									<span>23 November 2020, Singapore</span>
								</div>
							</div>
							<div className="review-desc-section">
								<h2>Improved skin!</h2>
								<p>I have dry to sensitive skin, I have been wearing the argon oil in the day before I apply my sunscreen. It has been helping to balance the oil on my face and also being very moisturising. I have heard great things abt it but didn’t expect it to be this good! It’s something u have to try yourself to experience the goodness it has :</p>
							</div>
						</div>
					</div>
				</div>
			</section>}
		</div>
		)
	}
}
export default inject('ProductsDetailStore')(observer(ProductsDetail));