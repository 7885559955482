import React, { Component } from 'react';
import {observer, inject} from 'mobx-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { GEO_API } from '../geoApi';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import { TagInput } from 'reactjs-tag-input';
import Moment from 'moment';
import FileBase64 from 'react-file-base64';
import FileInputComponent from 'react-file-input-previews-base64'
import MultiSelect from "react-multi-select-component";
import NumericLabel from 'react-pretty-numbers';
import ShowMoreText from 'react-show-more-text';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class CreateCampaigns extends Component{

    constructor(props) {
        super(props);              
  
      }

    componentDidMount(){
        this.props.CreatecampaignListStore.fetchCountries();               
        this.props.CreatecampaignListStore.fetchCategories();		
    } 

	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
	
    render(){
        const getUploadParams = ({ meta }) => {
            const url = `${GEO_API}`
            return { url, meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` } }
        }
        
        const handleChangeStatus = ({ meta }, status) => {
            console.log(status, meta)
        }
        
        const handleSubmit = (files, allFiles) => { 
            console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
        }

        const store = this.props.CreatecampaignListStore;
		const store2 = this.props.UserProfileStore.profile
        return (
            <> 
                <section className={store.createCampaignsPopup} id="createCampaignsPopup">
                    <div className="row">
                        <div className="create-right createrightCreate">
                        {/* <a class="slide" href="#">Slide Out</a> */}
                        <span onClick={store.closeCreateCampPopup} className="slidenew closeinpput">
                            <img src={`${process.env.PUBLIC_URL}/images/close-slider.svg`} alt=""  className="close-campaign" />
                        </span> 
                            <div className="inner-create-right box" id="fade-in">

                                {/* Add New Collaborations */}
                                
                                <div className={store.createCampaigns} id="createCampaigns">
                                  {/*<form onSubmit={this.handleSubmit}> */}
                                        <h2 className="page-heading">{store.campaignId ? 'Edit Campaign' : 'Create Campaign'}</h2>
                                         {/*<ul className="nav nav-tabs custom-nav">
                                            <li className="nav-item">
                                                <Link id="campaignsTab" onClick={store.showCampTab} className={store.campaignsTabclass} to="#">Campaigns Info</Link>
                                            </li>
                                           <li className="nav-item" >
                                            <Link id="hashtagTab" onClick={store.showHashTab}  className={store.hashtagTabclass}  to="#">Add Hashtag</Link>
                                            
                                            </li>
                                        </ul>*/}
										
                                        <div className="tab-content create-camp-content">
                                             <div id="campaignsInfo" className={store.campaignsInfoclass}>
												<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group custom-form-group">
                                                        <label htmlFor="campaignsTitle">Title for your campaign</label>
														{store.formvalue.campaignsTitle && store.formvalue.campaignsTitle !== 'NA' ?
                                                        <input type="text" className="form-control" name="campaignsTitle" maxlength="60" value={store.formvalue.campaignsTitle} onChange={store.handleChange} placeholder="Campaign Title (Max Character 60)"/>
														:<input type="text" className="form-control" name="campaignsTitle" value="" onChange={store.handleChange} placeholder="Campaign Title (Max Character 60)" maxlength="60" />}
                                                        <span className="errorcolor">{store.errors.campaignsTitle}</span>  
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group custom-form-group">
                                                        <label htmlFor="campaignDetails" className="form-modal-heading">Describe your campaign to our influencers</label>
														{store.formvalue.campaignDetails && store.formvalue.campaignDetails !== 'NA' ? 
                                                        <textarea className="form-control" name="campaignDetails" rows="5" id="campaignDetails" value={store.formvalue.campaignDetails} onChange={store.handleChange} placeholder="Enter Campaign Details"></textarea>
														:<textarea className="form-control" name="campaignDetails" rows="5" id="campaignDetails" value="" onChange={store.handleChange} placeholder="Enter Campaign Details"></textarea>}
                                                        <span className="errorcolor">{store.errors.campaignDetails}</span>  
                                                    </div>
                                                </div>
												{store.campaignId ?
													<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
														<div className="custom-checkbox form-group custom-form-group">
															<label className="create-form-label" htmlFor="gender">Type of Campaign</label> 
															<div className="d-inline mr-3">
																<input type="radio"  id="paid" name="campaignType" readonly checked={store.formvalue.campaignType === 'paid'} value="paid" />
																<label htmlFor="paid" className="radio-label" >Paid Campaign</label>
															</div>
															<div className="d-inline mr-3">
																<input type="radio"  id="sponsored" name="campaignType" value="sponsored" readonly  checked={store.formvalue.campaignType === 'sponsored'}  />
																<label htmlFor="sponsored" className="radio-label">Sponsored</label>
															</div>
															<div className="d-inline mr-3">
																<input type="radio"  id="shoutout" name="campaignType" value="shoutout" readonly  checked={store.formvalue.campaignType === 'shoutout'}  />
																<label htmlFor="shoutout" className="radio-label">Shoutout Exchange</label>
															</div>
														</div>
													</div>
												:
													 <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
														<div className="custom-checkbox form-group custom-form-group">
															<label className="create-form-label" htmlFor="gender">Type of Campaign</label> 
															<div className="d-inline mr-3">
																<input type="radio"  id="paid" name="campaignType" defaultChecked checked={store.formvalue.campaignType === 'paid'} value="paid" onChange={store.handleChange}  />
																<label htmlFor="paid" className="radio-label" >Paid Campaign</label>
															</div>
															<div className="d-inline mr-3">
																<input type="radio"  id="sponsored" name="campaignType" value="sponsored" checked={store.formvalue.campaignType === 'sponsored'} onChange={store.handleChange} />
																<label htmlFor="sponsored" className="radio-label">Sponsored</label>
															</div>
															<div className="d-inline mr-3">
																<input type="radio"  id="shoutout" name="campaignType" value="shoutout" checked={store.formvalue.campaignType === 'shoutout'} onChange={store.handleChange} />
																<label htmlFor="shoutout" className="radio-label">Shoutout Exchange</label>
															</div>
														</div>
													</div>
												}
												{store.formvalue.campaignType == 'paid' &&
                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group custom-form-group">
                                                        <label htmlFor="campaignAmount">Campaign's budget</label>
														<div className="campaign-amount col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
															<div className="campaign-price-wpr">
																<input type="text"                                                                      
																   className="form-control campaign-price"
																   name="campaignAmount"    
																   value={store.formvalue.campaignAmount}
																   onChange={store.handleChangePrice}                                                         
																   placeholder="Example: 1000"/>
																<span className="usd-text">USD</span>
															</div>
														</div>
                                                        <span className="errorcolor">{store.errors.campaignAmount}</span>       
                                                    </div>
                                                </div>}
												{store.formvalue.campaignType == 'sponsored' &&
                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group custom-form-group">
                                                        <label htmlFor="campaignAmount">Products Value</label>
														<div className="campaign-amount col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
															<div className="campaign-price-wpr">
																<input type="text"                                                                      
																   className="form-control campaign-price"
																   name="campaignAmount"    
																   value={store.formvalue.campaignAmount}
																   onChange={store.handleChangePrice}                                                         
																   placeholder="USD $00.0"/>
																<span className="usd-text">USD</span>
															</div>
														</div>
                                                        <span className="errorcolor">{store.errors.campaignAmount}</span>       
                                                    </div>
                                                </div>}
												{store2.instaUsername &&
                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group custom-form-group">
                                                        <label htmlFor="campaignAmount">My Followers Count</label>
														<div className="campaign-amount col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
															<div className="campaign-price-wpr">
																<input type="text"                                                                      
																   className="form-control campaign-price"
																   name="campaignAmount"    
																   value={store2.followers}  
																   disabled
																   placeholder="My Followers Count"/>
															</div>
														</div>    
                                                    </div>
                                                </div>}
												{store.isloader && 
												<div className="camp-icon-container">
													<div className="capmback-wrapper">
                                                        <div className="loaderimg-wrapper">
													        <img className="mb-4" src={`${process.env.PUBLIC_URL}/images/loader-image.svg`} alt="" />
                                                        </div>
													</div>
												</div>}
												<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="custom-form-group custom-date">
                                                        <label htmlFor="finished">When do you need the Campaign ending on?</label>
													</div>
												</div>
												<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group custom-form-group custom-date">
                                                        <div className="campaign-date-wpr">
                                                            <DatePicker
                                                                selected={store.formvalue.endStoryPostDate}
                                                                dateFormat="dd/MM/yyyy" 
																minDate={new Date()}
                                                                className="form-control"
                                                                onChange={store.handleChangeDate}
                                                                name="endStoryPostDate"
                                                                placeholderText="Select Date"
                                                            />
                                                            <span className="fas cal-icon fa-calendar"></span>
                                                        </div>
                                                         <span className="errorcolor camp-date-error">{store.errors.endStoryPostDate}</span>  
                                                    </div>
                                                </div>
												
												<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group custom-select-box">
                                                        <label htmlFor="category">Campaign's Countries</label>
														<div className="campaign-amount col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
															<div className="form-group custom-form-group m-left">
															<MultiSelect
																options={store.countries}
																value={store.formvalue.country}
																onChange={store.onCountryChanged}
																labelledBy={"Select"}
															/>
																<span className="errorcolor">{store.errors.country}</span>  
															</div>
														</div>
                                                    </div>
                                                </div>
												
												<div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group custom-select-box">
                                                        <label htmlFor="category">Which category does your campaign match?</label>
														<div className="campaign-amount col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
															<div className="form-group custom-form-group m-left">
															<MultiSelect
																options={store.categories}
																value={store.formvalue.category}
																onChange={store.onCategoryChanged}
																labelledBy={"Select"}
															/>
																<span className="errorcolor">{store.errors.category}</span>  
															</div>
														</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="custom-checkbox form-group custom-form-group">
                                                        <label className="create-form-label" htmlFor="gender">What influencers would you like offers from?</label> 
														<div className="d-inline mr-3">
														{store.formvalue.lookingInfluencerGender === 'any' || store.formvalue.lookingInfluencerGender === 'Any' ?
                                                            <input type="radio"  id="Any" name="lookingInfluencerGender" checked value="Any" onChange={store.handleChange}  />
														:
															<input type="radio"  id="Any" name="lookingInfluencerGender" value="Any" onChange={store.handleChange}  />
														}
                                                            <label htmlFor="Any" className="radio-label" >Any</label>
                                                        </div>
                                                        <div className="d-inline mr-3">
														{store.formvalue.lookingInfluencerGender === 'male' || store.formvalue.lookingInfluencerGender === 'Male' ?
                                                            <input type="radio"  id="male" name="lookingInfluencerGender" value="male"  checked onChange={store.handleChange}  />
														:
															<input type="radio"  id="male" name="lookingInfluencerGender" value="male" onChange={store.handleChange}  />
														}
                                                            <label htmlFor="male" className="radio-label">Male</label>
                                                        </div>
                                                        <div className="d-inline mr-3">
														{store.formvalue.lookingInfluencerGender === 'female' || store.formvalue.lookingInfluencerGender === 'Female' ?
                                                            <input type="radio"  id="female" name="lookingInfluencerGender" checked value="female" onChange={store.handleChange}  />
														:
															<input type="radio"  id="female" name="lookingInfluencerGender" value="female" onChange={store.handleChange}  />
														}
                                                            <label htmlFor="female" className="radio-label" >Female</label>
                                                        </div>
                                                        <div className="d-inline mr-3">
														{store.formvalue.lookingInfluencerGender === 'others' || store.formvalue.lookingInfluencerGender === 'Others' ?
                                                            <input type="radio"  id="others" name="lookingInfluencerGender" checked value="Others" onChange={store.handleChange}  />
														:
															<input type="radio"  id="others" name="lookingInfluencerGender" value="Others" onChange={store.handleChange}  />
														}
                                                            <label htmlFor="others" className="radio-label" >Others</label>
                                                        </div>
                                                    </div>
                                                </div>
												<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group custom-select-box">
                                                        <label htmlFor="age">Influencer's Age</label>
                                                        <div className="form-group custom-form-group m-left">
                                                            <select className="form-control" name="age" onChange={store.onAgeChange} >
                                                            <option value="">Select Age</option>
															<option selected={store.formvalue.age === '0-0'} value="0-0">Any</option>
                                                            <option selected={store.formvalue.age === '1-12'} value="1-12">Below 13</option>
                                                            <option selected={store.formvalue.age === '13-17'} value="13-17">13-17</option>
                                                            <option selected={store.formvalue.age === '18-24'} value="18-24">18-24</option>
                                                            <option selected={store.formvalue.age === '25-34'} value="25-34">25-34</option>
                                                            <option selected={store.formvalue.age === '35-44'} value="35-44">35-44</option>
                                                            <option selected={store.formvalue.age === '45-100'} value="45-100">Above 44</option>
                                                            </select>
                                                            <span className="errorcolor">{store.errors.age}</span>  
                                                        </div>
                                                    </div>
                                                </div>
												
												<div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div className="custom-checkbox form-group custom-form-group">
                                                        <label htmlFor="campaignFollowers">What should be minimum followers count for influencer applying to this campaign?</label>
														<div className="campaign-price-wpr">
															<select className="form-control" name="campaignFollowers" onChange={store.handleChangeFollowers}>
																<option selected={store.formvalue.campaignFollowers == 0} value="0">Any</option>
																<option selected={store.formvalue.campaignFollowers == 1000} value="1000">1000</option>
																<option selected={store.formvalue.campaignFollowers == 10000} value="10000">10k</option>
																<option selected={store.formvalue.campaignFollowers == 100000} value="100000">100k</option>
															</select>
                                                        </div>
                                                        <span className="errorcolor">{store.errors.campaignFollowers}</span>       
                                                    </div>
                                                </div>

                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 drag-drop custom-form-group">
                                                    <label className="create-form-label" htmlFor="campaignsTitle">Campaign Image</label>
                                                   
                                                    <div className="edit-right text-right">
								                    	<div className="img-container">		
                                                            <div className="form-control col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 image-upload">	
                                                                <label htmlFor="camp-image-input" className="uploadimg-label image-size">
                                                                    <img src={`${process.env.PUBLIC_URL}/images/addimage.svg`} className="uploadimg" alt="" /> 
                                                                </label>
                                                                
																<FileInputComponent
																  inputId="camp-image-input"
																  labelStyle={{display:'none'}}
																  imagePreview={false}
																  multiple={true}
																  callbackFunction={(file_arr)=>{store.getFiles(file_arr)}}
																  accept="image/*" 
																/>
                                                            </div>
                                                        </div>
                                                       <span  className="campaignimage">
													   {store.preloader? <>
														{store.formvalue.cfiles.length !== 0 ? store.formvalue.cfiles.map((img,index) =>
														<div className="campaignimage-wrapper">
                                                            <img style={{width:'100%'}} src={img} key={index} />	
																
                                                            <div onClick={()=>store.deleteImage(img)} className="delete-img-icon">
                                                                <img src={`${process.env.PUBLIC_URL}/images/cross-black.svg`} className="closeinpput" alt="" />
                                                            </div>
														</div>
                                                       ):null}
													   
													   {store.imageLoader.map((skelrow,index) => (
														<div className="campaignimage-wrapper" style={{margin: '8px 10px 0px 0px'}}>
                                                            <Skeleton width={160} height={160} />	
														</div>
														))}
													   
														</> : <>
                                                       {store.formvalue.cfiles.length !== 0 ? store.formvalue.cfiles.map((img,index) =>
                                                       <div className="campaignimage-wrapper">
                                                            <img style={{width:'100%'}} src={img} key={index} />	
																
                                                            <div onClick={()=>store.deleteImage(img)} className="delete-img-icon">
                                                                <img src={`${process.env.PUBLIC_URL}/images/cross-black.svg`} className="closeinpput" alt="" />
                                                            </div>
                                                       </div>
                                                       ):null}
													   </> }
                                                       </span> 	
								                       </div>
                                                       <span className="errorcolor">{store.errors.image}</span> 
                                                </div>
                                            </div>
                                        </div>

                                        <div className="footer-button-group row add-camp-footer-btn">
                                            <div className="btn-left-wpr col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6 ">
                                                <button onClick={store.openModal} type="button" className="btn cancel-btn btn-secondary mr-1">Cancel</button>
												{store.campaignId ? null : <>
													{(store.formvalue.campaignType || store.formvalue.campaignAmount || store.formvalue.category.length !== 0 || store.formvalue.age  || store.formvalue.cfiles.length !== 0 || store.formvalue.campaignsTitle || store.formvalue.campaignDetails) &&
														<button onClick={store.openDiscardModal} type="button" className="btn discard-btn btn-outline-secondary">Discard</button>
													}
													</>
												}	
                                            </div>
                                            <div className="btn-right-wpr col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6 ">
                                               { store.savnextbtn && <button id="saveNextBtn" type="button" onClick={store.showNextTab} className="btn btn-primary">Save and Next<i className="fas ml-2 fa-angle-double-right"></i></button>}
                                            </div>
                                        </div>
                                      {/*  </form> */}
                                </div>

                                <div className={store.campaignsDraftPopup}>
                                                <h1>Draft</h1>
                                            </div>

                                {/* Preview Section */}
                                <div className={store.CampaignsPreview} id="CampaignsPreview">
                                    <h2 className="prev-heading"> Preview</h2>
                                    <p className="prev-sub-heading">This campaign request will be shown to the most suitable influencers.</p>
                                    <div className="image-preview mb-2 campaignimage">
									{store.formvalue.cfiles.length !== 0 ? store.formvalue.cfiles.map((img,index) =>
										<img style={{width:'100%'}} src={img} key={index} />	
									):null} 
                                    </div>
                                    <h3 className="prev-title">Campaign Title</h3>
                                     <p className="prev-text one-line-ellipsis mb-4">{store.formvalue.campaignsTitle}</p>
                                    <h3 className="prev-title">Campaign Details</h3>
                                    <p className="prev-text mb-4 createcampaign-moreless">
										<ShowMoreText
										lines={3}
										more='Show more'
										less='Show less'
										anchorClass=''
										onClick={this.executeOnClick}
										expanded={false}
										width={900}
									>
									{store.formvalue.campaignDetails}
									</ShowMoreText>
									</p>
                                    <div className="row">
										{store.formvalue.campaignType &&
										<div className="col-xl-4 col-lg-4">
											<h3 className="prev-title">Campaign Type</h3>
											<p className="prev-text mb-4">{store.formvalue.campaignType == 'paid' ? 'Paid' : store.formvalue.campaignType == 'sponsored' ? 'Sponsored' : 'Shoutout Exchange'}</p>
										</div>}
										{store.formvalue.campaignType == 'paid' &&
                                        <div className="col-xl-4 col-lg-4">
                                            <h3 className="prev-title">Campaign Price</h3>
											<p style={{display: 'flex'}} className="prev-text  mb-4">$<NumberFormat value={store.formvalue.campaignAmount} displayType={'text'} thousandSeparator={true} /></p>
										</div>}
										{store.formvalue.campaignType == 'sponsored' &&
                                        <div className="col-xl-4 col-lg-4">
                                            <h3 className="prev-title">Products Value</h3>
											<p style={{display: 'flex'}} className="prev-text  mb-4">$<NumberFormat value={store.formvalue.campaignAmount} displayType={'text'} thousandSeparator={true} /></p>
										</div>}
										<div className="col-xl-4 col-lg-4">
                                            <h3 className="prev-title">Campaign Ending On</h3>
                                            <p className="prev-text mb-4">{Moment(store.formvalue.endStoryPostDate).format('DD MMM YYYY')}</p>
                                        </div>
										<div className="col-xl-4 col-lg-4">
											<h3 className="prev-title">Preferred Influencer's Gender</h3>
											<p className="prev-text mb-4">{store.formvalue.lookingInfluencerGender && store.formvalue.lookingInfluencerGender.charAt(0).toUpperCase() + store.formvalue.lookingInfluencerGender.slice(1)}</p>
										</div>
										{store.formvalue.age &&
										<div className="col-xl-4 col-lg-4">
											<h3 className="prev-title">Influencer's Age</h3>
											<p className="prev-text mb-4">{store.formvalue.age == '0-0' ? 'Any' : store.formvalue.age == '1-12' ? 'Below 13' : store.formvalue.age == '45-100' ? 'Above 44' : store.formvalue.age}</p>
										</div>}
										{store.formvalue.campaignFollowers > 0 ?
										<div className="col-xl-4 col-lg-4">
											<h3 className="prev-title">Minimum Followers</h3>
											<p className="prev-text mb-4 minimumpreview"><NumericLabel params={optionnumberformat}>{store.formvalue.campaignFollowers}</NumericLabel></p>
										</div>
										:
										<div className="col-xl-4 col-lg-4">
											<h3 className="prev-title">Minimum Followers</h3>
											<p className="prev-text mb-4 minimumpreview">Any</p>
										</div>
										}
											
                                    </div>
									
									{store.formvalue.country.length ? <h3 className="prev-title">Country or Region</h3> : null}
                                    <div className="hashtag-list mt-3 mb-3"> 
									{store.formvalue.country.length !==0 ? <>
									 {store.formvalue.country.length == store.countryCount ? <span>All</span> :
                                       store.formvalue.country.map((item,index) =>
										<span key={index}>{item.label} </span> 														
									  ) 
									 }
									</>:null}
                                    </div>
									
									{store.formvalue.category.length ? <h3 className="prev-title">Category</h3> : null}
                                    <div className="hashtag-list mt-3 mb-3">   
                                      {store.formvalue.category? store.formvalue.category.map((item,index) =>
										<span key={index}>{item.value && item.value.charAt(0).toUpperCase() + item.value.slice(1)} </span> 														
									  ):null}
                                   
                                    </div>
                                   {store.formvalue.tags.length !== 0 ? <h3 className="prev-title">Hashtag</h3> : null}
                                    <div className="hashtag-list mt-3">
                                      {store.formvalue.taghtml}   
                                      {store.formvalue.tags.length !== 0 ? store.formvalue.tags.map((item,index) =>
										<span key={index}>#{item.displayValue} </span> 														
										):null}
                                   
                                    </div>

                                    <div className="footer-button-group row add-camp-footer-btn">  
                                        <div className="btn-left-wpr col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6 ">
                                            <button onClick={store.openCreateCampPopup} className="btn cancel-btn edit-camp-btn btn-secondary mr-1">
                                            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <g transform="translate(-3.925 -7.226)">
                                                    <g transform="translate(6.385 7.686)">
                                                        <path d="M5.617,19.159,5.6,22.522a.73.73,0,0,0,.207.517.691.691,0,0,0,.5.207l3.345-.017a.691.691,0,0,0,.5-.207L21.7,11.469a.729.729,0,0,0,0-1.017L18.394,7.107a.729.729,0,0,0-1.017,0l-2.31,2.328L5.824,18.659a.768.768,0,0,0-.207.5ZM17.894,8.624l2.328,2.328-1.31,1.31L16.583,9.935ZM7.048,19.47l8.518-8.518,2.328,2.328-8.518,8.5L7.031,21.8Z" transform="translate(-8.06 -7.361)" fill="#6B6A77"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            Edit Campaign</button>
                                            {store.campaignId ? null : <button onClick={store.openDiscardModal} className="btn discard-btn  btn-outline-secondary">Discard</button>}
                                        </div>
                                        <div className="btn-right-wpr col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6 ">
                                            {<button onClick={store.postCampaings} disabled={store.oneClick} className="btn postcampaign-btn btn-primary">{store.campaignId ? 'Update Campaign' :'Post Campaign'}<i className="fas ml-2 fa-angle-double-right"></i></button>}
                                           {/* <button type="submit" className="btn btn-primary">Post Campaign<i className="fas ml-2 fa-angle-double-right"></i></button> */}
                                        </div>
                                    </div>
                                </div>
                             
                                {/* Collaboration Addess Successfully */}

                                <div className={store.CampAddedSuccessfull} id="CampAddedSuccessfull">
                                    <div className="inner-camp-added-success ">
                                        <div className="camp-success text-center ">
                                            <img className="mb-4" src={`${process.env.PUBLIC_URL}/images/icons/success.png`} alt="" /> 
                                            <h2>Campaign Submitted</h2>
                                            <p>Your campaign has been submitted.</p>
                                            <p>Once approved, you will start receiving offers from interested influencers within minutes.</p>
                                            <p className="allow-text">Allow 48 hours for campaign approval.</p>
                                           {/* <Link to="/my-campaigns" onClick={this.closeCreateCampPopup}><button className="btn btn-primary">My Campaigns<i className="fas ml-2 fa-angle-double-right"></i></button></Link>*/}
                                            <Link  onClick={store.closeCreateCampaignsPopup}><button className="btn btn-primary">My campaign<i className="fas ml-2 fa-angle-double-right"></i></button></Link>
                                          
                                        </div>
                                    </div>
                                </div>

                                {/* Draft Camppaigns */}
                                <div className={store.draftCampaigns} id="draftCampaigns">
                                    <h2 className="page-heading">Create Campaign</h2>
                                    <div className="no-image d-flex align-items-center justify-content-center">
                                        <img src={`${process.env.PUBLIC_URL}/images/no-img.svg`} alt="" /> 
                                    </div>
                                    <h3>Six Smart Ways to Write a Powerful Fundraiser Title - GoFundMe</h3>
                                    <p className="text-center">Read carefully and move on</p>

                                    <div className="text-center">
                                        <button className="btn btn-primary">Continue with the drafted Campaign<i className="fas ml-2 fa-angle-double-right"></i></button>
                                    </div>
                                    <p className="mt-3 mb-3 text-center">or</p>
                                    <div className="text-center">
                                    <button className="btn btn-primary">Discard this draft and create new<i className="fas ml-2 fa-angle-double-right"></i></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Discard POP UP */}

                    <Popup
                     open={store.discardOpen}
                     closeOnDocumentClick
                     onClose={store.closeDiscardModal}
                    >
                    <div className="custom-popup text-center">
                       <div className="popup-details">
                            <img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
                            <h2 className="popup-heading">Exit and Discard ?</h2>
                            <p className="popup-details">Are you sure you want to exit and discard this campaign brief. All data in this brief will be lost.</p>
                            <button  onClick={store.draftCampaings} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
                            <button onClick={store.closeCreateCampPopupDiscard} className="btn btn-primary closeinpput">Discard</button>
                        </div>
                        
                    </div>
                    </Popup>

                    {/* Close POP UP */}
                    <Popup
                     open={store.open}
                     closeOnDocumentClick
                     onClose={store.closeModal}
                    >
                    <div className="custom-popup text-center">
                       <div className="popup-details">
                            <img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
                            <p className="popup-details mb-4">Are you sure you want to Cancel?</p>
                            <button onClick={store.closeModal} className="btn cancel-btn btn-secondary mr-3">Cancel</button> 
                            <button onClick={store.closeCreateCampPopup} className="btn btn-primary closeinpput">Ok</button>
                        </div>
                        
                    </div>
                    </Popup>
                        
                    {/* Draft POP UP */}

                     <Popup
                     open={store.draftOpen}
                     closeOnDocumentClick
                     onClose={store.closeDraftModal}
                    >
                    <div className="custom-popup text-center">
                       <div className="popup-details">
                            <img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
                            <h2 className="popup-heading">Campaign Save in Draft Successfully</h2>
                            <button onClick={store.closeDraftModal} className="btn btn-primary">Ok</button>
                        </div>
                        
                    </div>
                    </Popup>

                </section>
            </>
        )
    }
    
}
export default inject('CreatecampaignListStore','UserProfileStore') (observer(CreateCampaigns))