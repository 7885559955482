import {action, observable, decorate} from 'mobx';
import { transactionCountResult } from '../api/TransactionCountApi';
import { transactionListResult } from '../api/TransactionListApi';
import { transactionListNewResult } from '../api/TransactionListNewApi';
import moment from 'moment';

class TransactionHistoryStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	transaction = [];
	offset = 0;
	totalCount = '';
	preloader = false;
	transactionEarn = [];
	offsetEarn = 0;
	totalCountEarn = '';
	preloaderEarn = false;
	transactionSpent = [];
	offsetSpent = 0;
	totalCountSpent = '';
	preloaderSpent = false;
	limit = 6;
	valuePrice = {min:0, max:10000};
	
	
	transactionListFetch = () => {
		if(this.auth){
			this.preloaderFetch(true);
			let userId = this.userId;
			let param1 = {include:["products","campaigns"],where:{or: [{camapignOwnerId:userId},{payeeOwnerId:userId},{productOwnerId:userId}],amount:{between: [this.valuePrice.min,this.valuePrice.max]},OwnerId:userId},order:["id DESC"],offset:this.offset,limit:this.limit}
			
			let count1 = {or: [{camapignOwnerId:userId},{payeeOwnerId:userId},{productOwnerId:userId}],amount:{between: [this.valuePrice.min,this.valuePrice.max]},OwnerId:this.userId}

			transactionListResult(JSON.stringify(param1))
				.then(res => {
					transactionCountResult(JSON.stringify(count1))
						.then(res1 => {
							this.transactionFetch(res.data);
							this.pageCountFetch(Math.ceil(res1.data.count / this.limit));
							this.totalCountFetch(res1.data.count);
							this.preloaderFetch(false);
						}).catch(error=>console.log(error))
				}).catch(error=>console.log(error))
		}
	}
	
	transactionEarnListFetch = () => {
		if(this.auth){
			this.preloaderEarnFetch(true);
			let userId = this.userId;
			let param1 = 'ownerId='+userId+'&txnType=credit&offset='+this.offsetEarn+'&limit='+this.limit

			transactionListNewResult(param1)
				.then(res => {
					this.transactionEarnFetch(res.data.transactionData);
					this.pageCountEarnFetch(Math.ceil(res.data.count / this.limit));
					this.totalCountEarnFetch(res.data.count);
					this.preloaderEarnFetch(false);
				}).catch(error=>console.log(error))
		}
	}
	
	transactionSpentListFetch = () => {
		if(this.auth){
			this.preloaderSpentFetch(true);
			let userId = this.userId;
			let param1 = 'ownerId='+userId+'&txnType=debit&offset='+this.offsetSpent+'&limit='+this.limit

			transactionListNewResult(param1)
				.then(res => {
					this.transactionSpentFetch(res.data.transactionData);
					this.pageCountSpentFetch(Math.ceil(res.data.count / this.limit));
					this.totalCountSpentFetch(res.data.count);
					this.preloaderSpentFetch(false);
				}).catch(error=>console.log(error))
		}
	}
	
	transactionFetch	= (data) => {this.transaction = data;}
	offsetFetch 		= (data) => {this.offset = data;}
	pageCountFetch 		= (data) => {this.pageCount = data;}
	totalCountFetch		= (data) => {this.totalCount = data;}
	preloaderFetch		= (data) => {this.preloader = data;}
	
	transactionEarnFetch	= (data) => {this.transactionEarn = data;}
	offsetEarnFetch 		= (data) => {this.offsetEarn = data;}
	pageCountEarnFetch 		= (data) => {this.pageCountEarn = data;}
	totalCountEarnFetch		= (data) => {this.totalCountEarn = data;}
	preloaderEarnFetch		= (data) => {this.preloaderEarn = data;}
	
	transactionSpentFetch	= (data) => {this.transactionSpent = data;}
	offsetSpentFetch 		= (data) => {this.offsetSpent = data;}
	pageCountSpentFetch 	= (data) => {this.pageCountSpent = data;}
	totalCountSpentFetch	= (data) => {this.totalCountSpent = data;}
	preloaderSpentFetch		= (data) => {this.preloaderSpent = data;}
	
	valuePriceFetch 	= (data) => {this.valuePrice = data;}

}

decorate(TransactionHistoryStore, {
	userId:observable,auth:observable,transaction: observable,offset: observable,totalCount: observable,pageCount:observable,limit: observable,preloader:observable,transactionEarn: observable,offsetEarn: observable,totalCountEarn: observable,pageCountEarn:observable,preloaderEarn:observable,transactionSpent: observable,offsetSpent: observable,totalCountSpent: observable,pageCountSpent:observable,preloaderSpent:observable,valuePrice:observable,offsetFetch: action,totalCountFetch: action,preloaderFetch:action,pageCountFetch: action, valuePriceFetch:action,transactionFetch:action,offsetEarnFetch: action,totalCountEarnFetch: action,preloaderEarnFetch:action,pageCountEarnFetch: action,transactionEarnFetch:action,offsetSpentFetch: action,totalCountSpentFetch: action,preloaderSpentFetch:action,pageCountSpentFetch: action,transactionSpentFetch:action,transactionListFetch:action,transactionEarnListFetch:action
});

export default TransactionHistoryStore;