import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from "react-hook-form";
import { login } from "../stores/LoginStore";

const Login = observer((props) => {
	
	const store = useContext(login);
	const { handleSubmit, register, errors } = useForm({});

	useEffect(() => {
		const interval = setInterval(() => {
			store.settoEmailNot('');
		}, 5000);
		return () => clearInterval(interval);
	}, []);
	
	return (
		<React.Fragment>
			{/* LOGIN MODAL START */}
			<div className="modal fade custom-modal login-modal" id="loginModal">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-body">
							<h2 className="modal-heading">Log In Your Account</h2>
							<span className="errorcolor">{store.toEmailNot}</span>
							<form onSubmit={handleSubmit(store.onSubmit)}> 
								<div className="custom-form email-input">      
									<input type="text" name="email" ref={register({
									  required: 'Email address is required',
									  pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: "Invalid email address"
									  }
									})} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<label htmlFor="EmailAddress">Email Address</label>
									<span className="errorcolor" >{errors.email && errors.email.message}</span>
								</div>
								
								<div className="custom-form password-input">      
									<input type="password" name="password" autoComplete="false" ref={register({
									  required: 'Password is required'
									})} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<label htmlFor="password">Enter password</label>
									<span className="errorcolor">{errors.password && errors.password.message}</span>
								</div>
								
								<Link to="#" className="forgot-password" data-toggle="modal" data-target="#forgotModal"> Forgot Password?</Link>
								<button type="submit" id="validlogin" className="btn btn-primary w-100 login-btn">Log in {store.isLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
							</form>
							<p className="login-with text-center">Login With Social Account</p>
							<div className="social-btn-wpr">
								<Link to="#" className="facebook-btn"><span className="fa fa-facebook"></span>facebook</Link>
								<Link to="#" className="insta-btn"><span className="fa fa-instagram"></span>Instagram</Link>
							</div>
							<p className="form-bottom-text text-center">Need a KOLI Account? <Link to="#" className="sign-up-link" data-toggle="modal" data-target="#signUpModal">Create an account</Link></p>
						</div>
					</div>
				</div>
			</div>
			{/* LOGIN MODAL END */}
		</React.Fragment>
	);
});

export default Login;