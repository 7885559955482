import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { InstagramPost } from '../api/InstagrampostApi';
import { usernameCheckResult } from '../api/UsernameCheckApi';
import { profileUpdateResult } from '../api/ProfileupdateApi';
import { InstagramToken } from '../api/InstagramTokenApi';
import { InstagramData } from '../api/InstagramDataApi';
import { baseUrl } from '../baseUrl';
import { APP_ID, APP_SECRET } from '../instaConfig';

class UserProfileStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	auth = JSON.parse(sessionStorage.getItem("user"));
	userId = JSON.parse(sessionStorage.getItem("userId"));
	instaPosts = '';
	instaPostCount = '';
	instaFollowersCount = '';
	notFound = false;
	postPrivate = false;
	profile = '';
	instaUsername = '';
	preloader = true;
	required = '';
	instaLoader = false;
	instaPop = false;
	
	profileFetchData = () => {
		if(this.auth){
			this.preloaderFetch(true)
			this.instaPostsFetch('')
			this.instaPostCountFetch('')
			this.instaFollowersCountFetch('')
			let param = {where:{ownerId:this.userId}}
			usernameCheckResult(JSON.stringify(param))
					.then(res1 => {
						if(res1.data.length !== 0){
							let result1 = res1.data[0]
							this.profileFetch(res1.data[0])
							this.instaUsernameFetch(res1.data[0].instaUsername)
							if(this.instaUsername){
								InstagramPost(this.instaUsername)
									.then(res => {
										if(res.data.graphql){
											let result = res.data.graphql.user;
											if(result.edge_owner_to_timeline_media.edges.length > 0){
												this.instaPostsFetch(result.edge_owner_to_timeline_media.edges);
												this.postPrivateFetch(false);
												this.notFoundFetch(false)
											}else{
												this.postPrivateFetch(false);
												this.notFoundFetch(false)
											}
											let instaUser1 = {
												followers: result.edge_followed_by.count,
												totalPosts: result.edge_owner_to_timeline_media.count
											}

											profileUpdateResult(this.userId,instaUser1)
												.then(res5 => {
													let resultprofile = res5.data.message.profile;
													this.instaFollowersCountFetch(resultprofile.followers);
													this.instaPostCountFetch(resultprofile.totalPosts);
													this.preloaderFetch(false)
												}).catch(error=>console.log(error))
										}else{
											this.instaFollowersCountFetch(result1.followers);
											this.instaPostCountFetch(result1.totalPosts);
											this.notFoundFetch(true)
											this.preloaderFetch(false)
										}
										
									}).catch(error=>[this.notFoundFetch(true),this.preloaderFetch(false),this.postPrivateFetch(false),this.instaFollowersCountFetch(result1.followers),this.instaPostCountFetch(result1.totalPosts)]) 
							}else{
								this.notFoundFetch(true)
								this.preloaderFetch(false)
								this.postPrivateFetch(false)
							}
						}else{
							this.preloaderFetch(false)
						}
					}).catch(error=>console.log(error)) 
			
		}
	}
	
	addAccountInsta = (event) => {
		event.preventDefault();
		this.requiredFetch('')
		this.instaLoaderFetch(true)
		let instaUsername = event.target.instaUsername.value;
		if(instaUsername){
			InstagramPost(instaUsername)
				.then(res => {
					if(res){
						let instaUser = {
							instaUsername: instaUsername,
							followers: res.data.graphql.user.edge_followed_by.count 
						}

						profileUpdateResult(this.userId,instaUser)
							.then(res1 => {
								if(res1.data.message.profile.instaUsername){
									let param = {where:{ownerId:this.userId}}
									usernameCheckResult(JSON.stringify(param))
										.then(res2 => {
											if(res2.data.length !== 0){
												this.profileFetch(res2.data[0])
												this.instaUsernameFetch(res2.data[0].instaUsername)
												if(this.instaUsername){
													InstagramPost(this.instaUsername)
														.then(res3 => {
															let result = res3.data.graphql.user;
															if(result.edge_owner_to_timeline_media.edges.length > 0){
																this.instaPostsFetch(result.edge_owner_to_timeline_media.edges);
																this.postPrivateFetch(false)
																this.notFoundFetch(false)
																this.instaPopFetch(false)
															}else{
																this.postPrivateFetch(false)
																this.notFoundFetch(false)
																this.instaPopFetch(false)
															}
															this.instaPostCountFetch(result.edge_owner_to_timeline_media.count);
															this.instaFollowersCountFetch(result.edge_followed_by.count);
															this.preloaderFetch(false)
														}).catch(error=>[this.notFoundFetch(true),this.instaPopFetch(false),this.preloaderFetch(false),this.postPrivateFetch(false)]) 
												}else{
													this.notFoundFetch(true)
													this.preloaderFetch(false)
													this.postPrivateFetch(false)
													this.instaPopFetch(false)
												}
											}else{
												this.preloaderFetch(false)
											}
										}).catch(error=>console.log(error)) 
								}
							}).catch(error=>[this.requiredFetch('Please check if you have entered the correct details or try again.'),this.instaPopFetch(true),this.instaLoaderFetch(false)])
					}else{
						this.requiredFetch('Please check if you have entered the correct details or try again.');
						this.instaLoaderFetch(false)
						this.instaPopFetch(true)
					}
				}).catch(error=>[this.requiredFetch('Please check if you have entered the correct details or try again.'),this.instaLoaderFetch(false),this.instaPopFetch(true)])
		}else{
			this.requiredFetch('Please enter username.')
			this.instaLoaderFetch(false)
			this.instaPopFetch(true)
		}
	}
	
	responseInstagram = (response) => {
		let appId = APP_ID
		let appSecret = APP_SECRET
		let redirectUrl = baseUrl
		let code = response

		let formData = new FormData();
		formData.append('client_id', appId);
		formData.append('client_secret', appSecret);
		formData.append('grant_type', 'authorization_code');
		formData.append('redirect_uri', redirectUrl);
		formData.append('code', code);
        
		InstagramToken(formData)
			.then(res => {
			let access_token = res.data.access_token
			InstagramData(access_token)
				.then(res1 => {
					let instaUser = {
						instaUsername: res1.data.username
					}

					profileUpdateResult(this.userId,instaUser)
						.then(res2 => {
							this.instaUsernameFetch(res2.data.message.profile.instaUsername)
							let param = {where:{ownerId:this.userId}}
							usernameCheckResult(JSON.stringify(param))
								.then(res3 => {
									if(res3.data.length !== 0){
										let result1 = res3.data[0]
										this.instaUsernameFetch(res3.data[0].instaUsername)
										if(this.instaUsername){
											InstagramPost(this.instaUsername)
												.then(res4 => {
													if(res4.data.graphql){
														let result = res4.data.graphql.user;
														if(result.edge_owner_to_timeline_media.edges.length > 0){
															this.instaPostsFetch(result.edge_owner_to_timeline_media.edges);
															this.postPrivateFetch(false)
															this.notFoundFetch(false)
														}else{
															this.postPrivateFetch(false)
															this.notFoundFetch(false)
														}
														let instaUser1 = {
															followers: result.edge_followed_by.count,
															totalPosts: result.edge_owner_to_timeline_media.count
														}

														profileUpdateResult(this.userId,instaUser1)
															.then(res5 => {
																//
															}).catch(error=>console.log(error))
														this.instaPostCountFetch(result.edge_owner_to_timeline_media.count);
														this.instaFollowersCountFetch(result.edge_followed_by.count);
													}else{
														this.instaFollowersCountFetch(result1.followers);
														this.instaPostCountFetch(result1.totalPosts);
														this.notFoundFetch(false)
														this.preloaderFetch(false)
													}
												}).catch(error=>[this.notFoundFetch(false),this.postPrivateFetch(false)]) 
										}else{
											this.notFoundFetch(true)
											this.postPrivateFetch(false)
										}
									}
								}).catch(error=>console.log(error))
						}).catch(error=>console.log(error))
				}).catch(error=>console.log(error))
			}).catch(error=>console.log(error))
	}
	
	instaPostsFetch = (data) => {
		this.instaPosts = data;
	}
	instaPostCountFetch = (data) => {
		this.instaPostCount = data;
	}
	instaFollowersCountFetch = (data) => {
		this.instaFollowersCount = data;
	}
	notFoundFetch = (data) => {
		this.notFound = data;
	}
	postPrivateFetch = (data) => {
		this.postPrivate = data;
	}
	profileFetch = (data) => {
		this.profile = data;
	}
	instaUsernameFetch = (data) => {
		this.instaUsername = data;
	}
	preloaderFetch  = (data) =>{
		this.preloader = data;
	}
	requiredFetch 		= (data) => { this.required = data; }
	instaLoaderFetch 	= (data) => { this.instaLoader = data; }
	instaPopFetch 		= (data) => { 
		this.instaPop = data; 
		this.instaLoaderFetch(false)
	}
}

decorate(UserProfileStore, {
	auth: observable,
	userId: observable,
	instaPop: observable,
	required: observable,
	instaLoader: observable,
	instaPosts: observable,
	instaPostCount: observable,
	instaFollowersCount: observable,
	notFound: observable,
	profile: observable,
	instaUsername: observable,
	preloader: observable,
	postPrivate: observable,
	instaPostsFetch: action,
	instaPostCountFetch: action,
	instaFollowersCountFetch: action,
	notFoundFetch: action,
	profileFetch: action,
	instaUsernameFetch: action,
	preloaderFetch: action,
	postPrivateFetch: action,
	instaLoaderFetch: action,
	requiredFetch: action,
	addAccountInsta: action,
	instaPopFetch: action,
});

export default UserProfileStore;