import {action, observable, decorate} from 'mobx';
import { productCountResult } from '../api/ProductCountApi';
import { productListResult } from '../api/ProductListApi';
import { productListNewResult } from '../api/ProductListNewApi';
import { campaignCountResult } from '../api/CampaignCountApi';
import { campaignListResult } from '../api/CampaignListApi';
import { categoryResult } from '../api/CampaignCategoryApi';
import { countriesResult } from '../api/CountryApi';
import moment from 'moment';

class ProductListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	product = [];
	offset = 0;
	totalCount = '';
	limit = 12;
	categoryFilter = [];
	category = [];
	productListFilter = [];
	checkSearch = false;
	totalCountFilter = '';
	valuePrice = {min:0, max:10000};
	priceSort = '';
	preloader = false;
	search = '';
	searchKeyword = false;
	currentDate = moment().format('YYYY-MM-DD');
	selected = 0;
	checkedItems = {};
	required = false;
	
	productListFetch = () => {
		this.checkSearchFetch(false);
		
		let param = 'offset='+this.offset+'&limit='+this.limit

		productListNewResult(param)
			.then(res => {
				console.log(res)
				this.productList(res.data.products);
				this.pageCountFetch(Math.ceil(res.data.count / this.limit));
				this.totalCountFetch(res.data.count);
				this.preloaderFetch(false);
			}).catch(error=>console.log(error))
	}

	handleSubmit = (event) => {
		
		this.preloaderFetch(true);
		this.checkSearchFetch(true);
		let param;
		let count;
		let priceOrder;
		let search;
		let checkCountry;
		let checkSearch;
		let checkGender;
		let checkCategory;
		//let checkCategorySorted;
		
		let pattern =  '%25'+this.search+'%25'; 
		
		if(this.priceSort === '1'){
			priceOrder = "productAmount ASC";
		}else if(this.priceSort === '2'){
			priceOrder = "productAmount DESC";
		}else{
			priceOrder = ["stockCount DESC","id DESC"];
		}

		if(this.category){
			let scat = [];
			let scatsort = [];
			this.category.map(function(item,index){
				scat[index]='["'+item+'\"]';
				scatsort[index]=item;
			});
			//let sorted = scat.sort()

			if(scat.length > 0){
				checkCategory = {inq:scat}
				//checkCategorySorted = sorted
			}else{
				checkCategory = {neq:null}

			} 
		}else{
			checkCategory = {neq:null}
		}
		
		if(this.search){
			param = {where:{productTitle:{like:pattern},productAmount:{between: [this.valuePrice.min,this.valuePrice.max]},productStatus:1,productCategories:checkCategory},order:priceOrder,offset:this.offset,limit:this.limit}
			
			count = {productTitle:{like:pattern},productAmount:{between: [this.valuePrice.min,this.valuePrice.max]},order:priceOrder,productStatus:1,productCategories:checkCategory}
			
		}else{
			param = {where:{productAmount:{between: [this.valuePrice.min,this.valuePrice.max]},productStatus:1,productCategories:checkCategory},order:priceOrder,offset:this.offset,limit:this.limit}
			
			count = {productAmount:{between: [this.valuePrice.min,this.valuePrice.max]},order:priceOrder,productStatus:1,productCategories:checkCategory}
		}
		
		productListResult(JSON.stringify(param))
			.then(res => {
				productCountResult(JSON.stringify(count))
					.then(res1 => {
						this.productListFilterFetch(res.data);
						this.pageCountFilterFetch(Math.ceil(res1.data.count / this.limit));
						this.totalCountFilterFetch(res1.data.count);
						this.preloaderFetch(false);
					}).catch(error=>console.log(error))
			}).catch(error=>console.log(error))
	}
	
	fetchCategories = () => {
		let param = {where:{categoryStatus:1}}
		categoryResult(JSON.stringify(param))
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.categoryFilterFetch(res.data);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	categoryUnset = (data) => {
		
		if(this.category.indexOf(data) === -1){
			this.category.push(data)
		}else{
			for( var i = 0; i < this.category.length; i++){ 
				if ( this.category[i] === data) { 
					this.category.splice(i, 1); 
				}
			}
		}
		const isChecked = false;
		const {checkedItems} = this
		checkedItems[data] = isChecked
		this.checkedItemsFetch(checkedItems);
		this.offsetFetch(0);
		this.handleSubmit();
		this.preloaderFetch(true);
	}
	
	changeCategory = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const {checkedItems} = this
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
		if(this.category.indexOf(item) === -1){
			this.category.push(item)
		}else{
			for( var i = 0; i < this.category.length; i++){ 
				if ( this.category[i] === item) { 
					this.category.splice(i, 1); 
				}
			}
		}
		this.offsetFetch(0);
		this.pageCountFilterFetch('');
		this.totalCountFilterFetch('');
		this.handleSubmit();
		this.preloaderFetch(true);
	}
	
	productList	 		= (data) => {this.product = data;}
	offsetFetch 		= (data) => {this.offset = data;}
	pageCountFetch 		= (data) => {this.pageCount = data;}
	totalCountFetch		= (data) => {this.totalCount = data;}
	categoryFilterFetch = (data) => {this.categoryFilter = data;}
	categoryFetch 		= (data) => {this.category = data;}
	
	productListFilterFetch  = (data) => {this.productListFilter = data;}
	pageCountFilterFetch 	= (data) => {this.pageCountFilter = data;}
	totalCountFilterFetch 	= (data) => {this.totalCountFilter = data;}
	checkSearchFetch 		= (data) => {this.checkSearch = data;}
	priceSortFetch 			= (data) => {this.priceSort = data;}
	preloaderFetch 			= (data) => {this.preloader = data;}
	searchFetch 			= (data) => {this.search = data;}
	searchKeywordFetch 		= (data) => {this.searchKeyword = data;}
	valuePriceFetch 		= (data) => {this.valuePrice = data;}
	selectedFetch 			= (data) => {this.selected = data;}
	checkedItemsFetch 		= (data) => { this.checkedItems = data; }
	requiredFetch 			= (data) => { this.required = data; }

}

decorate(ProductListStore, {
	required:observable,checkedItems:observable,selected:observable,product: observable,productListFilter:observable,offset: observable,totalCount: observable,pageCount:observable,limit: observable,categoryFilter: observable,category: observable,totalCountFilter: observable,pageCountFilter: observable,checkSearch: observable,priceSort:observable,preloader:observable,search: observable,searchKeyword:observable,valuePrice:observable,currentDate:observable,productList: action,offsetFetch: action,totalCountFetch: action,categoryFilterFetch: action,categoryFetch: action,handleSubmit: action,productListFilterFetch: action,pageCountFilterFetch: action,totalCountFilterFetch: action,checkSearchFetch: action,priceSortFetch:action,preloaderFetch:action,pageCountFetch: action, searchFetch:action,searchKeywordFetch:action,valuePriceFetch:action,selectedFetch:action,categoryUnset:action,changeCategory:action,checkedItemsFetch:action,requiredFetch:action
});

export default ProductListStore;