let backendHost;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  backendHost = 'https://staging-koli-co.koliapp.com/#/';
} else {
  backendHost = 'https://staging-koli-co.koliapp.com/#/';
}

export const shareUrl = `${backendHost}`;