import React, { useEffect, useContext } from 'react';
import {observer} from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { registerUser } from "../stores/SignupStore";
import ReCAPTCHA from "react-google-recaptcha";



const Signup = observer((props) => {
	
	const store = useContext(registerUser);
	const { handleSubmit, register, errors } = useForm({});
	const myRef = React.createRef()
	
	const onSubmit = (data,e) => {
		if(store.disabled){
			store.setUserAlreadyMsg('Oops, you have to check the recaptcha!')
		}else{
			store.contactFetchData(data,e);
		}
	};
	
	const captchaChange = (value) => {
		if(value){
			store.disabledFetch(false)
		}else{
			store.disabledFetch(true)
		}
	}

	useEffect(() => {
		store.fetchCountries('Country Fetch');
		store.fetchGeoPhoneCode('Fetch Geo PhoneCode');
		const interval = setInterval(() => {
			store.setEmailAlreadyMsg('');
			store.setWentWrong(false);
			store.setUserAlreadyMsg('')
			store.usernameAlreadyMsgFetch(false)
		}, 8000);
		return () => clearInterval(interval);
	}, []);
	
	return (
		<React.Fragment>
			{store.isModalOpen ?
			<div className="modal fade custom-modal email-sent" id="emailSentModal" style={{display:'block',opacity:'1'}}>
				<div className="modal-dialog modal-dialog-centered">
				  <div className="modal-content signup-success-content">
					<div className="modal-body text-center signup-success-body">
						<h2 className="modal-heading">Congratulations!</h2>
						<p>Your account has been created successfully. Please verify your email id to access your account.</p>
						<button className="btn btn-primary log-in-link" onClick={() => store.setIsModalOpen(false)} data-dismiss="modal">Done</button>
					</div>
				  </div>
				</div>
			</div> :
			<div className="modal fade custom-modal signUp-modal" id="signUpModal">
				<div className="modal-dialog modal-dialog-centered">
				  <div className="modal-content">
					<div className="modal-body">
						<h2 className="modal-heading">Create Your Account</h2>
						<p className="errorcolor">{store.wentWrong && 'Something went wrong. Please try again later!'}</p>
						<form onSubmit={handleSubmit(onSubmit)}> 
							<div className="name-wpr clearfix">
								<div className="custom-form  name-left">
									<input type="text" name="first" ref={register({ required: 'First name is required' })} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<label htmlFor="first">First name</label>
									<span className="errorcolor">{errors.first && errors.first.message}</span>
								</div>

								<div className="custom-form name name-right">      
									<input type="text" name="last" ref={register({ required: 'Last name is required' })} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<label htmlFor="last">Last name</label>
									<span className="errorcolor">{errors.last && errors.last.message}</span>
								</div>
							</div>
							<div className="custom-form">      
								<input type="text" autocomplete="off" onBlur={(e)=>store.usernameCheckData(e)} name="username" ref={register({
								  required: 'Username is required',
								})} />
								<span className="highlight"></span>
								<span className="bar"></span>
								<label htmlFor="Username">Username</label>
								<span className="errorcolor">{errors.username && errors.username.message} {store.usernameAlreadyMsg && 'Username already exist'}</span>
							</div>
							<div className="custom-form email-address">      
								<input type="text" name="email" ref={register({
								  required: 'Email address is required',
								  pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Invalid email address"
								  }
								})} />
								<span className="highlight"></span>
								<span className="bar"></span>
								<label htmlFor="EmailAddress">Email Address</label>
								<span className="errorcolor">{errors.email && errors.email.message} {store.emailAlreadyMsg}</span>
							</div>

							<div className="custom-form password">      
								<input type="password" name="password" autoComplete="false" ref={register({
								required: 'Password is required',
								minLength: {
								value: 8,
								message: "Password must have at least 8 characters"
								}
							})} />
								<span className="highlight"></span>
								<span className="bar"></span>
								<label htmlFor="EmailAddress">Password</label>
								<span className="errorcolor">{errors.password && errors.password.message}</span>
							</div>

							<div className="mobile-wpr clearfix">
								<div className="custom-form  mobile-left">
									<select  name="countrycode" ref={register}>
										{store.phoneCode ? store.phoneCode.map((c,index) =>
											<option value={c.slice(1)} selected={store.geoPhoneCode === c} key={index}>{c}</option>
										):null}
									</select>
									<span className="highlight"></span>
									<span className="bar"></span>
									<label htmlFor="Mobile">Mobile</label>
								</div>

								<div className="custom-form mobile mobile-right">      
									<input type="text" maxLength="10" name="mobile" ref={register({
								  required: 'Mobile number is required',
								  pattern: {
									value: /^[0-9]+$/,
									message: "Invalid mobile number"
								  }
								})} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<span className="errorcolor">{errors.mobile && errors.mobile.message}</span>
								</div>
							</div>
							<div className="tc-checkbox">
								<div className="form-check">
									<input type="checkbox" className="valueBlank" className="form-check-input" name="agreed" value="" ref={register({
										required: 'This field is required',
									})} />

									<label className="form-check-label">
										<span className="checkbox-text">
											I have read and agreed to the <Link to="/terms-condition" className="signup-tc">terms and conditions.</Link>
										</span>
									</label>
								</div>
								<span className="errorcolor">{errors.agreed && errors.agreed.message}</span>
							</div>
							<div className="tc-checkbox">
								<div className="reCaptcha-wrapper">
								  <ReCAPTCHA
								  ref={(r) =>
								  store.captcha = r}
								  sitekey="6LdzllMaAAAAALC2h5hxSkQKK_ZF8BDUKTTRy_de"
								  onChange={captchaChange}
								  />
								</div>
								<span className="errorcolor">{store.userAlreadyMsg && store.userAlreadyMsg}</span>								
                            </div>
							<button type="submit" id="validsignup" className="btn btn-primary w-100">Create Account {store.isLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
							
						</form>
						<p className="form-bottom-text text-center">Already have an Account? <Link to="#" className="log-in-link">Log In</Link></p>
					</div>
				  </div>
				</div>
			</div>}
		</React.Fragment>
	);
});

export default Signup;