import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link, Redirect } from 'react-router-dom';
import NumericLabel from 'react-pretty-numbers';
import {observer, inject} from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import EllipsisText from "react-ellipsis-text";
import FileBase64 from 'react-file-base64';
import Popup from "reactjs-popup";
import moment from 'moment';

var optionnumberformat = {
  'justification':'C',
  'percentage':false,
  'precision':0,
  'wholenumber':null,
  'commafy':true,
  'shortFormat':true,
  'shortFormatMinValue': 1000,
  'shortFormatPrecision': 1,
  'title':true,
  'cssClass':['red']
};

class InfluencerProfile extends Component {
	
	constructor(props){
		super(props);
		this.username = this.props.match.params.username
		this.myRef = React.createRef()
	}
	
	componentDidMount = ()=>{
        this.props.InfluencerProfileStore.profileFetchData(this.username);
        this.props.InfluencerProfileStore.profileOwnFetchData('My profile');
        this.props.InfluencerProfileStore.blockSuccessFetch(false);
        this.props.InfluencerProfileStore.blockPopFetch(false);
		window.scrollTo(0,this.myRef.current.offsetTop);
    }
	
	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
	
	linkConnectClose = () =>{
		sessionStorage.removeItem('instalink')
		this.props.InfluencerProfileStore.notFoundMyFetch(false)
	}
	
	reportChange = (e) =>{
		this.props.InfluencerProfileStore.reportFetch(e.target.value);
	}

    
	
    render() {
		const store = this.props.InfluencerProfileStore;
		const instalinkcheck = sessionStorage.getItem('instalink');
		let dateFetch = this.props.InfluencerProfileStore.lastSeen;
		let eventDate = moment(dateFetch).format('DD/MM/YYYY HH:mm:ss');
		let todayDate = moment().format('DD/MM/YYYY HH:mm:ss');
		let lastDate = moment.utc(moment(todayDate,"DD/MM/YYYY HH:mm:ss").diff(moment(eventDate,"DD/MM/YYYY HH:mm:ss"))).format("m")

        return (
            <>	
				<div ref={this.myRef}></div>
				{store.preloader ? 
				<section className="inf-profile-wpr  after-header">
                    <div className="container-fluid pl-0 pr-0" >
                        <div className="inf-profile-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-7 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div className="profile-img-wpr">
                                            <div className="profile-img">
											    <Skeleton circle={true} height={190} width={190} />
                                            </div>
                                            {/*<span className="heart-wpr">
                                                <i className="fas fa-heart"> </i>
                                            </span>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                        <div className="right-top">
                                            <div className="profile-name">
												<h2><Skeleton width={144} /></h2>
                                                <h3><Skeleton width={144} /></h3>
                                            </div>
                                        </div>
                                        <div className="right-bottom">
                                            <div className="right-bottom-left">
                                                <div className="d-flex">
                                                    <div className="followers">
                                                        <span className="f-num"><Skeleton /></span>
                                                        <span className="f-text"><Skeleton width={100} height={20} /></span>
                                                    </div>
                                                    <div className="post">
                                                        <span className="f-num"><Skeleton /></span>
                                                        <span className="f-text"><Skeleton width={100} height={20} /></span>
                                                    </div>
												</div>
                                                <div className="right-social">
													<Skeleton width={147} height={42} />			
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bg_al">
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
								<div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
								<div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
								<div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
								<div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
								<div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
								<div className="col-12 col-md-4 col-lg-4 mb_30" >
                                    <Skeleton height={250} width={225} />
                                </div>
                            </div>
                        </div>
                    </div>
				</section> :
                <section className="inf-profile-wpr  after-header">
                    <div className="container-fluid pl-0 pr-0" >
                        <div className="inf-profile-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-7 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div className="profile-img-wpr">
                                            <div className="profile-img">
											    {(store.avatarUrl && store.avatarUrl != 'NA') ? <img src={store.avatarUrl} alt="" /> : <img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" />}
                                            </div>
                                            {/*<span className="heart-wpr">
                                                <i className="fas fa-heart"> </i>
                                            </span>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                        <div className="right-top">
                                            <div className="profile-name">
												<h2>{store.fullName}</h2>
                                                <h3>{store.username && '@'+store.username.toString().replace(/@/g, "")}</h3>
                                                <div className="statusnotification-wrapper">
                                                    {store.lastSeen && lastDate > 1 ? <span className="statuelastseen-text">{moment(store.lastSeen).fromNow()}</span> : store.lastSeen == null ? '' : <span className="statusonline-text">Online</span>}
                                                </div>
												{/* <ul><li><em>
													{store.lastSeen && lastDate > 5 ? <span>{moment(store.lastSeen).fromNow()}</span> : store.lastSeen == null ? <span className="statuelastseen-text">A few moment ago</span> : <span className="statusonline-text">online</span>}
												</em></li></ul> */}
                                            </div>
											{store.auth &&
											<div className="report-menu-wpr">
                                                <span className="settings">
                                                    <i className="fas fa-cog"> </i>
                                                </span>
                                                <ul className="report-menu">
												<li><Link to="#"  className="makeofferreset" onClick={()=>[store.enterReportFetch(''),store.errImageFetch(false)]}data-toggle="modal" data-target="#reportModal"><img src={`${process.env.PUBLIC_URL}/images/icons/report.svg`} alt="" />Report the user </Link></li>
												<li><Link to="#" onClick={()=>store.blockPopFetch(true)}  > <img src={`${process.env.PUBLIC_URL}/images/icons/block.svg`} alt="" style={{marginRight: '14px'}} />Block </Link></li>
                                                </ul>
                                            </div>
											}
                                        </div>
                                        <div className="right-bottom">
                                            <div className="right-bottom-left">
                                                <div className="d-flex">
                                                    <div className="followers">
                                                        <span className="f-num"><NumericLabel params={optionnumberformat}>{store.instaFollowersCount}</NumericLabel></span>
                                                        <span className="f-text">Followers</span>
                                                    </div>
                                                    {/* <div className="post">
                                                        <span className="f-num"><NumericLabel params={optionnumberformat}>{store.instaPostCount ? store.instaPostCount : '0'}</NumericLabel></span>
                                                        <span className="f-text">Posts</span>
                                                    </div>*/}
                                                </div>
												
                                                <div className="right-social">
													{store.instaUsername ?
														<a href={`https://www.instagram.com/${store.instaUsername}`} rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i> instagram</a>
													: <Link to="#"><i className="fab fa-instagram"></i> instagram</Link>					
													}			
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bg_al">
                        <div className="nocontend-minheight">
							{store.auth && <>
								{store.instaUsernameOwn || !instalinkcheck || !store.notFoundMy ? '' :
								<div className="instatconnect-alert">
									<p>Connect your account with us so that we can feature you for more campaigns</p>
									<Link to="/profile">Connect</Link>
									<span onClick={this.linkConnectClose}><img src={`${process.env.PUBLIC_URL}/images/alert-close.svg`} alt="" /></span>
								</div> 
							}
							</>
							}
                           {/* <Tabs className="influencerprofile-tab-wrapper">
                                <TabList>
                                    <Tab><h2 className="page-heading text-left">IG Posts</h2></Tab>
									{store.campHide ? '' : <Tab><h2 className="page-heading text-left">Campaigns ({store.userCampCount})</h2></Tab>}
                                </TabList>
                            
                                <TabPanel>
                                <div className="row">
                                    {store.instaPosts.length !== 0 ? store.instaPosts.map((inf,index)=>
                                        <div className="col-12 col-md-4 col-lg-4 mb_30" key={index}>
                                            <div className="inf_content" id="profile-post-number" data-toggle="modal" data-target={`.profileimg${index}`}>
                                                <div className="content-overlay"></div>
                                                <span><img src={`${process.env.PUBLIC_URL}/images/icons/copy.svg`} alt="" /></span>
                                                <img className="inf-image" src={inf.node.display_url} alt=""/>
                                                <div className="content-details fadeIn-bottom">
                                                    <p className="content-text number-post">
                                                        <Link to="#"><img src={`${process.env.PUBLIC_URL}/images/category-icon/heart-hover.svg`} alt="" /><NumericLabel params={optionnumberformat}>{inf.node.edge_media_preview_like.count}</NumericLabel></Link>
                                                        <Link to="#"><i className="fa fa-comment"></i><NumericLabel params={optionnumberformat}>{inf.node.edge_media_to_comment.count}</NumericLabel></Link>
                                                    </p>
                                                </div>*/}
                                                {/* insta profile modal */}
                                                 {/*<div className={`modal fade custom-modal login-modal profileimg${index}`} id="profileimg">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-7 col-lg-7 pr-lg-0">
                                                                    <img className="img-fluid inf-image" src={inf.node.display_url} alt="" />
                                                                    </div>
                                                                    <div className="col-12 col-md-5 col-lg-5 pl-lg-0 pl-md-0">
                                                                        <div className="modal-right-info">
                                                                            <div className="modal-right-text">
                                                                                <p>{inf.node.edge_media_to_caption.edges.length > 0 ? inf.node.edge_media_to_caption.edges[0].node.text : null}</p>
                                                                            </div>
                                                                            <div className="footer-insta-bottom">
																				<div className="col-4 col-md-3 col-lg-3 pl-lg-0">
																					<div className="insta-text"><i className="fa fa-heart-o" aria-hidden="true"></i><NumericLabel params={optionnumberformat}>{inf.node.edge_media_preview_like.count}</NumericLabel></div>
																				</div>
																				<div className="col-4 col-md-3 col-lg-3 pr-lg-0">
																					<div className="insta-text"><i className="fa fa-comment-o" aria-hidden="true"></i><NumericLabel params={optionnumberformat}>{inf.node.edge_media_to_comment.count}</NumericLabel></div>
																				</div>
																				<div className="col-4 col-md-6 col-lg-6 pr-lg-0">
																					<div className="modal-insta-img">
																						<img src={`${process.env.PUBLIC_URL}/images/Instagram_logo.svg`} className="img-fluid" alt="" />
																					</div>
																				</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                {/* insta profile modal */}
                                           {/* </div>
                                        </div>
                                    ): null} 
									{store.notFound &&
										<div className="col-12 mt-5  col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center account-not-linked">
                                            <img src={`${process.env.PUBLIC_URL}/images/no-post.png`} alt=""/>
                                            <h3 className="page-heading text-capitalize">Instagram account not linked</h3>
                                            <p >Please link your Instagram account to make your posts visible.</p> 
									</div> }
									{store.postPrivate &&
										<div className="col-12 mt-5  col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center account-not-linked">
                                            <img src={`${process.env.PUBLIC_URL}/images/private.png`} alt=""/>
                                            <h3 className="page-heading text-capitalize">This Account is Private</h3>
                                            <p>Make your account public so that users can view your work.</p> 
									</div>}
                                </div>
                                </TabPanel>
                                <TabPanel>
								<div className="campaign-page my-campaigns">
									<div className="row feature-list">
										{store.userCamp.length !== 0 ? store.userCamp.map((inf,index)=>
											<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
												<div className="feature-item">
													<div className="feature-image">
														<img src={inf.campaignImage} alt="" />
													</div>
													<div className="feature-details">
														<h3>{inf.campaignTitle}</h3> */}
															{/*<p className="place"> <img src={`${process.env.PUBLIC_URL}/images/feature/location.svg`} alt={inf.campaignTitle} /> {inf.country === 'All' ? 'All Country' : inf.country}</p>*/}
														{/*<h4>{inf.campaignAmountCurrency} {inf.campaignAmount}</h4>
														<p><EllipsisText text={inf.campaignDetails} length={110} /></p>
														<Link to={`${process.env.PUBLIC_URL}/campaigndetail/${inf.id}`}>View Application <img src={`${process.env.PUBLIC_URL}/images/feature/Group 5550@2x.png`} alt="" /></Link>
													</div>
													{inf.campaignStatus === 1?
													<div className="received awaiting">
														 <span className="bookmark-text"><span className="fa fa-clock-o algny"></span> Awaiting Approval </span>
													</div>:(inf.campaignStatus==2)?
													<div className="received">                                   
														<span className="bookmark-text" style={{background:'#689dff'}}><span className="fa fa-clock-o algny"></span> {inf.remarks.length} Applications</span>
													</div>:<div className="received awarded"><span className="bookmark-text"><span className="far fa-check-circle"></span> Awarded</span></div>
													}
												</div>
											</div>) : null}
										</div>
									</div>
                                </TabPanel>
                            </Tabs>*/}
                        </div>
                        </div>
                    </div>
					<div className="payoutSuccess">
					<Popup
					open={store.blockPop}
					closeOnDocumentClick
					onClose={() => store.blockPopFetch(false)}
					>
					<div className="custom-popup text-center logoutmodel-popup">
						<div className="popup-details">
							<img className="mb-4" src={`${process.env.PUBLIC_URL}/images/exit-imgae.svg`} alt="" /> 
							<p className="popup-details mb-4">Are you sure you want to Block?</p>
							<button onClick={()=>store.blockPopFetch(false)} className="btn cancel-btn btn-secondary mr-3">No</button> 
							<button onClick={()=> store.BlockUser(store.blockUserID)} className="btn btn-primary">Yes {store.blockLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
						</div>
						
					</div>
					</Popup>
					</div>
					{store.reportDoneMsg ?
						<div className="modal fade custom-modal email-sent" id="emailSentModal" style={{display:'block',opacity:'1'}}>
							<div className="modal-dialog modal-dialog-centered">
							  <div className="modal-content">
								<div className="modal-body text-center">
									<h2 className="modal-heading">Congratulations!</h2>
									<p>Reported Successfully</p>
									<button className="btn btn-primary" onClick={() => store.reportDoneMsgFetch(false)} data-dismiss="modal">Ok</button>
								</div>
							  </div>
							</div>
						</div> :
						<div className="modal fade custom-modal report-modal" id="reportModal">
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-body ">
										<h2 className="modal-heading">Report</h2>
										<p className="get-det">Get details about a specific abuse report</p>
										<form onSubmit={store.reportSubmit} method="post"> 
											<div className="form-group">
												<label htmlFor="comment" className="form-modal-heading">Comment</label>
												<textarea className="form-control offeramountreset" rows="5" id="comment" onChange={this.reportChange} placeholder="Enter Your Comment"></textarea>
												<span className="text-danger">{store.enterReport && store.enterReport}</span>
											</div>
											<input type="hidden" name="influencerId" value={this.props.match.params.username} />
											<div className="form-group">
												<label htmlFor="comment" className="form-modal-heading">Image (Optional) {store.imageLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</label>
												<p className="form-control report"><FileBase64 onDone={ store.getFiles } /></p>
												{store.errImage && <span className="text-danger">The maximum allowed size for uploads is 2mb. Only image with the following extensions are allowed: jpg, jpeg, png.</span>}
											</div>
											<button type="submit" disabled={store.imageLoader} className="btn btn-primary w-100" >Report {store.loader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}</button>
										</form>
									</div>
								</div>
							</div>
						</div>}
				</section>}
            </>
        )
    }
}


export default inject('InfluencerProfileStore')(observer(InfluencerProfile));
