import React from 'react';

class Download extends React.Component{
	
	render(){
		return (
			<React.Fragment>
			{/* Download Section Start */}
				<section className="contaier-fluid download-section extra-header-below">
					<div className="container">
						<div className="row">
							<div className="col mob-wpr col-lg-6 col-md-12 col-12">
								<img className="img1" src={`${process.env.PUBLIC_URL}/images/Ellipse 28.svg`} alt="" />
								<img className="img2" src={`${process.env.PUBLIC_URL}/images/Ellipse 29.svg`} alt="" />
								<img className="img3" src={`${process.env.PUBLIC_URL}/images/Ellipse 30.svg`} alt="" />
								<img className="img4" src={`${process.env.PUBLIC_URL}/images/iPhone X Face.png`} alt="" />
							</div>
							<div className="col col-lg-6 col-md-12 col-12 ">
								<div className="tips-wpr">
									<h3>Download Our App Now</h3>
									<p>Get accessed o thousands of influencers on the mobile app. As a marketer, you can post a job to hire new social media micro influencers. As an influencer, you can apply for jobs to start campaigning and posting for other brands.</p>
									<div className="download-wpr mb_nonne">
										<a href="https://apps.apple.com/sg/app/koli/id1458269685" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/applestore.svg`} alt="applestore" /></a>
										<a href="https://play.google.com/store/apps/details?id=co.koli.android&hl=en" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/googleplay.svg`} alt="googleplay" /></a>
									</div>
									<div className="download-wpr show_mbl">
										<a href="https://koli.page.link/mobile_app" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/applestore.svg`} alt="applestore" /></a>
										<a href="https://koli.page.link/mobile_app" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/googleplay.svg`} alt="googleplay" /></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* Download Section End */}
			</React.Fragment>
		)
	}
}

export default Download;