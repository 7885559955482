import {action, observable, decorate} from 'mobx';
import { campaignListResult } from '../api/CampaignListApi';
import moment from 'moment';

class FeatureListStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	campaign = [];
	preloader = true;
	sponsoredCampaign = [];
	shoutoutCampaign = [];
	currentDate = moment().format('YYYY-MM-DD');
	
	featuredCampaign = () => {
		let param1 = {include:['remarks'],where:{campaignStatus:{gt: 1},isDisabled:0,campaignType:'paid',endStoryPostDate: {gte: this.currentDate}},order:"featurePosition ASC",limit:10}
		campaignListResult(JSON.stringify(param1))
			.then(res => { 
				this.campaignFetch(res.data);
			}).catch(error=>console.log(error))
	}

	
	campaignFetch = (data) => {
		this.campaign = data;
	}


	

	featureSponsoredCampaign = () => {
		this.preloaderFetch(true)
		let param2 = {include:['remarks'],where:{campaignStatus:{gt: 1},isDisabled:0,campaignType:'sponsored',endStoryPostDate: {gte: this.currentDate}},order:"featurePosition ASC",limit:10}
		campaignListResult(JSON.stringify(param2))
			.then(res => {
				this.sponsoredCampaignFetch(res.data);
					this.preloaderFetch(false)
			}).catch(error=>console.log(error))
	}

	sponsoredCampaignFetch = (data) => {
		this.sponsoredCampaign = data;
	}


    featureShoutoutCampaign = () => {
    	this.preloaderFetch(true)
		let param3 = {include:['remarks','profile'],where:{campaignStatus:{gt: 1},isDisabled:0,campaignType:'shoutout',endStoryPostDate: {gte: this.currentDate}},order:"featurePosition ASC",limit:10}
		campaignListResult(JSON.stringify(param3))
			.then(res => {
				this.shoutoutCampaignFetch(res.data);
					this.preloaderFetch(false)
			}).catch(error=>console.log(error))
	}

	shoutoutCampaignFetch = (data) => {
		this.shoutoutCampaign = data;
	}
	preloaderFetch = (data) => {
		this.preloader = data;
	}
}



decorate(FeatureListStore, {
	campaign: observable,
	preloader: observable,
	sponsoredCampaign: observable,
	shoutoutCampaign: observable,
	currentDate:observable,
	campaignFetch: action,
	preloaderFetch: action,
	sponsoredCampaignFetch: action,
	shoutoutCampaignFetch: action,
});

export default FeatureListStore;