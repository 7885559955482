import React, {Component} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {observer, inject} from 'mobx-react';
import { profileResult } from '../api/ProfileApi';
import { paymentResult } from '../api/CampaignPaymentApi';
import Skeleton from 'react-loading-skeleton';
import { Redirect, Link } from 'react-router-dom';
 
class CheckoutForm extends React.Component {
	
	constructor(props){
		super(props);
		this.state={
			errMessage:'',
			payMessage:false,
			isLoader:false
		}
	}
	
  handleSubmit = async (event) => {
    event.preventDefault();
	const {stripe, elements} = this.props;
   if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      //console.log(result.error.message);
	  this.setState({errMessage:result.error.message})
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
	 // console.log(result.token)
	 this.setState({errMessage:''})
	 this.paymentFetch(result.token.id)
    }
  };
  
  	paymentFetch = (token) =>{
		this.setState({isLoader:true})
		profileResult(this.props.applicantid)
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					let result = JSON.parse(data.message[0].profile);

					const dataParam = {
						amount: this.props.offerAmount,
						name:(result.first ? result.first : '')+ " " +(result.last ? result.last : ''),
						email:result.email, 
						description : 'Stripe Payment for Influencer Hiring',
						stripeToken: token,
						applicantOwnerId: result.ownerId,
						campaignId: this.props.campid,
						ownerId: this.props.ownerId
					}
					paymentResult(dataParam)
						.then(res1 => {
							if(res1.data.paymentStatus === 'succeeded'){
								this.setState({payMessage:true});
								this.setState({errMessage:''});
								this.setState({isLoader:false})
							}else{
								this.setState({errMessage:'Something went wrong. Please try again later!'});
								this.setState({payMessage:false});
								this.setState({isLoader:false})
							}
					}).catch(error=>console.log(error))
					
				}
			}).catch(error=>console.log(error))
		
	}
  
  render() {
    const {stripe} = this.props;
    return (
	<>
	{this.state.payMessage ?
	  <div className="payment-container">
      <section className="cd-header-back after-header"></section>
		<section className="cd-header-details payment-wrapper">
         <div className="container custom-container">
			  <section className="paysuccessful-section">
				<div className="container" >
				  <div className="row">
					  <div className="successful-wrapper">
						<div className="payment-result-mask">
						  <img src="/images/payment-success-check.svg" alt="" className="img-fluid"/>
						  <h2>Your Payment has been Successful.</h2>
							{this.props.applicant.avatarUrl && this.props.applicant.avatarUrl !== 'NA' ?
									<img src={this.props.applicant.avatarUrl} alt="" className="img-fluid" style={{height:'80px',width:'80px',borderRadius: '50%'}} /> 
								:
									<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid" style={{height:'80px',width:'80px',borderRadius: '50%'}} />
							}
						  {this.props.applicant.first || this.props.applicant.last && <h3>{this.props.applicant.first} {this.props.applicant.last}</h3>}
						  <h5>@{this.props.applicant.username ? this.props.applicant.username.toString().replace(/@/g, "") : ''}</h5>
						  <p>Successfully selected by you for <br/> your campaign.</p>
						  <Link to="/my-campaigns" ><button style={{cursor:'pointer'}} className="transaction-btn" type="button">My Campaign</button></Link>
						</div>
					  </div>
				  </div>
				</div>
			  </section>
		</div>
		</section>
		</div>
	:
      <div className="payment-container">
      <section className="cd-header-back after-header"></section>
		{this.props.preloader ? <>
	  
		<section className="cd-header-details payment-wrapper">
         <div className="container custom-container">
               <div className="paymenttop-back">
                <div className="row">
                  <div className="col-6 col-md-10 col-lg-10">
                    <Link className="backbtn" to={`/application-user-list/applicants/${this.props.campid}`}><i className="fa fa-chevron-left"></i> Back</Link>
                  </div>
                  <div className="col-6 col-md-2 col-lg-2">
                    <div className="striptop-lockicon">Secure Checkout</div>
                  </div>
                </div>
               </div>
               
               <div className="cd-header-image">
                  <div className="row">
                     <div className="col-12 col-md-8 col-lg-8">
                        <div className="cd-header-img-wpr">
                          <Skeleton width={790} height={420} />
                        </div>
                        <div className="headerbottom-info">
                          <h2><Skeleton /></h2>
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                              <div clssname="campaign-type-mask">
                                <div className="hashtag-wpr">
                                  <h4 className="payment-tag-title">Campaign Type</h4>
                                  <div className="hashtag-list">
                                    <span><Skeleton /></span>
                                    <span><Skeleton /></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="payableamout-wrapper">
                                <h4>Payable Amount</h4>
                                <p><Skeleton /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="paymentfor-wrapper">
                          <div className="row">
                            <div className="col-6 col-md-5 col-lg-4">
                              <div className="payforuser-avatar">
								<Skeleton circle={true} width={50} height={50} />
                              </div>
                              <div className="payforuser-info">
                                <h2><Skeleton /></h2>
                                <p><Skeleton /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                     </div>
                     <div className="col-12 col-md-4 col-lg-4">
                        <div className="interest-wpr straipayment-wrapper">
                            <Skeleton width={'100%'} height={272} />
                        </div>  
                      
                     </div>
                  </div>  
              
            </div>
          </div>
		</section> </> : <>
	  
	  {this.props.campaign.length !== 0 ? this.props.campaign.map((inf,index) =>
      <section className="cd-header-details payment-wrapper" key={index}>
         <div className="container custom-container">
               <div className="paymenttop-back">
                <div className="row">
                  <div className="col-12 col-md-10 col-lg-10">
                     <Link className="backbtn" to={`/application-user-list/applicants/${this.props.campid}`}><i className="fa fa-chevron-left"></i> Back</Link>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2">
                    <div className="striptop-lockicon">Secure Checkout</div>
                  </div>
                </div>
               </div>
               
               <div className="cd-header-image">
                  <div className="row">
                     <div className="col-12 col-md-8 col-lg-8">
                        <div className="cd-header-img-wpr">
                          <img src={inf.campaignImage} alt="" className="header-banner-wrapper"/>
                        </div>
                        <div className="headerbottom-info">
                          <h2>{inf.campaignTitle}</h2>
                          <div className="row">
						  {inf.campaignCategories.length !== 0 &&
                            <div className="col-12 col-md-6 col-lg-6">
                              <div clssname="campaign-type-mask">
                                <div className="hashtag-wpr">
                                  <h4 className="payment-tag-title">Campaign Type</h4>
                                  <div className="hashtag-list">
                                    {inf.campaignCategories.map((cat,index) =>
										<span key={index}>{cat && cat.charAt(0).toUpperCase() + cat.slice(1)}</span>
									)}
                                  </div>
                                </div>
                              </div>
                            </div>
								}
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="payableamout-wrapper">
                                <h4>Payable Amount</h4>
                                <p>${this.props.offerAmount}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="paymentfor-wrapper">
                          <div className="row">
                            <div className="col-12 col-md-5 col-lg-4">
                              <div className="payforuser-avatar">
								{this.props.applicant.avatarUrl && this.props.applicant.avatarUrl !== 'NA' ?
									<img src={this.props.applicant.avatarUrl} alt="" className="img-fluid" style={{height:'50px',width:'50px',borderRadius: '50%'}} /> 
								:
									<img src={`${process.env.PUBLIC_URL}/images/profile-avatar.svg`} alt="" className="img-fluid" style={{height:'50px',width:'50px',borderRadius: '50%'}} />
								}
                              </div>
                              <div className="payforuser-info">
                                {this.props.applicant.first || this.props.applicant.last && <h2>{this.props.applicant.first} {this.props.applicant.last}</h2>}
                                <p>@{this.props.applicant.username ? this.props.applicant.username.toString().replace(/@/g, "") : ''}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                     </div>
                     <div className="col-12 col-md-4 col-lg-4">
                        <div className="interest-wpr straipayment-wrapper">
                           {/*<div className="price-wpr">
                              <h4>Price</h4>
                              <p className="price">USD 6</p>
                           </div>
                           <button type="button" className="btn btn-primary w-100 log-in-link">
                            <img src="/images/edit-profile.svg" alt="" className="edit-applied"/> I'm Interested</button>
                            <div className="share-friends-wpr text-center">
                              <h3>Share With Friends</h3>
                              <ul className="cd-social">
                                 <li>
                                    <button aria-label="facebook" className="react-share__ShareButton">
                                    <a href="#/campaigndetail/527">
                                    <i class="fa fa-facebook"></i>
                                    </a>
                                    </button>
                                 </li>
                                 <li>
                                    <button aria-label="linkedin" className="react-share__ShareButton">
                                    <a href="#/campaigndetail/527">
                                    <i className="fab fa-linkedin-in"></i>
                                    </a>
                                    </button>
                                 </li>
                                 <li>
                                    <button aria-label="twitter" className="react-share__ShareButton">
                                    <a href="#/campaigndetail/527">
                                    <i className="fab fa-twitter"></i>
                                    </a>
                                    </button>
                                 </li>
                              </ul>
                            </div>*/}
                            <form onSubmit={this.handleSubmit} className="cardetails-wrapper">
                              <div className="cartaccept-type">
                                <div className="cartaccept-type-text">Payment Details</div>
                                <div className="cartaccept-type-card">
                                  <img src={`${process.env.PUBLIC_URL}/images/all-cards.png`} alt="" className="img-fluid payment-cards"/>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputemail">Name on Card</label>
                                <input type="text" className="form-control" id="exampleInputemail" aria-describedby="name" placeholder="Name on Card"/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputemail">Card Number</label>
                                <CardElement type="text" className="form-control" id="exampleInputemail" aria-describedby="name" placeholder="Name"/>
                              </div>
							  <p className="text-danger">{this.state.errMessage && this.state.errMessage}</p>
                              <div className="row">
                                <div className="col-6 col-md-6 col-lg-6">
                                  <button type="submit" style={{cursor: 'pointer'}} disabled={!stripe} className="payviastrip-btn">
                                    Pay Now {this.state.isLoader && <i className="fa fa-spinner fa-spin" style={{fontSize:'14px'}}></i>}
                                  </button>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                  <img src={`${process.env.PUBLIC_URL}/images/powardbystripe.svg`} alt="" className="img-fluid payment-masterimg"/>
                                </div>
                              </div>
                             
                            </form> 
                        </div>  
                      
                     </div>
                  </div>  
              
            </div>
          </div>
		</section> 
	  ) : null }</>}
      </div> 
	}</>
    );
  }
}

class InjectedCheckoutForm extends Component {
	constructor(props){
		super(props);
	}
  render() {
    return (
		<ElementsConsumer>
			{({stripe, elements, campid}) => (
			  <CheckoutForm stripe={stripe} elements={elements} preloader={this.props.preloader} campaign={this.props.campaign} applicant={this.props.applicant} ownerId={this.props.ownerId} campid={this.props.campid} applicantid={this.props.applicantid} offerAmount={this.props.offerAmount} />
			)}
		</ElementsConsumer>
	);
  }
}
 
const stripePromise = loadStripe('pk_test_kYxI1uwoMn3Nesv2MeSIu3n200nwgmf3wA');

class Payment extends Component {
	constructor(props){
		super(props);
		this.myRef = React.createRef()
		this.campid = this.props.match.params.campid
		this.applicantid = this.props.match.params.userid
	}
	
	componentDidMount = () =>{
		this.props.CampaignDetailStore.campaignDetailFetch(this.campid)
		this.props.CampaignDetailStore.applicantFetch(this.applicantid)
		this.props.CampaignDetailStore.remarkCheckOfferFetch(this.campid,this.applicantid)
	}
	
  render() {
	const store = this.props.CampaignDetailStore;
    return (
	<>
	{store.userId ? 
		<Elements stripe={stripePromise}>
			<InjectedCheckoutForm offerAmount={store.offerAmount} campaign={store.campaign} preloader={store.preloader} applicant={store.applicantPay} ownerId={store.userId} campid={this.campid} applicantid={this.applicantid} />
		</Elements>
	: <Redirect to="/" />} </>
	);
  }
}
export default inject('CampaignDetailStore')(observer(Payment));