import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { phoneCodeResult } from '../api/GeoPhoneCodeApi';
import { profileResult } from '../api/ProfileApi';
import { countriesResult } from '../api/CountryApi';
import { profileUpdateResult } from '../api/ProfileupdateApi';
import { profileImageResult } from '../api/ProfileimageApi';
import { addAccountResult } from '../api/AccountlinkApi';
import moment from 'moment';
import { interestResult } from '../api/InterestApi';
import { InstagramPost } from '../api/InstagrampostApi';
import { bankUpdateResult } from '../api/bankUpdateApi';
import { bankResult } from '../api/UserBankApi';
import { bankDeleteResult } from '../api/BankDeleteApi';
import { InstagramToken } from '../api/InstagramTokenApi';
import { InstagramData } from '../api/InstagramDataApi';
import { baseUrl } from '../baseUrl';
import { APP_ID, APP_SECRET } from '../instaConfig';
import { userblockedResult } from '../api/UserblockedApi';
import { userUnblockblockedResult } from '../api/UnblockApi';


class EditprofileStore {
	 
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	phoneCode = [];
	auth = JSON.parse(sessionStorage.getItem("user")); 
	userId = JSON.parse(sessionStorage.getItem("userId")); 
	geoPhoneCode = '';
	profile = [];
	blockeduser = [];
	date = '';
	country = [];
	loader = false;
	wentWrong = false;
	checkedItems = {};
	interest = [];
	done = false;
	errImage = false;
	files = '';
	isLoader = false;
	avatarUrl = '';
	instaPop = false;
	instaUsername = '';
	required = '';
	facebookUsername = '';
	instaLoader = false;
	bankloader = false;
	bank = '';
	bankDeletePop = false;
	modalHide = false;
	saving = '';
	current = '';
	checkEmail = '';
	enableDisableMail = false;
	facebookLoader = false;
	tab={
		profileTab : 'nav-link active',
		blockedTab : 'nav-link',
		settingTab : 'nav-link'
	};
	section={
		profile : 'container tab-pane active show',
		blocked : 'container tab-pane fade',
		setting : 'container tab-pane fade'
	};
	unblockCheck = false;
	unblockLoader = false;
	
	fetchUserData = () => {
		profileResult(this.userId)
			.then(res => { 
				const {status,data} = res
				let result = JSON.parse(data.message[0].profile);
				if(status===200 && !data.error ){
					let inreress = []
					let newObj = {}
					for (const value of result.interests) {
						newObj[value] = true	
					}
					this.checkedItemsFetch(newObj);
					this.avatarUrlFetch(result.avatarUrl)
					this.instaUsernameFetch(result.instaUsername)
					this.facebookUsernameFetch(result.facebookUsername)
					this.dateFetch(result.dob ? moment.utc(result.dob).toDate() : this.date)
					this.profileFetch(result)
					
					if(result.emailNotificationSetting === 1){
						this.emailNotifyFetch(true)
					}else if(result.emailNotificationSetting === 0){
						this.emailNotifyFetch(false)
					}
				}
			}).catch(error=>console.log(error))
	}
	
	fetchblockedUserData = () => {
		let blockedData = {include: ["profile"], where:{ownerId:this.userId}}
		userblockedResult(JSON.stringify(blockedData))
			.then(res => { 
			const {status,data} = res
				if(status===200 && !data.error ){
					this.blockUserFetch(res.data)
				}	
				
			}).catch(error=>console.log(error))
	}
	UnblockUser = (blockID) => {
		this.unblockLoaderFetch(true)
		userUnblockblockedResult(blockID)
			.then(res => { 
			const {status,data} = res
				if(status===200 && !data.error ){
					let blockedData = {include: ["profile"], where:{ownerId:this.userId}}
					userblockedResult(JSON.stringify(blockedData))
					.then(res => { 
					const {status,data} = res
						if(status===200 && !data.error ){
							this.blockUserFetch(res.data)
							this.unblockLoaderFetch(false)
						}
					}).catch(error=>console.log(error))
				}	
				
			}).catch(error=>console.log(error))
	}

	bankFetchData = () =>{	
		let bankUser = {where:{ownerId:this.userId}}
		bankResult(JSON.stringify(bankUser))
		.then(res => {
			if(res.data.length > 0){
				this.bankFetch(res.data[0])
				if(res.data[0].accountType === 'saving'){
					this.savingFetch(true)
					this.currentFetch(false)
				}else{
					this.currentFetch(true)
					this.savingFetch(false)
				}
			}else{
				this.bankFetch('')
			}
		}).catch(error=>console.log(error))
	}
	
	fetchCountries = () => {
		countriesResult()
			.then(res => {
				const {status,data} = res
				if(status===200 && !data.error ){
					this.countryFetch(res.data);
					let count = [];
					res.data.sort(function(a,b){
						count.push(b.phonePrefix)
					});
					this.phoneCodeFetch([...new Set(count.sort())]);
				}	
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	fetchGeoPhoneCode = () => {
		phoneCodeResult()
			.then(res => {
				this.geoPhoneCodeFetch(res.data.country_calling_code);
			}).catch(error=>console.log('error  ',JSON.stringify(error)))
	}
	
	fetchInterest = () => {
		interestResult()
			.then(res => {
				this.interestFetch(res.data.value);
			}).catch(error=>console.log('error  ',JSON.stringify(error)));
	}
	
	handleChangeInterest = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const {checkedItems} = this
		checkedItems[item] = isChecked
		this.checkedItemsFetch(checkedItems);
			let inreress = []
			for (let [key, value] of Object.entries(this.checkedItems)){
				if(value)
				inreress.push(key)
			}
			let profileUpdate = {
				interests: inreress,
			}

			profileUpdateResult(this.userId,profileUpdate)
				.then(res => {
					const {status,data} = res
				}).catch(error=>console.log('error  ',JSON.stringify(error)))
		
	}
	
	handleSubmit = (data,e) => {
		this.loaderFetch(true)
		let profileUpdate;
		if(this.date){
			profileUpdate = {
				first: data.first,
				last: data.last,
				countryCode: data.countryCode,
				mobile: data.mobile,
				gender: data.gender,
				dob: this.date,
				country:data.country,
				bio:data.bio,
				instaPerPost:data.instaPerPost,
				facebookPerPost:data.facebookPerPost
			}
		}else{
			profileUpdate = {
				first: data.first,
				last: data.last,
				countryCode: data.countryCode,
				mobile: data.mobile,
				gender: data.gender,
				country:data.country,
				bio:data.bio,
				dob: null,
				instaPerPost:data.instaPerPost,
				facebookPerPost:data.facebookPerPost
			}
		}
		
		profileUpdateResult(this.userId,profileUpdate)
			.then(res => {
				let result = res.data.message.profile;
				this.profileFetch(result)
				sessionStorage.setItem("user", JSON.stringify(result));
				this.loaderFetch(false)
				this.doneFetch(true)
			}).catch(error=>console.log(error))
	}
	
	removeInstaAccount = () => {
		this.instaLoaderFetch(true)
		let profileUpdate = {
			instaUsername:'',
			followers:''
		}
		profileUpdateResult(this.userId,profileUpdate)
			.then(res => {
				this.instaUsernameFetch('');
				this.instaLoaderFetch(false)
			}).catch(error=>console.log(error))
	}
	
	removeFacebookAccount = () => {
		this.facebookLoaderFetch(true)
		let profileUpdate = {
			facebookUsername:''
		}
		profileUpdateResult(this.userId,profileUpdate)
			.then(res => {
				this.facebookUsernameFetch('');
				this.facebookLoaderFetch(false)
			}).catch(error=>console.log(error))
	}
	
	changeDate = data => {
		this.date = data
	}
	
	getFiles = (files) => {
		let size = files.size;
		let sizeSplit = size.split(" ");
		let image1 = ["image/jpg", "image/jpeg", "image/png"];

		if(image1.find(element => element === files.type)){ 
			if(sizeSplit[0] < 10 && sizeSplit[1] === 'kB'){
				this.filesFetch(files);
				this.isLoaderFetch(true);
				let profileUpdate = {
					base64ImageData: this.files.base64,
				}
				profileImageResult(profileUpdate)
					.then(res => {
						const {status,data} = res
						if(status===200 && !data.error ){
							let picUpdate = {
								avatarUrl: res.data.path,
								coverImageUrl: res.data.path
							}
							profileUpdateResult(this.userId,picUpdate)
								.then(res1 => {
									if(res1.status===200 ){
										this.avatarUrlFetch(res1.data.message.profile.avatarUrl);
										this.isLoaderFetch(false);
									} 
								}).catch(error=>console.log(error))
						}
					}).catch(error=>console.log(error))
			}else{
				this.errImageFetch(true);
			}
		}else{
			this.errImageFetch(true);
		}
	}
	
	addAccountInsta = (data,e) => {
		this.requiredFetch('')
		this.instaLoaderFetch(true)
		if(data.instaUsername){
			InstagramPost(data.instaUsername)
				.then(res => {
					if(res){
						let instaUser = {
							instaUsername: data.instaUsername,
							followers: res.data.graphql.user.edge_followed_by.count 
						}

						profileUpdateResult(this.userId,instaUser)
							.then(res1 => {
								this.instaUsernameFetch(res1.data.message.profile.instaUsername)
								this.facebookUsernameFetch(res1.data.message.profile.facebookUsername)
								this.instaLoaderFetch(false)
								this.instaPopFetch(false)
							}).catch(error=>[this.requiredFetch('Please check if you have entered the correct details or try again.'),this.instaLoaderFetch(false)])
					}else{
						this.requiredFetch('Please check if you have entered the correct details or try again.');
						this.instaLoaderFetch(false)
					}
				}).catch(error=>[this.requiredFetch('Please check if you have entered the correct details or try again.'),this.instaLoaderFetch(false)])
		}else{
			this.requiredFetch('Please enter username.')
			this.instaLoaderFetch(false)
		}
	}
	
	responseInstagram = (response) => {
		let appId = APP_ID
		let appSecret = APP_SECRET
		let redirectUrl = baseUrl
		let code = response

		let formData = new FormData();
		formData.append('client_id', appId);
		formData.append('client_secret', appSecret);
		formData.append('grant_type', 'authorization_code');
		formData.append('redirect_uri', redirectUrl);
		formData.append('code', code);
        
		InstagramToken(formData)
			.then(res => {
			let access_token = res.data.access_token
			InstagramData(access_token)
				.then(res1 => {
					let instaUser = {
						instaUsername: res1.data.username
					}

					profileUpdateResult(this.userId,instaUser)
						.then(res2 => {
							this.instaUsernameFetch(res2.data.message.profile.instaUsername)
							profileResult(this.userId)
								.then(res3 => {
									const {status,data} = res3
									let result = JSON.parse(res3.data.message[0].profile);
									if(status===200 && !res3.data.error ){
										this.instaUsernameFetch(result.instaUsername)
										
										if(result.instaUsername){
											InstagramPost(result.instaUsername)
												.then(res4 => {
													if(res4.data.graphql){
														let result1 = res4.data.graphql.user;
														let instaUser1 = {
															followers: result1.edge_followed_by.count,
															totalPosts: result1.edge_owner_to_timeline_media.count
														}

														profileUpdateResult(this.userId,instaUser1)
															.then(res5 => {
																//
															}).catch(error=>console.log(error))
													}
												}).catch(error=>console.log(error))
										}
									}
								}).catch(error=>console.log(error))
						}).catch(error=>console.log(error))
				}).catch(error=>console.log(error))
			}).catch(error=>console.log(error))
	}
	
	emailNotifySetting = () => {
		this.enableDisableMailFetch(true)
	}
	
	submitEmailSetting = () => {
		this.bankloaderFetch(true)
		let checkedEmail = this.emailNotify
		let inreress;
		if(this.emailNotify === true){
			inreress = 0;
		}else{
			inreress = 1;
		}
		
		let profileUpdate = {emailNotificationSetting:inreress}

		profileUpdateResult(this.userId,profileUpdate)
			.then(res => {
				let result = res.data.message.profile;
				if(result.emailNotificationSetting === 1){
					this.emailNotifyFetch(true)
					this.enableDisableMailFetch(false)
					this.bankloaderFetch(false)
				}else if(result.emailNotificationSetting === 0){
					this.emailNotifyFetch(false)
					this.enableDisableMailFetch(false)
					this.bankloaderFetch(false)
				}
			}).catch(error=>console.log(error))
	}		
	
	bankSubmit = (data,e) => {
		this.bankloaderFetch(true)
		let bankUpdate;
		if(data.bankId){
			bankUpdate = {
				ownerId: this.userId,
				accountType: data.accountType,
				bankName: data.bankName,
				accountNumber: data.accountNumber,
				accountHolderName: data.accountHolderName,
				swiftCode:data.swiftCode,
				rountingNumber:data.rountingNumber,
				bankAddress:data.bankAddress,
				id:data.bankId
			}
		}else{
			bankUpdate = {
				ownerId: this.userId,
				accountType: data.accountType,
				bankName: data.bankName,
				accountNumber: data.accountNumber,
				accountHolderName: data.accountHolderName,
				swiftCode:data.swiftCode,
				rountingNumber:data.rountingNumber,
				bankAddress:data.bankAddress
			}
		}

		bankUpdateResult(bankUpdate)
			.then(res => {
				if(res.data){
					this.bankFetch(res.data)
					if(res.data.accountType === 'saving'){
						this.savingFetch(true)
						this.currentFetch(false)
					}else{
						this.currentFetch(true)
						this.savingFetch(false)
					}
					this.modalHideFetch(false)
					this.bankloaderFetch(false)
				}else{
					this.bankFetch('')
					this.modalHideFetch(false)
					this.bankloaderFetch(false)
				}
			}).catch(error=>console.log(error))
	}
	
	bankDelete = () =>{
		this.bankloaderFetch(true)
		let bankUser = {where:{ownerId:this.userId}}
			bankResult(JSON.stringify(bankUser))
			.then(res => {
				if(res.data.length > 0){
					let bankId = res.data[0].id
					bankDeleteResult(bankId)
					.then(res => {
						if(res.data.count > 0){
							this.bankFetch(res.data[0])
							this.bankloaderFetch(false)
							this.bankDeletePopFetch(false)
						}else{
							this.bankFetch('')
							this.bankloaderFetch(false)
							this.bankDeletePopFetch(false)
						}
					}).catch(error=>console.log(error))
				}else{
					this.bankFetch('')
					this.bankloaderFetch(false)
					this.bankDeletePopFetch(false)
				}
			}).catch(error=>console.log(error))
		
	}
	
	tab={
		profileTab : 'nav-link active',
		blockedTab : 'nav-link',
		settingTab : 'nav-link'
	};
	section={
		profile : 'container tab-pane active show',
		blocked : 'container tab-pane fade',
		setting : 'container tab-pane fade'
	};
	
	tabChange = (tab) =>{
		if(tab == 'profile'){
			this.tab.profileTab = 'nav-link active';
			this.tab.blockedTab = 'nav-link';
			this.tab.settingTab = 'nav-link';
			this.section.profile = 'container tab-pane active show';
			this.section.blocked = 'container tab-pane fade';
			this.section.setting = 'container tab-pane fade';
		}else if(tab == 'blocked'){
			this.tab.profileTab = 'nav-link';
			this.tab.blockedTab = 'nav-link active';
			this.tab.settingTab = 'nav-link';
			this.section.profile = 'container tab-pane';
			this.section.blocked = 'container tab-pane fade active show';
			this.section.setting = 'container tab-pane fade';
		}else{
			this.tab.profileTab = 'nav-link';
			this.tab.blockedTab = 'nav-link';
			this.tab.settingTab = 'nav-link active';
			this.section.profile = 'container tab-pane';
			this.section.blocked = 'container tab-pane fade';
			this.section.setting = 'container tab-pane fade active show';
		}
	}
	
	responseFacebook = (response) => {
		if(response){
			this.facebookLoaderFetch(true)
				if(response.name){
					let facebookUser = {
						facebookUsername: response.name,
					}

					profileUpdateResult(this.userId,facebookUser)
						.then(res1 => {
							this.instaUsernameFetch(res1.data.message.profile.instaUsername)
							this.facebookUsernameFetch(res1.data.message.profile.facebookUsername)
							this.facebookLoaderFetch(false)
						}).catch(error=>[this.requiredFetch('Something went wrong. Please try again later!'),this.facebookLoaderFetch(false)])
				}else{
					this.requiredFetch('Something went wrong. Please try again later!');
					this.facebookLoaderFetch(false)
				}
		}else{
			this.facebookLoaderFetch(false)
		}
	}
	
	phoneCodeFetch 		= (data) => {this.phoneCode = data;}
	geoPhoneCodeFetch 	= (data) => { this.geoPhoneCode = data; }
	profileFetch 		= (data) => { this.profile = data; }
	blockUserFetch 		= (data) => { this.blockeduser = data; }
	dateFetch 			= (data) => { this.date = data; }
	countryFetch 		= (data) => { this.country = data; }
	loaderFetch 		= (data) => { this.loader = data; }
	wentWrongFetch 		= (data) => { this.wentWrong = data; }
	checkedItemsFetch 	= (data) => { this.checkedItems = data; }
	interestFetch 		= (data) => { this.interest = data; }
	doneFetch 			= (data) => { this.done = data; }
	errImageFetch 		= (data) => { this.errImage = data; }
	filesFetch 			= (data) => { this.files = data; }
	isLoaderFetch 		= (data) => { this.isLoader = data; }
	avatarUrlFetch 		= (data) => { this.avatarUrl = data; }
	instaPopFetch 		= (data) => { this.instaPop = data; }
	instaUsernameFetch 	= (data) => { this.instaUsername = data; }
	requiredFetch 		= (data) => { this.required = data; }
	facebookUsernameFetch 	= (data) => { this.facebookUsername = data; }
	instaLoaderFetch 		= (data) => { this.instaLoader = data; }
	emailNotifyFetch 		= (data) => { this.emailNotify = data; }
	bankloaderFetch 		= (data) => { this.bankloader = data; }
	bankFetch 				= (data) => { this.bank = data; }
	bankDeletePopFetch 		= (data) => { this.bankDeletePop = data; }
	modalHideFetch 			= (data) => { this.modalHide = data; }
	savingFetch 			= (data) => { this.saving = data; }
	currentFetch 			= (data) => { this.current = data; }
	enableDisableMailFetch  = (data) => { this.enableDisableMail = data; }
	facebookLoaderFetch  	= (data) => { this.facebookLoader = data; }
	unblockCheckFetch  		= (data) => { this.unblockCheck = data; }
	unblockLoaderFetch      = (data) => { this.unblockLoader = data; }
}

decorate(EditprofileStore, {
	unblockLoader:observable,unblockCheck:observable,facebookLoader:observable,tab:observable,section:observable,enableDisableMail:observable,checkEmail:observable,current:observable,saving:observable,modalHide:observable,bankDeletePop:observable,bank:observable,bankloader:observable,emailNotify:observable,auth:observable,userId:observable,phoneCode: observable,geoPhoneCode: observable,profile: observable,blockeduser: observable,date: observable,country: observable,loader: observable,wentWrong: observable,checkedItems: observable,interest: observable,done: observable,errImage: observable,files: observable,isLoader: observable,avatarUrl: observable,instaPop: observable,instaUsername: observable,facebookUsername: observable,instaLoader: observable,phoneCodeFetch: action,geoPhoneCodeFetch: action,profileFetch: action,blockUserFetch: action,dateFetch: action,countryFetch: action,loaderFetch: action,wentWrongFetch: action,checkedItemsFetch: action,interestFetch: action,handleChangeInterest: action,handleSubmit: action,changeDate: action,doneFetch: action,getFiles: action,errImageFetch: action,filesFetch: action,isLoaderFetch: action,avatarUrlFetch: action,removeInstaAccount: action,instaPopFetch: action,instaUsernameFetch: action,addAccountInsta: action,requiredFetch: action,facebookUsernameFetch: action,instaLoaderFetch: action,emailNotifyFetch:action,bankSubmit:action,emailNotifySetting:action,bankloaderFetch:action,bankFetch:action,bankDeletePopFetch:action,bankDelete:action,modalHideFetch:action,savingFetch:action,currentFetch:action,submitEmailSetting:action,enableDisableMailFetch:action,tabChange:action,responseFacebook:action,removeFacebookAccount:action,facebookLoaderFetch:action,responseInstagram:action, fetchblockedUserData:action, UnblockUser:action,unblockCheckFetch:action,unblockLoaderFetch:action
});
export const userProfile = createContext(new EditprofileStore());
export default EditprofileStore;