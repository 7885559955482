import {action, observable, decorate} from 'mobx';
import {createContext} from 'react';
import { forgetFetchResult } from '../api/ForgetApi';

class ForgetStore {
	
	constructor(rootStore) {
		this.rootStore = rootStore;
	}
	
	emailNotMsg = false;
	isModalOpen = false;
	isLoader = false;
	emailForget = '';
	
	forgetFetchData = (data,e) => {
		this.setIsLoaderFetch(true);
		let userForget = {
			email: data.email
		}
		forgetFetchResult(userForget)
			.then(res => {
				let dataemail = JSON.parse(res.config.data)
				if(res.status === 204){
					this.setEmailForget(dataemail.email);
					this.isModalOpenFetch(true);
					this.setIsLoaderFetch(false);
					e.target.reset();
				}
			}).catch(error=>[this.emailNotMsgFetch(true),this.setIsLoaderFetch(false)]) 
	}
	
	setIsLoaderFetch = (data) => {
		this.isLoader = data;
	}
	isModalOpenFetch = (data) => {
		this.isModalOpen = data;
	}
	emailNotMsgFetch = (data) => {
		this.emailNotMsg = data;
	}
	setEmailForget = (data) => {
		this.emailForget = data;
	}
	
}

decorate(ForgetStore, {
	emailNotMsg: observable,
	isModalOpen: observable,
	isLoader: observable,
	emailForget: observable,
	setIsLoaderFetch: action,
	isModalOpenFetch: action,
	emailNotMsgFetch: action,
	forgetFetchData: action,
	setEmailForget: action
});

export const forgetPassword = createContext(new ForgetStore());
export default ForgetStore;